import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logWarn } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class ApiImpersonationMismatchError extends FiguresBaseError {
  constructor(
    readonly message: string,
    public mismatchCompanyId: string | number,
    public impersonatedCompanyId: string | number
  ) {
    super(message);
    Object.setPrototypeOf(this, ApiImpersonationMismatchError.prototype);
  }
}

export const handleImpersonationMismatchError = (
  ctx: AppContext,
  res: NextApiResponse,
  error: ApiImpersonationMismatchError
) => {
  logWarn(ctx, "[error] Impersonation Mismatch", { error });

  res.redirect(
    `/impersonation-mismatch?impersonatedCompanyId=${error.impersonatedCompanyId}&mismatchCompanyId=${error.mismatchCompanyId}`
  );

  res.status(HttpStatus.FORBIDDEN).json({
    mismatchCompanyId: error.mismatchCompanyId,
    impersonatedCompanyId: error.impersonatedCompanyId,
    ...makeErrorPayload({ error, defaultCode: ErrorCode.IMPERSONATION_MISMATCH }),
  });
};
