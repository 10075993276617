import { AdditionalFieldNature, ExternalEmployeeStatus, Gender, type Prisma } from "@prisma/client";
import { map, mapSeries } from "bluebird";
import { parseISO } from "date-fns";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { compact, get, orderBy } from "~/lib/lodash";
import { and } from "~/lib/prismaHelpers";
import { assertNotNil, getKeys } from "~/lib/utils";
import {
  baseEmptyFilters,
  type FilterOptionsInput,
  isDateRangeAdditionalKey,
  isNumberRangeAdditionalKey,
  isPercentageRangeAdditionalKey,
  isStringAdditionalKey,
} from "~/services/employee-filter";
import { buildUniqueSalaryRangeEmployeeFilterWhereClauses } from "~/services/employee-filter/buildUniqueSalaryRangeEmployeeFilterWhereClauses";
import { fetchSalaryRangeEmployeeRangePositionings } from "~/services/employee-filter/fetchSalaryRangeEmployeeRangePositionings";
import { formatGender } from "~/services/external-employee/gender";
import { validatePopulationFilters } from "~/services/populations/validatePopulationFilters";

const fetchCompanyPopulations = async (ctx: AppContext, populationId?: number, companyId?: number) => {
  return ctx.prisma.population.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      ...(populationId && { id: { not: populationId } }),
    },
    select: { id: true, name: true },
  });
};

const fetchCompanyExternalEmployeesLocations = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  return ctx.prisma.externalLocation.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      employees: { some: scope },
    },
    select: {
      _count: {
        select: {
          employees: { where: scope },
        },
      },
      id: true,
      name: true,
      country: {
        select: {
          id: true,
          name: true,
          alpha2: true,
        },
      },
    },
  });
};

const fetchCompanyExternalEmployeesLevels = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  return ctx.prisma.externalLevel.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      employees: { some: scope },
    },
    select: {
      _count: {
        select: {
          employees: { where: scope },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesJobs = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  return ctx.prisma.externalJob.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      employees: { some: scope },
    },
    select: {
      _count: {
        select: {
          employees: { where: scope },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesPerformanceReviewRatings = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  return ctx.prisma.performanceReviewRating.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      externalEmployees: {
        some: {
          AND: [scope, { status: { not: ExternalEmployeeStatus.SKIPPED } }],
        },
      },
    },
    select: {
      _count: {
        select: {
          externalEmployees: { where: scope },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesManagers = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  const externalEmployeesByManager = await ctx.prisma.externalEmployee.groupBy({
    by: ["managerExternalEmployeeId"],
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      managerExternalEmployeeId: { not: null },
      AND: [scope],
    },
    _count: {
      _all: true,
    },
  });

  const managers = await ctx.prisma.externalEmployee.findMany({
    where: {
      id: { in: compact(externalEmployeesByManager.map((row) => row.managerExternalEmployeeId)) },
    },
    select: {
      id: true,
      firstName: true,
      lastName: true,
    },
  });

  return managers.map((manager) => ({
    id: manager.id,
    firstName: manager.firstName,
    lastName: manager.lastName,
    count: externalEmployeesByManager.find((row) => row.managerExternalEmployeeId === manager.id)?._count._all ?? 0,
  }));
};

const fetchCompanyExternalEmployeesGenders = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  const externalEmployeesByGender = await ctx.prisma.externalEmployee.groupBy({
    by: ["gender"],
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      AND: [scope],
      gender: { not: null },
    },
    _count: {
      _all: true,
    },
  });

  return map(externalEmployeesByGender, (row) => ({
    gender: assertNotNil(row.gender),
    count: row._count._all,
  }));
};

const fetchCompanyExternalEmployeeBusinessUnits = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  const externalEmployees = (await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      businessUnit: { not: null },
      AND: [scope],
    },
    distinct: ["businessUnit"],
    select: {
      businessUnit: true,
    },
  })) as { businessUnit: string }[];

  return map(externalEmployees, async ({ businessUnit }) => {
    const count = await ctx.prisma.externalEmployee.count({
      where: {
        companyId: getRequiredUser(ctx).companyId,
        OR: [{ businessUnit }, { extraBusinessUnits: { has: businessUnit } }],
        AND: [scope],
      },
    });

    return {
      id: businessUnit,
      name: businessUnit,
      count,
    };
  });
};

const fetchCompanyExternalEmployeeRawContractTypes = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  const externalEmployeesByRawContractType = await ctx.prisma.externalEmployee.groupBy({
    by: ["rawContractType"],
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      rawContractType: { not: null },
      AND: [scope],
    },
    _count: {
      _all: true,
    },
  });

  return map(externalEmployeesByRawContractType, (row) => ({
    id: assertNotNil(row.rawContractType),
    name: assertNotNil(row.rawContractType),
    count: row._count._all,
  }));
};

export const fetchCompanyStringAdditionalFields = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput,
  companyId?: number
) => {
  const additionalFields = await ctx.prisma.additionalField.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      nature: AdditionalFieldNature.STRING,
    },
    select: {
      id: true,
      name: true,
    },
  });

  const externalEmployeesByAdditionalField = await ctx.prisma.additionalFieldValue.groupBy({
    by: ["stringValue", "additionalFieldId"],
    where: {
      additionalFieldId: { in: additionalFields.map((field) => field.id) },
      externalEmployee: scope,
    },
    _count: { _all: true },
  });

  return additionalFields.map((field) => ({
    ...field,
    values: externalEmployeesByAdditionalField
      .filter((row) => row.additionalFieldId === field.id)
      .map((row) => ({
        id: row.stringValue,
        name: row.stringValue,
        count: row._count._all,
      })),
  }));
};

export const fetchCompanyRangeAdditionalFields = async (ctx: AppContext, companyId?: number) => {
  return ctx.prisma.additionalField.findMany({
    where: {
      companyId: companyId ?? getRequiredUser(ctx).companyId,
      nature: { in: [AdditionalFieldNature.DATE, AdditionalFieldNature.NUMBER, AdditionalFieldNature.PERCENTAGE] },
      values: {
        some: {},
      },
    },
    select: {
      id: true,
      name: true,
      nature: true,
    },
  });
};

export type FetchExternalEmployeeFilterOptionsParams = {
  scope?: Prisma.ExternalEmployeeWhereInput;
  populationId?: number;
  withAdditionalFields?: boolean;
  companyId?: number;
};

export const fetchExternalEmployeeFilterOptions = async (
  ctx: AppContext,
  params?: FetchExternalEmployeeFilterOptionsParams
) => {
  const user = getRequiredUser(ctx);
  const companyId = params?.companyId ?? user.companyId;
  const scope = and([params?.scope, { status: { not: ExternalEmployeeStatus.SKIPPED } }]);

  const { withAdditionalFields = true } = params ?? {};

  const [
    populations,
    rawLocations,
    rawLevels,
    rawJobs,
    rawGenders,
    additionalFields,
    rangeAdditionalFields,
    businessUnits,
    rawContractTypes,
    rawPerformanceReviewRatings,
    rawManagers,
    rawSalaryRangePositionings,
  ] = await Promise.all([
    fetchCompanyPopulations(ctx, params?.populationId, companyId),
    fetchCompanyExternalEmployeesLocations(ctx, scope, companyId),
    fetchCompanyExternalEmployeesLevels(ctx, scope, companyId),
    fetchCompanyExternalEmployeesJobs(ctx, scope, companyId),
    fetchCompanyExternalEmployeesGenders(ctx, scope, companyId),
    withAdditionalFields ? fetchCompanyStringAdditionalFields(ctx, scope, companyId) : [],
    withAdditionalFields ? fetchCompanyRangeAdditionalFields(ctx, companyId) : [],
    fetchCompanyExternalEmployeeBusinessUnits(ctx, scope, companyId),
    fetchCompanyExternalEmployeeRawContractTypes(ctx, scope, companyId),
    fetchCompanyExternalEmployeesPerformanceReviewRatings(ctx, scope, companyId),
    fetchCompanyExternalEmployeesManagers(ctx, scope, companyId),
    fetchSalaryRangeEmployeeRangePositionings(
      ctx,
      user.company.defaultSalaryGridId,
      {
        externalEmployee: scope,
      },
      companyId
    ),
  ]);

  const locations = rawLocations.map((loc) => ({ ...loc, count: loc._count.employees }));
  const levels = rawLevels.map((level) => ({ ...level, count: level._count.employees }));
  const jobs = rawJobs.map((job) => ({ ...job, count: job._count.employees }));
  const managers = rawManagers.map((manager) => ({
    ...manager,
    name: `${manager.firstName} ${manager.lastName}`,
  }));
  const performanceReviewRatings = rawPerformanceReviewRatings.map((performanceReview) => ({
    id: performanceReview.id,
    name: performanceReview.name,
    count: performanceReview._count.externalEmployees,
  }));
  const genders = rawGenders.map((gender) => ({
    id: gender.gender,
    name: formatGender(ctx.t, gender.gender),
    count: gender.count,
  }));
  const rangePositionings = rawSalaryRangePositionings.map((positioning) => ({
    id: positioning.id,
    name: positioning.name,
    count: positioning.count,
  }));

  return {
    ...baseEmptyFilters,
    populations,
    locations: orderBy(locations, ["count", "name"], ["desc", "asc"]),
    levels: orderBy(levels, ["count", "name"], ["desc", "asc"]),
    jobs: orderBy(jobs, ["count", "name"], ["desc", "asc"]),
    performanceReviewRatings: orderBy(performanceReviewRatings, ["position", "name"], ["asc", "asc"]),
    managers: orderBy(managers, ["count", "lastName", "firstName"], ["desc", "asc", "asc"]),
    genders: orderBy(genders, ["count", "name"], ["desc", "asc"]),
    businessUnits: orderBy(businessUnits, ["count", "name"], ["desc", "asc"]),
    rawContractTypes: orderBy(rawContractTypes, ["count", "name"], ["desc", "asc"]),
    hireDate: null,
    additionalFields,
    rangeAdditionalFields,
    rangePositionings,
  };
};

export const buildExternalEmployeeFilterWhereClauses = async (
  ctx: AppContext,
  selectedFilterOptions: FilterOptionsInput,
  options?: {
    omitPerformanceReviewRating?: boolean;
    omitLiveSalaryRangeEmployee?: boolean;
  }
): Promise<Prisma.ExternalEmployeeWhereInput | null> => {
  const populations = await ctx.prisma.population.findMany({
    where: { companyId: getRequiredUser(ctx).companyId, id: { in: selectedFilterOptions.populations } },
    select: { query: true, filters: true, includedExternalEmployeeIds: true, excludedExternalEmployeeIds: true },
  });

  const populationWhereClauses = await mapSeries(populations, async (population) => {
    const { where } = await convertPopulationToExternalEmployeeWhereClause(ctx, {
      ...population,
      filters: validatePopulationFilters(population.filters),
    });

    return where;
  });

  const additionalFields = getKeys(selectedFilterOptions)
    .filter(isStringAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("additional-field-", "")),
      additionalFieldValues: selectedFilterOptions[key],
    }));

  const numberAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isNumberRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("number-additional-field-", "")),
      min: selectedFilterOptions[key]?.[0] ? Number(selectedFilterOptions[key]?.[0]) : undefined,
      max: selectedFilterOptions[key]?.[1] ? Number(selectedFilterOptions[key]?.[1]) : undefined,
    }));

  const percentageAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isPercentageRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("percentage-additional-field-", "")),
      min: selectedFilterOptions[key]?.[0] ? Number(selectedFilterOptions[key]?.[0]) : undefined,
      max: selectedFilterOptions[key]?.[1] ? Number(selectedFilterOptions[key]?.[1]) : undefined,
    }));

  const dateAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isDateRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("date-additional-field-", "")),
      min: (selectedFilterOptions[key]?.[0] as Date) ?? undefined,
      max: (selectedFilterOptions[key]?.[1] as Date) ?? undefined,
    }));

  const additionalFieldConditions = compact([
    ...additionalFields.map(
      ({ additionalFieldId, additionalFieldValues }) =>
        additionalFieldValues?.length && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              stringValue: { in: additionalFieldValues },
            },
          },
        }
    ),
    ...numberAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              numberValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
    ...percentageAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              percentageValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
    ...dateAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              dateValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
  ]) satisfies Prisma.ExternalEmployeeWhereInput[];

  const salaryRangeEmployeeWhere = !options?.omitLiveSalaryRangeEmployee
    ? buildUniqueSalaryRangeEmployeeFilterWhereClauses(selectedFilterOptions)
    : [];

  const baseWhereClauses = compact([
    !!selectedFilterOptions?.locations?.length && {
      locationId: { in: selectedFilterOptions.locations },
    },
    !!selectedFilterOptions?.jobs?.length && {
      jobId: { in: selectedFilterOptions.jobs },
    },
    !!selectedFilterOptions?.levels?.length && {
      levelId: { in: selectedFilterOptions.levels },
    },
    !options?.omitPerformanceReviewRating &&
      !!selectedFilterOptions?.performanceReviewRatings?.length && {
        performanceReviewRatingId: { in: selectedFilterOptions.performanceReviewRatings },
      },
    !!selectedFilterOptions?.managers?.length && {
      managerExternalEmployeeId: { in: selectedFilterOptions.managers },
    },
    !!selectedFilterOptions?.businessUnits?.length && {
      OR: [
        { businessUnit: { in: selectedFilterOptions.businessUnits } },
        { extraBusinessUnits: { hasSome: selectedFilterOptions.businessUnits } },
      ],
    },
    !!selectedFilterOptions?.rawContractTypes?.length && {
      rawContractType: { in: selectedFilterOptions.rawContractTypes },
    },
    !!selectedFilterOptions.genders?.length && {
      gender: {
        in: compact(selectedFilterOptions.genders.map((rawGender) => get(Gender, rawGender))),
      },
    },
    selectedFilterOptions.hireDate?.length && {
      hireDate: {
        ...(selectedFilterOptions.hireDate?.[0] && {
          gte: parseISO(selectedFilterOptions.hireDate[0]),
        }),
        ...(selectedFilterOptions.hireDate?.[1] && {
          lte: parseISO(selectedFilterOptions.hireDate[1]),
        }),
      },
    },
    salaryRangeEmployeeWhere.length && {
      liveSalaryRangeEmployee: {
        AND: salaryRangeEmployeeWhere,
      },
    },
    ...additionalFieldConditions,
  ]);

  if (baseWhereClauses.length === 0 && populationWhereClauses.length === 0) {
    return null;
  }

  return and([
    baseWhereClauses.length && { AND: baseWhereClauses },
    populationWhereClauses && { OR: populationWhereClauses },
  ]);
};

export const convertPopulationToExternalEmployeeWhereClause = async (
  ctx: AppContext,
  population: {
    query: string | null;
    filters: FilterOptionsInput | null;
    includedExternalEmployeeIds: number[];
    excludedExternalEmployeeIds: number[];
  }
) => {
  const baseWhere = and([
    { companyId: getRequiredUser(ctx).companyId },
    population.query && ({ normalisedColumns: { contains: population.query, mode: "insensitive" } } as const),
  ]) satisfies Prisma.ExternalEmployeeWhereInput;

  const filterWhere = population.filters
    ? await buildExternalEmployeeFilterWhereClauses(ctx, population.filters)
    : undefined;

  const where = and([
    { OR: [and([baseWhere, filterWhere]), { id: { in: population.includedExternalEmployeeIds } }] },
    { NOT: { id: { in: population.excludedExternalEmployeeIds } } },
  ]);

  return { baseWhere, filterWhere, where };
};
