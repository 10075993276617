import { type Company, IntegrationSource, type Prisma } from "@prisma/client";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { type BambooEmployee, getMappedBambooEmployees, getRawBambooEmployees } from "~/lib/hris/bamboo";
import {
  type CompleteCharliehrEmployee,
  getMappedCharliehrEmployees,
  getRawCharliehrEmployees,
} from "~/lib/hris/charliehr";
import {
  type CompleteFactorialEmployee,
  getMappedFactorialEmployees,
  getRawFactorialEmployees,
} from "~/lib/hris/factorial";
import { getAdditionalMonthRulesForSync, getExternalLocationsForSync } from "~/lib/hris/helpers/getNumberOfMonth";
import { type HibobEmployee, getMappedHibobEmployees, getRawHibobEmployees } from "~/lib/hris/hibob";
import {
  type HibobEmployee as HibobDepecratedEmployee,
  getMappedHibobEmployees as getDeprecatedMappedHibobEmployees,
  getRawHibobEmployees as getDeprecatedRawHibobEmployees,
} from "~/lib/hris/hibobDeprecated";
import { type HumaansEmployee, getMappedHumaansEmployees, getRawHumaansEmployees } from "~/lib/hris/humaans";
import { type KomboEmployee, getMappedKomboEmployees, getRawKomboEmployees } from "~/lib/hris/kombo";
import {
  type LuccaEmployeesWithSalariesAndCustomFields,
  getMappedLuccaEmployees,
  getRawLuccaEmployees,
} from "~/lib/hris/lucca";
import { type PersonioApiEmployee, getMappedPersonioEmployees, getRawPersonioEmployees } from "~/lib/hris/personio";
import { type WorkdayEmployee, getMappedWorkdayEmployees, getRawWorkdayEmployees } from "~/lib/hris/workday";
import { isIn } from "~/lib/utils";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

export type IntegrationConfig = Partial<Prisma.IntegrationSettingsUncheckedCreateInput>;

export type StaticModels = AsyncReturnType<typeof getStaticModelsForSync>;

export const getStaticModelsForSync = async (ctx: AppContext) => {
  const countries = await ctx.prisma.country.findMany();
  const currencies = await ctx.prisma.currency.findMany();
  const additionalMonthRules = await getAdditionalMonthRulesForSync(ctx);
  const externalLocations = await getExternalLocationsForSync(ctx, {
    countryIds: additionalMonthRules.map(({ country }) => country.id),
  });

  return {
    countries,
    currencies,
    additionalMonthRules,
    externalLocations,
  };
};

export type GetMappedEmployees = (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
) => Promise<EmployeeData[]>;

export const getMappedEmployees = (source: IntegrationSource): GetMappedEmployees =>
  match(source)
    .with(IntegrationSource.BAMBOO, () => getMappedBambooEmployees)
    .with(IntegrationSource.CHARLIEHR, () => getMappedCharliehrEmployees)
    .with(IntegrationSource.FACTORIAL, () => getMappedFactorialEmployees)
    .with(IntegrationSource.HIBOB, () => getMappedHibobEmployees)
    .with(IntegrationSource.HIBOB_DEPRECATED, () => getDeprecatedMappedHibobEmployees)
    .with(IntegrationSource.HUMAANS, () => getMappedHumaansEmployees)
    .with(IntegrationSource.LUCCA, () => getMappedLuccaEmployees)
    .with(IntegrationSource.KOMBO_PAYFIT, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_NMBRS, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_HIBOB, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_OFFICIENT, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_REMOTE, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_SAGEHR, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_SUCCESSFACTORS, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_AFAS, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_BREATHEHR, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_CATALYSTONE, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_CEZANNEHR, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_DATEV, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_DEEL, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_ENTRAID, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_EURECIA, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_GOOGLEWORKSPACE, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_HAILEYHR, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_HEAVENHR, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_HRWORKS, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_IRISCASCADE, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_KENJO, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_MIRUS, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_OKTA, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_ORACLEHCM, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_PLANDAY, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_SAPLING, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_SESAMEHR, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_MANUAL, () => getMappedKomboEmployees)
    .with(IntegrationSource.KOMBO_FACTORIAL, () => getMappedKomboEmployees)
    .with(IntegrationSource.PERSONIO, () => getMappedPersonioEmployees)
    .with(IntegrationSource.WORKDAY, () => getMappedWorkdayEmployees)
    .exhaustive();

export const HRIS_HANDLED_WITH_KOMBO_SYNC = [
  IntegrationSource.KOMBO_PAYFIT,
  IntegrationSource.KOMBO_NMBRS,
  IntegrationSource.KOMBO_HIBOB,
  IntegrationSource.KOMBO_OFFICIENT,
  IntegrationSource.KOMBO_REMOTE,
  IntegrationSource.KOMBO_SAGEHR,
  IntegrationSource.KOMBO_SUCCESSFACTORS,
  IntegrationSource.KOMBO_AFAS,
  IntegrationSource.KOMBO_BREATHEHR,
  IntegrationSource.KOMBO_FACTORIAL,
  IntegrationSource.KOMBO_CATALYSTONE,
  IntegrationSource.KOMBO_CEZANNEHR,
  IntegrationSource.KOMBO_DATEV,
  IntegrationSource.KOMBO_DEEL,
  IntegrationSource.KOMBO_ENTRAID,
  IntegrationSource.KOMBO_EURECIA,
  IntegrationSource.KOMBO_GOOGLEWORKSPACE,
  IntegrationSource.KOMBO_HAILEYHR,
  IntegrationSource.KOMBO_HEAVENHR,
  IntegrationSource.KOMBO_HRWORKS,
  IntegrationSource.KOMBO_IRISCASCADE,
  IntegrationSource.KOMBO_KENJO,
  IntegrationSource.KOMBO_MIRUS,
  IntegrationSource.KOMBO_OKTA,
  IntegrationSource.KOMBO_ORACLEHCM,
  IntegrationSource.KOMBO_PLANDAY,
  IntegrationSource.KOMBO_SAPLING,
  IntegrationSource.KOMBO_SESAMEHR,
];

export type KomboIntegrations = (typeof HRIS_HANDLED_WITH_KOMBO_SYNC)[number];

export const KOMBO_IDENTIFIER_BY_INTEGRATION_SOURCE: Record<KomboIntegrations, string> = {
  [IntegrationSource.KOMBO_PAYFIT]: "payfit",
  [IntegrationSource.KOMBO_NMBRS]: "nmbrs",
  [IntegrationSource.KOMBO_HIBOB]: "hibob",
  [IntegrationSource.KOMBO_OFFICIENT]: "officient",
  [IntegrationSource.KOMBO_REMOTE]: "remotecom",
  [IntegrationSource.KOMBO_SAGEHR]: "sagehr",
  [IntegrationSource.KOMBO_SUCCESSFACTORS]: "successfactors",
  [IntegrationSource.KOMBO_AFAS]: "afas",
  [IntegrationSource.KOMBO_BREATHEHR]: "breathehr",
  [IntegrationSource.KOMBO_CATALYSTONE]: "catalystone",
  [IntegrationSource.KOMBO_CEZANNEHR]: "cezannehr",
  [IntegrationSource.KOMBO_DATEV]: "datev",
  [IntegrationSource.KOMBO_DEEL]: "deel",
  [IntegrationSource.KOMBO_ENTRAID]: "entraid",
  [IntegrationSource.KOMBO_EURECIA]: "eurecia",
  [IntegrationSource.KOMBO_GOOGLEWORKSPACE]: "googleworkspace",
  [IntegrationSource.KOMBO_HAILEYHR]: "haileyhr",
  [IntegrationSource.KOMBO_HEAVENHR]: "heavenhr",
  [IntegrationSource.KOMBO_HRWORKS]: "hrworks",
  [IntegrationSource.KOMBO_IRISCASCADE]: "iriscascade",
  [IntegrationSource.KOMBO_KENJO]: "kenjo",
  [IntegrationSource.KOMBO_MIRUS]: "mirus",
  [IntegrationSource.KOMBO_OKTA]: "okta",
  [IntegrationSource.KOMBO_ORACLEHCM]: "oraclehcm",
  [IntegrationSource.KOMBO_PLANDAY]: "planday",
  [IntegrationSource.KOMBO_SAPLING]: "sapling",
  [IntegrationSource.KOMBO_SESAMEHR]: "sesamehr",
  [IntegrationSource.KOMBO_FACTORIAL]: "factorial",
};

export const isKomboIntegration = (integrationSource: IntegrationSource) =>
  isIn(integrationSource, [...HRIS_HANDLED_WITH_KOMBO_SYNC, IntegrationSource.KOMBO_MANUAL]);

export type IntegrationDump =
  | CompleteCharliehrEmployee
  | CompleteFactorialEmployee
  | BambooEmployee
  | HibobEmployee
  | HibobDepecratedEmployee
  | HumaansEmployee
  | KomboEmployee
  | PersonioApiEmployee
  | WorkdayEmployee;

type IntegrationDumps = IntegrationDump[] | LuccaEmployeesWithSalariesAndCustomFields;

export type GetRawEmployees = (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
) => Promise<IntegrationDumps>;

export const getRawEmployees = (source: IntegrationSource): GetRawEmployees =>
  match(source)
    .with(IntegrationSource.BAMBOO, () => getRawBambooEmployees)
    .with(IntegrationSource.CHARLIEHR, () => getRawCharliehrEmployees)
    .with(IntegrationSource.FACTORIAL, () => getRawFactorialEmployees)
    .with(IntegrationSource.HIBOB, () => getRawHibobEmployees)
    .with(IntegrationSource.HIBOB_DEPRECATED, () => getDeprecatedRawHibobEmployees)
    .with(IntegrationSource.HUMAANS, () => getRawHumaansEmployees)
    .with(IntegrationSource.LUCCA, () => getRawLuccaEmployees)
    .with(IntegrationSource.KOMBO_PAYFIT, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_NMBRS, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_HIBOB, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_OFFICIENT, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_REMOTE, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_SAGEHR, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_SUCCESSFACTORS, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_AFAS, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_BREATHEHR, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_CATALYSTONE, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_CEZANNEHR, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_DATEV, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_DEEL, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_ENTRAID, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_EURECIA, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_GOOGLEWORKSPACE, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_HAILEYHR, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_HEAVENHR, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_HRWORKS, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_IRISCASCADE, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_KENJO, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_MIRUS, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_OKTA, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_ORACLEHCM, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_PLANDAY, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_SAPLING, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_SESAMEHR, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_FACTORIAL, () => getRawKomboEmployees)
    .with(IntegrationSource.KOMBO_MANUAL, () => getRawKomboEmployees)
    .with(IntegrationSource.PERSONIO, () => getRawPersonioEmployees)
    .with(IntegrationSource.WORKDAY, () => getRawWorkdayEmployees)
    .exhaustive();
