// noinspection JSUnusedGlobalSymbols

import { createTheme } from "@mui/material";
import { UserLocale } from "@prisma/client";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "~/../tailwind.config.js";

export const { theme } = resolveConfig(tailwindConfig);
const { boxShadow, colors, fontFamily, padding, margin } = theme;

declare module "@mui/material/styles" {
  interface PaletteOptions {
    "primary-alt": PaletteOptions["primary"];
    "secondary-alt": PaletteOptions["primary"];
    "gray": PaletteOptions["primary"];
    "white": PaletteOptions["primary"];
    "neutral": PaletteOptions["primary"];
  }

  interface TypeBackground {
    white: string;
    gray: string;
  }

  interface TypographyVariants {
    card: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    card?: React.CSSProperties;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    primary: true;
    secondary: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
    gray: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    "primary-alt": true;
    "secondary-alt": true;
    "gray": true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
    neutral: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h6: false;
    body2: false;
    overline: false;
    button: false;
    // this one is added to maintain all typography and should not be added anymore
    card: true;
  }
}

declare module "@mui/system/createTheme" {
  interface ThemeOptions {
    borderRadius: {
      "2xl": string;
      "3xl": string;
      "DEFAULT": string;
      "full": string;
      "lg": string;
      "md": string;
      "none": string;
      "sm": string;
      "xl": string;
    };
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    "xs": true;
    "sm": true;
    "md": true;
    "lg": true;
    "xl": true;
    "2xl": true;
    "3xl": true;
  }

  interface Theme {
    borderRadius: {
      "2xl": string;
      "3xl": string;
      "DEFAULT": string;
      "full": string;
      "lg": string;
      "md": string;
      "none": string;
      "sm": string;
      "xl": string;
    };
  }

  interface TypeText {
    white: string;
  }
}

const breakpoints = {
  "xs": 0,
  "sm": 0,
  "md": 0,
  "lg": 0,
  "xl": 0,
  "2xl": 0,
  "3xl": 0,
};

for (const [key, value] of Object.entries(theme.screens)) {
  if (typeof value === "string") {
    Object.assign(breakpoints, { [key]: parseInt(value, 10) });
  }
}

export const createFiguresTheme = (locale: UserLocale) => {
  return createTheme({
    breakpoints: {
      values: breakpoints,
    },
    borderRadius: theme.borderRadius,
    spacing: 4,
    palette: {
      "common": {
        black: "#000",
        white: "#fff",
      },
      "background": {
        white: "#fff",
        gray: "#F8FAFA",
      },
      "text": {
        primary: colors.gray[900],
        secondary: colors.gray[500],
        disabled: colors.gray[300],
        white: colors.white,
      },
      "primary": {
        ...colors.primary,
        main: colors.primary[500],
        dark: colors.primary[600],
        light: colors.primary[400],
        contrastText: "#fff",
      },
      "secondary": {
        ...colors.secondary,
        main: colors.secondary[500],
        dark: colors.secondary[600],
        light: colors.secondary[400],
        contrastText: "#fff",
      },
      "info": {
        ...colors.blue,
        main: colors.blue[500],
        dark: colors.blue[600],
        light: colors.blue[400],
        contrastText: "#fff",
      },
      // Primary-alt, secondary-alt and info-alt were added for the Chip component
      "primary-alt": {
        main: colors.primary[100],
        dark: colors.primary[300],
        light: colors.primary[400],
        contrastText: colors.primary[700],
      },
      "secondary-alt": {
        ...colors.secondary,
        main: colors.secondary[100],
        dark: colors.secondary[300],
        light: colors.secondary[400],
        contrastText: colors.secondary[800],
      },
      "gray": {
        ...colors.gray,
        main: colors.gray[500],
        dark: colors.gray[600],
        light: colors.gray[400],
        contrastText: "#fff",
      },
      "neutral": {
        ...colors.gray,
        main: colors.gray[500],
        dark: colors.gray[600],
        light: colors.gray[400],
        contrastText: "#fff",
      },
      "white": {
        main: "#fff",
      },
    },
    typography: {
      fontFamily: fontFamily.sans.join(),
      card: {
        fontSize: "2.125rem",
        lineHeight: "1.235rem",
        letterSpacing: "0px",
        fontWeight: "400",
      },
    },
    // Custom CSS applied on the component level
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardWarning: {
            "color": colors.secondary[900],
            "backgroundColor": colors.secondary[50],
            ".MuiAlert-icon": {
              color: colors.secondary[500],
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          groupLabel: {
            top: "0px",
            backgroundColor: colors.gray[200],
          },
          inputRoot: {
            "&.MuiInputBase-sizeSmall": {
              fontSize: "0.875rem",
              padding: "3px 4px",
            },
            ".MuiAutocomplete-clearIndicator ": {
              marginTop: "3px",
            },
            ".MuiAutocomplete-clearIndicator .MuiSvgIcon-root": {
              fontSize: "0.875rem",
            },
            ".MuiAutocomplete-popupIndicator ": {
              marginTop: "3px",
            },
            ".MuiAutocomplete-popupIndicator .MuiSvgIcon-root": {
              fontSize: "0.875rem",
            },
          },
        },
        defaultProps: {
          size: "small",
        },
      },
      MuiButton: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          sizeSmall: {
            padding: "4px 8px",
          },
          root: {
            "textTransform": "none",
            "whiteSpace": "nowrap",
            "&.MuiButton-containedWhite": {
              "minWidth": "42px",
              "color": colors.gray[700],
              "border": `1px solid ${colors.gray[300]}`,
              "boxShadow": "none",
              "&:hover": {
                borderColor: colors.gray[400],
              },
            },
            "&.MuiButton-containedPrimary.Mui-disabled": {
              color: colors.white,
              opacity: 0.6,
              backgroundColor: colors.primary[500],
            },
            "&.MuiButton-containedSecondary.Mui-disabled": {
              color: colors.white,
              opacity: 0.6,
              backgroundColor: colors.secondary[500],
            },
            "&.MuiButton-containedWhite.Mui-disabled": {
              color: colors.gray[900],
              opacity: 0.6,
              backgroundColor: colors.white,
            },
            "&.MuiButton-sizeMedium .MuiButton-endIcon .MuiSvgIcon-root": {
              fontSize: "1rem",
            },
            "&.MuiButton-sizeMedium .MuiButton-startIcon .MuiSvgIcon-root": {
              fontSize: "1rem",
            },
          },
        },
      },
      MuiCard: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            "padding": `${padding[4]} ${padding[5]}`,
            "&:not(.MuiPaper-outlined)": {
              boxShadow: boxShadow["md"],
            },
          },
        },
      },
      MuiChip: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            ".MuiChip-icon.MuiChip-iconSmall": {
              fontSize: "0.75rem",
              lineHeight: "1rem",
            },
            "&.MuiChip-colorError": {
              "backgroundColor": colors.red[100],
              "color": colors.red[700],
              "&.MuiChip-outlined": {
                borderColor: colors.red[500],
                color: colors.red[500],
              },
            },
            "&.MuiChip-colorInfo": {
              "backgroundColor": colors.blue[100],
              "color": colors.blue[700],
              "&.MuiChip-outlined": {
                borderColor: colors.blue[500],
                color: colors.blue[500],
              },
            },
            "&.MuiChip-colorWarning": {
              "backgroundColor": colors.orange[100],
              "color": colors.orange[700],
              "&.MuiChip-outlined": {
                borderColor: colors.orange[500],
                color: colors.orange[500],
              },
            },
            "&.MuiChip-outlined": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: "small",
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: "small",
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            "backgroundColor": colors.white,
            "&.MuiOutlinedInput-root": {
              fontSize: "0.875rem",
              padding: "3px 8px",
            },
            "&.MuiOutlinedInput-root input": {
              padding: "3px 3px",
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: colors.secondary[600],
            textDecorationColor: colors.secondary[600],
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            padding: "2px 0",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "24px !important",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          secondary: {
            fontSize: "0.75rem",
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          select: {
            padding: "2px 4px",
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            "&.MuiSnackbar-root": {
              "& .MuiSnackbarContent-root": {
                backgroundColor: colors.white,
                color: colors.gray[900],
                borderRadius: theme.borderRadius["lg"],
              },
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            "&.Mui-completed": {
              color: colors.secondary[500],
            },
            "&.Mui-active": {
              color: colors.secondary[500],
            },
          },
        },
      },
      MuiStepContent: {
        styleOverrides: {
          root: {
            "& .MuiCollapse-vertical.MuiCollapse-root": {
              paddingTop: "16px",
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            "backgroundColor": colors.white,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: colors.gray[300],
              top: 0,
            },
            ".MuiOutlinedInput-notchedOutline legend": {
              display: "none !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: colors.gray[500],
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            position: "inherit",
            transform: "none",
            fontSize: "0.75rem",
            lineHeight: "1rem",
            textTransform: "uppercase",
            marginBottom: "1px",
            marginLeft: "5px",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "textTransform": locale === UserLocale.EN ? "capitalize" : "unset",
            "border": `solid 1px ${theme.colors.gray[200]}`,
            "marginRight": "1px",
            "paddingTop": "4px",
            "paddingBottom": "4px",
            "backgroundColor": theme.colors.white,
            "&.Mui-selected.MuiToggleButton-primary": {
              borderColor: colors.primary[500],
            },
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          placement: "top",
        },
        styleOverrides: {
          tooltip: {
            backgroundColor: colors.white,
            border: `1px solid ${colors.gray[100]}`,
            color: colors.gray[800],
            boxShadow: boxShadow["lg"],
            maxWidth: "24rem",
            borderRadius: "8px",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: "1.5rem",
            lineHeight: "1.9rem",
            letterSpaceing: "0px",
            fontWeight: "600",
          },
          h2: {
            fontSize: "1.125rem",
            lineHeight: "1.75rem",
            letterSpaceing: "0px",
            fontWeight: "600",
          },
          h3: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            letterSpaceing: "0px",
            fontWeight: "600",
          },
          h4: {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            letterSpaceing: "0px",
            fontWeight: "600",
          },
          h5: {
            fontWeight: "600",
            fontSize: "0.75rem",
            lineHeight: "1.125rem",
          },
          subtitle1: {
            fontSize: "0.8125rem",
            lineHeight: "1.25rem",
            fontWeight: "500",
            letterSpaceing: "0px",
          },
          subtitle2: {
            fontSize: "0.75rem",
            lineHeight: "1.125rem",
            fontWeight: "500",
            letterSpaceing: "0px",
          },
          body1: {
            fontSize: "0.875rem",
          },
          caption: {
            lineHeight: "1.125rem",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.75rem",
            lineHeight: "1rem",
            textTransform: "uppercase",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "rowGap": "0.25rem",
            "& .MuiFormLabel-root": {
              marginLeft: "5px",
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            "&.MuiFormControlLabel-labelPlacementEnd": {
              marginRight: 0,
            },
            "&.MuiFormControlLabel-labelPlacementEnd .MuiSwitch-root": {
              marginRight: margin[4],
            },
            "&.MuiFormControlLabel-labelPlacementStart": {
              marginLeft: 0,
            },
            "&.MuiFormControlLabel-labelPlacementStart .MuiSwitch-root": {
              marginLeft: margin[4],
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            // This case is to handle FormControlLabel composed with MenuItem mainly inside a Dropdown
            "&.MuiFormControlLabel-root": {
              paddingLeft: padding[2],
            },
            "borderRadius": "0.375rem",
          },
          gutters: {
            margin: margin[1],
            padding: "4px 8px",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "36px",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "fontSize": "0.8125rem",
            "padding": "8px 12px",
            "minHeight": "36px",
            "&": {
              borderRadius: "2px",
            },
          },
        },
      },
    },
  });
};
