import { type IntegrationSettings, type PerformanceReviewIntegrationSettings } from "@prisma/client";
import { map } from "bluebird";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { findUniqueClientSecret } from "~/repositories/ClientSecret";

type IntegrationSettingsForDecryption = Pick<
  IntegrationSettings | PerformanceReviewIntegrationSettings,
  "id" | "encryptedClientSecretId_"
>;
export type DecryptedIntegrationSettings<T extends IntegrationSettingsForDecryption> = T & {
  clientSecret: string;
};

export const decryptIntegrationSettings = async <T extends IntegrationSettingsForDecryption>(
  ctx: AppContext,
  settings: T
) => {
  const decryptedSecret = await findUniqueClientSecret(ctx, {
    where: { id: settings.encryptedClientSecretId_ },
  });

  if (!decryptedSecret) {
    throw new BusinessLogicError("An issue occurred with your HRIS Integration").withErrorCode("F166").withMetadata({
      reason: "No client secret found for integration settings or performance review integration settings",
      integrationSettingsId: settings.id,
      encryptedClientSecretId: settings.encryptedClientSecretId_,
    });
  }

  return { ...settings, clientSecret: decryptedSecret.secret } satisfies DecryptedIntegrationSettings<T>;
};

export const decryptManyIntegrationSettings = async <T extends IntegrationSettingsForDecryption>(
  ctx: AppContext,
  settings: T[]
) => {
  return map(settings, async (s) => decryptIntegrationSettings(ctx, s));
};
