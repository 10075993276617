import cheerio from "cheerio";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";

type Cache = {
  cities: string | null;
  countries: string | null;
};

const cache: Cache = {
  cities: null,
  countries: null,
};

type CostOfLivingResult = {
  name: string;
  value: number;
};

export const getCityCostOfLivingIndex = async (ctx: AppContext, city: string): Promise<CostOfLivingResult | null> => {
  const html = await value(async () => {
    if (cache.cities) {
      return cache.cities as string;
    }

    const htmlRes = await fetch(`https://www.numbeo.com/cost-of-living/rankings_current.jsp?displayColumn=2`);
    const html = await htmlRes.text();

    cache.cities = html;

    return html;
  });

  const $ = cheerio.load(html);

  const nameColumn = $(`td.cityOrCountryInIndicesTable:contains('${city}')`);
  if (!nameColumn.text()) {
    return null;
  }

  const valueColumn = nameColumn.parent().children().last();

  return {
    name: nameColumn.text(),
    value: parseFloat(valueColumn.text()),
  };
};

export const getCountryCostOfLivingIndex = async (
  ctx: AppContext,
  country: string
): Promise<CostOfLivingResult | null> => {
  const html = await value(async () => {
    if (cache.countries) {
      return cache.countries as string;
    }

    const htmlRes = await fetch(
      `https://www.numbeo.com/cost-of-living/rankings_by_country.jsp?title=2021&displayColumn=2`
    );
    const html = await htmlRes.text();

    cache.countries = html;

    return html;
  });

  const $ = cheerio.load(html);

  const nameColumn = $(`td.cityOrCountryInIndicesTable:contains('${country}')`);
  if (!nameColumn) {
    return null;
  }

  const valueColumn = nameColumn.parent().children().last();

  return {
    name: nameColumn.text(),
    value: parseFloat(valueColumn.text()),
  };
};
