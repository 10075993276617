import { type AlertProps } from "@mui/material/Alert/Alert";
import React, { useCallback, useContext, useState } from "react";
import { value } from "~/components/helpers";
import { isNumber } from "~/lib/lodash";

type BaseAlert = Omit<AlertProps, "icon"> & {
  message: string | JSX.Element;
  autoHideDuration?: number | null;
};

export type FiguresAlert = BaseAlert & {
  id: string;
};

type AlertsProps = {
  alerts: FiguresAlert[];
  triggerAlert: (alert: BaseAlert) => void;
  dismissAlert: (alert: Pick<BaseAlert, "id">) => void;
};

const noop = async () => {
  //
};

const AlertsContext = React.createContext<AlertsProps>({
  alerts: [],
  triggerAlert: noop,
  dismissAlert: noop,
});

export const useAlerts = (): AlertsProps => {
  return useContext(AlertsContext);
};

const DELAY_TO_REMOVE_ALERT_IN_MS = 200;
const DEFAULT_DELAY_TO_HIDE_ALERT_IN_MS = 5000;

export const AlertsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [alerts, setAlerts] = useState<FiguresAlert[]>([]);

  const triggerAlert = useCallback(
    (alert: BaseAlert) => {
      const newAlert = {
        id: `${+new Date()}`,
        variant: "filled",
        autoHideDuration: DEFAULT_DELAY_TO_HIDE_ALERT_IN_MS,
        color: value(() => {
          const severity = alert.severity || "success";

          if (severity === "success") return "primary";
          if (severity === "error") return "secondary";

          return severity;
        }),
        ...alert,
      } satisfies FiguresAlert;

      // For now we only keep one alert
      setAlerts([newAlert]);

      if (isNumber(newAlert.autoHideDuration)) {
        setTimeout(() => dismissAlert({ id: newAlert.id }), newAlert.autoHideDuration + DELAY_TO_REMOVE_ALERT_IN_MS);
      }
    },
    [alerts]
  );

  const dismissAlert = useCallback(
    (alert: Pick<BaseAlert, "id">) => {
      const index = alerts.findIndex((item) => {
        return item.id === alert.id;
      });

      if (index === -1) {
        return;
      }

      const newAlerts = [...alerts];
      newAlerts.splice(index, 1);

      setAlerts(newAlerts);
    },
    [alerts]
  );

  return <AlertsContext.Provider value={{ alerts, triggerAlert, dismissAlert }}>{children}</AlertsContext.Provider>;
};
