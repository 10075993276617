import { Stack, Typography } from "@mui/material";
import copyToClipboard from "copy-to-clipboard";
import { type FormikHelpers } from "formik";
import HttpStatus from "http-status-codes";
import { useRouter } from "next/router";
import { type Route } from "nextjs-routes";
import { useCallback } from "react";
import { Button } from "~/components/ui/core/Button";
import { useAlerts } from "~/hooks/useAlerts";
import { useHttpImpersonationHeader } from "~/hooks/useHttpImpersonationHeader";
import { ApiValidationError } from "~/lib/errors/apiValidationError";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { useI18n } from "~/lib/i18n/useI18n";
import { compact, isEmpty, isUndefined } from "~/lib/lodash";
import { ErrorCode } from "~/lib/makeErrorPayload";
import { ImpersonatedCompanyHeader } from "~/lib/session";

export type FetchOptions<Query extends Record<string, unknown>> = {
  method?: "POST" | "GET";
  body?: unknown;
  query?: Query;
  signal?: AbortSignal;
  successMessage?: string;
  setIsLoading?: (isLoading: boolean) => void;
  setErrors?: FormikHelpers<unknown>["setErrors"];
};

export type ApiRoutePath = Route["pathname"] & `/api/${string}`;
export type ApiRoute = Route & { pathname: ApiRoutePath };

export const useApi = <Query extends Record<string, unknown>>() => {
  const router = useRouter();
  const { t } = useI18n();
  const { triggerAlert } = useAlerts();
  const { shouldUseHttpImpersonationHeader, companyId } = useHttpImpersonationHeader();

  const formatErrorMessageWithCode = (params: { message: string; errorCode: string; errorId?: string }) => {
    return (
      <Stack spacing={2}>
        <Stack>
          <Typography>{params.message}</Typography>
          <Typography>Code: {compact([params.errorCode, params.errorId]).join("-")}</Typography>
        </Stack>

        <Stack alignItems="start">
          <Button
            color="white"
            variant="text"
            onClick={() => {
              copyToClipboard(JSON.stringify(params, null, 2));
              triggerAlert({
                severity: "success",
                message: t("common.copied-to-clipboard"),
              });
            }}
          >
            {t("common.copy-to-clipboard")}
          </Button>
        </Stack>
      </Stack>
    );
  };

  const apiFetch = useCallback(
    async <T,>(url: ApiRoutePath, options: FetchOptions<Query> = {}): Promise<T> => {
      const isForm = options.body instanceof FormData;
      let res;
      try {
        if (options.setIsLoading) {
          options.setIsLoading(true);
        }

        const queryParams = buildQueryParams<Query>(options.query);

        const fullEndpoint = `${url}${queryParams}`.replace(/\/\//g, "/");

        res = await fetch(fullEndpoint, {
          method: options.method || "POST",
          headers: {
            "Accept": "application/json",
            ...(!isForm && { "Content-Type": "application/json" }),
            ...(shouldUseHttpImpersonationHeader && { [ImpersonatedCompanyHeader]: `${companyId}` }),
            "Figures-Location": window.location.pathname,
          },
          ...(!!options.body && isForm && { body: options.body as FormData }),
          ...(!!options.body && !isForm && { body: JSON.stringify(options.body) }),
          signal: options.signal,
        });
      } catch (err) {
        // Ignore ABORT_ERR errors, as they are thrown when fetch receives an abort signal from React
        if (!(err instanceof DOMException) || err.code !== err.ABORT_ERR) {
          triggerAlert({
            severity: "error",
            message: formatErrorMessageWithCode({
              message: "An unexpected error occurred. Please try again later.",
              errorCode: ErrorCode.UNEXPECTED,
            }),
          });
        }
        throw err;
      } finally {
        options.setIsLoading?.(false);
      }

      if (res.redirected) {
        window.location.href = res.url;
      }

      // Query failed (non 2XX http status)
      if (!res.ok) {
        switch (res.status) {
          case HttpStatus.TOO_MANY_REQUESTS:
            triggerAlert({
              severity: "error",
              message: formatErrorMessageWithCode({
                message: "Too many requests. Please try again later.",
                errorCode: ErrorCode.TOO_MANY_REQUESTS,
              }),
              autoHideDuration: null,
            });
            throwApiErrorWithStatusCode(
              `Too many requests. Please try again later. [${ErrorCode.TOO_MANY_REQUESTS}]`,
              res.status
            );

          case HttpStatus.UNAUTHORIZED:
            triggerAlert({
              severity: "error",
              message: formatErrorMessageWithCode({
                message: "Unauthorised request. Please sign in again.",
                errorCode: ErrorCode.UNAUTHORISED,
              }),
              autoHideDuration: null,
            });
            throwApiErrorWithStatusCode(
              `Unauthorised request. Please sign in again. [${ErrorCode.UNAUTHORISED}]`,
              res.status
            );

          case HttpStatus.UNPROCESSABLE_ENTITY:
            // The body should contain validation errors from Yup
            const unprocessableBody = await res.json();

            if (options.setErrors) {
              // Pipe errors to formik if the helper is provided
              options.setErrors(unprocessableBody.fields);
            }
            throw new ApiValidationError(unprocessableBody.error, unprocessableBody.fields);

          case HttpStatus.FORBIDDEN:
            const forbiddenBody = await res.json();
            const forbiddenMessage = forbiddenBody?.error ?? "You do not have the rights to perform this operation.";
            const forbiddenId = forbiddenBody?.errorId ?? "ERR_API_FORBIDDEN";
            const forbiddenCode = forbiddenBody?.errorCode ?? ErrorCode.FORBIDDEN;

            triggerAlert({
              severity: "error",
              message: formatErrorMessageWithCode({
                message: forbiddenMessage,
                errorId: forbiddenId,
                errorCode: forbiddenCode,
              }),
              autoHideDuration: null,
            });

            if (forbiddenCode === ErrorCode.IMPERSONATION_FORBIDDEN) {
              window.location.href = `/impersonation-forbidden?impersonatedCompanyId=${forbiddenBody.impersonatedCompanyId}`;
            }

            if (forbiddenCode === ErrorCode.IMPERSONATION_MISMATCH) {
              window.location.href = `/impersonation-mismatch?impersonatedCompanyId=${forbiddenBody.impersonatedCompanyId}&mismatchCompanyId=${forbiddenBody.mismatchCompanyId}`;
            }

            return Promise.reject(forbiddenMessage);

          default:
            const defaultBody = await res.json();
            // Any unhandled status code (including 500) could return a body containing {error}
            const defaultMessage = defaultBody?.error ?? "An unexpected error occurred. Please try again later.";
            const defaultId = defaultBody?.errorId ?? "ERR_API_DEFAULT";
            const defaultCode = defaultBody?.errorCode ?? "FDEF";

            triggerAlert({
              severity: "error",
              message: formatErrorMessageWithCode({
                message: defaultMessage,
                errorId: defaultId,
                errorCode: defaultCode,
              }),
              autoHideDuration: null,
            });
            throwApiErrorWithStatusCode(defaultMessage, res.status);
        }
      }

      // Query succeeded
      // Trigger an alert
      if (options.successMessage) {
        triggerAlert({
          severity: "success",
          message: options.successMessage,
        });
      }

      // Return JSON response
      return res.json();
    },
    [triggerAlert, shouldUseHttpImpersonationHeader, router]
  );

  return { apiFetch };
};

const buildQueryParams = <Query extends Record<string, unknown>>(query: FetchOptions<Query>["query"] | undefined) => {
  if (isEmpty(query)) {
    return "";
  }

  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (isUndefined(value)) {
      return;
    }

    params.append(key, String(value));
  });

  return `?${params}`;
};

const throwApiErrorWithStatusCode = (errorMessage: string, statusCode: number) => {
  const error = new FiguresBaseError(errorMessage) as FiguresBaseError & { status: number };
  error.status = statusCode;
  throw error;
};
