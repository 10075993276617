import { type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";

export const selectSalaryRangeForAutocomplete = {
  id: true,
  min: true,
  max: true,
  midpoint: true,
  level: { select: { id: true, name: true, position: true } },
  band: {
    select: {
      measure: true,
      currency: true,
      job: { select: { id: true, name: true } },
      location: { select: { id: true, name: true } },
    },
  },
} satisfies Prisma.SalaryRangeSelect;

export const fetchSalaryRangesForAutocomplete = async (ctx: AppContext, params: { salaryGridId: number }) => {
  return ctx.prisma.salaryRange.findMany({
    where: {
      band: { grid: whereSalaryGridIs({ salaryGridId: params.salaryGridId }) },
    },
    select: selectSalaryRangeForAutocomplete,
    orderBy: [
      { band: { job: { name: "asc" } } },
      { band: { location: { name: "asc" } } },
      { level: { position: "asc" } },
    ],
  });
};

export type FetchSalaryRangesForAutocompleteResult = AsyncReturnType<typeof fetchSalaryRangesForAutocomplete>;
