import { type Prisma } from "@prisma/client";
import { isArray, isNil, isObject, isPlainObject, map, mapValues } from "~/lib/lodash";
import { prismaSchemaDef, type PrismaPayload } from "~/lib/prismaTypes";
import { isIn } from "~/lib/utils";

/**
 * Called by our Prisma proxy on every Prisma query response to convert Prisma's `BigInt` type to a `Number`.
 */
export const convertBigIntsFromPrisma = (res: unknown) => {
  return mapValuesDeep(res, (value) => {
    if (typeof value === "bigint") {
      return Number(value);
    }

    return value;
  });
};

const mapValuesDeep = (
  object: unknown,
  fn: (value: unknown, key: unknown, baseObject: unknown) => unknown,
  key?: unknown,
  baseObject = object
): unknown => {
  if (isArray(object)) {
    return map(object, (innerObject, index) => mapValuesDeep(innerObject, fn, index));
  }

  if (isPlainObject(object)) {
    return mapValues(object as object, (value, key) => mapValuesDeep(value, fn, key, object));
  }

  if (isObject(object)) {
    return object;
  }

  return fn(object, key, baseObject);
};

/**
 * List of Prisma methods that can be nested on insert/update/connect operations.
 */
const nestedMethods = [
  "create",
  "createMany",
  "update",
  "updateMany",
  "connect",
  "connectOrCreate",
  "upsert",
  "connect",
];

/**
 * Called by our Prisma proxy on every Prisma create/update/connect operation to round `BigInt` fields to `Number`.
 */
export const roundBigIntsForPrisma = (params: { modelName: Prisma.ModelName; data: PrismaPayload }): PrismaPayload => {
  const { modelName, data } = params;

  // If the data is null, return the data as is
  if (isNil(data)) {
    return data;
  }

  // If the data is an empty array, return the data as is
  if (isArray(data) && data.length === 0) {
    return data;
  }

  // If the data is an array, recursively round the fields of each item
  if (isArray(data) && data.length > 0) {
    return data.map((item) => roundBigIntsForPrisma({ modelName, data: item }));
  }

  // Get the properties of the model
  const properties = prismaSchemaDef.definitions[modelName]?.properties ?? {};

  // Map over the data and round the fields of each item
  return mapValues(data, (value, key) => {
    // If the key doesn't exist in the model's properties, it might be a nested operation
    if (!Object.hasOwn(properties, key)) {
      // Check if this is a Prisma nested operation (create, update, etc.)
      if (isIn(key, nestedMethods)) {
        return handleNestedOperation(modelName, value);
      }

      return value;
    }

    // Get property definition and check for references to other models
    const property = properties[key];
    const anyOfItem = property.anyOf?.find((item: Record<string, string>) => !!item?.$ref);
    const reference = property?.$ref || property.items?.$ref || anyOfItem?.$ref;

    // If there's no reference to another model, handle primitive types
    if (!reference) {
      return handleBigIntField(value, property.type);
    }

    // Extract the referenced model name for nested objects
    const correspondingDependencyModel = reference.replace("#/definitions/", "") as keyof typeof Prisma.ModelName;

    return handleRelatedModels(correspondingDependencyModel, value);
  });
};

const handleNestedOperation = (modelName: Prisma.ModelName, value: unknown): PrismaPayload | PrismaPayload[] => {
  if (isArray(value)) {
    return value.map((item) =>
      roundBigIntsForPrisma({
        modelName,
        data: item,
      })
    );
  }

  // Handle nested operations like createMany
  if (isObject(value) && "data" in value) {
    const newValue = { ...value };
    newValue.data = handleNestedOperation(modelName, value.data);
    return newValue;
  }

  // Handle single nested operation (e.g., create, update)
  return roundBigIntsForPrisma({
    modelName,
    data: value as PrismaPayload,
  });
};

const handleBigIntField = (value: unknown, type: string | string[]) => {
  // Don't modify null values
  if (isIn("null", type as string[]) && value === null) {
    return value;
  }

  // In JSON schema, `integer` is a synonym for `bigint`, while `number` can be a float
  // But in JS, `number` can be a float
  const isPropertyTypeBigInt = isIn("integer", type as string[]);
  const isValueBigInt = typeof value === "bigint" || typeof value === "number";

  // Round integer fields (including bigint)
  if (isPropertyTypeBigInt && isValueBigInt) {
    return Math.round(Number(value));
  }

  // Return unmodified value for non-bigint types
  return value;
};

const handleRelatedModels = (correspondingDependencyModel: Prisma.ModelName, value: unknown): PrismaPayload => {
  // Handle single related model (one-to-one or many-to-one relationships)
  return roundBigIntsForPrisma({
    modelName: correspondingDependencyModel,
    data: value as PrismaPayload,
  });
};
