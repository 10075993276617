import { CompensationReviewCampaignStatus } from "@prisma/client";
import { isPast } from "date-fns";
import { ensure } from "~/lib/ensure";
import { resetCampaignRecommendations } from "~/services/compensation-review/campaigns/admin/resetCampaignRecommendations";
import { sendLaunchNotifications } from "~/services/compensation-review/campaigns/notifications/sendLaunchNotifications";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

export const startCampaign = async (ctx: CompensationReviewCampaignContext) => {
  ensure(() => ctx.permissions.canConfigure);

  await resetCampaignRecommendations(ctx);

  await ctx.prisma.compensationReviewCampaign.update({
    where: { id: ctx.campaign.id },
    data: {
      status: CompensationReviewCampaignStatus.IN_PROGRESS,
      highlightedConfigurationSteps: [],
    },
  });

  if (ctx.campaign.launchNotificationEnabled && (!ctx.campaign.startDate || isPast(ctx.campaign.startDate))) {
    await sendLaunchNotifications(ctx);
  }
};
