import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logWarn } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class ApiFeatureAccessError extends FiguresBaseError {
  constructor(readonly message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiFeatureAccessError.prototype);
  }
}

export const handleFeatureAccessError = (ctx: AppContext, res: NextApiResponse, error: ApiFeatureAccessError) => {
  logWarn(ctx, "[error] Invalid feature access", { error });

  res.status(HttpStatus.FORBIDDEN).json(makeErrorPayload({ error, defaultCode: ErrorCode.API_FEATURE_ACCESS }));
};
