import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { deleteCampaign } from "~/services/compensation-review/campaigns/admin/deleteCampaign";

const DeleteCompensationReviewCampaignJobDataSchema = BaseJobDataSchema.concat(
  object({ campaignId: number().required() })
);

export type DeleteCompensationReviewCampaignJobData = YupOutputType<
  typeof DeleteCompensationReviewCampaignJobDataSchema
>;

export const deleteCompensationReviewCampaignWorkerService = async (
  ctx: AppContext,
  data: DeleteCompensationReviewCampaignJobData
) => {
  await deleteCampaign(ctx, { campaignId: data.campaignId });
};

export const sendDeleteCompensationReviewCampaignJob = async (
  ctx: AppContext,
  data: DeleteCompensationReviewCampaignJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.DELETE_COMPENSATION_REVIEW_CAMPAIGN,
    data,
    options: {
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.DELETE_COMPENSATION_REVIEW_CAMPAIGN },
        with: { campaignId: data.campaignId },
      }),
    },
  });
};
