import { type ParsedUrlQuery } from "querystring";
import { mixed, number, object, string } from "yup";
import { value } from "~/components/helpers";
import { parseNumber, parseString } from "~/lib/queryParams";

export const parseOrderParams = (
  query?: ParsedUrlQuery,
  options?: { defaultColumn: string; defaultDirection?: "asc" | "desc" }
): OrderParams => {
  const column = parseString(query, "order");

  if (!column) {
    const defaultColumn = options?.defaultColumn ?? null;
    const defaultDirection = options?.defaultDirection ?? "asc";

    return { column: defaultColumn, direction: defaultDirection };
  }

  const direction = parseString(query, "direction", {
    default: "asc",
    oneOf: ["asc", "desc"] as const,
  });

  return { column, direction };
};

export type OrderParams = {
  column: string | null;
  direction: "asc" | "desc";
};

export const parsePaginationParams = (query?: ParsedUrlQuery, options?: { defaultLimit: number }) => {
  const page = value(() => {
    if (query) {
      const pageFromQuery = parseNumber(query, "page");

      if (pageFromQuery && pageFromQuery >= 1) {
        return pageFromQuery;
      }
    }

    return 1;
  });

  const limit = value(() => {
    const defaultLimit = options?.defaultLimit ?? 25;

    if (query) {
      const limitFromQuery = parseNumber(query, "limit");

      if (limitFromQuery && limitFromQuery >= 1) {
        if (limitFromQuery > 100) {
          return 100;
        }
        return limitFromQuery;
      }
    }

    return defaultLimit;
  });

  return {
    page,
    limit,
  };
};

export type PaginationParams = ReturnType<typeof parsePaginationParams>;

export const prismaPaginationParams = (params: PaginationParams) => {
  const take = params.limit;
  const skip = params.limit * (params.page - 1);

  return {
    take,
    skip,
  };
};

export const buildPaginationResult = <T, M = unknown>(params: {
  pagination?: PaginationParams;
  items: T[];
  meta?: M;
  count: number;
}) => {
  if (!params.pagination) {
    return {
      items: params.items,
      count: params.count,
      currentPage: 1,
      totalPages: 1,
      nextPage: null,
      previousPage: null,
      meta: params.meta as M,
    };
  }

  const { skip: offset } = prismaPaginationParams(params.pagination);

  const hasNextPage = offset + params.items.length < params.count;
  const hasPreviousPage = params.pagination.page > 1;

  const nextPage = hasNextPage ? params.pagination.page + 1 : null;
  const previousPage = hasPreviousPage ? params.pagination.page - 1 : null;

  const totalPages = Math.ceil(params.count / params.pagination.limit);

  return {
    items: params.items,
    count: params.count,
    meta: params.meta as M,
    currentPage: params.pagination.page,
    totalPages,
    nextPage,
    previousPage,
  };
};

export type PaginationResult<T, M = unknown> = {
  items: T[];
  count: number;
  meta: M;
  currentPage: number;
  totalPages: number;
  nextPage: number | null;
  previousPage: number | null;
};

export const PaginationSchema = object({
  page: number().required().min(1),
  limit: number().required().min(1).max(100),
});

export const OrderingSchema = object({
  column: string().nullable().defined(),
  direction: mixed<"asc" | "desc">().oneOf(["asc", "desc"]).required(),
});
