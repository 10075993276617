/**
 * Naive implementation of an ordered list that keeps only N max elements.
 * Only efficient with a small N value.
 */
export class MaxOrderedList<T> {
  private list: T[] = [];

  constructor(
    private readonly maxSize: number,
    private readonly sortFn: (a: T, b: T) => number
  ) {}

  values() {
    return [...this.list];
  }

  push(element: T) {
    this.list.push(element);
    this.list.sort(this.sortFn);
    this.list = this.list.slice(0, this.maxSize);
  }

  removeAt(elementIndex: number) {
    this.list.splice(elementIndex, 1);
  }
}
