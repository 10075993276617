// Have to put it there because of circular deps
export enum KomboEmploymentType {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  CONTRACT = "CONTRACT",
  INTERNSHIP = "INTERNSHIP",
  FREELANCE = "FREELANCE",
  WORKING_STUDENT = "WORKING_STUDENT",
  APPRENTICESHIP = "APPRENTICESHIP",
  TRAINING = "TRAINING",
}
