import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { startCampaign } from "~/services/compensation-review/campaigns/admin/startCampaign";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

const StartCampaignJobDataSchema = BaseJobDataSchema.concat(object({ campaignId: number().required() }));

export type StartCampaignJobData = YupOutputType<typeof StartCampaignJobDataSchema>;

export const startCampaignWorkerService = async (ctx: AppContext, data: StartCampaignJobData) => {
  const context = await fetchCompensationReviewCampaignContext(ctx, data);

  await startCampaign(context);
};

export const sendStartCampaignJob = async (ctx: AppContext, data: StartCampaignJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.START_COMPENSATION_REVIEW_CAMPAIGN,
    data,
    options: {
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.START_COMPENSATION_REVIEW_CAMPAIGN },
        with: { campaignId: data.campaignId },
      }),
    },
  });
};
