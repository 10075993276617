// Throw this error on kombo 503 timeout
// And catch it in sync external employees to avoid deactivation of the integration
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";

export class KomboTimeoutError extends FiguresBaseError {
  constructor(public readonly error: string) {
    super(error);

    Object.setPrototypeOf(this, KomboTimeoutError.prototype);
  }
}
