import { Gender } from "@prisma/client";
import { match } from "ts-pattern";

type GetGenderParams = {
  value?: string | null;
  options: {
    male: string;
    female: string;
  };
};

export const getGender = (params: GetGenderParams) => {
  const { value, options } = params;

  return match(value)
    .with(options.female, () => Gender.FEMALE)
    .with(options.male, () => Gender.MALE)
    .otherwise(() => Gender.UNDISCLOSED);
};
