import { createWriteStream, readFileSync } from "fs";
import sizeOf from "image-size";
import { tmpdir } from "os";
import { join } from "path";
import { fetch } from "~/lib/fetch";

export const downloadClearbitImage = async (params: { companyId: number; companyUrl: string }) => {
  const res = await fetch(`https://logo.clearbit.com/${params.companyUrl}?size=512`);

  const path: string = await new Promise((resolve, reject) => {
    const imagePath = join(tmpdir(), `company-logo-${params.companyId}.png`);
    const fileStream = createWriteStream(imagePath);

    res.body.pipe(fileStream);
    res.body.on("error", (err) => reject(err));

    fileStream.on("finish", () => resolve(imagePath));
  });

  const fileBuffer = readFileSync(path);

  // Clearbit image is broken
  if (fileBuffer.length === 1) {
    return null;
  }

  const { width, height } = sizeOf(path) as { width: number; height: number };

  return {
    path,
    width,
    height,
    size: fileBuffer.length,
    content: fileBuffer,
  };
};
