import { InfoOutlined, Search } from "@mui/icons-material";
import { Checkbox, CircularProgress, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import { useRouter } from "next/router";
import React, {
  type ChangeEvent,
  type CSSProperties,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRendersCount } from "react-use";
import { useDebounce } from "use-debounce";
import { value } from "~/components/helpers";
import { Button } from "~/components/ui/core/Button";
import { Icon } from "~/components/ui/core/Icon";
import { Link, NO_LINK } from "~/components/ui/core/Link";
import { ColumnsDropDownMenu } from "~/components/ui/core/table/ColumnsDropDownMenu";
import { TitledTooltip } from "~/components/ui/core/TitledTooltip";
import { useDelayedLoadingState } from "~/hooks/useDelayedLoadingState";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { isFunction, isString, keyBy, mapValues, orderBy, partition, pick, sumBy } from "~/lib/lodash";
import { type OrderParams, type PaginationResult, parseOrderParams } from "~/lib/pagination";
import { parseString } from "~/lib/queryParams";
import { currentUrlWithQueryParams } from "~/lib/url";
import { stripDiacritics } from "~/lib/utils";
import { type TableColumnVisibilityKeys } from "~/pages/api/update-user-flag";
import { ACTIONS_COLUMN_ID, getColumnsOrder } from "~/services/table/getColumnsOrder";

export const CHECKBOX_WIDTH = 42;

export type RowSelection = { [key: number | string]: boolean };

export type Column<Entry> = {
  id: string;
  name?: string;
  formatName?: () => string | JSX.Element;
  tooltip?: string;
  sortable?: boolean;
  grow?: number;
  size?: number;
  fixed?: boolean;
  maxWidth?: string | number;
  borderLeft?: boolean;
  align?: "left" | "right" | "center";
  headerStyle?: CSSProperties;
  selector?: keyof Entry | ((row: Entry) => string | number | boolean | Date | null | undefined);
  format?: (row: Entry, index: number, rows: Entry[]) => JSX.Element | string | number | null;
  classNames?: (row: Entry, index: number) => string | null;
  styles?: (row: Entry, index: number) => CSSProperties | null;
  defaultVisibility?: boolean;
  orderingId?: string;
};

type Props<Entry> = {
  id?: string;
  className?: string;
  columns: Column<Entry>[];
  data: Entry[];
  dense?: boolean;
  inCard?: boolean;
  visibleColumnsKey?: TableColumnVisibilityKeys;
  navigation?: LocalNavigation<Entry> | UrlSyncedNavigation<Entry>;
  hideEmptyPagination?: boolean;
  hideTableHead?: boolean;
  highlightedData?: Entry[];
  highlightClass?: string;
  striped?: boolean;
  onRowClick?: (entry: Entry, index: number) => void;
  isRowClickable?: (entry: Entry) => boolean;
  disabledClick?: boolean;
  noDataComponent?: JSX.Element;
  afterSort?: (sortedData: Entry[]) => void;
  renderHeader?: (components: {
    searchInput?: JSX.Element;
    visibilityDropDown: (className?: string) => JSX.Element;
  }) => JSX.Element;
  initialSort?: {
    onSort?: (columnId: string) => Promise<void>;
    sortByColumn?: string | null;
  };
  tableSelection?: ReturnType<typeof useTableSelection<Entry>>;
  rowsAreLoading?: boolean;
  rowToKey?: (row: Entry) => string | number;
};

type LocalNavigation<Entry> = {
  type: "local";
  pagination?: { perPage: number };
  search?: {
    fullWidth?: boolean;
    placeholder?: string;
    dense?: boolean;
    entryToStrings: (row: Entry) => string[];
    afterSearch?: (visibleRows: Entry[]) => void;
  };
};

type UrlSyncedNavigation<Entry> = {
  type: "url";
  shallow?: boolean;
  pagination: {
    meta: PaginationResult<Entry>;
  };
  search?: {
    fullWidth?: boolean;
    placeholder?: string;
    dense?: boolean;
  };
};

const initialSortSeparator = "***";

const ACTION_ID = "checkbox";

export const Table = <Entry,>({
  id,
  columns,
  data,
  dense = false,
  inCard = false,
  navigation = { type: "local" },
  hideTableHead = false,
  hideEmptyPagination = false,
  striped = true,
  highlightedData = [],
  highlightClass = "bg-primary-300",
  onRowClick,
  isRowClickable,
  disabledClick,
  noDataComponent,
  afterSort,
  renderHeader,
  initialSort,
  tableSelection,
  visibleColumnsKey,
  className,
  rowToKey,
  rowsAreLoading,
}: Props<Entry>): ReactElement => {
  const { t } = useI18n();

  const { renderLoader } = useLoader({ rowsAreLoading });

  const { displayedColumns, hasFixedColumns, renderVisibilityDropDown } = useColumnsOrdering({
    columns,
    visibleColumnsKey,
    tableSelection,
  });

  const { columnMaxSizes, columnSizes } = useColumnsSizing({ columns: displayedColumns });

  const { filteredData, renderSearchInput } = useDataFiltering({
    navigation,
    data,
    onQueryChange: () => {
      handlePageChange(0);
    },
  });

  const { orderedData, orderColumnId, orderDirection, handleOrderChange, handleDirectionChange } = useDataOrdering({
    navigation,
    displayedColumns,
    data: filteredData,
    initialSort,
    afterSort,
  });

  const { paginatedData, totalCount, handlePageChange, renderPaginator } = useDataPagination({
    data: orderedData,
    navigation,
    hideEmptyPagination,
  });

  const { renderFullTableSelector } = useFullTableSelection({
    tableSelection,
    data: paginatedData,
    totalCount,
  });

  const renderTableHeader = useCallback(() => {
    if (renderHeader) {
      return renderHeader({ searchInput: renderSearchInput(), visibilityDropDown: renderVisibilityDropDown });
    }

    if (navigation.search) {
      return renderSearchInput();
    }

    return null;
  }, [renderHeader, navigation.search, renderSearchInput, renderVisibilityDropDown]);

  return (
    <div className={classNames(className, "relative flex w-full flex-col")}>
      {renderTableHeader()}

      {renderFullTableSelector()}

      <div className={classNames({ "relative overflow-x-auto": true, "-mx-6": inCard })}>
        {renderLoader()}

        <table className="w-full text-xs text-gray-900" id={id}>
          {!hideTableHead && (
            <thead>
              <tr className="bg-white">
                {displayedColumns.map((column, index) => {
                  if (column.id === ACTION_ID && tableSelection) {
                    return (
                      <CheckboxCell
                        key={column.id}
                        rowSelection={tableSelection.rowSelection}
                        onRowSelectionChange={tableSelection.onRowSelectionChange}
                        getRowSelectionIndex={tableSelection?.getRowSelectionIndex}
                        data={orderedData}
                        enableRowSelection={tableSelection.enableRowSelection}
                        disabled={paginatedData.every((entry) => !tableSelection.enableRowSelection(entry))}
                        index={index}
                        isHead
                        fixed={hasFixedColumns}
                      />
                    );
                  }

                  const { align = "left" } = column;

                  const sizeIndex = tableSelection ? index - 1 : index;
                  const maxWidth = column.maxWidth ?? `${(columnMaxSizes[sizeIndex] as number) * 100}%`;
                  const width = `${(columnSizes[sizeIndex] as number) * 100}%`;

                  const name = column.formatName ? column.formatName() : column.name;
                  const lastFixed = column.fixed && !displayedColumns[index + 1]?.fixed;

                  return (
                    <th
                      key={index}
                      className={classNames({
                        "font-semibold": true,
                        "py-3 px-4": !dense,
                        "py-1 px-2": dense,
                        "cursor-pointer": column.sortable,
                        "group hover:text-primary-600": column.sortable,
                        "border-l-2 border-gray-400": column.borderLeft,
                        "text-left": align === "left",
                        "text-right": align === "right",
                        "text-center": align === "center",
                        "sticky z-10 bg-white": column.fixed,
                      })}
                      style={{ ...column.headerStyle, maxWidth, width }}
                      onClick={async () => {
                        const id = column.orderingId ?? column.id;

                        if (!column.sortable) {
                          return;
                        }
                        if (id !== orderColumnId) {
                          handleDirectionChange("asc");
                          await initialSort?.onSort?.(`${id}${initialSortSeparator}asc`);
                        } else {
                          const direction = orderDirection === "asc" ? "desc" : "asc";
                          handleDirectionChange(direction);
                          await initialSort?.onSort?.(`${id}${initialSortSeparator}${direction}`);
                        }

                        handleOrderChange(id);
                      }}
                    >
                      <div
                        className={classNames({
                          "relative flex items-center space-x-2 whitespace-nowrap": true,
                          "justify-end": align === "right",
                          "justify-center": align === "center",
                        })}
                      >
                        {!column.tooltip && name && <span>{name}</span>}

                        {column.tooltip && column.name && (
                          <TitledTooltip heading={column.name} text={column.tooltip ?? ""}>
                            <Stack direction="row" alignItems="center" gap={1}>
                              {name && <span>{name}</span>}
                              <InfoOutlined className="text-base text-gray-500" />
                            </Stack>
                          </TitledTooltip>
                        )}

                        {(column.orderingId === orderColumnId || column.id === orderColumnId) && (
                          <Icon
                            className="text-gray-500 group-hover:text-primary-600"
                            name={orderDirection === "asc" ? "arrow-up" : "arrow-down"}
                          />
                        )}
                      </div>

                      {lastFixed && <div className="absolute top-0 bottom-0 right-1 w-px bg-gray-200" />}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}

          <tbody>
            <>
              {orderedData.length === 0 && rowsAreLoading === false && (
                <tr>
                  <td colSpan={displayedColumns.length}>
                    {noDataComponent ?? (
                      <div className="w-full p-5 text-center text-sm font-medium">
                        {t("components.core.table.no-records-found")}
                      </div>
                    )}
                  </td>
                </tr>
              )}

              {paginatedData.map((entry, index) => {
                const highlight = highlightedData.includes(entry);

                const rowIsClickable = (isRowClickable ? isRowClickable(entry) : true) && onRowClick && !disabledClick;

                return (
                  <tr
                    key={rowToKey?.(entry) ?? index}
                    className={classNames({
                      "group transition": striped,
                      "bg-gray-50": striped && index % 2 === 0,
                      "bg-white": (striped && index % 2 === 1) || !striped,
                      "cursor-not-allowed": disabledClick,
                      "cursor-pointer hover:bg-primary-100": rowIsClickable,
                      [highlightClass]: highlight,
                    })}
                  >
                    {displayedColumns.map((column, columnIndex) => {
                      if (column.id === ACTION_ID && tableSelection) {
                        return (
                          <CheckboxCell
                            key={column.id}
                            rowSelection={tableSelection.rowSelection}
                            onRowSelectionChange={tableSelection.onRowSelectionChange}
                            getRowSelectionIndex={tableSelection.getRowSelectionIndex}
                            data={orderedData}
                            enableRowSelection={tableSelection.enableRowSelection}
                            disabled={!tableSelection.enableRowSelection(entry)}
                            entry={entry}
                            index={index}
                            fixed={hasFixedColumns}
                          />
                        );
                      }

                      const sizeIndex = tableSelection ? columnIndex - 1 : columnIndex;
                      const size = column.maxWidth ?? `${columnMaxSizes[sizeIndex] as number}%`;
                      const lastFixed = column.fixed && !displayedColumns[columnIndex + 1]?.fixed;

                      const value = isFunction(column.selector)
                        ? column.selector(entry)
                        : !!column.selector
                        ? entry[column.selector]
                        : null;

                      const formattedValue = column.format
                        ? column.format(entry, index, paginatedData)
                        : value?.toString();

                      return (
                        <td
                          key={column.id}
                          className={classNames(column.classNames?.(entry, index), {
                            "whitespace-nowrap py-2 px-4": !dense,
                            "py-1 px-2": dense,
                            "border-l-2 border-gray-400": column.borderLeft,
                            "text-right": column.align === "right",
                            "text-center": column.align === "center",
                            "sticky z-10 bg-inherit": column.fixed,
                          })}
                          style={{ ...column.styles?.(entry, index), maxWidth: size }}
                          onClick={() => {
                            if (column.id !== ACTIONS_COLUMN_ID && rowIsClickable) {
                              onRowClick?.(entry, index);
                            }
                          }}
                        >
                          <div>{formattedValue}</div>
                          {lastFixed && <div className="absolute top-0 bottom-0 right-1 w-px bg-gray-200" />}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>

      {renderPaginator()}
    </div>
  );
};

export const EmptyCell: React.FC = () => {
  return <span className="text-gray-500">-</span>;
};

type CheckboxCellProps<Entry> = {
  rowSelection: RowSelection;
  onRowSelectionChange: (newValue: RowSelection) => void;
  getRowSelectionIndex?: (T: Entry) => number | string;
  data: Entry[];
  disabled?: boolean;
  isHead?: boolean;
  entry?: Entry;
  index: number;
  fixed: boolean;
  enableRowSelection?: (T: Entry) => boolean;
};

const CheckboxCell = <Entry,>({
  rowSelection,
  onRowSelectionChange,
  getRowSelectionIndex,
  data,
  disabled = false,
  isHead = false,
  entry,
  index,
  enableRowSelection = () => true,
  fixed,
}: CheckboxCellProps<Entry>): ReactElement => {
  const rowIndex = value(() => {
    if (getRowSelectionIndex && entry) return getRowSelectionIndex(entry);

    return index;
  });

  const onSelectionChange = (unfilteredRowSelection: RowSelection) => {
    const selection = unfilteredRowSelection;

    data.forEach((entry) => {
      selection[getRowSelectionIndex?.(entry) as number | string] =
        enableRowSelection(entry) && !!unfilteredRowSelection[getRowSelectionIndex?.(entry) as number | string];
    });

    onRowSelectionChange(selection);
  };

  const getAllRowSelected = () => {
    const allRows = value(() => {
      if (getRowSelectionIndex) {
        return keyBy(data, (entry) => getRowSelectionIndex?.(entry));
      }

      return keyBy(
        data.map((_, index) => index),
        "index"
      );
    });

    return mapValues(allRows, () => true);
  };

  const isChecked = value(() => {
    if (!isHead) return rowSelection[rowIndex] ?? false;

    return !data.some((data, index) => {
      if (getRowSelectionIndex) {
        return enableRowSelection(data) && !rowSelection[getRowSelectionIndex(data)];
      }

      return enableRowSelection(data) && !rowSelection[index];
    });
  });

  const Tag = isHead ? "th" : "td";

  return (
    <Tag
      className={classNames({
        "py-3 px-4 text-left": true,
        "sticky left-0 z-20 bg-inherit": fixed,
      })}
      style={{
        minWidth: CHECKBOX_WIDTH,
        maxWidth: CHECKBOX_WIDTH,
        width: CHECKBOX_WIDTH,
      }}
    >
      <Checkbox
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (isHead) {
            if (isChecked) return onRowSelectionChange({});

            return onSelectionChange(getAllRowSelected());
          }

          onSelectionChange({ ...rowSelection, [rowIndex]: event.target.checked });
        }}
        checked={isChecked}
        className="animate-scale p-0 transition duration-75 ease-in-out"
        size="small"
        disabled={disabled}
      />
    </Tag>
  );
};

export const useTableSelection = <Entry,>(params: {
  pagination?: PaginationResult<Entry>;
  enableFullSelection?: boolean;
  enableRowSelection?: <T extends Entry>(row: T) => boolean;
  getRowSelectionIndex?: <T extends Entry>(row: T) => number | string;
}) => {
  const [isFullSelected, setIsFullSelected] = useState(false);
  const [rowSelection, setRowSelection] = useState<{ [key: number | string]: boolean }>({});

  const items = params.pagination?.items ?? [];

  const onRowSelectionChange = (newRowSelection: { [key: number | string]: boolean }) => {
    setRowSelection(newRowSelection);
    setIsFullSelected(false);
  };

  const onFullSelectionChange = (newIsFullSelected: boolean) => {
    setIsFullSelected(newIsFullSelected);

    if (newIsFullSelected) {
      setRowSelection(
        Object.fromEntries(
          items.map((row, index) => [
            params.getRowSelectionIndex ? params.getRowSelectionIndex(row) : index,
            newIsFullSelected,
          ]) ?? []
        )
      );
    } else {
      setRowSelection({});
    }
  };

  const resetSelection = () => {
    setIsFullSelected(false);
    setRowSelection({});
  };

  const selectedItems = useMemo(
    () =>
      items.filter((row, rowIndex) => {
        const index = params.getRowSelectionIndex ? params.getRowSelectionIndex(row) : rowIndex;

        return rowSelection[index];
      }),
    [rowSelection, params.pagination]
  );

  const selectedIds = useMemo(
    () =>
      Object.entries(rowSelection)
        .filter(([, value]) => value)
        .filter(([key]) => !isNaN(Number(key)))
        .map(([key]) => {
          return Number(key);
        }),
    [rowSelection]
  );

  const selectedStringIds = useMemo(
    () =>
      Object.entries(rowSelection)
        .filter(([, value]) => value)
        .filter(([key]) => isNaN(Number(key)))
        .map(([key]) => {
          return `${key}`;
        }),
    [rowSelection]
  );

  const selectionSize = useMemo(() => {
    if (isFullSelected) return params.pagination?.count ?? 0;

    return selectedIds.length;
  }, [params.pagination, selectedIds, isFullSelected]);

  const selectItem = (item: Entry) => {
    setRowSelection({ ...rowSelection, [params.getRowSelectionIndex?.(item) as number | string]: true });
  };

  const isEmpty = selectionSize === 0;

  return {
    enableFullSelection: params.enableFullSelection,
    enableRowSelection: params.enableRowSelection ?? (() => true),
    getRowSelectionIndex: params.getRowSelectionIndex,

    isFullSelected,
    rowSelection,
    selectedItems,
    selectedIds,
    selectedStringIds,
    selectionSize,
    isEmpty,

    onRowSelectionChange,
    onFullSelectionChange,
    resetSelection,
    selectItem,
  };
};

export const useColumnsOrdering = <Entry,>(params: {
  columns: Column<Entry>[];
  visibleColumnsKey?: TableColumnVisibilityKeys;
  tableSelection?: ReturnType<typeof useTableSelection<Entry>>;
}) => {
  const { user } = useSession();

  const [fixedColumns, nonFixedColumns] = partition(params.columns, (column) => column.fixed);
  const hasFixedColumns = fixedColumns.length > 0;

  const actionColumn: Column<Entry> = { id: ACTION_ID };

  const nonFixedColumnsSnapshot = useMemo(() => {
    return JSON.stringify(nonFixedColumns.map((column) => pick(column, ["id", "orderingId"])));
  }, [nonFixedColumns]);

  const [orderedColumnIds, setOrderedColumnIds] = useState<string[]>([]);
  const [visibleColumnIds, setVisibleColumnIds] = useState<string[]>([]);

  useEffect(() => {
    const columns = [...fixedColumns, ...getColumnsOrder(user, params.visibleColumnsKey, nonFixedColumns)];

    setOrderedColumnIds(columns.map((column) => column.id));
    setVisibleColumnIds(
      columns.filter((column) => column.fixed || column.defaultVisibility !== false).map((column) => column.id)
    );
  }, [nonFixedColumnsSnapshot]);

  const handleVisibilityChange = (columnId: string, visible: boolean) => {
    if (visible) {
      setVisibleColumnIds([...visibleColumnIds, columnId]);
    } else {
      setVisibleColumnIds(visibleColumnIds.filter((id) => id !== columnId));
    }
  };

  const orderedColumns = useMemo(() => {
    return orderBy(params.columns, (column) => {
      const index = orderedColumnIds.indexOf(column.id);

      if (index === -1 && !column.fixed) return +Infinity;

      return index;
    });
  }, [nonFixedColumnsSnapshot, orderedColumnIds, params.columns]);

  const displayedColumns = useMemo(() => {
    const displayedColumns = orderedColumns.filter((column) => visibleColumnIds.includes(column.id));

    if (params.tableSelection?.rowSelection) {
      return [actionColumn, ...displayedColumns];
    }

    return displayedColumns;
  }, [orderedColumns, visibleColumnIds, params.tableSelection]);

  const renderVisibilityDropDown = useCallback(
    (className?: string) => {
      return (
        <ColumnsDropDownMenu
          className={className}
          orderedColumns={orderedColumns}
          visibleColumnIds={visibleColumnIds}
          onReorder={setOrderedColumnIds}
          onVisibilityChange={handleVisibilityChange}
          visibleColumnsKey={params.visibleColumnsKey}
        />
      );
    },
    [orderedColumns, visibleColumnIds, setOrderedColumnIds, handleVisibilityChange, params.visibleColumnsKey]
  );

  return {
    displayedColumns,
    hasFixedColumns,
    renderVisibilityDropDown,
  };
};

const useColumnsSizing = <Entry,>(params: { columns: Column<Entry>[] }) => {
  const { columns } = params;

  const totalMaxSize = sumBy(columns, (column) => column.grow ?? 1);
  const columnMaxSizes = columns.map((column) => (column.grow ?? 1) / totalMaxSize);

  const totalSize = sumBy(columns, (column) => column.size ?? 1);
  const columnSizes = columns.map((column) => (column.size ?? 1) / totalSize);

  return {
    columnMaxSizes,
    columnSizes,
  };
};

const useDataFiltering = <Entry,>(params: {
  navigation: NonNullable<Props<Entry>["navigation"]>;
  data: Entry[];
  onQueryChange: (query: string) => void;
}) => {
  const { navigation, data, onQueryChange } = params;

  const { t } = useI18n();
  const router = useRouter();
  const [query, setQuery] = useState<string>(parseString(router.query, "query") ?? "");

  /**
   * In "local" navigation mode, filter the data with the provided callback
   */
  const filteredData = useMemo(() => {
    if (navigation.type !== "local" || !navigation.search || !query.length) {
      return data;
    }

    const queryLower = query.toLowerCase();

    return data.filter((entry) => {
      if (!navigation.search) {
        return true;
      }

      const strings = navigation.search.entryToStrings(entry);

      return strings.some((string) => {
        return stripDiacritics(string)
          .toLowerCase()
          .includes(queryLower as string);
      });
    });
  }, [data, query]);

  /**
   * In "local" navigation mode, trigger afterSearch when the filtered data has changed
   */
  useEffect(() => {
    if (navigation.type !== "local" || !navigation.search?.afterSearch) {
      return;
    }

    navigation.search.afterSearch(filteredData);
  }, [filteredData]);

  /**
   * In "URL" navigation mode, synchronise the search query.
   */
  const [debouncedQuery] = useDebounce(query, 300);
  const hasRendered = useRendersCount() > 1;
  useEffect(() => {
    if (!hasRendered) return;

    if (navigation.type !== "url") {
      return;
    }

    if (!isString(debouncedQuery)) {
      return;
    }

    void router.replace(currentUrlWithQueryParams(router, { page: null, query: debouncedQuery }), undefined, {
      shallow: navigation.shallow,
    });
  }, [debouncedQuery]);

  const renderSearchInput = useCallback(() => {
    if (!navigation.search) {
      return;
    }

    return (
      <TextField
        variant="outlined"
        className={classNames({
          "w-64": !navigation.search.fullWidth,
          "w-full": navigation.search.fullWidth,
        })}
        placeholder={navigation.search.placeholder ?? t("components.core.table.filter-results")}
        type="text"
        size="small"
        value={query}
        onChange={(event) => {
          setQuery(event.target.value);
          onQueryChange(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" className="text-gray-500" />
            </InputAdornment>
          ),
        }}
      />
    );
  }, [navigation.search, query]);

  return { filteredData, query, renderSearchInput };
};

const useDataOrdering = <Entry,>(params: {
  navigation: NonNullable<Props<Entry>["navigation"]>;
  displayedColumns: Column<Entry>[];
  data: Entry[];
  initialSort: Props<Entry>["initialSort"];
  afterSort: Props<Entry>["afterSort"];
}) => {
  const { navigation, data, initialSort, displayedColumns, afterSort } = params;

  const router = useRouter();
  const routerOrderParams = params.navigation.type === "url" ? parseOrderParams(router.query) : null;

  const [orderColumnId, setOrderColumnId] = useState<string | null>(routerOrderParams?.column ?? null);
  const [orderDirection, setOrderDirection] = useState<OrderParams["direction"]>(routerOrderParams?.direction ?? "asc");

  useEffect(() => {
    if (!initialSort?.sortByColumn) return;

    const [columnId, order] = initialSort.sortByColumn.split(initialSortSeparator);
    if (!columnId || !order) return;

    setOrderColumnId(columnId);
    setOrderDirection(order as OrderParams["direction"]);
  }, [initialSort]);

  /**
   * In "local" navigation mode, order the data using the state selector & order
   */
  const orderedData = useMemo(() => {
    if (navigation.type !== "local") {
      return data;
    }

    return orderBy(
      data,
      (entry) => {
        if (orderColumnId === null) {
          return true;
        }

        const column = displayedColumns.find(
          (column) => column.orderingId === orderColumnId || column.id === orderColumnId
        );
        if (!column) {
          return true;
        }

        return isFunction(column.selector) ? column.selector(entry) : !!column.selector ? entry[column.selector] : null;
      },
      orderDirection
    );
  }, [data, orderColumnId, orderDirection]);

  /**
   * In "URL" navigation mode, synchronise the order column & direction.
   */
  useEffect(() => {
    if (navigation.type !== "url") {
      return;
    }

    const column = displayedColumns.find(
      (column) => column.orderingId === orderColumnId || column.id === orderColumnId
    );
    if (!column || !column.sortable) {
      return;
    }

    const orderParam = orderColumnId;
    const directionParam = orderDirection === "desc" ? "desc" : null;

    void router.replace(
      currentUrlWithQueryParams(router, { page: null, order: orderParam, direction: directionParam }),
      undefined,
      { shallow: navigation.shallow }
    );
  }, [orderColumnId, orderDirection]);

  useEffect(() => {
    afterSort?.(orderedData);
  }, [JSON.stringify(orderedData)]);

  return {
    orderColumnId,
    orderDirection,
    orderedData,

    handleOrderChange: setOrderColumnId,
    handleDirectionChange: setOrderDirection,
  };
};

const useDataPagination = <Entry,>(params: {
  data: Entry[];
  navigation: NonNullable<Props<Entry>["navigation"]>;
  hideEmptyPagination: Props<Entry>["hideEmptyPagination"];
}) => {
  const { data, navigation, hideEmptyPagination } = params;

  const { t } = useI18n();
  const router = useRouter();
  const [localPage, setLocalPage] = useState(0);

  const offset = localPage * (navigation.type === "local" ? navigation.pagination?.perPage ?? 0 : 0);

  /**
   * In "local" navigation mode, compute the pages count based on the filtered data size
   */
  const pagesCount = useMemo(() => {
    if (navigation.type !== "local" || !navigation.pagination) {
      return null;
    }

    return Math.ceil(data.length / navigation.pagination.perPage);
  }, [data]);

  /**
   * In "local" navigation mode, slice the data using the local state
   */
  const paginatedData = useMemo(() => {
    if (navigation.type !== "local" || !navigation.pagination) {
      return data;
    }

    return data.slice(offset, offset + navigation.pagination.perPage);
  }, [data, offset]);

  const totalCount = navigation.type === "url" ? navigation.pagination.meta.count : paginatedData.length;

  const renderPaginator = useCallback(() => {
    const showLocalPaginator =
      navigation.type === "local" &&
      pagesCount !== null &&
      pagesCount > 0 &&
      !(hideEmptyPagination && pagesCount === 1);

    const showUrlPaginator =
      navigation.type === "url" &&
      navigation.pagination.meta.count > 0 &&
      !(hideEmptyPagination && navigation.pagination.meta.totalPages === 1);

    if (showLocalPaginator) {
      return (
        <div className="mt-6 mb-2 mr-6 flex items-center space-x-2 self-center">
          <Button
            size="small"
            variant="contained"
            disabled={localPage <= 0}
            onClick={() => setLocalPage(localPage - 1)}
            className="min-w-0"
          >
            &larr;
          </Button>
          <Typography className="text-sm text-gray-700">
            {t("components.core.table.start-page", {
              pageCount: pagesCount.toString(),
              localPage: String(localPage + 1),
            })}
          </Typography>
          <Button
            size="small"
            variant="contained"
            disabled={localPage >= pagesCount - 1}
            onClick={() => setLocalPage(localPage + 1)}
            className="min-w-0"
          >
            &rarr;
          </Button>
        </div>
      );
    }

    if (showUrlPaginator) {
      return (
        <div className="mt-6 mb-2 mr-6 flex items-center space-x-2 self-center">
          <Link
            noLinkStyle
            shallow={navigation.shallow}
            to={
              navigation.pagination.meta.previousPage
                ? currentUrlWithQueryParams(router, {
                    page: navigation.pagination.meta.previousPage,
                  })
                : NO_LINK
            }
          >
            <Button
              size="small"
              variant="contained"
              disabled={!navigation.pagination.meta.previousPage}
              className="min-w-0"
            >
              &larr;
            </Button>
          </Link>
          <Typography className="text-sm text-gray-700">
            {t("components.core.table.end-page", {
              currentPage: navigation.pagination.meta.currentPage.toString(),
              totalPages: navigation.pagination.meta.totalPages.toString(),
            })}
          </Typography>
          <Link
            noLinkStyle
            shallow={navigation.shallow}
            to={
              navigation.pagination.meta.nextPage
                ? currentUrlWithQueryParams(router, {
                    page: navigation.pagination.meta.nextPage,
                  })
                : NO_LINK
            }
          >
            <Button
              size="small"
              variant="contained"
              disabled={!navigation.pagination.meta.nextPage}
              className="min-w-0"
            >
              &rarr;
            </Button>
          </Link>
        </div>
      );
    }
  }, [navigation.type, navigation.pagination, localPage, hideEmptyPagination, pagesCount]);

  return {
    paginatedData,
    totalCount,
    pagesCount,

    handlePageChange: setLocalPage,
    renderPaginator,
  };
};

const useFullTableSelection = <Entry,>(params: {
  data: Entry[];
  tableSelection: Props<Entry>["tableSelection"];
  totalCount: number;
}) => {
  const { tableSelection, data, totalCount } = params;

  const { t } = useI18n();

  const showFullTableSelector = useMemo(() => {
    if (!tableSelection?.enableFullSelection) return false;
    if (!data.length) return false;
    if (data.length === totalCount) return false;

    const selectedData = data.filter((entry) => {
      if (!tableSelection?.rowSelection || !tableSelection.getRowSelectionIndex) return false;

      return tableSelection.rowSelection[tableSelection.getRowSelectionIndex(entry)];
    });

    const selectableData = data.filter((entry) => {
      if (!tableSelection?.enableRowSelection) return false;
      return tableSelection.enableRowSelection(entry);
    });

    return selectedData.length === selectableData.length && selectedData.length > 0 && selectableData.length > 0;
  }, [data, tableSelection?.enableFullSelection, tableSelection?.rowSelection, tableSelection?.getRowSelectionIndex]);

  const renderFullTableSelector = useCallback(() => {
    if (!showFullTableSelector) return null;

    return (
      <Stack
        className="-mx-6 border-b bg-gray-100 py-1"
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {tableSelection?.isFullSelected && (
          <>
            <Typography>
              {t("components.core.table.rows-are-selected", {
                paginationCount: totalCount.toString(),
              })}
            </Typography>
            <Button variant="text" onClick={tableSelection.resetSelection}>
              {t("components.core.table.clear-selection")}
            </Button>
          </>
        )}

        {!tableSelection?.isFullSelected && (
          <>
            <Typography>
              {t("components.core.table.rows-on-this-page", { displayedData: data.length.toString() })}{" "}
            </Typography>
            <Button
              variant="text"
              onClick={() => {
                tableSelection?.onFullSelectionChange?.(true);
              }}
            >
              {t("components.core.table.select-all", {
                paginationCount: totalCount.toString(),
              })}
            </Button>
          </>
        )}
      </Stack>
    );
  }, [showFullTableSelector, data, totalCount, tableSelection]);

  return {
    renderFullTableSelector,
  };
};

const useLoader = <Entry,>(params: { rowsAreLoading: Props<Entry>["rowsAreLoading"] }) => {
  const isLoaderVisible = useDelayedLoadingState({ value: params.rowsAreLoading ?? false });

  const renderLoader = useCallback(() => {
    if (!isLoaderVisible) return null;

    return (
      <div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-25">
        <CircularProgress size={30} color="secondary" />
      </div>
    );
  }, [isLoaderVisible]);

  return {
    renderLoader,
  };
};
