import {
  CompensationReviewCompensationItem,
  CompensationReviewIncreaseRuleTarget,
  CompensationReviewIncreaseRuleType,
  CompensationReviewIncreaseRuleUnit,
  type Currency,
  type Prisma,
  SalaryGridMeasure,
} from "@prisma/client";
import { match } from "ts-pattern";
import { type SalaryRangeAutocompleteValue } from "~/components/compensation-review/SalaryRangeAutocomplete";
import { chain, isNil, maxBy, minBy } from "~/lib/lodash";
import { convertCurrency } from "~/lib/money";
import { type CompensationReviewData } from "~/services/compensation-review/compensationReviewContext";
import { type EnrichIncreaseRulesWithPopulationResult } from "~/services/compensation-review/shared/rules/enrichIncreaseRulesWithPopulation";

export type EmployeeForRules = Prisma.CompensationReviewEmployeeGetPayload<{
  select: {
    id: true;
    isPromoted: true;
    baseSalary: true;
    variablePay: true;
    onTargetEarnings: true;
    currency: {
      select: {
        euroExchangeRate: true;
      };
    };
    eligibilities: {
      select: {
        budgetId: true;
      };
    };
    targetRangeAfterPromotion: {
      select: {
        min: true;
        max: true;
        midpoint: true;
        band: {
          select: {
            measure: true;
            currency: true;
          };
        };
      };
    };
  };
}> & {
  salaryRangeEmployee: {
    range: {
      min: number;
      max: number;
      midpoint: number;
      band: {
        measure: SalaryGridMeasure;
        currency: Currency;
      };
    };
  } | null;
};

export const resolveRules = (
  ctx: CompensationReviewData,
  params: {
    rules: EnrichIncreaseRulesWithPopulationResult;
    compensationItem: CompensationReviewCompensationItem;
    employee: EmployeeForRules;
    promotion: {
      isPromoted: boolean;
      targetRangeAfterPromotion: SalaryRangeAutocompleteValue | null;
    };
    skipPermissions?: boolean;
  }
) => {
  const { employee, rules: baseRules, promotion, skipPermissions = false } = params;

  const baseSalaryInCampaignCurrency = convertCurrency(employee.baseSalary, employee.currency, ctx.parameters.currency);

  const onTargetEarningsInCampaignCurrency = convertCurrency(
    employee.onTargetEarnings,
    employee.currency,
    ctx.parameters.currency
  );

  const targetRange = promotion.isPromoted
    ? promotion.targetRangeAfterPromotion ?? employee.salaryRangeEmployee?.range
    : employee.salaryRangeEmployee?.range;

  const comparedSalaryBandAmount = match(targetRange?.band.measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => baseSalaryInCampaignCurrency)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => onTargetEarningsInCampaignCurrency)
    .otherwise(() => null);

  const rules = chain(baseRules)
    .filter((rule) => rule.compensationItem === params.compensationItem)
    .filter((rule) => {
      if (rule.employeeIds === null) return true;

      return rule.employeeIds.includes(employee.id);
    })
    .filter((rule) => {
      if (rule.target === CompensationReviewIncreaseRuleTarget.PROMOTED_EMPLOYEES) {
        return employee.isPromoted;
      }

      if (rule.target === CompensationReviewIncreaseRuleTarget.NON_PROMOTED_EMPLOYEES) {
        return !employee.isPromoted;
      }

      return true;
    })
    .map((rule) => {
      const targetAmount = match(rule.unit)
        .with(CompensationReviewIncreaseRuleUnit.AMOUNT, () => rule.amount)
        .with(CompensationReviewIncreaseRuleUnit.PERCENTAGE, () => {
          const variablePayInCampaignCurrency = convertCurrency(
            employee.variablePay,
            employee.currency,
            ctx.parameters.currency
          );

          const amount = match(params.compensationItem)
            .with(CompensationReviewCompensationItem.BASE_SALARY, () => baseSalaryInCampaignCurrency)
            .with(CompensationReviewCompensationItem.VARIABLE_PAY, () => variablePayInCampaignCurrency)
            .with(CompensationReviewCompensationItem.ON_TARGET_EARNINGS, () => onTargetEarningsInCampaignCurrency)
            .otherwise(() => null);

          if (amount === null) return null;

          return Math.round(amount * rule.amount);
        })
        .with(CompensationReviewIncreaseRuleUnit.COMPA_RATIO, () => {
          if (!targetRange || !comparedSalaryBandAmount) return null;

          return Math.round(
            convertCurrency(targetRange.midpoint, targetRange.band.currency, ctx.parameters.currency) * rule.amount -
              comparedSalaryBandAmount
          );
        })
        .with(CompensationReviewIncreaseRuleUnit.RANGE_PENETRATION, () => {
          if (!targetRange || !comparedSalaryBandAmount) return null;

          const min = convertCurrency(targetRange.min, targetRange.band.currency, ctx.parameters.currency);
          const max = convertCurrency(targetRange.max, targetRange.band.currency, ctx.parameters.currency);

          const targetAmount = min + (max - min) * rule.amount;

          return Math.round(targetAmount - comparedSalaryBandAmount);
        })
        .exhaustive();

      if (targetAmount === null) return null;

      return {
        ...rule,
        targetAmount,
        minAmount: null,
        maxAmount: null,
        recommendationAmount: null,
        ...(rule.type === CompensationReviewIncreaseRuleType.MINIMUM_INCREASE && { minAmount: targetAmount }),
        ...(rule.type === CompensationReviewIncreaseRuleType.MAXIMUM_INCREASE && { maxAmount: targetAmount }),
        ...(rule.type === CompensationReviewIncreaseRuleType.INITIAL_RECOMMENDATION && {
          recommendationAmount: targetAmount,
        }),
      };
    })
    .compact()
    .value();

  type Rule = (typeof rules)[number];

  const hardMin = chain(rules)
    .filter((rule) => !isNil(rule.minAmount) && rule.hardLimit)
    .maxBy((rule) => rule.minAmount)
    .value() as Rule | undefined;

  const hardMax = chain(rules)
    .filter((rule) => !isNil(rule.maxAmount) && rule.hardLimit)
    .minBy((rule) => rule.maxAmount)
    .value() as Rule | undefined;

  const softMin = chain(rules)
    .filter((rule) => !isNil(rule.minAmount) && !rule.hardLimit)
    .maxBy((rule) => rule.minAmount)
    .value() as Rule | undefined;

  const softMax = chain(rules)
    .filter((rule) => !isNil(rule.maxAmount) && !rule.hardLimit)
    .minBy((rule) => rule.maxAmount)
    .value() as Rule | undefined;

  const overallMin = maxBy([hardMin, softMin], (rule) => rule?.minAmount);
  const overallMax = minBy([hardMax, softMax], (rule) => rule?.maxAmount);

  return {
    rules,

    overallLimits: { min: overallMin, max: overallMax },

    checkSoftLimits: (value: number) => {
      if (!isNil(overallMin?.minAmount) && value < overallMin.minAmount) return false;
      if (!isNil(overallMax?.maxAmount) && value > 0 && value > overallMax.maxAmount) return false;

      return true;
    },

    checkHardLimits: (value: number) => {
      if (!skipPermissions && ctx.permissions.canBypassRules) return true;

      if (!isNil(hardMin?.minAmount) && value < hardMin.minAmount) return false;
      if (!isNil(hardMax?.maxAmount) && value > 0 && value > hardMax.maxAmount) return false;

      return true;
    },
  };
};
