import { Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar, { type SnackbarCloseReason } from "@mui/material/Snackbar";
import React, { useState } from "react";
import { type FiguresAlert, useAlerts } from "~/hooks/useAlerts";
import { omit } from "~/lib/lodash";

export const Alerts: React.FC = () => {
  const { alerts } = useAlerts();

  if (!alerts.length) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
      {alerts.map((alert, index) => (
        <AlertInSnackbar key={alert.id} alert={alert} index={index} />
      ))}
    </Stack>
  );
};

const AlertInSnackbar: React.FC<{ alert: FiguresAlert; index: number }> = ({ alert, index }) => {
  const { dismissAlert } = useAlerts();
  const [open, setOpen] = useState(true);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    dismissAlert(alert);
  };

  return (
    <Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={alert.autoHideDuration}
        open={open}
        onClose={handleClose}
        sx={{ marginBottom: `${index * 60}px !important` }}
      >
        <Alert onClose={handleClose} {...omit(alert, "autoHideDuration")} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
