import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import { type ValidationError } from "yup";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { keyBy, mapValues } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export const handleValidationError = (ctx: AppContext, res: NextApiResponse, baseError: ValidationError) => {
  const error = new FiguresBaseError("Validation error").fromError(baseError);
  logWarn(ctx, "[error] Validation error", { error });

  res.status(HttpStatus.UNPROCESSABLE_ENTITY).json({
    ...makeErrorPayload({ error, defaultCode: ErrorCode.UNPROCESSABLE_ENTITY }),
    fields: mapValidationErrorFields(baseError),
  });
};

export const mapValidationErrorFields = (error: ValidationError) => {
  return mapValues(
    keyBy(error.inner, (field: ValidationError) => {
      return field.path;
    }),
    (field: ValidationError) => {
      return field.message;
    }
  );
};
