import {
  type Currency,
  EmployeeMarketPositioning,
  EmployeeStatus,
  ExternalEmployeeStatus,
  type Prisma,
} from "@prisma/client";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chain, compact, includes, isString, lowerCase, partition, sumBy } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { convertCurrency } from "~/lib/money";
import { buildPaginationResult, type OrderParams, prismaPaginationParams } from "~/lib/pagination";
import { applyPrismaRestrictions } from "~/lib/prismaTokens";
import { isIn, isNotNull } from "~/lib/utils";
import { type FetchCompanyEmployeesInput } from "~/pages/api/employee/fetch-company-employees";
import { type PeopleTab } from "~/pages/people";
import { type FilterOptionsInput } from "~/services/employee-filter";
import {
  buildExternalEmployeeFilterWhereClauses,
  fetchExternalEmployeeFilterOptions,
} from "~/services/employee-filter/fetchExternalEmployeeFilterOptions";
import { type EmployeeStatsMeasure } from "~/services/employee-stats/utils";
import { LEVELS_MAP } from "~/services/employee/employeeLevel";
import { buildFetchExternalEmployeesWhereClause } from "~/services/external-employee/fetchExternalEmployeesForPeopleDashboard";
import { selectExternalEmployeeData } from "~/services/external-employee/selectExternalEmployeeData";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";
import { getMarketPositioningType } from "~/services/marketPositioning";

export const companyEmployeesSelect = {
  id: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  employeeDataValidationFlags: {
    where: { isLive: true },
  },
  isFounder: true,
  externalJobTitle: true,
  externalLevel: true,
  level: true,
  gender: true,
  baseSalary: true,
  onTargetBonus: true,
  onTargetBonusPercentage: true,
  fixedBonus: true,
  fixedBonusPercentage: true,
  updateReason: true,
  birthDate: true,
  hireDate: true,
  createdAt: true,
  jobId: true,
  locationId: true,
  source: true,
  picture: {
    select: {
      id: true,
      path: true,
      width: true,
      height: true,
    },
  },
  currency: {
    select: {
      id: true,
      name: true,
      code: true,
      symbol: true,
      euroExchangeRate: true,
      decimals: true,
      createdAt: true,
      updatedAt: true,
      translations: true,
    },
  },
  job: {
    select: {
      id: true,
      name: true,
      description: true,
      descriptionTranslations: true,
      availableLevels: true,
      family: { select: { name: true } },
    },
  },
  location: {
    select: {
      id: true,
      name: true,
      isRemote: true,
      country: {
        select: {
          id: true,
          name: true,
          alpha2: true,
        },
      },
    },
  },
  externalEmployee: {
    select: {
      ...selectExternalEmployeeUserPicture,
      ...selectExternalEmployeeData,
      businessUnit: true,
      status: true,
      performanceReviewRating: {
        select: {
          id: true,
          name: true,
        },
      },
      additionalFieldValues: {
        orderBy: { additionalFieldId: "asc" },
        select: {
          stringValue: true,
          numberValue: true,
          dateValue: true,
          percentageValue: true,
          additionalFieldId: true,
        },
      },
    },
  },

  mappingLocation: {
    select: {
      id: true,
      name: true,
      isRemote: true,
      country: {
        select: {
          id: true,
          name: true,
          alpha2: true,
        },
      },
    },
  },

  user: {
    select: { firstName: true, lastName: true, email: true },
  },

  liveEmployeeStats: {
    select: {
      comparisonScope: true,
      targetPercentile: true,
      comparedEmployeesCount: true,
      comparedCompaniesCount: true,
      comparedLevels: true,
      baseSalaryDifference: true,
      baseSalaryForPercentile: true,
      baseSalaryPercentageDifference: true,
      baseSalaryDataQuality: true,
      onTargetEarningsDifference: true,
      onTargetEarningsForPercentile: true,
      onTargetEarningsPercentageDifference: true,
      onTargetEarningsDataQuality: true,
      totalCashDifference: true,
      totalCashForPercentile: true,
      totalCashPercentageDifference: true,
      totalCashDataQuality: true,
      comparedLocations: { select: { name: true } },
    },
  },
} satisfies Prisma.EmployeeSelect;

export const fetchCompanyEmployees = async (ctx: AppContext, params: FetchCompanyEmployeesInput) => {
  const baseWhere = await buildFetchEmployeesWhereClause(ctx, params);
  const orderBy = buildFetchEmployeesOrderByClause(ctx, params);
  const externalEmployeeWhereFilters = params.filters
    ? await buildExternalEmployeeFilterWhereClauses(ctx, params.filters)
    : undefined;

  const extraWhereClause = params.onlyFlagged ? { employeeDataValidationFlags: { some: { isLive: true } } } : {};

  const where = {
    AND: compact([
      baseWhere,
      extraWhereClause,
      externalEmployeeWhereFilters && { externalEmployee: externalEmployeeWhereFilters },
    ]),
  } satisfies Prisma.EmployeeWhereInput;

  const employeesPromise = ctx.prisma.employee.findMany({
    ...applyPrismaRestrictions(),
    where,
    orderBy,
    select: companyEmployeesSelect,

    ...(params.pagination && {
      ...prismaPaginationParams(params.pagination),
    }),
  });

  const countPromise = ctx.prisma.employee.count({
    where,
    orderBy,
  });

  const [items, count, filterOptions] = await Promise.all([
    employeesPromise,
    countPromise,
    fetchExternalEmployeeFilterOptions(ctx),
  ]);

  return buildPaginationResult({
    items,
    count,
    pagination: params.pagination ?? undefined,
    meta: {
      filterOptions,
    },
  });
};

export type FetchCompanyEmployeesResult = AsyncReturnType<typeof fetchCompanyEmployees>;

export const buildFetchEmployeesWhereClause = async (
  ctx: AppContext,
  params: {
    query?: string | null;
    extraWhere?: Prisma.EmployeeWhereInput | null;
    inBackoffice?: boolean;
    externalEmployeeIds?: number[];
  }
) => {
  const user = getRequiredUser(ctx);

  const where: Prisma.EmployeeWhereInput = {
    companyId: user.companyId,

    ...(user.isSuperAdmin &&
      params.inBackoffice &&
      params.extraWhere?.companyId && {
        companyId: params.extraWhere?.companyId,
      }),

    status: EmployeeStatus.LIVE,

    ...(params.externalEmployeeIds && {
      externalEmployee: { id: { in: params.externalEmployeeIds } },
    }),
  };

  if (isString(params.query)) {
    const lowerQuery = lowerCase(params.query);
    const employeeLevels = Object.entries(LEVELS_MAP)
      .filter(([key]) => includes(lowerCase(key), lowerQuery))
      .map(([, value]) => value);

    where.AND = params.query.split(" ").flatMap((part) => {
      const matches = { contains: part, mode: "insensitive" } as const;

      return {
        OR: compact([
          { externalLevel: matches },
          { externalEmployee: { normalisedColumns: matches } },
          { externalJobTitle: matches },
          { job: { OR: [{ name: matches }, { family: { name: matches } }] } },
          { location: { OR: [{ name: matches }, { country: { name: matches } }] } },
          employeeLevels.length > 0 && { level: { in: employeeLevels } },
        ]),
      };
    });
  }

  return where;
};

const buildFetchEmployeesOrderByClause = (
  ctx: AppContext,
  params: {
    order?: OrderParams | null;
  }
) => {
  const column = params.order?.column ?? null;
  const direction = params.order?.direction ?? "asc";
  const directionNullsLast = { sort: direction, nulls: "last" } as const;

  return match<string | null, Prisma.Enumerable<Prisma.EmployeeOrderByWithRelationInput>>(column)
    .with("employee-number", () => ({ employeeNumber: direction }))
    .with("business-unit", () => ({ externalEmployee: { businessUnit: direction } }))
    .with("name", () => [
      { externalEmployee: { firstName: directionNullsLast } },
      { externalEmployee: { lastName: directionNullsLast } },
    ])
    .with("job", () => [{ job: { family: { name: direction } } }, { job: { name: direction } }])
    .with("original-job-title", () => ({ externalEmployee: { job: { name: direction } } }))
    .with("level", () => ({ level: direction }))
    .with("location", () => [{ location: { country: { name: direction } } }, { location: { name: direction } }])
    .with("gender", () => ({ gender: directionNullsLast }))
    .with("base-salary", () => ({ baseSalary: direction }))
    .with("on-target-bonus", () => ({ onTargetBonus: directionNullsLast }))
    .with("fixed-bonus", () => ({ fixedBonus: directionNullsLast }))
    .with("total-cash", () => ({ liveEmployeeStats: { totalCash: direction } }))
    .with("on-target-earnings", () => ({ liveEmployeeStats: { onTargetEarnings: direction } }))
    .with("base-salary", () => ({ baseSalary: direction }))
    .with("target-percentile", () => ({ liveEmployeeStats: { targetPercentile: direction } }))
    .with("total-cash-market-percentile", () => ({ liveEmployeeStats: { totalCashForPercentile: direction } }))
    .with("total-cash-market-percentile-difference", () => ({ liveEmployeeStats: { totalCashDifference: direction } }))
    .with("total-cash-market-percentile-percentage-difference", () => ({
      liveEmployeeStats: { totalCashPercentageDifference: direction },
    }))
    .with("base-salary-market-percentile", () => ({ liveEmployeeStats: { baseSalaryForPercentile: direction } }))
    .with("base-salary-market-percentile-difference", () => ({
      liveEmployeeStats: { baseSalaryDifference: direction },
    }))
    .with("base-salary-market-percentile-percentage-difference", () => ({
      liveEmployeeStats: { baseSalaryPercentageDifference: direction },
    }))
    .with("on-target-earnings-market-percentile", () => ({
      liveEmployeeStats: { onTargetEarningsForPercentile: direction },
    }))
    .with("on-target-earnings-market-percentile-difference", () => ({
      liveEmployeeStats: { onTargetEarningsDifference: direction },
    }))
    .with("on-target-earnings-market-percentile-percentage-difference", () => ({
      liveEmployeeStats: { onTargetEarningsPercentageDifference: direction },
    }))
    .with("performance-review-rating", () => ({
      externalEmployee: { performanceReviewRating: { position: direction } },
    }))
    .otherwise((column) => {
      if (column) {
        logWarn(ctx, "[warn] Unhandled employee order column", { column });
      }

      return { employeeNumber: direction };
    });
};

export const fetchEmployeeHistory = async (ctx: AppContext, externalId: string) => {
  const where = await buildFetchEmployeesWhereClause(ctx, {});
  where.status = undefined;

  return ctx.prisma.employee.findMany({
    where: {
      AND: [
        where,
        {
          externalId: externalId,
          status: "HISTORICAL",
        },
      ],
    },
    include: {
      externalEmployee: {
        select: {
          employeeNumber: true,
          firstName: true,
          lastName: true,
          birthDate: true,
          hireDate: true,
          picture: true,
          job: {
            select: {
              name: true,
            },
          },
          level: {
            select: {
              name: true,
            },
          },
        },
      },
      job: true,
      location: { include: { country: true } },
      currency: true,
      user: true,
      employeeDataValidationFlags: true,
    },
    orderBy: { createdAt: "asc" },
  });
};

export type EmployeeHistory = AsyncReturnType<typeof fetchEmployeeHistory>;

export const Measures: EmployeeStatsMeasure[] = ["totalCash", "baseSalary", "onTargetEarnings"];

export const fetchCompanyEmployeesAggregates = async (
  ctx: AppContext,
  params: {
    query?: string | null;
    filters?: FilterOptionsInput;
    tab?: PeopleTab | null;
    currency: Currency;
  }
) => {
  const user = getRequiredUser(ctx);
  const baseWhere = await buildFetchExternalEmployeesWhereClause(ctx, { query: params.query, tab: params.tab });
  const employeeWhere = await buildFetchEmployeesWhereClause(ctx, params);
  const whereFilters = params.filters ? await buildExternalEmployeeFilterWhereClauses(ctx, params.filters) : undefined;

  const where = {
    AND: compact([
      baseWhere,
      { mappedEmployee: employeeWhere },
      whereFilters,
      { companyId: user.companyId, status: { not: ExternalEmployeeStatus.SKIPPED } },
    ]),
  };

  const externalEmployees = await ctx.prisma.externalEmployee.findMany({
    where,
    select: {
      mappedEmployee: {
        select: {
          currency: { select: { id: true, euroExchangeRate: true, decimals: true } },
          liveEmployeeStats: {
            select: {
              totalCashDifference: true,
              totalCashPercentageDifference: true,
              baseSalaryDifference: true,
              baseSalaryPercentageDifference: true,
              onTargetEarningsDifference: true,
              onTargetEarningsPercentageDifference: true,
            },
          },
        },
      },
    },
  });

  const employees = chain(externalEmployees)
    .map((externalEmployee) => externalEmployee.mappedEmployee)
    .compact()
    .value();

  const aggregates = chain(Measures)
    .keyBy((measure) => measure)
    .mapValues((measure) => {
      const employeesMeasures = employees.map((employee) => {
        const difference = employee.liveEmployeeStats?.[`${measure}Difference`] ?? null;
        const percentageDifference = employee.liveEmployeeStats?.[`${measure}PercentageDifference`] ?? null;
        const marketPositioning = isNotNull(percentageDifference)
          ? getMarketPositioningType(percentageDifference)
          : null;
        const isOnTargetOrAbove = isIn(marketPositioning, [
          EmployeeMarketPositioning.ON_TARGET,
          EmployeeMarketPositioning.ABOVE,
          EmployeeMarketPositioning.WAY_ABOVE,
        ]);

        return {
          currency: employee.currency,
          difference,
          isOnTargetOrAbove,
        };
      });

      const [measuresWithStats, measuresWithoutStats] = partition(
        employeesMeasures,
        (measure) => measure.difference !== null
      );

      const employeesCount = employees.length;
      const employeesWithStatsCount = measuresWithStats.length;
      const employeesWithoutStatsCount = measuresWithoutStats.length;
      const employeesAboveTargetCount = employeesMeasures.filter((employee) => employee.isOnTargetOrAbove).length;
      const employeesAboveTargetRatio = employeesAboveTargetCount / employeesCount;
      const distanceToTargetSum = -sumBy(employeesMeasures, (employee) =>
        convertCurrency(Math.min(0, employee.difference ?? 0), employee.currency, params.currency)
      );

      return {
        employeesCount,
        employeesWithStatsCount,
        employeesWithoutStatsCount,
        employeesAboveTargetCount,
        employeesAboveTargetRatio,
        distanceToTargetSum,
      };
    })
    .value();

  // lodash's mapValue breaks the key type
  return aggregates as Record<EmployeeStatsMeasure, (typeof aggregates)[string]>;
};

export type AggregateForMeasure = AsyncReturnType<typeof fetchCompanyEmployeesAggregates>[EmployeeStatsMeasure];

export type FetchCompanyEmployeesAggregatesResult = AsyncReturnType<typeof fetchCompanyEmployeesAggregates>;

export const fetchEmployeesThatNeedRemappingCount = async (ctx: AppContext) => {
  return ctx.prisma.employee.count({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      status: EmployeeStatus.LIVE,
      externalEmployee: { status: ExternalEmployeeStatus.NEEDS_REMAPPING },
    },
  });
};

export const fetchNonMappedEmployeesCount = async (ctx: AppContext) => {
  return ctx.prisma.externalEmployee.count({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      status: { in: [ExternalEmployeeStatus.UNMAPPED, ExternalEmployeeStatus.PARTIAL] },
      mappedEmployeeId: null,
    },
  });
};
