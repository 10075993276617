import { EmployeeStatus, type Prisma } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { logDebug } from "~/lib/logger";
import { dangerouslyIncludeHistoricalExternalRemunerationItems } from "~/lib/prisma-restrictions/schemas/generateExternalRemunerationItemHistoricalSchema";
import { deleteEmployee } from "~/services/employee/employeeDelete";
import { safeDeleteImage } from "~/services/image";
import { disconnectExternalEmployeesFromPermissions } from "~/services/permissions/disconnectExternalEmployees";

export const deleteExternalEmployee = async (ctx: AppContext, externalEmployeeId: number) => {
  const now = new Date();

  const externalEmployee = await ctx.prisma.externalEmployee.findUniqueOrThrow({
    where: { id: externalEmployeeId },
    select: externalEmployeeSelectForDeletion,
  });

  logDebug(ctx, `[ext-emp] Deleting external employee`, {
    companyId: externalEmployee.companyId,
    source: externalEmployee.source,
    externalEmployeeId: externalEmployee.id,
    employeeId: externalEmployee.mappedEmployeeId,
    externalId: externalEmployee.externalId,
  });

  await ctx.prisma.externalRemunerationItem.deleteMany({
    ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
    where: { employeeId: externalEmployee.id },
  });

  await ctx.prisma.salaryRangeEmployee.deleteMany({
    where: { externalEmployeeId: externalEmployee.id },
  });

  await ctx.prisma.additionalFieldValue.deleteMany({
    where: { externalEmployeeId: externalEmployee.id },
  });

  await disconnectExternalEmployeesFromPermissions(ctx, {
    companyId: externalEmployee.companyId,
    externalEmployeeIds: [externalEmployee.id],
  });

  await ctx.prisma.analyticsEmployee.updateMany({
    where: { externalEmployeeId: externalEmployee.id },
    data: { status: EmployeeStatus.HISTORICAL },
  });

  await ctx.prisma.datasetEmployee.updateMany({
    where: {
      externalEmployeeId: externalEmployee.id,
      OR: [{ lastValidAt: { gt: now } }, { lastValidAt: null }],
    },
    data: {
      lastValidAt: now,
    },
  });

  await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployee.id },
    data: { deletedAt: now, jobId: null, levelId: null, locationId: null },
  });

  await safeDeleteImage(ctx, externalEmployee.picture);

  if (externalEmployee.mappedEmployeeId) {
    await deleteEmployee(ctx, externalEmployee.mappedEmployeeId, { reason: "not-an-employee-anymore" });
  }

  logDebug(ctx, `[ext-emp] Successfully deleted external employee`, {
    companyId: externalEmployee.companyId,
    source: externalEmployee.source,
    externalEmployeeId: externalEmployee.id,
    employeeId: externalEmployee.mappedEmployeeId,
    externalId: externalEmployee.externalId,
  });
};

const externalEmployeeSelectForDeletion = {
  companyId: true,
  source: true,
  id: true,
  mappedEmployeeId: true,
  externalId: true,
  pictureId: true,
  mappedEmployee: {
    select: {
      pictureId: true,
    },
  },
  picture: {
    select: {
      id: true,
      bucket: true,
      path: true,
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;
