import { type PutObjectCommandInput } from "@aws-sdk/client-s3";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";

export const uploadFile = async (ctx: AppContext, input: PutObjectCommandInput) => {
  try {
    await ctx.files.putObject(input);
  } catch (awsError) {
    throw new BusinessLogicError("Failed to upload file")
      .fromError(awsError)
      .withErrorCode("F107")
      .withMetadata({ input, awsError });
  }
};
