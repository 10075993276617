import { type Country, type Prisma } from "@prisma/client";
import classNames from "classnames";
import React from "react";
import CountryFlag from "react-country-flag";
import { useListLocationsQuery } from "~/hooks/useQueries";
import { useI18n } from "~/lib/i18n/useI18n";
import { groupBy } from "~/lib/lodash";

const locationSelectForList = {
  id: true,
  name: true,
  country: {
    select: {
      id: true,
    },
  },
} satisfies Prisma.EmployeeLocationSelect;

type LocationForList = Prisma.EmployeeLocationGetPayload<{ select: typeof locationSelectForList }>;

type Props = {
  locations: LocationForList[];
  vertical?: boolean;
  className?: string;
};

export const LocationList: React.FC<Props> = ({ vertical = false, locations, className }) => {
  const { t } = useI18n();
  const { data: locationData } = useListLocationsQuery();

  const locationsGroupedByCountry = groupBy(locations, (location) => {
    return location.country.id;
  });

  const allLocationsGroupedByCountry = groupBy(locationData?.locations ?? [], (location) => {
    return location.countryId;
  });

  return (
    <span
      className={classNames(className, {
        "flex": true,
        "items-center space-x-2": !vertical,
        "flex-col items-start space-y-1": vertical,
      })}
    >
      {!locations.length && (
        <span className="flex items-center space-x-1 text-gray-400">
          <span>{t("components.ui.location-list.all-locations")}</span>
        </span>
      )}

      {Object.values(locationsGroupedByCountry).map((locations) => {
        const templateCountry = locations[0]?.country as Country;
        const allLocationsFromCountry = locations.length === allLocationsGroupedByCountry[templateCountry.id]?.length;

        return (
          <span key={templateCountry.id} className="flex shrink-0 items-center">
            <span className="flex items-center">
              <CountryFlag svg className="mr-1.5" countryCode={templateCountry.alpha2} />
              {allLocationsFromCountry && <span>{templateCountry.name}</span>}
              {!allLocationsFromCountry && (
                <>
                  {locations.map((location, index) => {
                    return (
                      <span key={location.id} className="flex items-center space-x-0">
                        {index > 0 && <span className="mr-1 text-gray-500">,</span>}
                        <span>{location.name}</span>
                      </span>
                    );
                  })}
                </>
              )}
            </span>
          </span>
        );
      })}
    </span>
  );
};
