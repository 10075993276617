import { QueueJobStatus } from "@prisma/client";
import type { AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { makeQueueDriver } from "~/lib/queue/makeQueueDriver";
import { type QueueJobName } from "~/lib/queue/queueJobName";
import { type CancelJobQueueJobInput } from "~/pages/api/admin/job-queue/cancel-job-queue-job";

export const cancelJobQueueJob = async (ctx: AppContext, input: CancelJobQueueJobInput) => {
  const job = await ctx.prisma.queueJob.findUniqueOrThrow({
    where: { id: input.jobId },
    select: { externalJobId: true, name: true },
  });

  if (!job.externalJobId) {
    throw new BusinessLogicError("Job has no external job ID").withMetadata({ jobId: input.jobId });
  }

  try {
    await makeQueueDriver().cancelJob(ctx, { jobId: job.externalJobId, jobName: job.name as QueueJobName });

    await ctx.prisma.queueJob.update({
      where: { id: input.jobId },
      data: { status: QueueJobStatus.ABORTED },
    });
  } catch (error) {
    throw new FiguresBaseError("Failed to cancel job").fromError(error).withMetadata({ jobId: input.jobId });
  }
};
