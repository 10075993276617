import { type AppContext } from "~/lib/context";
import { traceBusinessService } from "~/lib/datadog/tracing";
import { applyUserAwareTFunction } from "~/lib/i18n/applyUserAwareTfunction";
import { logWarn } from "~/lib/logger";
import { protectPrismaClient } from "~/lib/prisma";
import { flagContextAsInitialised, getPrismaRecursiveProtectionCount, isContextInitialised } from "~/lib/prismaTokens";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { getDefaultCurrency } from "~/services/currency";
import { getActiveFeatureFlagsForUser } from "~/services/featureFlags";
import { getActivePermissionsForUser } from "~/services/permissions/validatePermissions";
import { getActiveProductsForUser } from "~/services/subscriptions/validateSubscription";

export const initContext = async (ctx: AppContext, user?: NullableAuthenticatedUser) => {
  return traceBusinessService({ serviceName: "initContext", tags: { "user.id": user?.id ?? "unknown" } }, async () => {
    if (isContextInitialised(ctx)) {
      logWarn(ctx, "[init-context] WARNING: Recursively initialising context", {
        recursiveProtectionCount: getPrismaRecursiveProtectionCount(ctx.prisma),
        // eslint-disable-next-line no-restricted-syntax
        stack: new Error().stack,
      });
    }

    if (user) {
      ctx.user = user;
    }

    // /!\ We need to compute FFs before protecting prisma
    //
    // /!\ Careful if prisma has already been protected,
    // /!\ feature flags will be generated with previous restrictions
    // /!\ Particular attention to companies included with feature flags
    // /!\ ADMINS_FROM_SELECTED_COMPANIES and ALL_USERS_FROM_SELECTED_COMPANIES scopes
    // /!\ will be tested with `previousUser.companyId === currentUser.companyId`
    ctx.featureFlags = await getActiveFeatureFlagsForUser(ctx);
    ctx.subscriptions = await getActiveProductsForUser(ctx);
    ctx.prisma = await protectPrismaClient(ctx);
    ctx.globalPermissionsContext = await getActivePermissionsForUser(ctx);
    ctx.defaultCurrency = await getDefaultCurrency(ctx);
    applyUserAwareTFunction(ctx);

    flagContextAsInitialised(ctx);

    return ctx;
  });
};
