import { mapSeries } from "bluebird";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { logInfo } from "~/lib/logger";
import { getId } from "~/lib/utils";
import { fetchCompanyAdditionalFields } from "~/services/additional-field/fetchCompanyAdditionalFields";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";

export const refreshCompensationReviewAdditionalFields = async (ctx: CompensationReviewContext) => {
  const user = getRequiredUser(ctx);

  const additionalFields = await fetchCompanyAdditionalFields(ctx);

  logInfo(ctx, "[chore] Refreshing compensation review additional fields", {
    additionalFields,
    companyId: user.companyId,
  });

  const compensationReviewAdditionalFieldsToDelete = await ctx.prisma.compensationReviewAdditionalField.findMany({
    where: {
      companyId: user.companyId,
      ...prismaCompensationReviewScope(ctx.scope),
    },
    select: {
      id: true,
    },
  });

  if (compensationReviewAdditionalFieldsToDelete.length > 0) {
    const idsToDelete = compensationReviewAdditionalFieldsToDelete.map(getId);

    await ctx.prisma.compensationReviewAdditionalFieldValue.deleteMany({
      where: {
        additionalFieldId: { in: idsToDelete },
      },
    });

    await ctx.prisma.compensationReviewAdditionalField.deleteMany({
      where: {
        id: { in: idsToDelete },
      },
    });
  }

  await ctx.prisma.compensationReviewAdditionalField.createMany({
    data: additionalFields.map((additionalField) => ({
      ...prismaCompensationReviewScope(ctx.scope),
      companyId: user.companyId,
      additionalFieldId: additionalField.id,
      name: additionalField.name,
      nature: additionalField.nature,
    })),
  });

  const compensationReviewAdditionalFields = await ctx.prisma.compensationReviewAdditionalField.findMany({
    where: {
      companyId: user.companyId,
      ...prismaCompensationReviewScope(ctx.scope),
    },
    select: {
      id: true,
      additionalFieldId: true,
    },
  });

  const compensationReviewEmployees = await ctx.prisma.compensationReviewEmployee.findMany({
    where: {
      companyId: user.companyId,
      ...prismaCompensationReviewScope(ctx.scope),
    },
    select: {
      id: true,
      externalEmployee: {
        select: {
          additionalFieldValues: true,
        },
      },
    },
  });

  await mapSeries(compensationReviewEmployees, async (compensationReviewEmployee) => {
    await mapSeries(compensationReviewEmployee.externalEmployee.additionalFieldValues, async (additionalFieldValue) => {
      const compensationReviewAdditionalFieldId = compensationReviewAdditionalFields.find(
        (field) => field.additionalFieldId === additionalFieldValue.additionalFieldId
      )?.id;

      if (!compensationReviewAdditionalFieldId) {
        logInfo(ctx, "[chore] Missing compensation review additional field", {
          additionalFieldValue,
          companyId: user.companyId,
          compensationReviewEmployeeId: compensationReviewEmployee.id,
        });
        return;
      }

      await ctx.prisma.compensationReviewAdditionalFieldValue.create({
        data: {
          stringValue: additionalFieldValue.stringValue,
          numberValue: additionalFieldValue.numberValue,
          dateValue: additionalFieldValue.dateValue,
          percentageValue: additionalFieldValue.percentageValue,
          company: {
            connect: {
              id: ctx.companyId,
            },
          },
          compensationReviewEmployee: {
            connect: {
              id: compensationReviewEmployee.id,
            },
          },
          compensationReviewAdditionalField: {
            connect: {
              id: compensationReviewAdditionalFieldId,
            },
          },
        },
      });
    });
  });
};
