import { type ModelName, type WhereInput } from "@casl/prisma/dist/types/prismaClientBoundTypes";
import { EmployeeLevel, Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { traceBusinessService } from "~/lib/datadog/tracing";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { intersection, mapValues } from "~/lib/lodash";
import { dangerouslyIgnorePrismaRestrictions } from "~/lib/prismaTokens";
import { getId, getKeys } from "~/lib/utils";
import { canAccessLevel } from "~/services/employee/employeeLevel";
import { fetchUserExternalEmployeeManageeIdsIgnoringSuperAdmin } from "~/services/external-employee/fetchExternalEmployeeManageeIds";
import { getAllowedJobFamilyIds } from "~/services/job";
import { generateAccountPermissionsSchema } from "~/services/permissions/accountPermissions";
import { generateAdditionalFieldsPermissionsSchema } from "~/services/permissions/additionalFieldsPermissions";
import { type ActionName } from "~/services/permissions/appAbility";
import { generateCompensationReviewAdditionalFieldsPermissionsSchema } from "~/services/permissions/compensarionReviewAdditionalFieldsPermissions";
import { generateCompensationReviewPermissionsSchema } from "~/services/permissions/compensationReviewPermissions";
import { generateEmployeePermissionsSchema } from "~/services/permissions/employeePermissions";
import { generateMarketDataPermissionsSchema } from "~/services/permissions/marketDataPermissions";
import { generateOwnCompanyPermissionsSchema } from "~/services/permissions/ownCompanyPermissions";
import { generatePublicPermissionsSchema } from "~/services/permissions/publicPermissions";
import { generateReadOnlyPermissionsSchema } from "~/services/permissions/readOnlyPermissions";
import { generateSalaryBandsPermissionsSchema } from "~/services/permissions/salaryBandsPermissions";
import { getUserRoles } from "~/services/user/permissions/utils/getUserRoles";
import { makeInSameBusinessUnitFilter } from "./businessUnitPermissions";

type ActionsSchema<Subject extends ModelName> = {
  [Action in ActionName]: boolean | WhereInput<Subject>;
};

export type PermissionsSchema = {
  [Subject in ModelName]: ActionsSchema<Subject>;
};

const computeUserPermissionsSchemaFilters = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  const { isAdmin, isHr, isManager, isRecruiter, isEmployee } = getUserRoles(user);

  const ownCompany = { id: user.companyId };
  const ownedByUser = { userId: user.id };
  const ownedByCompany = { companyId: user.companyId };
  const isSelf = { id: user.id };

  const externalEmployeeManageeIds = await fetchUserExternalEmployeeManageeIdsIgnoringSuperAdmin(ctx, {
    user,
    dangerouslyIgnorePrismaRestrictions: true,
  });

  const employeeManagees = await value(async () => {
    if (!externalEmployeeManageeIds) {
      return null;
    }

    return fetchEmployeeManageesForPermissions(ctx, { externalEmployeeManageeIds });
  });

  const isEmployeeOrRecruiter = isEmployee || isRecruiter;

  const allowedCountryIds = value(() => {
    if (isEmployeeOrRecruiter && isManager) {
      const manageesCountryIds = employeeManagees?.map(({ location }) => location.countryId);
      // NOTE: Returning [-1] here allows us to properly handle the case where a manager has an empty country list (no managees).
      return manageesCountryIds?.length ? manageesCountryIds : [-1];
    }

    if (isEmployeeOrRecruiter || isHr) {
      return user.permissions.allowedCountries.map(getId);
    }

    return null;
  });

  const allowedLocationIds = value(() => {
    if (isEmployeeOrRecruiter && isManager) {
      const manageesLocationIds = employeeManagees?.map(({ location }) => location.id);
      // NOTE: Returning [-1] here allows us to properly handle the case where a manager has an empty country list (no managees).
      return manageesLocationIds?.length ? manageesLocationIds : [-1];
    }

    return null;
  });

  const allowedJobFamilyIds = value(() => {
    const allowedJobFamilyIdsForCompany = value(() => {
      if (!user.company.companyBenchmarkRestriction?.allowedJobFamilies) {
        return null;
      }

      if (user.company.companyBenchmarkRestriction?.allowedJobFamilies.length === 0) {
        return null;
      }

      return user.company.companyBenchmarkRestriction?.allowedJobFamilies.map(getId);
    });

    if (isEmployeeOrRecruiter && isManager) {
      const manageesJobFamilyIds = employeeManagees?.map(({ job }) => job.familyId);

      if (!allowedJobFamilyIdsForCompany) {
        // NOTE: Returning [-1] here allows us to properly handle the case where a manager has an empty country list (no managees).
        return manageesJobFamilyIds?.length ? manageesJobFamilyIds : [-1];
      }

      if (manageesJobFamilyIds?.length) {
        return intersection(allowedJobFamilyIdsForCompany, manageesJobFamilyIds);
      }

      // NOTE: Returning [-1] here allows us to properly handle the case where a manager has an empty country list (no managees).
      return [-1];
    }

    if (isEmployeeOrRecruiter || isHr) {
      return getAllowedJobFamilyIds(user);
    }

    return allowedJobFamilyIdsForCompany;
  });

  const allowedJobIds = value(() => {
    if (isEmployeeOrRecruiter && isManager) {
      const manageesJobIds = employeeManagees?.map(({ job }) => job.id);
      return manageesJobIds?.length ? manageesJobIds : [-1];
    }

    return null;
  });

  const allowedLevels = value(() => {
    if (isEmployeeOrRecruiter && isManager) {
      return employeeManagees?.map(({ level }) => level);
    }

    if (isEmployeeOrRecruiter || isHr) {
      return getKeys(EmployeeLevel).filter((level) => canAccessLevel(ctx.t, user, level));
    }

    return null;
  });

  return {
    isAdmin,
    isEmployee,
    isHr,
    isRecruiter,
    isManager,

    isSelf,
    ownCompany,
    ownedByCompany,
    ownedByUser,
    inSameBusinessUnit: await makeInSameBusinessUnitFilter(ctx, { externalEmployeeManageeIds }),

    externalEmployeeManageeIds,
    allowedCountryIds,
    allowedLocationIds,
    allowedJobFamilyIds,
    allowedJobIds,
    allowedLevels,
  };
};

export type UserPermissionsSchemaFilters = AsyncReturnType<typeof computeUserPermissionsSchemaFilters>;

export const generatePermissionsSchema = async (ctx: AppContext): Promise<PermissionsSchema> => {
  const user = getRequiredUser(ctx);

  return traceBusinessService({ serviceName: "generatePermissionsSchema", tags: { "user.id": user.id } }, async () => {
    if (user.isSuperAdmin && (!config.app.isCli || config.dev.dangerouslyBypassSuperAdminCliRestriction)) {
      return mapValues(Prisma.ModelName, () => ({ read: true, update: true, delete: true }));
    }

    const filters = await computeUserPermissionsSchemaFilters(ctx);

    const employeePermissionsSchema = generateEmployeePermissionsSchema(ctx, filters);

    /**
     * Prisma — CASL
     * We explicitly require all Prisma models to have permissions defined here.
     * Seeing an error here, means you probably created a new model and need to define a permission schema for it.
     */
    return {
      ...generatePublicPermissionsSchema(),
      ...generateReadOnlyPermissionsSchema(),
      ...generateOwnCompanyPermissionsSchema(ctx, filters),
      ...generateAccountPermissionsSchema(ctx, filters),
      ...generateMarketDataPermissionsSchema(ctx, filters),
      ...employeePermissionsSchema,
      ...generateAdditionalFieldsPermissionsSchema(ctx, filters),
      ...generateCompensationReviewAdditionalFieldsPermissionsSchema(ctx, filters),
      ...(await generateSalaryBandsPermissionsSchema(ctx, filters)),
      ...generateCompensationReviewPermissionsSchema(ctx, {
        filters,
        employeePermissionsSchema,
      }),
    };
  });
};

const fetchEmployeeManageesForPermissions = async (
  ctx: AppContext,
  params: { externalEmployeeManageeIds: number[] }
) => {
  return ctx.prisma.employee.findMany({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: { externalEmployee: { id: { in: params.externalEmployeeManageeIds } } },
    select: {
      level: true,
      job: {
        select: {
          id: true,
          familyId: true,
        },
      },
      location: {
        select: {
          id: true,
          countryId: true,
        },
      },
    },
  });
};
