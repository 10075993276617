import { CompensationReviewIncreaseRuleTarget } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import { type CompensationReviewCampaignBudget } from "~/services/compensation-review/campaigns/fetchCampaign";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { convertPopulationToExternalEmployeeWhereClause } from "~/services/employee-filter/fetchExternalEmployeeFilterOptions";
import { validatePopulationFilters } from "~/services/populations/validatePopulationFilters";

export const enrichIncreaseRulesWithPopulation = async (
  ctx: CompensationReviewContext,
  params: {
    budgetId: number;
    rules: Omit<CompensationReviewCampaignBudget["increaseRules"][number], "id" | "population">[];
  }
) => {
  return mapSeries(params.rules, async (rule) => {
    if (!rule.populationId) {
      return {
        ...rule,
        budgetId: params.budgetId,
        employeeIds: null,
      };
    }

    const population = await ctx.prisma.population.findUniqueOrThrow({
      where: { id: rule.populationId },
      select: { query: true, filters: true, includedExternalEmployeeIds: true, excludedExternalEmployeeIds: true },
    });

    const { where } = await convertPopulationToExternalEmployeeWhereClause(ctx, {
      ...population,
      filters: validatePopulationFilters(population.filters),
    });

    const compensationReviewEmployees = await ctx.prisma.compensationReviewEmployee.findMany({
      where: {
        ...prismaCompensationReviewScope(ctx.scope),
        eligibilities: { some: { budgetId: params.budgetId } },
        externalEmployee: where,
        ...(rule.target === CompensationReviewIncreaseRuleTarget.PROMOTED_EMPLOYEES && {
          isPromoted: true,
        }),
        ...(rule.target === CompensationReviewIncreaseRuleTarget.NON_PROMOTED_EMPLOYEES && {
          isPromoted: false,
        }),
      },
      select: { id: true },
    });

    return {
      ...rule,
      budgetId: params.budgetId,
      employeeIds: compensationReviewEmployees.map((employee) => employee.id),
    };
  });
};

export type EnrichIncreaseRulesWithPopulationResult = AsyncReturnType<typeof enrichIncreaseRulesWithPopulation>;

export const enrichCompensationReviewRules = async (ctx: CompensationReviewContext) => {
  const enrichedRules = await mapSeries(ctx.parameters.budgets, async (budget) => {
    return enrichIncreaseRulesWithPopulation(ctx, {
      budgetId: budget.id,
      rules: budget.increaseRules,
    });
  });

  return enrichedRules.flat();
};

export type EnrichedRule = AsyncReturnType<typeof enrichCompensationReviewRules>[number];
