import {
  type ExternalEmployee,
  ExternalEmployeeSource,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  type Prisma,
} from "@prisma/client";
import { match } from "ts-pattern";
import { type IconName } from "~/components/ui/Icons";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { sumBy } from "~/lib/lodash";
import { isNotNull } from "~/lib/utils";

type EmployeeSourceDetails = {
  name: string;
  slug: string;
  icon: IconName;
};

export const getEmployeeSourceDetails = (source: ExternalEmployeeSource): EmployeeSourceDetails => {
  return match<ExternalEmployeeSource, EmployeeSourceDetails>(source)
    .with(ExternalEmployeeSource.BAMBOO, () => {
      return {
        name: "Bamboo",
        slug: "bamboo",
        icon: "bamboo",
      };
    })
    .with(ExternalEmployeeSource.CHARLIEHR, () => {
      return {
        name: "Charlie HR",
        slug: "charliehr",
        icon: "charliehr",
      };
    })
    .with(ExternalEmployeeSource.FACTORIAL, () => {
      return {
        name: "Factorial",
        slug: "factorial",
        icon: "factorial",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_FACTORIAL, () => {
      return {
        name: "Factorial",
        slug: "factorial",
        icon: "factorial",
      };
    })
    .with(ExternalEmployeeSource.HIBOB, () => {
      return {
        name: "Hibob",
        slug: "hibob",
        icon: "hibob",
      };
    })
    .with(ExternalEmployeeSource.HIBOB_DEPRECATED, () => {
      return {
        name: "Hibob",
        slug: "hibob",
        icon: "hibob",
      };
    })
    .with(ExternalEmployeeSource.HUMAANS, () => {
      return {
        name: "Humaans",
        slug: "humaans",
        icon: "humaans",
      };
    })
    .with(ExternalEmployeeSource.LUCCA, () => {
      return {
        name: "Lucca",
        slug: "lucca",
        icon: "lucca",
      };
    })
    .with(ExternalEmployeeSource.MANUAL, () => {
      return {
        name: "Manual input",
        slug: "manual",
        icon: "user-check",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_PAYFIT, () => {
      return {
        name: "Payfit",
        slug: "payfit",
        icon: "payfit",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_NMBRS, () => {
      return {
        name: "NMBRS",
        slug: "nmbrs",
        icon: "nmbrs",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_HIBOB, () => {
      return {
        name: "Hibob",
        slug: "hibob",
        icon: "hibob",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_OFFICIENT, () => {
      return {
        name: "Officient",
        slug: "officient",
        icon: "officient",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_REMOTE, () => {
      return {
        name: "Remote",
        slug: "remotecom",
        icon: "remote",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_SAGEHR, () => {
      return {
        name: "SageHR",
        slug: "sagehr",
        icon: "sagehr",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_SUCCESSFACTORS, () => {
      return {
        name: "SAP SuccessFactors",
        slug: "sapsuccessfactors",
        icon: "sapsuccessfactors",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_AFAS, () => {
      return {
        name: "AFAS Software",
        slug: "afas",
        icon: "afas",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_BREATHEHR, () => {
      return {
        name: "Breathe HR",
        slug: "breathehr",
        icon: "breathehr",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_CATALYSTONE, () => {
      return {
        name: "CatalystOne",
        slug: "catalystone",
        icon: "catalystone",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_CEZANNEHR, () => {
      return {
        name: "Cezanne HR",
        slug: "cezannehr",
        icon: "cezannehr",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_DATEV, () => {
      return {
        name: "DATEV LODAS",
        slug: "datev",
        icon: "datev",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_DEEL, () => {
      return {
        name: "Deel",
        slug: "deel",
        icon: "deel",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_ENTRAID, () => {
      return {
        name: "Entra ID",
        slug: "entraid",
        icon: "entraid",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_EURECIA, () => {
      return {
        name: "Eurécia",
        slug: "eurecia",
        icon: "eurecia",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_GOOGLEWORKSPACE, () => {
      return {
        name: "Google Workspace",
        slug: "googleworkspace",
        icon: "googleworkspace",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_HAILEYHR, () => {
      return {
        name: "Hailey HR",
        slug: "haileyhr",
        icon: "haileyhr",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_HEAVENHR, () => {
      return {
        name: "Heaven HR",
        slug: "heavenhr",
        icon: "heavenhr",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_HRWORKS, () => {
      return {
        name: "HR Works",
        slug: "hrworks",
        icon: "hrworks",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_IRISCASCADE, () => {
      return {
        name: "IRIS Cascade",
        slug: "iriscascade",
        icon: "iriscascade",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_KENJO, () => {
      return {
        name: "Kenjo",
        slug: "kenjo",
        icon: "kenjo",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_MIRUS, () => {
      return {
        name: "Mirus",
        slug: "mirus",
        icon: "mirus",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_OKTA, () => {
      return {
        name: "Okta",
        slug: "okta",
        icon: "okta",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_ORACLEHCM, () => {
      return {
        name: "Oracle HCM",
        slug: "oraclehcm",
        icon: "oraclehcm",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_PLANDAY, () => {
      return {
        name: "Planday",
        slug: "planday",
        icon: "planday",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_SAPLING, () => {
      return {
        name: "Sapling",
        slug: "sapling",
        icon: "sapling",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_SESAMEHR, () => {
      return {
        name: "Sesame HR",
        slug: "sesamehr",
        icon: "sesamehr",
      };
    })
    .with(ExternalEmployeeSource.KOMBO_MANUAL, () => {
      return {
        name: "Manual",
        slug: "manual",
        icon: "manual",
      };
    })
    .with(ExternalEmployeeSource.PERSONIO, () => {
      return {
        name: "Personio",
        slug: "personio",
        icon: "personio",
      };
    })
    .with(ExternalEmployeeSource.SPREADSHEET, () => {
      return {
        name: "Spreadsheet",
        slug: "spreadsheet",
        icon: "spreadsheet",
      };
    })
    .with(ExternalEmployeeSource.PAYSPECTIVE, () => {
      return {
        name: "Payspective",
        slug: "payspective",
        icon: "spreadsheet",
      };
    })
    .with(ExternalEmployeeSource.WORKDAY, () => {
      return {
        name: "Workday",
        slug: "workday",
        icon: "workday",
      };
    })
    .exhaustive();
};

export const externalEmployeeSelectForRemunerationComputation = {
  remunerationItems: {
    select: {
      amount: true,
      asPercentage: true,
      status: true,
      date: true,
      nature: {
        select: { name: true, mappedType: true },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeeForRemunerationComputation = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeeSelectForRemunerationComputation;
}>;

export const computeExternalEmployeeTotalRemuneration = (
  employee: ExternalEmployeeForRemunerationComputation,
  type?: ExternalRemunerationType
): number => {
  const remunerationItems = employee.remunerationItems
    .filter((item) => item.status === ExternalRemunerationStatus.LIVE)
    .filter((item) => {
      if (!type) {
        return true;
      }
      return item.nature.mappedType === type;
    });

  return Math.round(
    sumBy(remunerationItems, (item) => {
      return item.amount;
    })
  );
};

export const computeExternalEmployeeRemunerationIfExist = (
  employee: ExternalEmployeeForRemunerationComputation,
  type: ExternalRemunerationType,
  options?: { percentageMode?: boolean }
): number | null => {
  if (options?.percentageMode && type === ExternalRemunerationType.FIXED_SALARY) {
    throw new BusinessLogicError("Cannot compute fixed salary as percentage of itself");
  }

  const remunerationItems = employee.remunerationItems.filter((item) => {
    return (
      item.nature.mappedType === type &&
      ((!options?.percentageMode && item.asPercentage === null) ||
        (options?.percentageMode && item.asPercentage !== null))
    );
  });

  if (remunerationItems.length === 0) {
    return null;
  }

  return sumBy(remunerationItems, options?.percentageMode ? "asPercentage" : "amount");
};

export const formatExternalEmployeeName = (
  employee: Pick<ExternalEmployee, "externalId" | "firstName" | "lastName" | "employeeNumber">
): string => {
  const parts = [employee.firstName, employee.lastName].filter(isNotNull);

  if (parts.length > 0) {
    return parts.join(" ");
  }

  if (employee.employeeNumber) {
    return `#${employee.employeeNumber}`;
  }

  return `Ext. #${employee.externalId}`;
};

export type RemunerationBag = {
  baseSalary: number | null;
  fixedBonus: number | null;
  fixedBonusPercentage: number | null;
  onTargetBonus: number | null;
  onTargetBonusPercentage: number | null;
};

export type RemunerationsWithSources = {
  local: RemunerationBag;
  external: RemunerationBag;
  computed: RemunerationBag;
};

type EmployeeForRemunerationComputation = RemunerationBag;

export type MinimumExternalEmployeeForRemunerationComputation = ExternalEmployeeForRemunerationComputation & {
  mappedEmployee: EmployeeForRemunerationComputation | null;
};

export const computeRemunerationsWithSources = (
  externalEmployee: MinimumExternalEmployeeForRemunerationComputation
): RemunerationsWithSources => {
  const externalRemuneration: RemunerationBag = {
    baseSalary: computeExternalEmployeeRemunerationIfExist(externalEmployee, ExternalRemunerationType.FIXED_SALARY),
    fixedBonus: computeExternalEmployeeRemunerationIfExist(externalEmployee, ExternalRemunerationType.FIXED_BONUS),
    onTargetBonus: computeExternalEmployeeRemunerationIfExist(
      externalEmployee,
      ExternalRemunerationType.VARIABLE_BONUS
    ),
    fixedBonusPercentage: computeExternalEmployeeRemunerationIfExist(
      externalEmployee,
      ExternalRemunerationType.FIXED_BONUS,
      {
        percentageMode: true,
      }
    ),
    onTargetBonusPercentage: computeExternalEmployeeRemunerationIfExist(
      externalEmployee,
      ExternalRemunerationType.VARIABLE_BONUS,
      {
        percentageMode: true,
      }
    ),
  };

  const localRemuneration: RemunerationBag = {
    baseSalary: externalEmployee.mappedEmployee?.baseSalary ?? null,
    fixedBonus: externalEmployee.mappedEmployee?.fixedBonus ?? null,
    onTargetBonus: externalEmployee.mappedEmployee?.onTargetBonus ?? null,
    fixedBonusPercentage: externalEmployee.mappedEmployee?.fixedBonusPercentage ?? null,
    onTargetBonusPercentage: externalEmployee.mappedEmployee?.onTargetBonusPercentage ?? null,
  };

  return {
    local: localRemuneration,
    external: externalRemuneration,
    computed: {
      baseSalary:
        localRemuneration.baseSalary && localRemuneration.baseSalary !== externalRemuneration.baseSalary
          ? localRemuneration.baseSalary
          : externalRemuneration.baseSalary,
      fixedBonus:
        localRemuneration.fixedBonus && localRemuneration.fixedBonus !== externalRemuneration.fixedBonus
          ? localRemuneration.fixedBonus
          : externalRemuneration.fixedBonus,
      onTargetBonus:
        localRemuneration.onTargetBonus && localRemuneration.onTargetBonus !== externalRemuneration.onTargetBonus
          ? localRemuneration.onTargetBonus
          : externalRemuneration.onTargetBonus,
      fixedBonusPercentage:
        localRemuneration.fixedBonusPercentage &&
        localRemuneration.fixedBonusPercentage !== externalRemuneration.fixedBonusPercentage
          ? localRemuneration.fixedBonusPercentage
          : externalRemuneration.fixedBonusPercentage,
      onTargetBonusPercentage:
        localRemuneration.onTargetBonusPercentage &&
        localRemuneration.onTargetBonusPercentage !== externalRemuneration.onTargetBonusPercentage
          ? localRemuneration.onTargetBonusPercentage
          : externalRemuneration.onTargetBonusPercentage,
    },
  };
};
