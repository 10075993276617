/*
 * This function allos to go through an object's keys and match them against a
 * predefined rule to transform it's associated value.
 * This is useful to redact secrets from datadog's tracing or from our logs
 * */
import { compact, isString } from "~/lib/lodash";
import { isIn } from "~/lib/utils";

export const transformObject = <
  T extends object | null | undefined,
  ReturnType = T extends null | undefined ? object : T,
>(
  obj: T,
  params: { transformers: Transformer[] }
) => {
  if (!obj) {
    return {} as ReturnType;
  }

  return Object.entries(obj).reduce((acc: Record<string, unknown>, [key, value]) => {
    params.transformers.forEach(({ keyMatcher, valueMapper }) => {
      if (keyMatcher(key)) {
        acc[key] = valueMapper(value);
        return;
      }

      if (typeof value === "object" && value !== null) {
        acc[key] = transformObject(value as Record<string, unknown>, params);
        return;
      }

      acc[key] = value;
    });

    return acc;
  }, {}) as ReturnType;
};

export type Transformer = {
  keyMatcher: (key: string) => boolean;
  valueMapper: <T>(value: T) => T | unknown;
};

export const makeSecretRedactorTransformer = (source?: string) => {
  return {
    keyMatcher: (key: string) => {
      const lowerCaseKey = key.toLowerCase();
      return lowerCaseKey.includes("secret") && !lowerCaseKey.includes("id");
    },
    valueMapper: () => compact(["<redacted>", isString(source) && `from ${source}`]).join(" "),
  } as const satisfies Transformer;
};

export const makeQueueJobDataRedactorTransformer = (source?: string) => {
  return {
    keyMatcher: (key: string) => {
      const lowerCaseKey = key.toLowerCase();
      return isIn(lowerCaseKey, ["rows", "values"]);
    },
    valueMapper: () => compact(["<redacted>", isString(source) && `from ${source}`]).join(" "),
  } as const satisfies Transformer;
};
