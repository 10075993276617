import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { startCampaignSandbox } from "~/services/compensation-review/campaigns/admin/startCampaignSandbox";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

const StartCampaignSandboxJobDataSchema = BaseJobDataSchema.concat(object({ campaignId: number().required() }));

export type StartCampaignSandboxJobData = YupOutputType<typeof StartCampaignSandboxJobDataSchema>;

export const startCampaignSandboxWorkerService = async (ctx: AppContext, data: StartCampaignSandboxJobData) => {
  const context = await fetchCompensationReviewCampaignContext(ctx, data);

  await startCampaignSandbox(context);
};

export const sendStartCampaignSandboxJob = async (ctx: AppContext, data: StartCampaignSandboxJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.START_COMPENSATION_REVIEW_CAMPAIGN_SANDBOX,
    data,
    options: {
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.START_COMPENSATION_REVIEW_CAMPAIGN_SANDBOX },
        with: { campaignId: data.campaignId },
      }),
    },
  });
};
