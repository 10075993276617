import { Check, DragIndicator, LayersOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { FormGroup, ListItemIcon, ListItemText, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { type ItemInterface, ReactSortable } from "react-sortablejs";
import { Button } from "~/components/ui/core/Button";
import { DropDown } from "~/components/ui/core/DropDown";
import { IconButton } from "~/components/ui/core/IconButton";
import { type Column } from "~/components/ui/core/Table";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { type TableColumnVisibilityKeys } from "~/pages/api/update-user-flag";
import { useUpdateColumnsPreferencesMutation } from "~/pages/api/users/update-columns-preferences";
import { ACTIONS_COLUMN_ID } from "~/services/table/getColumnsOrder";
import { getUserRoles } from "~/services/user/permissions/utils/getUserRoles";

type ColumnsDropDownMenuProps<Entry> = {
  orderedColumns: Column<Entry>[];
  visibleColumnIds: string[];
  onReorder: (orderedColumnIds: string[]) => void;
  onVisibilityChange: (columnId: string, visible: boolean) => void;
  visibleColumnsKey?: TableColumnVisibilityKeys;
  className?: string;
};

export const ColumnsDropDownMenu = <Entry,>({
  orderedColumns,
  visibleColumnIds,
  onReorder,
  onVisibilityChange,
  className,
  visibleColumnsKey,
}: ColumnsDropDownMenuProps<Entry>) => {
  const { t } = useI18n();
  const { user, updateFlag, isUpdatingFlag } = useSession();

  const [isVisibilityDropdownOpen, setIsVisibilityDropdownOpen] = useState<boolean>(false);

  const updateColumnsPreferences = useUpdateColumnsPreferencesMutation({
    successMessage: t("components.core.table.columns-saved"),
  });

  const displayedColumns = orderedColumns.filter((column) => !column.fixed && column.id !== ACTIONS_COLUMN_ID);

  const persistedPayload = useMemo(() => {
    return displayedColumns.map((column) => ({
      id: column.id,
      visible: visibleColumnIds.includes(column.id),
    }));
  }, [displayedColumns, visibleColumnIds]);

  const handleReorder = (orderedColumns: Column<Entry>[]) => {
    onReorder(orderedColumns.map((column) => column.id));
  };

  return (
    <DropDown
      open={isVisibilityDropdownOpen}
      onOpenChange={(open: boolean) => setIsVisibilityDropdownOpen(open)}
      className={className}
      label={t("components.core.table.columns")}
      ButtonProps={{ startIcon: <LayersOutlined fontSize="medium" /> }}
      MenuProps={{
        MenuListProps: {
          className: "!p-0",
        },
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        transformOrigin: { vertical: "top", horizontal: "right" },
      }}
      header={t("components.core.table.ordering-visibility")}
    >
      <FormGroup className="flex flex-col flex-nowrap overflow-x-scroll">
        <div className="overflow-x-scroll">
          <ReactSortable list={displayedColumns} setList={handleReorder} handle=".handler">
            {displayedColumns.map((column: Column<Entry> & ItemInterface) => {
              const isVisible = visibleColumnIds.includes(column.id);

              return (
                <Stack key={column.id} direction="row" alignItems="center" className="handler">
                  <MenuItem
                    className={classNames({
                      "my-0 w-full items-center py-1": true,
                      "bg-primary-100": column.chosen,
                      "cursor-grab": !column.chosen,
                      "cursor-grabbing": column.chosen,
                    })}
                    disableRipple
                    onClick={() => onVisibilityChange(column.id, !isVisible)}
                  >
                    <ListItemIcon>
                      <DragIndicator className="text-sm text-gray-500" />
                    </ListItemIcon>

                    <ListItemText className={classNames({ "!-mt-0.5": true, "opacity-50": !isVisible })}>
                      <Typography variant="caption">{column.name}</Typography>
                    </ListItemText>

                    <IconButton className="ml-4">
                      {isVisible ? (
                        <VisibilityOutlined className="text-sm" />
                      ) : (
                        <VisibilityOffOutlined className="text-sm text-gray-300" />
                      )}
                    </IconButton>
                  </MenuItem>
                </Stack>
              );
            })}
          </ReactSortable>
        </div>

        {!!visibleColumnsKey && (
          <Stack className="border-t py-2 px-1" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<Check fontSize="small" />}
              isLoading={isUpdatingFlag}
              onClick={async () => {
                await updateFlag({ [visibleColumnsKey]: persistedPayload }, t("common.saved-successfully"));
              }}
            >
              {t("components.core.table.save-columns")}
            </Button>

            {getUserRoles(user).isAdmin && (
              <Tooltip title={t("components.core.table.default.tooltip")}>
                <Button
                  variant="contained"
                  isLoading={updateColumnsPreferences.isLoading}
                  onClick={async () => {
                    await updateColumnsPreferences.mutateAsync({
                      columns: persistedPayload,
                      columnsKey: visibleColumnsKey,
                    });

                    await updateFlag({ [visibleColumnsKey]: persistedPayload });
                  }}
                >
                  {t("components.core.table.set-as-company-default")}
                </Button>
              </Tooltip>
            )}
          </Stack>
        )}
      </FormGroup>
    </DropDown>
  );
};
