import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import { config } from "~/config";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logWarn } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class BusinessLogicError extends FiguresBaseError {
  constructor(readonly message: string) {
    super(message);
    Object.setPrototypeOf(this, BusinessLogicError.prototype);
  }
}

export const handleBusinessLogicError = (ctx: AppContext, res: NextApiResponse, error: BusinessLogicError) => {
  logWarn(ctx, "[error] Business logic error", { error });

  if (config.app.isJest) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }

  res.status(HttpStatus.BAD_REQUEST).json(makeErrorPayload({ error, defaultCode: ErrorCode.BUSINESS_LOGIC }));
};
