import { CompensationReviewSalaryPeriod } from "@prisma/client";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

export const getDefaultSalaryPeriod = async (ctx: CompensationReviewCampaignContext) => {
  const hasAnyEmployee = !!(await ctx.prisma.compensationReviewEmployee.count({
    where: { campaignId: ctx.campaign.id },
  }));

  if (!hasAnyEmployee) {
    return CompensationReviewSalaryPeriod.YEARLY;
  }

  const hasEmployeeWithYearlySalaryPeriod = !!(await ctx.prisma.compensationReviewEmployee.findFirst({
    where: {
      campaignId: ctx.campaign.id,
      eligibilities: { some: {} },
      defaultSalaryPeriod: CompensationReviewSalaryPeriod.YEARLY,
    },
    select: { id: true },
  }));

  return hasEmployeeWithYearlySalaryPeriod
    ? CompensationReviewSalaryPeriod.YEARLY
    : CompensationReviewSalaryPeriod.MONTHLY;
};
