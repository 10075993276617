import {
  type Currency,
  type EmployeeLevel,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  type ExternalRemunerationNature,
  type FundingRound,
  type Gender,
  type Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { chunk, isEqual, isNil, sortBy } from "~/lib/lodash";
import { type ComputeEmployeeCompensationInput } from "~/services/employee";
import { computeExternalEmployeeCompensation } from "~/services/external-employee/computeCompensation";
import { sendSyncDatasetEmployeesJob } from "~/workers/syncDatasetEmployees";

export const selectForCompanyForDatasetEmployee = {
  id: true,
  tags: true,
  liveSurvey: {
    select: {
      employeesCount: true,
      lastFundingRound: true,
    },
  },
} satisfies Prisma.CompanySelect;

export type CompanyDataForDatasetEmployeeSync = Prisma.CompanyGetPayload<{
  select: typeof selectForCompanyForDatasetEmployee;
}>;

export const externalEmployeeSelectForDatasetEmployeeSync = {
  id: true,
  externalId: true,
  companyId: true,
  status: true,
  source: true,
  level: {
    select: {
      mappedLevel: true,
    },
  },
  job: {
    select: {
      mappedJob: {
        select: {
          id: true,
        },
      },
    },
  },
  location: {
    select: {
      mappedLocation: {
        select: {
          id: true,
        },
      },
    },
  },
  mappedEmployee: {
    select: {
      gender: true,
      jobId: true,
      locationId: true,
      level: true,
      benchmarkLevel: true,
      employeeDataValidationFlags: true,
      baseSalary: true,
      fixedBonus: true,
      fixedBonusPercentage: true,
      onTargetBonus: true,
      onTargetBonusPercentage: true,
      currency: true,
    },
  },
  remunerationItems: {
    select: {
      amount: true,
      nature: {
        select: {
          mappedType: true,
        },
      },
    },
  },
  currency: true,
  gender: true,
} satisfies Prisma.ExternalEmployeeSelect;

const EMPLOYEE_BATCH_SIZE = 100;

export const syncAllDatasetEmployeesByCompany = async (
  ctx: AppContext,
  props: { company: CompanyDataForDatasetEmployeeSync }
) => {
  const { company } = props;

  const allExternalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: company.id,
      status: ExternalEmployeeStatus.MAPPED,
    },
    select: { id: true },
  });

  await mapSeries(chunk(allExternalEmployees, EMPLOYEE_BATCH_SIZE), (externalEmployeeIds) =>
    sendSyncDatasetEmployeesJob(ctx, {
      companyId: company.id,
      externalEmployeeIds: externalEmployeeIds.map((ee) => ee.id),
    })
  );
};

export const syncSomeDatasetEmployeesByCompany = async (
  ctx: AppContext,
  props: { company: CompanyDataForDatasetEmployeeSync; externalEmployeeIds: number[] }
) => {
  const { company, externalEmployeeIds } = props;

  const externalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: company.id,
      id: { in: externalEmployeeIds },
    },
    select: externalEmployeeSelectForDatasetEmployeeSync,
  });

  await mapSeries(externalEmployees, async (externalEmployee) => {
    //still choose Employee over everything else, because of the "Employee form" behavior overriding mappings and sync.
    const { mappedEmployee: employee } = externalEmployee;
    const mappedLevel = employee?.level ?? externalEmployee.level?.mappedLevel;
    const mappedJobId = employee?.jobId ?? externalEmployee.job?.mappedJob?.id;
    const mappedLocationId = employee?.locationId ?? externalEmployee.location?.mappedLocation?.id;

    const companyInformation = {
      headcount: company.liveSurvey?.employeesCount,
      fundingRound: company.liveSurvey?.lastFundingRound,
      companyTags: sortBy(company.tags.map((tag) => tag.id)),
    };

    if (employee?.employeeDataValidationFlags?.some((flag) => flag.isLive) || !employee) {
      return;
    }

    await syncDatasetEmployee(ctx, {
      externalEmployee: {
        ...externalEmployee,
        mappedLevel,
        mappedJobId,
        mappedLocationId,
        companyId: company.id,
        company: companyInformation,
        mappedEmployee: employee,
      },
    });
  });
};

type SyncDatasetEmployeeProps = {
  id: number;
  source: ExternalEmployeeSource;
  externalId: string;
  companyId: number;
  mappedLocationId?: number;
  mappedJobId?: number;
  mappedLevel?: EmployeeLevel | null;
  gender?: Gender | null;
  remunerationItems: {
    amount: number;
    nature: Pick<ExternalRemunerationNature, "mappedType">;
  }[];
  mappedEmployee: ComputeEmployeeCompensationInput & { benchmarkLevel: { min: number; max: number } };
  currency: Currency;
  company: {
    headcount?: number | null;
    fundingRound?: FundingRound | null;
    companyTags: number[];
  };
};

export const syncDatasetEmployee = async (
  ctx: AppContext,
  props: {
    externalEmployee: SyncDatasetEmployeeProps;
    euro?: Currency;
  }
) => {
  const { externalEmployee } = props;

  const existingDatasetEmployee = await ctx.prisma.datasetEmployee.findFirst({
    where: {
      externalId: externalEmployee.externalId,
      companyId: externalEmployee.companyId,
      lastValidAt: null,
    },
  });

  const currency = externalEmployee.mappedEmployee?.currency ?? externalEmployee.currency;

  const { compensation: baseSalary } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: "baseSalary",
    targetCurrency: currency,
  });

  if (
    isNil(externalEmployee.mappedLocationId) ||
    isNil(externalEmployee.mappedJobId) ||
    isNil(externalEmployee.mappedLevel) ||
    isNil(baseSalary)
  ) {
    return;
  }

  const { compensation: fixedBonus } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: "fixedBonus",
    targetCurrency: currency,
  });

  const { compensation: onTargetBonus } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: "onTargetBonus",
    targetCurrency: currency,
  });

  const hasSpreadsheetSource = externalEmployee.source === ExternalEmployeeSource.SPREADSHEET;

  if (!existingDatasetEmployee) {
    return ctx.prisma.datasetEmployee.create({
      data: {
        companyId: externalEmployee.companyId,
        externalId: externalEmployee.externalId,
        currencyCode: externalEmployee.currency.code,
        euroExchangeRate: externalEmployee.currency.euroExchangeRate,
        companyHeadcount: externalEmployee.company.headcount,
        companyFundingRound: externalEmployee.company.fundingRound,
        companyTagsIds: externalEmployee.company.companyTags,
        locationId: externalEmployee.mappedLocationId,
        figuresLocationId: externalEmployee.mappedLocationId,
        jobId: externalEmployee.mappedJobId,
        figuresJobId: externalEmployee.mappedJobId,
        level: externalEmployee.mappedLevel,
        figuresLevel: externalEmployee.mappedLevel,
        benchmarkLevelMin: externalEmployee.mappedEmployee.benchmarkLevel.min,
        benchmarkLevelMax: externalEmployee.mappedEmployee.benchmarkLevel.max,
        figuresBenchmarkLevelMin: externalEmployee.mappedEmployee.benchmarkLevel.min,
        figuresBenchmarkLevelMax: externalEmployee.mappedEmployee.benchmarkLevel.max,
        gender: externalEmployee.gender,
        baseSalary,
        fixedBonus,
        onTargetBonus,
        liveAt: new Date(),
        excludedFromDataset: true,
        externalEmployeeId: externalEmployee.id,
        ...(hasSpreadsheetSource && { lastValidAt: new Date() }),
      },
    });
  }

  const hadLocationChanged = existingDatasetEmployee.locationId !== externalEmployee.mappedLocationId;
  const isFiguresLocationDifferentFromNewLocation =
    existingDatasetEmployee.figuresLocationId !== externalEmployee.mappedLocationId;
  const hadJobChanged = existingDatasetEmployee.jobId !== externalEmployee.mappedJobId;
  const isFiguresJobDifferentFromNewJob = existingDatasetEmployee.figuresJobId !== externalEmployee.mappedJobId;
  const hadLevelChanged = existingDatasetEmployee.level !== externalEmployee.mappedLevel;
  const isFiguresLevelDifferentFromNewLevel = existingDatasetEmployee.figuresLevel !== externalEmployee.mappedLevel;

  const updatedDatasetEmployee = {
    ...existingDatasetEmployee,
    //mapping informations
    ...(hadLocationChanged && {
      locationId: externalEmployee.mappedLocationId,
    }),
    ...(isFiguresLocationDifferentFromNewLocation &&
      hadLocationChanged && {
        figuresLocationId: externalEmployee.mappedLocationId,
      }),
    ...(hadJobChanged && {
      jobId: externalEmployee.mappedJobId,
    }),
    ...(isFiguresJobDifferentFromNewJob &&
      hadJobChanged && {
        figuresJobId: externalEmployee.mappedJobId,
      }),
    ...(hadLevelChanged && {
      level: externalEmployee.mappedLevel,
    }),
    ...(isFiguresLevelDifferentFromNewLevel &&
      hadLevelChanged && {
        figuresLevel: externalEmployee.mappedLevel,
      }),

    //compensation information
    ...(existingDatasetEmployee.baseSalary !== baseSalary && {
      baseSalary: baseSalary,
    }),
    ...(existingDatasetEmployee.fixedBonus !== fixedBonus && {
      fixedBonus: fixedBonus,
    }),
    ...(existingDatasetEmployee.onTargetBonus !== onTargetBonus && {
      onTargetBonus: onTargetBonus,
    }),
    //company information
    ...(existingDatasetEmployee.companyFundingRound !== externalEmployee.company.fundingRound && {
      companyFundingRound: externalEmployee.company.fundingRound,
    }),
    ...(existingDatasetEmployee.companyHeadcount !== externalEmployee.company.headcount && {
      companyHeadcount: externalEmployee.company.headcount,
    }),
    ...(existingDatasetEmployee.companyTagsIds !== externalEmployee.company.companyTags && {
      companyTagsIds: externalEmployee.company.companyTags,
    }),
    ...(existingDatasetEmployee.benchmarkLevelMin !== externalEmployee.mappedEmployee.benchmarkLevel.min && {
      benchmarkLevelMin: externalEmployee.mappedEmployee.benchmarkLevel.min,
    }),
    ...(existingDatasetEmployee.benchmarkLevelMax !== externalEmployee.mappedEmployee.benchmarkLevel.max && {
      benchmarkLevelMax: externalEmployee.mappedEmployee.benchmarkLevel.max,
    }),
    ...(existingDatasetEmployee.figuresBenchmarkLevelMin !== externalEmployee.mappedEmployee.benchmarkLevel.min &&
      existingDatasetEmployee.benchmarkLevelMin !== externalEmployee.mappedEmployee.benchmarkLevel.min && {
        figuresBenchmarkLevelMin: externalEmployee.mappedEmployee.benchmarkLevel.min,
      }),
    ...(existingDatasetEmployee.figuresBenchmarkLevelMax !== externalEmployee.mappedEmployee.benchmarkLevel.max &&
      existingDatasetEmployee.benchmarkLevelMax !== externalEmployee.mappedEmployee.benchmarkLevel.max && {
        figuresBenchmarkLevelMax: externalEmployee.mappedEmployee.benchmarkLevel.max,
      }),
  };

  if (!isEqual(updatedDatasetEmployee, existingDatasetEmployee)) {
    await ctx.prisma.datasetEmployee.update({
      where: { id: existingDatasetEmployee.id },
      data: {
        lastValidAt: hasSpreadsheetSource ? existingDatasetEmployee.lastValidAt : new Date(), //for spreadsheet source, we keep the original lastValidAt
        excludedFromDataset: true,
      },
    });

    await ctx.prisma.datasetEmployee.create({
      data: {
        companyId: updatedDatasetEmployee.companyId,
        externalId: updatedDatasetEmployee.externalId,
        companyHeadcount: updatedDatasetEmployee.companyHeadcount,
        companyFundingRound: updatedDatasetEmployee.companyFundingRound,
        locationId: updatedDatasetEmployee.locationId,
        figuresLocationId: updatedDatasetEmployee.figuresLocationId,
        companyTagsIds: updatedDatasetEmployee.companyTagsIds,
        jobId: updatedDatasetEmployee.jobId,
        figuresJobId: updatedDatasetEmployee.figuresJobId,
        level: updatedDatasetEmployee.level,
        figuresLevel: updatedDatasetEmployee.figuresLevel,
        gender: updatedDatasetEmployee.gender,
        baseSalary: baseSalary,
        currencyCode: externalEmployee.currency.code,
        euroExchangeRate: externalEmployee.currency.euroExchangeRate,
        benchmarkLevelMin: updatedDatasetEmployee.benchmarkLevelMin,
        benchmarkLevelMax: updatedDatasetEmployee.benchmarkLevelMax,
        fixedBonus: fixedBonus,
        onTargetBonus: onTargetBonus,
        liveAt: new Date(),
        excludedFromDataset: true,
        externalEmployeeId: externalEmployee.id,
        ...(hasSpreadsheetSource && { lastValidAt: new Date() }),
      },
    });
  }
};
