import { ExternalEmployeeSource, ExternalRemunerationStatus, ExternalRemunerationType } from "@prisma/client";
import { isAfter, isBefore, parseISO, subYears } from "date-fns";
import { match } from "ts-pattern";
import { type KomboEmployment } from "~/lib/hris/kombo";
import { isIn } from "~/lib/utils";
import { type EmployeeData } from "~/services/synchronization/syncExternalEmployees";

export type PayfitVariableCompensation = {
  bonuses: PayfitBonusCompensation[];
  contractual_annual_variable_pay?: number | null;
};

// These ids are based a kombo spreadsheet
// https://docs.google.com/spreadsheets/d/1Y1fMNuEYlJZfATAo5jOMp4p3iDrgzJqYwaB-YfYvGMs/edit
export const PAYFIT_VARIABLE_TYPE_NAMES = [
  { id: "1", name: "Prime sur objectif" },
  { id: "23", name: "Prime sur objectifs" },
  { id: "65", name: "Commissions" },
] as const;
export const PAYFIT_BONUSES_TYPE_NAMES = [
  // Careful to NOT MODIFY the ’ and ' :pray:
  { id: "20", name: "Prime de vacances" },
  { id: "17", name: "Prime de bilan" },
  { id: "18", name: "Prime de rendement" },
  { id: "2", name: "Prime d’assiduité" },
  { id: "3", name: "Prime de sécurité" },
  { id: null, name: "Autre" },
  { id: "16", name: "Prime de dimanche" },
  { id: "14", name: "Prime de risque/danger" },
  { id: "15", name: "Prime de froid" },
  { id: "4", name: "Prime de pénibilité" },
  { id: "5", name: "Prime de nuit" },
  { id: "6", name: "Prime d’insalubrité ou de salissure" },
  { id: "7", name: "Prime de fin d’année" },
  { id: "8", name: "Prime de Noël" },
  { id: "9", name: "Prime de naissance" },
  { id: "10", name: "Prime de mariage" },
  { id: "68", name: "Prime de partage de la valeur" },
  { id: "12", name: "Prime d’habillage/d’outillage" },
  { id: null, name: "Prime d'ancienneté" },
  { id: null, name: "Prime d'expatriation" },
  { id: "27", name: "Prime d'impatriation" },
  { id: null, name: "Prime de mentorat" },
  { id: "35", name: "Prime de management" },
  { id: "36", name: "Prime de démarrage" },
  { id: null, name: "Prime de formation" },
  { id: "40", name: "Prime de sourcing" },
  { id: "42", name: "Prime de déplacement" },
  { id: "43", name: "Prime de cooptation" },
  { id: "44", name: "Prime exceptionnelle" },
  { id: null, name: "Prime de staffing" },
  { id: "46", name: "Prime business" },
  { id: "49", name: "Prime de pouvoir d'achat" },
] as const;

// 51.991 is a payfit field containing the bonus id
// Kombo expose it as it
// Here is a message from kombo's cto
// https://figures-hr.slack.com/archives/C04LYNTTMU6/p1689873962930919?thread_ts=1685967421.135029&cid=C04LYNTTMU6
export type PayfitBonusCompensation = {
  amount: number;
  end_date: string | null;
  start_date: string;
  frequency: "ONCE" | "MONTHLY" | "QUARTERLY" | "SEMIANNUALLY" | "ANNUALLY";
  type_name: string;
  remote_data: {
    "51.991": string;
  };
};

export const variableAndBonusMultiplier = (frequency: PayfitBonusCompensation["frequency"]) =>
  match(frequency)
    .with("ONCE", () => 1)
    .with("MONTHLY", () => 12)
    .with("QUARTERLY", () => 4)
    .with("SEMIANNUALLY", () => 2)
    .with("ANNUALLY", () => 1)
    .exhaustive();

export const computeExternalRemunerationItem = (
  bonus: PayfitBonusCompensation,
  companyId: number,
  type: Extract<ExternalRemunerationType, "FIXED_BONUS" | "VARIABLE_BONUS">
) => {
  const multiplier = variableAndBonusMultiplier(bonus.frequency);

  const externalId = match(type)
    .with(ExternalRemunerationType.FIXED_BONUS, () => {
      const prefix = `fixed-${bonus.type_name}`;

      return bonus.frequency !== "ONCE" ? prefix : `${prefix}-${bonus.start_date}`;
    })
    .with(ExternalRemunerationType.VARIABLE_BONUS, () => {
      const prefix = `variable-${bonus.type_name}`;

      return bonus.frequency !== "ONCE" ? prefix : `${prefix}-${bonus.start_date}`;
    })
    .exhaustive();

  return {
    company: {
      connect: { id: companyId },
    },
    source: ExternalEmployeeSource.KOMBO_PAYFIT,
    externalId,
    amount: bonus.amount * multiplier * 100,
    status: ExternalRemunerationStatus.LIVE,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: companyId,
            source: ExternalEmployeeSource.KOMBO_PAYFIT,
            externalId,
          },
        },
        create: {
          source: ExternalEmployeeSource.KOMBO_PAYFIT,
          externalId,
          name: bonus.type_name,
          mappedType: type,
          company: {
            connect: {
              id: companyId,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

export const getVariableBonuses = (
  fixedAndVariableBonuses: PayfitBonusCompensation[],
  companyId: number,
  contractualVariable?: number | null
) => {
  if (!!contractualVariable) {
    return [
      {
        company: {
          connect: { id: companyId },
        },
        source: ExternalEmployeeSource.KOMBO_PAYFIT,
        externalId: "variable-bonus",
        status: ExternalRemunerationStatus.LIVE,
        amount: contractualVariable * 100,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: companyId,
                source: ExternalEmployeeSource.KOMBO_PAYFIT,
                externalId: "variable-bonus",
              },
            },
            create: {
              source: ExternalEmployeeSource.KOMBO_PAYFIT,
              externalId: "variable-bonus",
              name: "Variable bonus",
              mappedType: ExternalRemunerationType.VARIABLE_BONUS,
              company: {
                connect: {
                  id: companyId,
                },
              },
            },
          },
        },
      } satisfies EmployeeData["remunerationItems"][number],
    ];
  }

  return filterBonuses(fixedAndVariableBonuses, "variable").map((variableData) =>
    computeExternalRemunerationItem(variableData, companyId, ExternalRemunerationType.VARIABLE_BONUS)
  );
};

export const getActiveBonusItems = (bonuses: PayfitBonusCompensation[]) =>
  bonuses.filter((fixedAndVariable) => {
    const startDate = parseISO(fixedAndVariable.start_date);
    const endDate = fixedAndVariable.end_date ? parseISO(fixedAndVariable.end_date) : null;
    const today = new Date();

    if (isBefore(startDate, subYears(today, 1)) && fixedAndVariable.frequency === "ONCE") return false;
    if (isAfter(startDate, today)) return false;
    if (endDate && isBefore(endDate, subYears(today, 1))) return false;

    return true;
  });

const filterBonuses = (bonuses: PayfitBonusCompensation[], fixedOrVariable: "fixed" | "variable") => {
  const ids = match(fixedOrVariable)
    .with("fixed", () => PAYFIT_BONUSES_TYPE_NAMES.map(({ id }) => id))
    .with("variable", () => PAYFIT_VARIABLE_TYPE_NAMES.map(({ id }) => id))
    .exhaustive();

  const names = match(fixedOrVariable)
    .with("fixed", () => PAYFIT_BONUSES_TYPE_NAMES.map(({ name }) => name))
    .with("variable", () => PAYFIT_VARIABLE_TYPE_NAMES.map(({ name }) => name))
    .exhaustive();

  return bonuses.filter(
    (bonus) =>
      isIn(bonus.type_name, names) || (!!bonus?.remote_data["51.991"] && isIn(bonus.remote_data["51.991"], ids))
  );
};

export const getPayfitBonusItems = (employments: KomboEmployment[], companyId: number) => {
  const remunerationItems: EmployeeData["remunerationItems"] = [];

  if (employments.length && employments[0]?.remote_data && employments[0]?.remote_data["/_VARIABLE_COMPENSATION"]) {
    const allFixedAndVariableBonusesData = employments[0].remote_data["/_VARIABLE_COMPENSATION"];

    const { contractual_annual_variable_pay: contractualVariable } = allFixedAndVariableBonusesData;

    const fixedAndVariableBonusesData = getActiveBonusItems(allFixedAndVariableBonusesData.bonuses);

    const variableBonuses = getVariableBonuses(fixedAndVariableBonusesData, companyId, contractualVariable);

    if (variableBonuses.length) {
      remunerationItems.push(...variableBonuses);
    }

    const fixedBonuses = filterBonuses(fixedAndVariableBonusesData, "fixed").map((fixedData) =>
      computeExternalRemunerationItem(fixedData, companyId, ExternalRemunerationType.FIXED_BONUS)
    );

    if (fixedBonuses.length) {
      remunerationItems.push(...fixedBonuses);
    }
  }

  return remunerationItems;
};
