import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { Route } from "nextjs-routes";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logWarn } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class ForbiddenError extends FiguresBaseError {
  constructor(
    readonly message: string,
    public readonly redirect?: Route
  ) {
    super(message);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export const handleForbiddenError = (ctx: AppContext, res: NextApiResponse, error: ForbiddenError) => {
  logWarn(ctx, "[error] Forbidden", { error });

  res.status(HttpStatus.FORBIDDEN).json(makeErrorPayload({ error, defaultCode: ErrorCode.FORBIDDEN }));
};
