import { config } from "~/config";

const removeQueryParamsAndReload = () => {
  window.location.href = window.location.pathname;
};

export const handleExceptionalRightsChange = () => {
  if (config.dev.ignoreExceptionalRightsChangeRedirect) {
    removeQueryParamsAndReload();
    return;
  }

  if (window.location.pathname.startsWith("/admin")) {
    window.location.href = "/admin";
    return;
  }

  const campaignPattern = /^\/compensation-review\/campaigns\/(\d+)/;
  const match = window.location.pathname.match(campaignPattern);
  if (match) {
    window.location.href = `/compensation-review/campaigns/${match[1]}`;
    return;
  }

  if (window.location.pathname.startsWith("/compensation-review")) {
    removeQueryParamsAndReload();
    return;
  }

  window.location.href = "/home";
};
