import { CompensationReviewSalaryPeriod } from "@prisma/client";
import { createContext, useContext } from "react";
import { match } from "ts-pattern";
import { groupBy } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";

export type SalaryPeriodConfig = {
  numberOfMonths: number;
};

const SalaryPeriodContext = createContext<CompensationReviewSalaryPeriod>(CompensationReviewSalaryPeriod.YEARLY);

export const useSalaryPeriod = () => {
  const salaryPeriod = useContext(SalaryPeriodContext);

  return {
    salaryPeriod,

    convertSalaryToFrequency: (salary: number, options?: SalaryPeriodConfig) => {
      if (!options?.numberOfMonths) {
        return salary;
      }

      return match(salaryPeriod)
        .with(CompensationReviewSalaryPeriod.MONTHLY, () => salary / options.numberOfMonths)
        .with(CompensationReviewSalaryPeriod.YEARLY, () => salary)
        .exhaustive();
    },

    convertAmountToYearlyFrequency: (amount: number, options?: SalaryPeriodConfig) => {
      if (!options?.numberOfMonths) {
        return amount;
      }

      return match(salaryPeriod)
        .with(CompensationReviewSalaryPeriod.MONTHLY, () => Math.round(amount * options.numberOfMonths))
        .with(CompensationReviewSalaryPeriod.YEARLY, () => amount)
        .exhaustive();
    },
  };
};

export const SalaryPeriodProvider = SalaryPeriodContext.Provider;

export const getDefaultSalaryPeriod = (salaryPeriods: CompensationReviewSalaryPeriod[]) => {
  const groups = Object.values(groupBy(salaryPeriods));

  if (groups.length === 0 || groups.length > 1) return CompensationReviewSalaryPeriod.YEARLY;

  return assertNotNil(groups[0]?.[0]);
};
