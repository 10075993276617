import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { getId } from "~/lib/utils";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissionsSchema";

export const generateCompensationReviewAdditionalFieldsPermissionsSchema = (
  ctx: AppContext,
  filters: UserPermissionsSchemaFilters
) => {
  const user = getRequiredUser(ctx);
  const { isAdmin, isHr, ownedByCompany } = filters;

  const canAccessCompensationReviewAdditionalFields = value(() => {
    if (isAdmin || isHr) {
      return true;
    }

    // Check wether we can use this permission here or create a new one
    return user.permissions.canAccessAdditionalFields;
  });

  const allowedCompensationReviewAdditionalFieldIds = value(() => {
    // Trick to avoid CASL throwing an error, we just won't fetch anything.
    if (!canAccessCompensationReviewAdditionalFields) {
      return [-1];
    }

    if (isAdmin || isHr) {
      return null;
    }

    return user.permissions.allowedAdditionalFields.flatMap((additionalField) =>
      additionalField.compensationReviewAdditionalFields.map(getId)
    );
  });

  const compensationReviewAdditionalFieldIsAccessible = {
    ...ownedByCompany,
    ...(allowedCompensationReviewAdditionalFieldIds?.length && {
      id: { in: allowedCompensationReviewAdditionalFieldIds },
    }),
  };

  const compensationReviewAdditionalFieldValueIsAccessible = {
    ...ownedByCompany,
    ...(allowedCompensationReviewAdditionalFieldIds?.length && {
      additionalFieldId: { in: allowedCompensationReviewAdditionalFieldIds },
    }),
  };

  return {
    CompensationReviewAdditionalField: {
      read: compensationReviewAdditionalFieldIsAccessible,
      update: (isAdmin || isHr) && compensationReviewAdditionalFieldIsAccessible,
      delete: (isAdmin || isHr) && compensationReviewAdditionalFieldIsAccessible,
    },

    CompensationReviewAdditionalFieldValue: {
      read: compensationReviewAdditionalFieldValueIsAccessible,
      update: (isAdmin || isHr) && compensationReviewAdditionalFieldValueIsAccessible,
      delete: (isAdmin || isHr) && compensationReviewAdditionalFieldValueIsAccessible,
    },
  } satisfies Partial<PermissionsSchema>;
};
