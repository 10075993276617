import {
  CompensationReviewBudgetAdjustmentCriteria,
  CompensationReviewCompensationItem,
  CompensationReviewRecommendationOrigin,
  type EmployeeMarketPositioning,
  type SalaryRangeEmployeeRangePositioning,
} from "@prisma/client";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { bilinearInterpolation } from "~/lib/bilinearInterpolation";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { chain, clamp, isNil, mean, partition, sumBy } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";
import { computeRecommendationMatrix } from "~/services/compensation-review/campaigns/admin/computeRecommendationMatrix";
import { type FetchCampaignRecommendationDistributionResult } from "~/services/compensation-review/campaigns/admin/fetchCampaignRecommendationDistribution";
import {
  buildFetchCompensationReviewEmployeesInput,
  fetchCompensationReviewEmployees,
} from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import { type FetchCampaignResult } from "~/services/compensation-review/campaigns/fetchCampaign";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { enrichIncreaseRulesWithPopulation } from "~/services/compensation-review/shared/rules/enrichIncreaseRulesWithPopulation";
import { resolveRules } from "~/services/compensation-review/shared/rules/resolveRules";
import { getMarketPositioningDetails } from "~/services/marketPositioning";
import {
  DEFAULT_TIERS_MODE,
  getDefaultTierNames,
  getSalaryBandPositioningDetails,
} from "~/services/salaryBandsPositioning";

export const computeEmployeesWithMatrixRecommendations = async (
  ctx: CompensationReviewCampaignContext,
  params: {
    totalBudget: number;
    budgetId: number;
    subBudget: FetchCampaignResult["budgets"][number]["subBudgets"][number] & {
      adjustmentCriteria: CompensationReviewBudgetAdjustmentCriteria;
      recommendationsAllocation: number;
      performanceRewardFactor: number;
      adjustmentFactor: number;
    };
    recommendationDistribution: FetchCampaignRecommendationDistributionResult;
    enableSmoothing: boolean;
    enableRulesOverride: boolean;
  }
) => {
  const { adjustmentCriteria, recommendationsAllocation, performanceRewardFactor, adjustmentFactor } = params.subBudget;

  const employees = await fetchCompensationReviewEmployees(ctx, {
    scope: ctx.scope,
    ...buildFetchCompensationReviewEmployeesInput({
      onlyEligible: true,
      onlyEligibleToBudgetId: params.budgetId,
      pagination: null,
      extraWhere: params.recommendationDistribution.where,
    }),
  });

  const recommendationMatrix = computeRecommendationMatrix({
    campaign: ctx.campaign,
    subBudget: params.subBudget,
    recommendationDistribution: params.recommendationDistribution,
    adjustmentCriteria,
    recommendationsAllocation,
    performanceRewardFactor,
    adjustmentFactor,
    skippedPerformanceRatingIds: params.subBudget.skippedPerformanceRatingIds,
    skippedPositionings: params.subBudget.skippedPositionings,
  });

  const employeesWithMatrixRecommendations = await computeEmployeesWithBaseMatrixRecommendations(ctx, {
    ...params,
    employees,
    recommendationMatrix,
  });

  const employeesWithSmoothing = params.enableSmoothing
    ? computeEmployeesWithSmoothing(ctx, {
        employees: employeesWithMatrixRecommendations,
        recommendationMatrix,
        adjustmentCriteria,
      })
    : employeesWithMatrixRecommendations;

  const employeesWithAdjustment = computeEmployeesWithAdjustment(ctx, {
    employees: employeesWithSmoothing,
  });

  if (!params.enableRulesOverride) {
    return {
      employees: employeesWithAdjustment,
      budgetDifference: 0,
    };
  }

  return computeEmployeesWithRuleOverrides(ctx, {
    employees: employeesWithAdjustment,
    totalBudget: params.totalBudget,
    recommendationsAllocation,
  });
};

const computeEmployeesWithBaseMatrixRecommendations = async (
  ctx: CompensationReviewCampaignContext,
  params: {
    budgetId: number;
    subBudget: FetchCampaignResult["budgets"][number]["subBudgets"][number] & {
      adjustmentCriteria: CompensationReviewBudgetAdjustmentCriteria;
    };
    employees: AsyncReturnType<typeof fetchCompensationReviewEmployees>;
    recommendationDistribution: FetchCampaignRecommendationDistributionResult;
    recommendationMatrix: ReturnType<typeof computeRecommendationMatrix>;
  }
) => {
  const budget = getCompensationReviewBudget(ctx, params.budgetId);
  const { adjustmentCriteria } = params.subBudget;

  const rules = await enrichIncreaseRulesWithPopulation(ctx, {
    budgetId: budget.id,
    rules: budget.increaseRules,
  });

  return chain(params.employees.items)
    .map((employee) => {
      const increaseRules = resolveRules(ctx, {
        employee,
        rules,
        promotion: employee,
        compensationItem: CompensationReviewCompensationItem.ON_TARGET_EARNINGS,
      });

      const performanceRating =
        employee.performanceRating ?? params.recommendationDistribution.defaultPerformanceRating;

      if (!performanceRating) return;

      const { positioning, adjustmentFactor } = match(adjustmentCriteria)
        .with(CompensationReviewBudgetAdjustmentCriteria.MARKET_POSITIONING, () => ({
          positioning:
            employee.externalEmployee.mappedEmployee?.liveEmployeeStats?.onTargetEarningsMarketPositioning ??
            params.recommendationDistribution.defaultPositioning,
          adjustmentFactor:
            employee.externalEmployee.mappedEmployee?.liveEmployeeStats?.onTargetEarningsPercentageDifference,
        }))
        .with(CompensationReviewBudgetAdjustmentCriteria.SALARY_BANDS_POSITIONING, () => ({
          positioning:
            employee.salaryRangeEmployee?.orderingRangePositioning ??
            params.recommendationDistribution.defaultPositioning,
          adjustmentFactor: employee.salaryRangeEmployee?.orderingRangePenetration,
        }))
        .exhaustive();

      if (params.subBudget.skippedPerformanceRatingIds.includes(performanceRating.id)) return;
      if (params.subBudget.skippedPositionings.includes(positioning)) return;

      const matrixRow = assertNotNil(params.recommendationMatrix.find((row) => row.rating.id === performanceRating.id));
      const matrixCell = assertNotNil(matrixRow.columns.find((cell) => positioning === cell.positioning));

      const recommendationPercentage = matrixCell.recommendationPercentage;

      return {
        ...employee,
        increaseRules,
        performanceRating,
        matrixCell,
        adjustmentFactor,
        recommendationPercentage,
      };
    })
    .compact()
    .value();
};

const computeEmployeesWithSmoothing = (
  ctx: CompensationReviewCampaignContext,
  params: {
    employees: AsyncReturnType<typeof computeEmployeesWithBaseMatrixRecommendations>;
    recommendationMatrix: ReturnType<typeof computeRecommendationMatrix>;
    adjustmentCriteria: CompensationReviewBudgetAdjustmentCriteria;
  }
) => {
  const [employeesToSmooth, employeesWithoutSmoothing] = partition(
    params.employees,
    (employee) => !isNil(employee.adjustmentFactor)
  );

  const interpolate = chain(params.recommendationMatrix)
    .flatMap((row, x) => {
      return row.columns.map((cell) => {
        if (!cell.employeesCount) return;

        const positioningDetails = match(params.adjustmentCriteria)
          .with(CompensationReviewBudgetAdjustmentCriteria.MARKET_POSITIONING, () =>
            getMarketPositioningDetails(ctx.t, cell.positioning as EmployeeMarketPositioning)
          )
          .with(CompensationReviewBudgetAdjustmentCriteria.SALARY_BANDS_POSITIONING, () =>
            getSalaryBandPositioningDetails(ctx.t, {
              positioning: cell.positioning as SalaryRangeEmployeeRangePositioning,
              tiersMode: ctx.salaryGrid?.tiersMode ?? DEFAULT_TIERS_MODE,
              tiersNames: ctx.salaryGrid?.tiersNames ?? getDefaultTierNames(ctx.t),
            })
          )
          .exhaustive();

        const y = value(() => {
          if (isFinite(positioningDetails.min) && isFinite(positioningDetails.max)) {
            return mean([positioningDetails.min, positioningDetails.max]);
          }

          const adjustementFactorsWithinCell = employeesToSmooth
            .filter((employee) => employee.matrixCell === cell)
            .map((employee) => employee.adjustmentFactor);

          if (!adjustementFactorsWithinCell.length) return null;

          return mean(adjustementFactorsWithinCell);
        });

        if (!y) return;

        return { row, x, y, z: cell.recommendationPercentage };
      });
    })
    .compact()
    .thru((points) => {
      try {
        return bilinearInterpolation(points);
      } catch (error) {
        return () => {
          throw new BusinessLogicError(error.message).withErrorCode("F149");
        };
      }
    })
    .value();

  return [
    ...employeesToSmooth.map((employee) => {
      const recommendationPercentage = value(() => {
        try {
          const recommendationPercentage = interpolate({
            x: assertNotNil(employee.performanceRating.position),
            y: assertNotNil(employee.adjustmentFactor),
          });

          return Math.max(recommendationPercentage, 0);
        } catch {
          return employee.matrixCell.recommendationPercentage;
        }
      });

      return {
        ...employee,
        recommendationPercentage,
      };
    }),
    ...employeesWithoutSmoothing,
  ];
};

const computeEmployeesWithAdjustment = (
  ctx: CompensationReviewCampaignContext,
  params: {
    employees: AsyncReturnType<typeof computeEmployeesWithBaseMatrixRecommendations>;
  }
) => {
  return params.employees.map((employee) => ({
    ...employee,
    adjustment: {
      recommendationPercentage: employee.recommendationPercentage,
      recommendation: employee.convertedOnTargetEarnings * employee.recommendationPercentage,
      origin: CompensationReviewRecommendationOrigin.MATRIX as CompensationReviewRecommendationOrigin,
    },
  }));
};

const computeEmployeesWithRuleOverrides = (
  ctx: CompensationReviewCampaignContext,
  params: {
    employees: ReturnType<typeof computeEmployeesWithAdjustment>;
    totalBudget: number;
    recommendationsAllocation: number;
  }
) => {
  const allocableBudget = params.totalBudget * params.recommendationsAllocation;

  const adjustEmployees = (params: {
    employees: ReturnType<typeof computeEmployeesWithAdjustment>;
    remainingPasses: number;
  }): { employees: ReturnType<typeof computeEmployeesWithAdjustment>; budgetDifference: number } => {
    const allocatedBudget = sumBy(
      params.employees,
      (employee) => employee.convertedOnTargetEarnings * employee.adjustment.recommendationPercentage
    );
    const adjustmentFactor = allocableBudget / allocatedBudget;

    const employees = params.employees.map((employee) => {
      const recommendationPercentage = employee.adjustment.recommendationPercentage * adjustmentFactor;
      const adjustment = {
        recommendationPercentage,
        recommendation: employee.convertedOnTargetEarnings * recommendationPercentage,
        origin: CompensationReviewRecommendationOrigin.MATRIX as CompensationReviewRecommendationOrigin,
      };

      const isWithinLimits = employee.increaseRules.checkSoftLimits(adjustment.recommendation);

      if (!isWithinLimits) {
        const min = employee.increaseRules.overallLimits?.min?.targetAmount;
        if (!isNil(min)) {
          adjustment.recommendation = Math.max(adjustment.recommendation, min);
        }

        const max = employee.increaseRules.overallLimits?.max?.targetAmount;
        if (!isNil(max)) {
          adjustment.recommendation = clamp(adjustment.recommendation, 0, max);
        }

        adjustment.origin = CompensationReviewRecommendationOrigin.RULE;
        adjustment.recommendationPercentage = adjustment.recommendation / employee.convertedOnTargetEarnings;
      }

      return {
        ...employee,
        adjustment,
      };
    });

    const newTotalBudget = sumBy(employees, (employee) => employee.adjustment.recommendation);
    const budgetDifference = newTotalBudget - allocableBudget;

    if (params.remainingPasses === 0 || Math.abs(budgetDifference) < 50) {
      return {
        employees,
        budgetDifference,
      };
    }

    return adjustEmployees({
      employees,
      remainingPasses: params.remainingPasses - 1,
    });
  };

  return adjustEmployees({
    employees: params.employees,
    remainingPasses: 100,
  });
};
