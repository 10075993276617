import { EmployeeStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { logError, logInfo } from "~/lib/logger";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { JobPriority } from "~/lib/queue/drivers/queueDriver";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { getId, type YupOutputType } from "~/lib/utils";
import { sendUpdateEmployeesStatsJob } from "~/workers/updateEmployeesStats";

const BATCH_SIZE = 100;

const UpdateCompanyEmployeesStatsJobDataSchema = BaseJobDataSchema;

export type UpdateCompanyEmployeesStatsJobData = YupOutputType<typeof UpdateCompanyEmployeesStatsJobDataSchema>;

export const updateCompanyEmployeesStatsWorkerService = async (
  ctx: AppContext,
  data: UpdateCompanyEmployeesStatsJobData
) => {
  const { companyId } = UpdateCompanyEmployeesStatsJobDataSchema.validateSync(data, { abortEarly: false });

  try {
    let offset = 0;
    let employees: { id: number }[] = [];

    do {
      employees = await ctx.prisma.employee.findMany({
        where: {
          companyId,
          status: EmployeeStatus.LIVE,
        },
        select: { id: true },
        orderBy: { id: "asc" },
        skip: offset,
        take: BATCH_SIZE,
      });

      offset += BATCH_SIZE;

      if (employees.length > 0) {
        await sendUpdateEmployeesStatsJob(
          ctx,
          { companyId, employeeIds: employees.map(getId) },
          { priority: JobPriority.HIGH }
        );
      }
    } while (employees.length > 0);

    logInfo(ctx, "[company-employees-stats] Sending jobs to update employees stats for company", { companyId });
  } catch (error) {
    logError(
      ctx,
      "[company-employees-stats] There was an error while sending jobs to update employees stats for company",
      {
        error,
        companyId,
      }
    );

    throw error;
  }
};

export const sendUpdateCompanyEmployeesStatsJob = async (ctx: AppContext, data: UpdateCompanyEmployeesStatsJobData) => {
  return sendJob(ctx, {
    jobName: QueueJobName.UPDATE_COMPANY_EMPLOYEES_STATS,
    data,
    options: {
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.UPDATE_COMPANY_STATS },
      }),
    },
  });
};
