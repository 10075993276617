import { subject } from "@casl/ability";
import { type WhereInputPerModel } from "@casl/prisma/dist/types/prismaClientBoundTypes";
import { Prisma, type PrismaClient, UserLocale } from "@prisma/client";
import { value } from "~/components/helpers";
import { cloneObject } from "~/lib/cloning";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { addTranslationsToArgs, translatePrismaResponse } from "~/lib/i18n/prismaI18n";
import { cloneDeep, compact, isArray, isSymbol, mapValues, pick, upperFirst } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { applyDynamicPrismaRestrictions } from "~/lib/prisma-restrictions/applyDynamicPrismaRestrictions";
import { generatePrismaRestrictionsSchemasFn } from "~/lib/prisma-restrictions/generatePrismaRestrictionsSchemaFn";
import { DangerouslyIncludeSoftDeletedExternalEmployeesToken } from "~/lib/prisma-restrictions/schemas/generateExternalEmployeesSoftDeleteSchema";
import { DangerouslyIncludeHistoricalExternalRemunerationItemsToken } from "~/lib/prisma-restrictions/schemas/generateExternalRemunerationItemHistoricalSchema";
import { convertBigIntsFromPrisma, roundBigIntsForPrisma } from "~/lib/prismaBigintPatch";
import {
  ApplyPrismaRestrictionsToken,
  DangerouslyIgnorePrismaRestrictionsToken,
  DebugQueryToken,
  getPrismaRecursiveProtectionCount,
  incrementPrismaRecursiveProtectionCount,
  removeTokensFromPayload,
} from "~/lib/prismaTokens";
import {
  type PrismaDelegateFunction,
  type PrismaMethod,
  type PrismaModel,
  type PrismaPayload,
  prismaSchemaDef,
  ProtectedPrismaUniqueMethods,
} from "~/lib/prismaTypes";
import { isIn } from "~/lib/utils";
import { createAbility } from "~/services/permissions/createAbility";
import { BLACKLISTED_MODELS_NOT_TO_RESTRICT } from "~/services/permissions/publicPermissions";

// Add your model in this list if you want more debug details
const MODELS_TO_DEBUG = [] satisfies Prisma.ModelName[];

const restrictDeepIncludeOrSelect = (params: {
  upperFirstModelName: Prisma.ModelName;
  includeOrSelect: PrismaPayload;
  prismaRestrictions: WhereInputPerModel;
  isDebugEnabled?: boolean;
}): PrismaPayload => {
  const { upperFirstModelName, includeOrSelect, prismaRestrictions, isDebugEnabled } = params;
  const isModelToDebug = isIn(upperFirstModelName, MODELS_TO_DEBUG);
  const shouldLogDebug = isDebugEnabled && isModelToDebug;

  if (shouldLogDebug) {
    /* eslint-disable no-console */
    console.log();
    console.log();
    console.log(`===== DEBUG QUERY - restrictDeepIncludeOrSelect - ${upperFirstModelName} =====`);
    /* eslint-enable no-console */
  }

  const getModelRestrictions = (params: {
    modelName: Prisma.ModelName;
    property: Record<string, unknown>;
    payload: PrismaPayload;
    key: string;
  }) => {
    const { modelName, property, payload, key } = params;

    if (!Object.hasOwn(Prisma.ModelName, modelName)) {
      // Happens when reaching the last leaf of a branch and the value is not a protected model
      // e.g. include: { externalLocations: true }
      return payload;
    }

    const prismaModelRestriction = prismaRestrictions[modelName];

    const restrictionsToApply = value(() => {
      // Happens when model is restricted but user has no restriction defined
      // e.g. user can read all jobs
      if (Object.keys(prismaModelRestriction).length === 0) {
        return payload;
      }

      // Handles *-to-many relationships
      if (property.type === "array") {
        return {
          where: {
            ...includeOrSelect[key].where,
            ...prismaModelRestriction,
          },
        };
      }

      // Handles many-to-1 relationships
      if (!!property.anyOf) {
        return {
          ...payload,
          where: {
            ...payload.where,
            ...prismaModelRestriction,
          },
        };
      }

      // Supposedly reaching this point means we don't really care so far
      // about the value and just want to keep it as is
      // e.g. fetching the employee's manager { include: { manager: true } }
      return payload;
    });

    if (isDebugEnabled && isIn(modelName, MODELS_TO_DEBUG)) {
      /* eslint-disable no-console */
      console.log();
      console.log(`The following are the CASL Restrictions for ${modelName}:`);
      console.log(JSON.stringify(prismaModelRestriction, null, 2));
      console.log();
      console.log("And those are the metadata for the protection:");
      console.log(JSON.stringify({ property, payload }, null, 2));
      console.log();
      console.log("Finally, the protection itself:");
      console.log(JSON.stringify({ restrictionsToApply }, null, 2));
      /* eslint-enable no-console */
    }

    return restrictionsToApply;
  };

  const properties = prismaSchemaDef.definitions[upperFirstModelName].properties;

  const protectedIncludeOrSelect = mapValues(includeOrSelect, (value, key) => {
    if (!Object.hasOwn(properties, key)) {
      // Happens when querying a property not directly related to the model
      // e.g. user: { select: { companyId: true } }
      return value;
    }

    const property = properties[key];
    const anyOfItem = property.anyOf?.find((item: Record<string, string>) => !!item?.$ref);
    const reference = property?.$ref || property.items?.$ref || anyOfItem?.$ref;

    if (!reference) {
      // Happens when reaching the last leaf of a branch and the value is a simple one
      // e.g. defaultCurrency: { select: { code: true } }
      return value;
    }

    const correspondingDependencyModel = reference.replace("#/definitions/", "") as keyof typeof Prisma.ModelName;
    const modelRestrictions = getModelRestrictions({
      modelName: correspondingDependencyModel,
      property,
      payload: value,
      key,
    });

    if (shouldLogDebug) {
      /* eslint-disable no-console */
      console.log();
      console.log(`The following are the CASL Restrictions for ${upperFirstModelName}`);
      console.log(JSON.stringify(modelRestrictions, null, 2));
      /* eslint-enable no-console */
    }

    const restrictedValue = {
      ...modelRestrictions,
      ...pick(value, ["orderBy", "cursor", "take", "skip", "distinct"]),
    };

    // Recursively restricting nested include or select statements and
    // since Prisma doesn't allow for both statements on the same level,
    // this is enough to handle both cases
    if (value.include || value.select) {
      const restrictedNestedIncludeOrSelect = restrictDeepIncludeOrSelect({
        upperFirstModelName: correspondingDependencyModel,
        includeOrSelect: value.include ?? value.select,
        prismaRestrictions,
        isDebugEnabled,
      });

      const statement = value.include ? "include" : "select";

      return {
        ...restrictedValue,
        [statement]: restrictedNestedIncludeOrSelect,
      };
    }

    return restrictedValue;
  });

  return protectedIncludeOrSelect;
};

export const restrictPrismaParams = (params: {
  model: string;
  args: PrismaPayload;
  method?: PrismaMethod;
  actionRestrictions: WhereInputPerModel;
  readRestrictions: WhereInputPerModel;
  isDebugEnabled?: boolean;
}) => {
  const { model, args, method, actionRestrictions, readRestrictions, isDebugEnabled } = params;
  const modelName = getPrismaModelName(model);
  const isProtectedModel = Object.hasOwn(Prisma.ModelName, modelName);

  if (args.include) {
    args.include = restrictDeepIncludeOrSelect({
      upperFirstModelName: modelName,
      includeOrSelect: args.include,
      prismaRestrictions: readRestrictions,
      isDebugEnabled,
    });
  }

  if (args.select) {
    args.select = restrictDeepIncludeOrSelect({
      upperFirstModelName: modelName,
      includeOrSelect: args.select,
      prismaRestrictions: readRestrictions,
      isDebugEnabled,
    });
  }

  if (!isProtectedModel) {
    return args;
  }

  // Handling of combined keys here, such has bandId_levelId
  if (isIn(method, ProtectedPrismaUniqueMethods)) {
    args.where = {
      ...args.where,
      ...actionRestrictions[modelName],
    };

    return args;
  }

  const whereValues = compact([args.where, actionRestrictions[modelName]]);

  if (whereValues.length === 1) {
    args.where = whereValues[0];
  }

  if (whereValues.length > 1) {
    args.where = {
      AND: whereValues,
    };
  }

  return args;
};

const prismaActionAndMethodMapping = {
  findFirst: "read",
  findFirstOrThrow: "read",
  findMany: "read",
  count: "read",
  aggregate: "read",
  groupBy: "read",
  findUnique: "read",
  findUniqueOrThrow: "read",
  deleteMany: "delete",
  delete: "delete",
  updateMany: "update",
  update: "update",
  upsert: "create",
  create: "create",
  createMany: "create",
} as const;

export const protectPrismaClient = async (ctx: AppContext) => {
  const recursiveProtectionCount = getPrismaRecursiveProtectionCount(ctx.originalPrisma);
  if (recursiveProtectionCount > 1) {
    logWarn(ctx, "[prisma] WARNING: Recursive Prisma Client Protection detected!", {
      recursiveProtectionCount,
      // eslint-disable-next-line no-restricted-syntax
      stack: new Error().stack,
    });
  }

  const ability = await createAbility(ctx);
  const applyPrismaRestrictionSchemas = generatePrismaRestrictionsSchemasFn();

  const protectedPrisma = new Proxy(cloneObject(ctx.originalPrisma), {
    // WARNING: we moved the types to `any` because of the following issue:
    // tsc was complaining about the complexity of the combination of all our types
    // Before, it was: `target: PrismaClient, modelOrProperty: keyof PrismaClient`
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: (target: any, modelOrProperty: any) => {
      if (isSymbol(modelOrProperty) || modelOrProperty.startsWith("$") || modelOrProperty.startsWith("_")) {
        return target[modelOrProperty];
      }

      return mapValues(target[modelOrProperty], (fn: PrismaDelegateFunction, method: PrismaMethod) => {
        return async (args: PrismaPayload = {}) => {
          const modelName = getPrismaModelName(modelOrProperty);
          const payloadWithoutTokens = cloneDeep(removeTokensFromPayload(args)) as PrismaPayload;

          if (Object.hasOwn(args, DangerouslyIgnorePrismaRestrictionsToken)) {
            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          // Some models are not to be protected until everything is cleaned up
          const isBlacklistedModel = BLACKLISTED_MODELS_NOT_TO_RESTRICT.includes(modelName);

          // Some methods needs to be protected manually, like protectedUpsert below.
          const isManualProtectionRequested = Object.hasOwn(args, ApplyPrismaRestrictionsToken);

          if (!isManualProtectionRequested && isBlacklistedModel) {
            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          if (method === "upsert") {
            throw new BusinessLogicError(
              "Prisma upsert is forbidden in our codebase. Please use a combination of find + create/update instead."
            );
          }

          if (method === "create") {
            // ⚠️ No protection for creation atm
            // return protectedCreate(originalPrismaClient, ability, modelOrProperty, argsWithoutToken.data);
            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          if (method === "createMany") {
            if (!isArray(payloadWithoutTokens.data)) {
              // ⚠️ No protection for creation atm
              // await protectedCreate(originalPrismaClient, ability, modelOrProperty, argsWithoutToken.data);
              // return { count: 1 };
              return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            payloadWithoutTokens.data.forEach((data: any) => {
              if (!!ability && !ability.can("create", subject(modelName, data))) {
                throw new BusinessLogicError("You are not allowed to create this data");
              }
            });

            return wrapPrismaOperation(fn, payloadWithoutTokens, method, modelName, ctx.user?.locale);
          }

          try {
            const prismaAction = prismaActionAndMethodMapping[method];

            const protectedArgs = value(() => {
              if (!ability) return args;

              const { actionRestrictions, readRestrictions } = applyDynamicPrismaRestrictions({
                ability,
                prismaAction,
                prismaRestrictionSchemas: applyPrismaRestrictionSchemas({
                  includeSoftDeletedExternalEmployees: Object.hasOwn(
                    args,
                    DangerouslyIncludeSoftDeletedExternalEmployeesToken
                  ),
                  includeHistoricalRemunerationItems: Object.hasOwn(
                    args,
                    DangerouslyIncludeHistoricalExternalRemunerationItemsToken
                  ),
                }),
              });

              return restrictPrismaParams({
                model: modelOrProperty,
                args: payloadWithoutTokens,
                method,
                actionRestrictions,
                readRestrictions,
                isDebugEnabled: isDebugEnabled(args),
              });
            });

            handleQueryDebug({ originalArgs: args, protectedArgs, modelOrProperty, method });

            return wrapPrismaOperation(fn, protectedArgs, method, modelName, ctx.user?.locale);
          } catch (error) {
            throw new BusinessLogicError(`
            === SECURITY WARNING ===

            ${error.message}

            Incriminated Prisma query:

            prisma.${modelOrProperty}.${method}(${JSON.stringify(args, null, 2)});

            Please check your query against what you expected the restriction to be and report the issue if needed.
            `);
          }
        };
      });
    },
  }) as PrismaClient;

  incrementPrismaRecursiveProtectionCount(protectedPrisma);

  return protectedPrisma;
};

const addDefaultOrderBy = (args: PrismaPayload, method: PrismaMethod) => {
  if (!args.orderBy && method === "findMany") {
    args.orderBy = {
      id: "asc",
    };
  }
};

const wrapPrismaOperation = async (
  fn: PrismaDelegateFunction,
  args: PrismaPayload,
  method: PrismaMethod,
  modelName: Prisma.ModelName,
  locale?: UserLocale
) => {
  addTranslationsToArgs(args, modelName, method);
  addDefaultOrderBy(args, method);
  if (isIn(method, ["create", "createMany", "update", "updateMany"])) {
    args.data = roundBigIntsForPrisma({ modelName, data: args.data });
  }

  let res = await fn(args);

  res = translatePrismaResponse(res, method, locale ?? UserLocale.EN);
  res = convertBigIntsFromPrisma(res);

  return res;
};

const getPrismaModelName = (model: PrismaModel | string) => {
  return upperFirst(model) as Prisma.ModelName;
};

const handleQueryDebug = (params: {
  originalArgs: PrismaPayload;
  protectedArgs: PrismaPayload;
  modelOrProperty: string;
  method: string;
}) => {
  /* eslint-disable no-console */
  if (isDebugEnabled(params.originalArgs)) {
    console.log();
    console.log("===== DEBUG QUERY - ORIGINAL REQUEST =====");
    console.log(
      `prisma.${params.modelOrProperty}.${params.method}(${JSON.stringify(params.originalArgs, null, 2)});\``
    );

    console.log();
    console.log("===== DEBUG QUERY - PROTECTED REQUEST =====");
    console.log(`prisma.${params.modelOrProperty}.${params.method}(${JSON.stringify(params.protectedArgs, null, 2)});`);
  }
  /* eslint-enable no-console */
};

const isDebugEnabled = (args: PrismaPayload) => Object.hasOwn(args, DebugQueryToken);
