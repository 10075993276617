import { mapSeries } from "bluebird";
import { convertCurrency } from "~/lib/money";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { computeDateProration } from "~/services/compensation-review/shared/computeDateProration";

export const refreshConvertedAmounts = async (ctx: CompensationReviewCampaignContext) => {
  const employees = await ctx.prisma.compensationReviewEmployee.findMany({
    where: { campaignId: ctx.campaign.id },
    select: {
      id: true,
      fteCoefficient: true,
      baseSalary: true,
      variablePay: true,
      otherBonus: true,
      onTargetEarnings: true,
      totalCash: true,
      effectiveDate: true,
      currency: { select: { euroExchangeRate: true } },
      eligibilities: {
        select: {
          id: true,
          budgetedAmount: true,
        },
      },
      adjustments: {
        select: {
          id: true,
          submittedAmount: true,
          recommendedAmount: true,
          budgetId: true,
        },
      },
    },
  });

  await mapSeries(employees, async (employee) => {
    const convertAmount = <Amount extends number | null, ReturnType = Amount extends number ? number : null>(
      amount: Amount
    ): ReturnType => {
      if (amount === null) return null as ReturnType;

      return convertCurrency(amount, employee.currency, ctx.campaign.currency) as ReturnType;
    };

    const payload = {
      convertedBaseSalary: convertAmount(employee.baseSalary),
      convertedVariablePay: convertAmount(employee.variablePay),
      convertedOnTargetEarnings: convertAmount(employee.onTargetEarnings),
      convertedOtherBonus: convertAmount(employee.otherBonus),
      convertedTotalCash: convertAmount(employee.totalCash),
    };

    await ctx.prisma.compensationReviewEmployee.update({
      where: { id: employee.id },
      data: payload,
    });

    await mapSeries(employee.eligibilities, async (eligibility) => {
      await ctx.prisma.compensationReviewBudgetEligibility.update({
        where: { id: eligibility.id },
        data: {
          convertedBudgetedAmount: convertAmount(eligibility.budgetedAmount),
        },
      });
    });

    await mapSeries(employee.adjustments, async (adjustment) => {
      const budget = getCompensationReviewBudget(ctx, adjustment.budgetId);

      const convertedSubmittedAmount = convertAmount(adjustment.submittedAmount);
      const { proratedAmount: proratedSubmittedAmount } = computeDateProration({
        amount: convertedSubmittedAmount,
        prorationStartDate: budget.prorationStartDate,
        effectiveDate: employee.effectiveDate,
      });

      const convertedRecommendedAmount = convertAmount(adjustment.recommendedAmount);
      const { proratedAmount: proratedRecommendedAmount } = computeDateProration({
        amount: convertedRecommendedAmount,
        prorationStartDate: budget.prorationStartDate,
        effectiveDate: employee.effectiveDate,
      });

      const payload = {
        convertedRecommendedAmount,
        proratedRecommendedAmount,
        convertedSubmittedAmount,
        proratedSubmittedAmount,
      };

      await ctx.prisma.compensationReviewAdjustment.update({
        where: { id: adjustment.id },
        data: payload,
      });
    });
  });
};
