import tracer from "dd-trace";
import { type NextApiRequest } from "next";
import { type Route } from "nextjs-routes";
import { parse } from "url";
import packageJson from "~/../package.json";
import { datadogEnv } from "~/lib/datadog/datadogEnv";
import { flattenObject } from "~/lib/flattenObject";
import { isIn } from "~/lib/utils";

if (tracer) {
  tracer.init({
    service: packageJson.name,
    version: packageJson.version,
    env: datadogEnv,
    logInjection: true,
    plugins: true,
    startupLogs: false,
    flushInterval: 100,
    runtimeMetrics: true,
    profiling: false,
  });

  tracer.use("redis", { service: `${packageJson.name}.redis` });
  tracer.use("ioredis", { service: `${packageJson.name}.ioredis` });
  tracer.use("winston", { service: `${packageJson.name}.winston` });
  tracer.use("koa", { service: `${packageJson.name}.koa` });
  tracer.use("next", {
    service: `${packageJson.name}.next`,
    hooks: {
      request: (span, req) => {
        if (!span || !req) {
          return;
        }

        const parsedUrl = parse(req.url as string, true);

        if (isStaticFile(parsedUrl.pathname) || isStatusApi(parsedUrl.pathname)) {
          return;
        }

        const query = flattenObject(parsedUrl.query, { prefix: "next.query" });
        Object.entries(query).forEach(([key, value]) => {
          span.setTag(key, value);
        });

        const body = flattenObject((req as NextApiRequest).body, { prefix: "next.body" });
        Object.entries(body).forEach(([key, value]) => {
          span.setTag(key, value);
        });
      },
    },
  });
  tracer.use("http", { service: `${packageJson.name}.http` });
  tracer.use("http2", { service: `${packageJson.name}.http2` });
  tracer.use("dns", { service: `${packageJson.name}.dns` });
  tracer.use("pg", { service: `${packageJson.name}.pg` });
}

export const traceBusinessService = <T>(
  params: { serviceName: string; tags?: Record<string, unknown> },
  fn: () => Promise<T> | T
) => {
  return tracer.trace(
    "service.tracing",
    {
      tags: { "span.kind": "server", ...flattenObject(params.tags) },
      service: `${packageJson.name}.business-services`,
      resource: params.serviceName,
    },
    fn
  );
};

const STATIC_PATHS = ["/_next/", "/fonts/", "/images/", "/favicon.png", "/__nextjs_original-stack-frame"];

const isStaticFile = (pathname: string | null) => {
  if (!pathname) {
    return false;
  }

  // We need Next.js development assets to fall through
  if (pathname.startsWith("/_next/data/")) {
    return false;
  }

  return STATIC_PATHS.some((staticPath) => pathname.startsWith(staticPath));
};

const STATUS_APIS = ["/api/status", "/api/db-status"] satisfies Route["pathname"][];

const isStatusApi = (pathname: string | null) => {
  if (!pathname) {
    return false;
  }

  return isIn(pathname, STATUS_APIS);
};

export default tracer;
