import { ExternalEmployeeStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { getNumberOfMonthsFn } from "~/lib/hris/helpers/getNumberOfMonth";
import { chain, compact } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";
import { computeCompensationReviewEmployeeSalaryPayload } from "~/services/compensation-review/campaigns/admin/computeCompensationReviewEmployeePayload";
import {
  createCompensationReviewEmployee,
  externalEmployeeSelectForCompensationReviewEmployeeCreation,
  getPublishedSalaryRangeEmployee,
} from "~/services/compensation-review/campaigns/admin/createCompensationReviewEmployee";
import { refreshCampaignCurrencies } from "~/services/compensation-review/campaigns/admin/refreshCampaignCurrencies";
import { refreshCompensationReviewAdditionalFields } from "~/services/compensation-review/campaigns/admin/refreshCompensationReviewAdditionalFields";
import { getDefaultSalaryPeriod } from "~/services/compensation-review/campaigns/getDefaultSalaryPeriod";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { findOrCreatePerformanceReviewCycle } from "~/services/performance-review/findOrCreatePerformanceReviewCycle";

export const synchroniseCampaignEmployees = async (
  ctx: CompensationReviewCampaignContext,
  params: { synchroniseSalaryData: boolean }
) => {
  const { synchroniseSalaryData } = params;

  const campaign = assertNotNil(ctx.campaign);

  const { compensationReviewCurrencies } = await refreshCampaignCurrencies(ctx, { campaign });

  const existingCampaignEmployees = await ctx.prisma.compensationReviewEmployee.findMany({
    where: { campaignId: campaign.id },
    select: { id: true, externalEmployee: { select: externalEmployeeSelectForCompensationReviewEmployeeCreation } },
  });

  if (synchroniseSalaryData) {
    await mapSeries(existingCampaignEmployees, async (employee) => {
      const currency = compensationReviewCurrencies.find(
        (currency) => currency.currencyId === employee.externalEmployee.currency.id
      );

      const salaryPayload = computeCompensationReviewEmployeeSalaryPayload(ctx, employee.externalEmployee);

      const salaryRangeEmployee = getPublishedSalaryRangeEmployee(employee.externalEmployee);

      await ctx.prisma.compensationReviewEmployee.update({
        where: { id: employee.id },
        data: {
          ...salaryPayload,
          ...(currency && { currencyId: currency.id }),
          ...(salaryRangeEmployee && {
            compaRatio: salaryRangeEmployee.orderingCompaRatio,
            rangePenetration: salaryRangeEmployee.orderingRangePenetration,
            salaryBandMeasure: salaryRangeEmployee.range.band.measure,
          }),
        },
      });
    });
  }

  const newExternalEmployees = await ctx.prisma.externalEmployee.findMany({
    orderBy: { id: "asc" },
    where: {
      companyId: campaign.companyId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
      id: { notIn: existingCampaignEmployees.map((employee) => employee.externalEmployee.id) },
    },
    select: externalEmployeeSelectForCompensationReviewEmployeeCreation,
  });

  const performanceCycle = await findOrCreatePerformanceReviewCycle(ctx, {
    companyId: campaign.companyId,
    performanceReviewScope: { compensationReviewCampaignId: campaign.id },
  });

  const employeesPerformanceRatings = chain(newExternalEmployees)
    .filter((externalEmployee) => !!externalEmployee.performanceReviewRating)
    .map((externalEmployee) => externalEmployee.performanceReviewRating)
    .uniqBy((rating) => rating?.name)
    .orderBy((rating) => rating?.position)
    .value();

  const performanceRatings = await mapSeries(employeesPerformanceRatings, async (performanceRating) => {
    if (!performanceRating) return;

    const existingPerformanceReviewRating = await ctx.prisma.performanceReviewRating.findUnique({
      where: {
        performanceReviewCycleId_name: {
          performanceReviewCycleId: performanceCycle.id,
          name: performanceRating.name,
        },
      },
    });

    if (existingPerformanceReviewRating) {
      return existingPerformanceReviewRating;
    }

    return ctx.prisma.performanceReviewRating.create({
      data: {
        companyId: campaign.companyId,
        name: performanceRating.name,
        performanceReviewCycleId: performanceCycle.id,
        position: performanceRating.position,
      },
    });
  });

  const getNumberOfMonths = await getNumberOfMonthsFn(ctx);
  const defaultSalaryPeriod = await getDefaultSalaryPeriod(ctx);

  await mapSeries(newExternalEmployees, async (externalEmployee) => {
    const currency = assertNotNil(
      compensationReviewCurrencies.find((currency) => currency.currencyId === externalEmployee.currency.id)
    );

    const numberOfMonths = getNumberOfMonths(externalEmployee.location?.externalId);

    return createCompensationReviewEmployee(ctx, {
      externalEmployee,
      compensationReviewCurrencyId: currency.id,
      performanceRatings: compact(performanceRatings),
      numberOfMonths,
      defaultSalaryPeriod,
    });
  });

  await refreshCompensationReviewAdditionalFields(ctx);
};
