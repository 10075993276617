import { type ParsedUrlQuery } from "querystring";
import { ApiValidationError } from "~/lib/errors/apiValidationError";
import { chain, isArray, isString } from "~/lib/lodash";
import { isIn, stripDiacritics } from "~/lib/utils";

export const joinModels = (models: { id: number | string }[]) => {
  return models.map((model) => model.id).join(",");
};

export const parseArray = <Type extends string>(query: ParsedUrlQuery, name: string, defaultValue: Type[] = []) => {
  if (!query[name]) {
    return defaultValue;
  }

  const value = query[name] as string;

  return value.split(",") as Type[];
};

export const parseNumericArray = (query: ParsedUrlQuery, name: string, nanAsNull?: boolean) => {
  if (!query[name]) {
    return [];
  }

  const value = query[name] as string;

  return value
    .split(",")
    .map((value) => Number(value))
    .filter((item) => !!nanAsNull || !isNaN(item));
};

export const parseDateArray = (query: ParsedUrlQuery, name: string) => {
  if (!query[name]) {
    return [];
  }

  const value = query[name] as string;

  return value.split(",").map((value) => (value ? new Date(parseInt(value)) : value));
};

export const parseBooleanArray = (query: ParsedUrlQuery, name: string) => {
  if (!query[name]) {
    return [];
  }

  const value = query[name] as string;

  return value
    .split(",")
    .filter((value) => isIn(value, ["true", "false"]))
    .map((value) => value === "true");
};

type ParseOptions = {
  orThrow?: true;
};

export const parseBoolean = <
  Options extends ParseOptions | undefined,
  ReturnType = Options extends ParseOptions ? boolean : boolean | null,
>(
  query: ParsedUrlQuery | undefined,
  name: string,
  options?: Options
) => {
  if (!query || !query[name]) {
    if (options?.orThrow) {
      throw new ApiValidationError("Invalid query parameter", { [name]: "Not a boolean" });
    }

    return null as ReturnType;
  }

  return (query[name] === "true") as ReturnType;
};

export const parseNumber = <
  Options extends ParseOptions | undefined,
  ReturnType = Options extends ParseOptions ? number : number | null,
>(
  query: ParsedUrlQuery | undefined,
  name: string,
  options?: Options
): ReturnType => {
  const value = parseInt(query?.[name] as string, 10);

  if (isNaN(value)) {
    if (options?.orThrow) {
      throw new ApiValidationError("Invalid query parameter", { [name]: "Not a number" });
    }

    return null as ReturnType;
  }

  return value as ReturnType;
};

export const parseDecimalNumber = <
  Options extends ParseOptions | undefined,
  ReturnType = Options extends ParseOptions ? number : number | null,
>(
  query: ParsedUrlQuery | undefined,
  name: string,
  options?: Options
): ReturnType => {
  const value = parseFloat(query?.[name] as string);

  if (isNaN(value)) {
    if (options?.orThrow) {
      throw new ApiValidationError("Invalid query parameter", { [name]: "Not a number" });
    }

    return null as ReturnType;
  }

  return value as ReturnType;
};

type ParseStringOptions = ParseOptions & {
  oneOf?: string[];
  default?: string;
  lowerCase?: true;
  stripDiacritics?: true;
};

export const parseString = <
  Type extends string,
  Options extends ParseStringOptions | undefined = undefined,
  ReturnType = Options extends { oneOf: string[] }
    ? Options["oneOf"][number]
    : Options extends ParseStringOptions
    ? Type
    : Type | null,
>(
  query: ParsedUrlQuery | undefined,
  name: string,
  options?: Options
): ReturnType => {
  const value = query?.[name];

  if (!isString(value)) {
    if (options?.default) {
      return options.default as ReturnType;
    }

    if (options?.orThrow) {
      throw new ApiValidationError("Invalid query parameter", { [name]: "Not a string" });
    }

    return null as ReturnType;
  }

  return chain([value])
    .map((v) => {
      if (options?.stripDiacritics) {
        return stripDiacritics(v);
      }

      return v;
    })
    .map((v) => {
      if (options?.lowerCase) {
        return v.toLowerCase();
      }

      return v;
    })
    .map((v) => {
      if (!isArray(options?.oneOf)) {
        return v;
      }

      if (isIn(v, options.oneOf)) {
        return v;
      }

      if (options?.default) {
        return options.default;
      }

      if (options?.orThrow) {
        throw new ApiValidationError("Invalid query parameter", { [name]: `Not one of ${options.oneOf.join(", ")}` });
      }

      return null;
    })
    .first()
    .value() as ReturnType;
};
