import Head from "next/head";
import React from "react";
import { useI18n } from "~/lib/i18n/useI18n";

export const Meta: React.FC = () => {
  const url = "https://app.figures.hr";
  const image = "https://app.figures.hr/images/og-image.png";
  const { t } = useI18n();

  const description = t("meta.description");
  const title = `Figures · ${t("meta.tagline")}`;

  return (
    <Head>
      <title key="title">{title}</title>

      <link href="/favicon.png" rel="shortcut icon" type="image/png" />
      <link href="/favicon.png" rel="apple-touch-icon" />

      <meta key="description" content={description} name="description" />
      <meta key="ogTitle" content={title} property="og:title" />
      <meta key="ogSiteName" content={title} property="og:site_name" />
      <meta key="ogDescription" content={description} property="og:description" />
      <meta key="ogType" content="website" property="og:type" />
      <meta key="ogUrl" content={url} property="og:url" />
      <meta key="ogImage" content={image} property="og:image" />
      <meta key="keywords" content="figures, compensation benchmark" property="keywords" />
      <meta key="google" name="google" content="notranslate" />
      <meta key="robots" name="robots" content="noindex" />
    </Head>
  );
};

export const AllowPageIndexing = () => {
  return (
    <Head>
      <meta key="robots" name="robots" content="all" />
    </Head>
  );
};
