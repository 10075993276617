import { CompanyType } from "@prisma/client";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { hasNonNullableField } from "~/lib/utils";
import { companyForStatsSelect } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";

export const getDashboardCompany = async (ctx: AppContext, params: { companyId: number }) => {
  const user = getRequiredUser(ctx);

  const companyId = value(() => {
    if (user.isSuperAdmin || user.company.type === CompanyType.VENTURE_CAPITAL) {
      return params.companyId ?? user.companyId;
    }

    return user.companyId;
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: {
      id: companyId,
    },
    select: {
      ...companyForStatsSelect,
      id: true,
      tags: true,
      stats: true,
      useAdvancedLevels: true,
      useExternalLevels: true,
      marketPositioningId: true,
      marketPositioning: true,
      globalTargetPercentile: true,
      companyTargetPercentiles: {
        select: {
          jobFamilyId: true,
          value: true,
        },
      },
      comparisonGeographicStrategy: true,
      compareSpecificLocations: {
        select: {
          id: true,
          countryId: true,
        },
      },
      liveSurvey: true,
    },
  });

  if (!hasNonNullableField(company, "liveSurvey")) {
    throw new BusinessLogicError("Company should have a live survey");
  }

  return company;
};
