import { array, number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { logError, logInfo } from "~/lib/logger";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { JobPriority } from "~/lib/queue/drivers/queueDriver";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import {
  selectForCompanyForDatasetEmployee,
  syncSomeDatasetEmployeesByCompany,
} from "~/services/dataset-employee/syncDatasetEmployee";

const SyncDatasetEmployeesJobDataSchema = BaseJobDataSchema.concat(
  object({
    externalEmployeeIds: array().of(number().required()).required(),
  })
);

export type SyncDatasetEmployeesJobData = YupOutputType<typeof SyncDatasetEmployeesJobDataSchema>;

export const syncDatasetEmployeesWorkerService = async (ctx: AppContext, data: SyncDatasetEmployeesJobData) => {
  const { companyId, externalEmployeeIds } = SyncDatasetEmployeesJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: {
      id: companyId,
    },
    select: selectForCompanyForDatasetEmployee,
  });

  try {
    await syncSomeDatasetEmployeesByCompany(ctx, {
      company,
      externalEmployeeIds,
    });

    logInfo(ctx, `[dataset-employees] Synchronized dataset employees for company`, {
      companyId,
    });
  } catch (error) {
    logError(ctx, `[dataset-employees] There was an error while synchronizing dataset employees for company`, {
      error,
      companyId,
    });

    throw error;
  }
};

export const sendSyncDatasetEmployeesJob = async (ctx: AppContext, data: SyncDatasetEmployeesJobData) => {
  return sendJob(ctx, {
    jobName: QueueJobName.SYNC_DATASET_EMPLOYEES,
    data,
    options: {
      priority: JobPriority.HIGH,
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.SYNC_DATASET_EMPLOYEES },
      }),
    },
  });
};
