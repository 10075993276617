import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logError } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class KomboWebhookError extends FiguresBaseError {
  constructor(readonly message: string) {
    super(message);
    Object.setPrototypeOf(this, KomboWebhookError.prototype);
  }
}

export const handleKomboWebhookError = (ctx: AppContext, res: NextApiResponse, error: KomboWebhookError) => {
  logError(ctx, "[error] Kombo webhook error", { error });

  return res.status(HttpStatus.BAD_REQUEST).json(makeErrorPayload({ error, defaultCode: ErrorCode.KOMBO }));
};
