/* eslint-disable no-restricted-syntax */
import { type Prisma } from "@prisma/client";
import { map } from "bluebird";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { decryptClientSecret, encryptClientSecret, getClientSecretEncryptionKey } from "~/lib/crypto";
import { isString } from "~/lib/lodash";
import { logError } from "~/lib/logger";

export const findUniqueClientSecret = async (
  ctx: AppContext,
  args: Omit<Prisma.ClientSecretFindUniqueArgs, "select" | "include">
) => {
  const clientSecret = await ctx.prisma.clientSecret.findUnique({
    ...args,
    select: defaultSelectForClientSecret,
  });

  if (!clientSecret) {
    return null;
  }

  return handleClientSecretDecryptionAndReEncryption(ctx, clientSecret);
};

export const findUniqueClientSecretOrThrow = async (
  ctx: AppContext,
  args: Omit<Prisma.ClientSecretFindUniqueOrThrowArgs, "select" | "include">
) => {
  const clientSecret = await ctx.prisma.clientSecret.findUniqueOrThrow({
    ...args,
    select: defaultSelectForClientSecret,
  });

  return handleClientSecretDecryptionAndReEncryption(ctx, clientSecret);
};

export const findManyClientSecrets = async (
  ctx: AppContext,
  args: Omit<Prisma.ClientSecretFindManyArgs, "select" | "include">
) => {
  const clientSecrets = await ctx.prisma.clientSecret.findMany({
    ...args,
    select: defaultSelectForClientSecret,
  });

  return map(clientSecrets, async (clientSecret) => handleClientSecretDecryptionAndReEncryption(ctx, clientSecret), {
    concurrency: 5,
  });
};

type CreateClientSecretArgs = Omit<Prisma.ClientSecretCreateArgs, "data" | "select" | "include"> & {
  data: Omit<Prisma.ClientSecretUncheckedCreateInput, "encryptionKeyVersionId">;
};

export const createClientSecret = async (ctx: AppContext, args: CreateClientSecretArgs) => {
  const secret = await encryptClientSecret(ctx, { secret: args.data.secret });

  return ctx.prisma.clientSecret.create({
    ...args,
    data: {
      ...args.data,
      secret: secret.encryptedSecret,
      encryptionKeyVersionId: secret.encryptionKeyVersionId,
    },
    select: defaultSelectForClientSecret,
  });
};

type UpdateClientSecretArgs = Omit<Prisma.ClientSecretUpdateArgs, "select" | "include"> & {
  data: Omit<Prisma.ClientSecretUpdateArgs["data"], "encryptionKeyVersionId">;
};

export const updateClientSecret = async (ctx: AppContext, args: UpdateClientSecretArgs) => {
  const secret = await value(async () => {
    if (!args.data.secret) {
      return null;
    }

    const secret = isString(args.data.secret) ? args.data.secret : args.data.secret.set;

    if (!secret) {
      return null;
    }

    return encryptClientSecret(ctx, { secret });
  });

  return ctx.prisma.clientSecret.update({
    ...args,
    data: {
      ...args.data,
      ...(secret && {
        secret: secret.encryptedSecret,
        encryptionKeyVersionId: secret.encryptionKeyVersionId,
      }),
    },
    select: defaultSelectForClientSecret,
  });
};

export const deleteClientSecret = async (ctx: AppContext, args: Prisma.ClientSecretDeleteArgs) => {
  return ctx.prisma.clientSecret.delete(args);
};

export const deleteManyClientSecret = async (ctx: AppContext, args: Prisma.ClientSecretDeleteManyArgs) => {
  return ctx.prisma.clientSecret.deleteMany(args);
};

export const defaultSelectForClientSecret = {
  id: true,
  secret: true,
  companyId: true,
  encryptionKeyVersionId: true,
} satisfies Prisma.ClientSecretSelect;
type DefaultClientSecret = Prisma.ClientSecretGetPayload<{ select: typeof defaultSelectForClientSecret }>;

const handleClientSecretDecryptionAndReEncryption = async <T extends DefaultClientSecret>(
  ctx: AppContext,
  clientSecret: T
): Promise<T> => {
  try {
    const secret = await decryptClientSecret(ctx, {
      secret: clientSecret.secret,
      encryptionKeyVersionId: clientSecret.encryptionKeyVersionId,
    });

    const decryptedSecret = { ...clientSecret, secret } satisfies T;

    const currentEncryptionKey = await getClientSecretEncryptionKey(ctx);

    if (clientSecret.encryptionKeyVersionId !== currentEncryptionKey.encryptionKeyVersionId) {
      await updateClientSecret(ctx, {
        where: { id: clientSecret.id },
        data: { secret: decryptedSecret.secret },
      });
    }

    return decryptedSecret;
  } catch (error) {
    logError(ctx, "[client-secret] Failed to decrypt client secret", {
      error,
      clientSecretId: clientSecret.id,
      companyId: clientSecret.companyId,
    });

    return clientSecret;
  }
};
