import { mapSeries } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chunk } from "~/lib/lodash";
import { logInfo } from "~/lib/logger";
import { assertNotNil } from "~/lib/utils";
import { classifyJobTitlesWithEmbeddings } from "~/services/job/ai/classifyJobTitlesWithEmbeddings";
import { CURRENT_EMBEDDING_MODEL } from "~/services/job/ai/model";
import { shouldAutomaticallyMapExternalJobAfterClassification } from "~/services/job/ai/shouldAutomaticallyMapExternalJobAfterClassification";
import { classifyJob } from "~/services/jobClassification";
import { mapExternalJobs } from "~/services/mapping/mapExternalJobs";
import { autoMapExternalJobs } from "~/services/preMapping";

const BATCH_SIZE = 100;

export const suggestMappingForExternalJobs = async (ctx: AppContext, companyId: number) => {
  logInfo(ctx, "[mapping] Suggesting mappings for external jobs", { companyId });

  const externalJobs = await getExternalJobsToHandle(ctx, companyId);

  const suggestedJobsCache = new Map<number, number>();
  await mapSeries(externalJobs, async (externalJob) => {
    const suggestedJob = await suggestMapping(ctx, externalJob);
    if (suggestedJob) {
      suggestedJobsCache.set(externalJob.id, suggestedJob);
    }
  });

  if (!ctx.featureFlags.AI_POWERED_JOB_AUTOMAPPING_ENABLED) {
    await autoMapExternalJobs(ctx, companyId);
  }

  if (ctx.featureFlags.AI_POWERED_JOB_AUTOMAPPING_ENABLED) {
    for (const chunkExternalJobs of chunk(externalJobs, BATCH_SIZE)) {
      await suggestAndMapWithEmbeddings(ctx, {
        externalJobs: chunkExternalJobs,
        suggestedJobs: suggestedJobsCache,
      });
    }
  }
};

const getExternalJobsToHandle = async (ctx: AppContext, companyId: number) => {
  return ctx.prisma.externalJob.findMany({
    select: {
      id: true,
      name: true,
      companyId: true,
      suggestedJobId: true,
      suggestedAt: true,
      suggestionSkippedAt: true,
    },
    where: { companyId, mappedJobId: null, autoMappingEnabled: true },
  });
};

const suggestMapping = async (
  ctx: AppContext,
  externalJob: AsyncReturnType<typeof getExternalJobsToHandle>[number]
) => {
  if (externalJob.suggestionSkippedAt !== null) {
    return null;
  }

  const [suggestion] = await classifyJob(ctx, { jobTitle: externalJob.name });

  if (!suggestion) {
    if (externalJob.suggestedJobId) {
      await ctx.prisma.externalJob.update({
        where: { id: externalJob.id },
        data: { suggestedJobId: null, suggestionConfidenceScore: null, suggestedAt: null },
      });
    }

    return null;
  }

  await ctx.prisma.externalJob.update({
    where: { id: externalJob.id },
    data: {
      suggestedJobId: suggestion.job.id,
      suggestionConfidenceScore: suggestion.jobConfidenceScore,
      suggestedAt: new Date(),
    },
  });

  return suggestion.job.id;
};

type ExternalJobToClassify = AsyncReturnType<typeof getExternalJobsToHandle>[number];

const suggestAndMapWithEmbeddings = async (
  ctx: AppContext,
  params: { externalJobs: ExternalJobToClassify[]; suggestedJobs: Map<number, number> }
) => {
  const user = getRequiredUser(ctx);

  if (params.externalJobs.length === 0) {
    return;
  }

  const resultSuggestions = await classifyJobTitlesWithEmbeddings(ctx, {
    jobTitles: params.externalJobs.map((externalJob) => externalJob.name),
    kNearest: 3,
  });

  await Promise.all(
    resultSuggestions.map(async (suggestions, index) => {
      if (suggestions.length === 0) {
        return;
      }

      const externalJob = assertNotNil(params.externalJobs[index]);

      if (
        ctx.featureFlags.AI_POWERED_JOB_AUTOMAPPING_ENABLED &&
        shouldAutomaticallyMapExternalJobAfterClassification({
          classificationResults: suggestions,
          suggestedJobIdFromStringSimilarity: params.suggestedJobs.get(externalJob.id),
        })
      ) {
        const firstSuggestion = assertNotNil(suggestions[0]);

        await mapExternalJobs(ctx, {
          mapping: [
            {
              externalJobId: externalJob.id,
              mappedJobId: firstSuggestion.job.id,
            },
          ],
          companyId: user.companyId,
          autoMapped: true,
        });
      }

      await ctx.prisma.externalJobMappingSuggestion.deleteMany({
        where: {
          companyId: externalJob.companyId,
          externalJobId: externalJob.id,
          embeddingModel: CURRENT_EMBEDDING_MODEL,
        },
      });

      await ctx.prisma.externalJobMappingSuggestion.createMany({
        data: suggestions.map((suggestion) => ({
          companyId: externalJob.companyId,
          externalJobId: externalJob.id,
          suggestedJobId: suggestion.job.id,
          similarityScore: suggestion.similarity,
          suggestedAt: new Date(),
          embeddingModel: CURRENT_EMBEDDING_MODEL,
        })),
      });
    })
  );
};
