import {
  type CompensationReviewAdjustment,
  CompensationReviewCompensationItem,
  CompensationReviewRecommendationStatus,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import { type SalaryRangeAutocompleteValue } from "~/components/compensation-review/SalaryRangeAutocomplete";
import { value } from "~/components/helpers";
import { ensure } from "~/lib/ensure";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { trackCompensationReviewRecommandationsSubmitted } from "~/lib/external/segment/server/events";
import { sendOffCycleReviewPendingEmail } from "~/lib/external/sendinblue/templates";
import { roundTo } from "~/lib/math";
import { convertCurrency } from "~/lib/money";
import { assertNotNil } from "~/lib/utils";
import { type UpdateRecommendationInput } from "~/pages/api/compensation-review/update-recommendation";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import {
  computeAmountForCompensationItem,
  computeFteAmount,
} from "~/services/compensation-review/shared/compensationItems";
import { getCompensationReviewBudget } from "~/services/compensation-review/shared/compensationReviewBudget";
import { computeDateProration } from "~/services/compensation-review/shared/computeDateProration";
import { computeNewBandPositioning } from "~/services/compensation-review/shared/computeNewBandPositioning";
import { ensureFirstRecommendationIsListedToReviewer } from "~/services/compensation-review/shared/ensureFirstRecommendationIsListedToReviewer";
import { isPendingRecommendation } from "~/services/compensation-review/shared/recommendationStatus";
import { enrichCompensationReviewRules } from "~/services/compensation-review/shared/rules/enrichIncreaseRulesWithPopulation";
import { validateRules } from "~/services/compensation-review/shared/rules/validateRules";
import {
  selectCompensationReviewCurrency,
  transformCompensationReviewCurrency,
} from "~/services/compensation-review/shared/transformCompensationReviewCurrency";
import { selectSalaryRangeForAutocomplete } from "~/services/salary-bands/access/fetchSalaryRangesForAutocomplete";
import { CompensationReviewScopeType } from "../compensationReviewScope";
import { completeOffCycleRequestRequest } from "../off-cycle-reviews/completeOffCycleRequestRequest";

export const updateRecommendation = async (ctx: CompensationReviewContext, params: UpdateRecommendationInput) => {
  ensure(() => ctx.permissions.canReview);

  const recommendation = await fetchRecommendationForUpdate(ctx, {
    recommendationId: params.recommendationId,
    reviewerId: assertNotNil(ctx.authenticatedReviewer).id,
  });

  const targetRangeAfterPromotion = params.targetRangeIdAfterPromotion
    ? await ctx.prisma.salaryRange.findUniqueOrThrow({
        where: { id: params.targetRangeIdAfterPromotion },
        select: selectSalaryRangeForAutocomplete,
      })
    : null;

  const { employee } = recommendation;

  const employeeAdjustments = await ctx.prisma.compensationReviewAdjustment.findMany({
    where: {
      ...prismaCompensationReviewScope(ctx.scope),
      employeeId: employee.id,
    },
  });

  if (employeeAdjustments.length !== params.adjustments.length) {
    throw new BusinessLogicError("Some adjustments are not found");
  }

  const sourceAdjustments = await ctx.prisma.compensationReviewAdjustment.findMany({
    where: {
      ...prismaCompensationReviewScope(ctx.scope),
      id: { in: params.adjustments.map((adjustment) => adjustment.id) },
      OR: [{ recommendationId: recommendation.id }, { employeeId: employee.id }],
    },
  });

  const rules = await enrichCompensationReviewRules(ctx);
  const { isWithinHardLimits, isWithinSoftLimits } = validateRules(ctx, {
    rules,
    employee,
    promotion: { isPromoted: params.isPromoted, targetRangeAfterPromotion },
    adjustments: params.adjustments.map((adjustment) => {
      const sourceAdjustment = assertNotNil(sourceAdjustments.find((item) => item.id === adjustment.id));

      return {
        ...sourceAdjustment,
        submittedAmount: convertCurrency(adjustment.submittedAmount, employee.currency, ctx.parameters.currency),
      };
    }),
  });

  if (!isWithinHardLimits || (!isWithinSoftLimits && !params.comment)) {
    throw new BusinessLogicError("The recommendation is outside the limits");
  }

  await ctx.prisma.compensationReviewRecommendation.update({
    where: { id: recommendation.id },
    data: {
      comment: params.comment,
      status: CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,

      ...(ctx.permissions.canUpdatePromotions && {
        isPromoted: params.isPromoted,
        targetRangeAfterPromotionId: targetRangeAfterPromotion?.id ?? null,
      }),

      ...(params.submitRecommendation && {
        status: CompensationReviewRecommendationStatus.SUBMITTED,
        submittedAt: new Date(),
      }),
    },
  });

  const recommendationsToUpdate = params.adjustments
    .map((adjustment) => {
      const sourceAdjustment = assertNotNil(sourceAdjustments.find((item) => item.id === adjustment.id));
      const budget = getCompensationReviewBudget(ctx, sourceAdjustment.budgetId);

      return {
        ...adjustment,
        budget,
        sourceAdjustment,
      };
    })
    .filter((adjustment) => {
      return adjustment.sourceAdjustment.recommendationId === recommendation.id;
    });

  const adjustments = await mapSeries(recommendationsToUpdate, async (adjustment) => {
    const convertedSubmittedAmount = convertCurrency(
      adjustment.submittedAmount,
      employee.currency,
      ctx.parameters.currency
    );

    const { proratedAmount: proratedSubmittedAmount } = computeDateProration({
      amount: convertedSubmittedAmount,
      prorationStartDate: adjustment.budget.prorationStartDate,
      effectiveDate: employee.effectiveDate,
    });

    return ctx.prisma.compensationReviewAdjustment.update({
      where: { id: adjustment.id },
      data: {
        submittedAmount: adjustment.submittedAmount,
        convertedSubmittedAmount,
        proratedSubmittedAmount,
      },
    });
  });

  if (params.submitRecommendation) {
    await submitRecommendation(ctx, {
      recommendation,
      adjustments,
      recommendationInput: {
        isPromoted: params.isPromoted,
        targetRangeAfterPromotion,
      },
    });

    if (isPendingRecommendation(recommendation)) {
      const nextRecommendation = recommendation.employee.recommendations.find(
        (item) => item.position === recommendation.position + 1
      );

      if (nextRecommendation) {
        await ctx.prisma.compensationReviewRecommendation.update({
          where: {
            ...prismaCompensationReviewScope(ctx.scope),
            id: nextRecommendation.id,
          },
          data: {
            status: CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
            skippedByReviewerId: null,
          },
        });

        if (ctx.scope.type === CompensationReviewScopeType.OFF_CYCLE_REVIEW && nextRecommendation.reviewer) {
          await notifyNextReviewer(ctx, {
            employeeId: recommendation.employee.id,
            nextReviewerExternalEmployeeId: nextRecommendation.reviewer.externalEmployeeId,
          });
        }
      }

      if (!nextRecommendation && ctx.scope.type === CompensationReviewScopeType.OFF_CYCLE_REVIEW) {
        await completeOffCycleRequestRequest(ctx, {
          offCycleReviewConfigurationId: ctx.scope.id,
          employeeId: recommendation.employee.id,
        });
      }

      const submittedRecommendation = recommendation.employee.recommendations.find(
        (recommendation) => recommendation.status === CompensationReviewRecommendationStatus.SUBMITTED
      );

      if (submittedRecommendation) {
        await ctx.prisma.compensationReviewRecommendation.update({
          where: { id: submittedRecommendation.id },
          data: {
            status: CompensationReviewRecommendationStatus.LOCKED,
          },
        });
      }
    }

    await ensureFirstRecommendationIsListedToReviewer(ctx, { employeeId: recommendation.employee.id });

    const nonSubmittedRecommendationsCount = await ctx.prisma.compensationReviewRecommendation.count({
      where: {
        reviewerId: recommendation.reviewerId,
        OR: [{ submittedAt: null }, { status: CompensationReviewRecommendationStatus.LOCKED }],
      },
    });
    if (nonSubmittedRecommendationsCount === 0) {
      await trackCompensationReviewRecommandationsSubmitted(ctx, { scope: ctx.scope });
    }
  }
};

export const fetchRecommendationForUpdate = async (
  ctx: CompensationReviewContext,
  params: {
    recommendationId: number;
    reviewerId?: number;
  }
) => {
  const recommendation = await ctx.prisma.compensationReviewRecommendation.findFirstOrThrow({
    where: {
      id: params.recommendationId,
      ...prismaCompensationReviewScope(ctx.scope),
      ...(params.reviewerId && { reviewerId: params.reviewerId }),
      status: {
        in: [
          CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
          CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,
          CompensationReviewRecommendationStatus.SUBMITTED,
        ],
      },
    },
    select: {
      id: true,
      reviewerId: true,
      status: true,
      position: true,
      employee: {
        select: {
          id: true,
          isPromoted: true,
          effectiveDate: true,
          baseSalary: true,
          variablePay: true,
          onTargetEarnings: true,
          fteCoefficient: true,
          currency: { select: selectCompensationReviewCurrency },
          adjustments: true,
          eligibilities: {
            select: {
              budgetId: true,
            },
          },
          recommendations: {
            select: {
              id: true,
              status: true,
              position: true,
              reviewer: {
                select: {
                  id: true,
                  externalEmployeeId: true,
                },
              },
              isListedToReviewer: true,
            },
          },
          targetRangeAfterPromotion: {
            select: {
              min: true,
              max: true,
              midpoint: true,
              band: { select: { measure: true, currency: true } },
            },
          },
          externalEmployee: {
            select: {
              id: true,
              liveSalaryRangeEmployee: {
                select: {
                  range: {
                    select: {
                      min: true,
                      max: true,
                      midpoint: true,
                      band: { select: { measure: true, currency: true } },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  });

  return {
    ...recommendation,
    employee: {
      ...recommendation.employee,
      currency: transformCompensationReviewCurrency(recommendation.employee.currency),
      salaryRangeEmployee: recommendation.employee.externalEmployee.liveSalaryRangeEmployee ?? null,
    },
  };
};

export const submitRecommendation = async (
  ctx: CompensationReviewContext,
  params: {
    recommendation: AsyncReturnType<typeof fetchRecommendationForUpdate>;
    adjustments: CompensationReviewAdjustment[];
    recommendationInput?: {
      isPromoted: boolean;
      targetRangeAfterPromotion: SalaryRangeAutocompleteValue | null;
    };
  }
) => {
  const { recommendation, adjustments } = params;
  const { employee } = recommendation;

  if (params.recommendationInput && ctx.permissions.canUpdatePromotions) {
    await ctx.prisma.compensationReviewRecommendation.updateMany({
      where: {
        employeeId: recommendation.employee.id,
        position: { gt: recommendation.position },
      },
      data: {
        isPromoted: params.recommendationInput.isPromoted,
        targetRangeAfterPromotionId: params.recommendationInput.targetRangeAfterPromotion?.id,
      },
    });
  }

  await mapSeries(recommendation.employee.adjustments, async (adjustment) => {
    const recommendationAdjustment = adjustments.find(
      (item) => item.budgetId === adjustment.budgetId && item.compensationItem === adjustment.compensationItem
    );

    if (!recommendationAdjustment) return;

    await ctx.prisma.compensationReviewAdjustment.update({
      where: { id: adjustment.id },
      data: {
        submittedAmount: recommendationAdjustment.submittedAmount,
        convertedSubmittedAmount: recommendationAdjustment.convertedSubmittedAmount,
        proratedSubmittedAmount: recommendationAdjustment.proratedSubmittedAmount,
      },
    });

    await ctx.prisma.compensationReviewAdjustment.updateMany({
      where: {
        budgetId: recommendationAdjustment.budgetId,
        compensationItem: recommendationAdjustment.compensationItem,
        recommendation: {
          employeeId: recommendation.employee.id,
          position: { gt: recommendation.position },
        },
      },
      data: {
        recommendedAmount: recommendationAdjustment.submittedAmount,
        convertedRecommendedAmount: recommendationAdjustment.convertedSubmittedAmount,
        proratedRecommendedAmount: recommendationAdjustment.proratedSubmittedAmount,
      },
    });
  });

  const employeeAdjustments = await ctx.prisma.compensationReviewAdjustment.findMany({
    where: {
      ...prismaCompensationReviewScope(ctx.scope),
      employeeId: employee.id,
      compensationItem: {
        in: [CompensationReviewCompensationItem.BASE_SALARY, CompensationReviewCompensationItem.VARIABLE_PAY],
      },
    },
  });

  const onTargetEarningsRecommendation = computeAmountForCompensationItem(
    employeeAdjustments,
    CompensationReviewCompensationItem.ON_TARGET_EARNINGS,
    ["recommendedAmount"]
  );

  const baseSalarySubmittedAmount = computeAmountForCompensationItem(
    employeeAdjustments,
    CompensationReviewCompensationItem.BASE_SALARY,
    ["submittedAmount"]
  );

  const onTargetEarningsSubmittedAmount = computeAmountForCompensationItem(
    employeeAdjustments,
    CompensationReviewCompensationItem.ON_TARGET_EARNINGS,
    ["submittedAmount"]
  );

  const isIncreaseDifferentFromRecommendation = value(() => {
    return roundTo(onTargetEarningsRecommendation, 100) !== roundTo(onTargetEarningsSubmittedAmount, 100);
  });

  const onTargetEarningsIncreasePercentage = employee.onTargetEarnings
    ? onTargetEarningsSubmittedAmount / employee.onTargetEarnings
    : null;

  const newOnTargetEarnings = employee.onTargetEarnings + onTargetEarningsSubmittedAmount;

  const newBandPositioning = computeNewBandPositioning({
    employee,
    targetRange: params.recommendationInput?.targetRangeAfterPromotion ?? employee.salaryRangeEmployee?.range ?? null,
    newValues: {
      fteBaseSalary: computeFteAmount(employee.baseSalary + baseSalarySubmittedAmount, employee.fteCoefficient),
      fteOnTargetEarnings: computeFteAmount(
        employee.onTargetEarnings + onTargetEarningsSubmittedAmount,
        employee.fteCoefficient
      ),
    },
  });

  await ctx.prisma.compensationReviewEmployee.update({
    where: {
      id: employee.id,
      ...prismaCompensationReviewScope(ctx.scope),
    },
    data: {
      isIncreaseDifferentFromRecommendation,
      onTargetEarningsIncreasePercentage,
      convertedNewOnTargetEarnings: convertCurrency(newOnTargetEarnings, employee.currency, ctx.parameters.currency),
      newCompaRatio: newBandPositioning?.newCompaRatio ?? null,
      newRangePenetration: newBandPositioning?.newRangePenetration ?? null,

      ...(params.recommendationInput &&
        ctx.permissions.canUpdatePromotions && {
          isPromoted: params.recommendationInput.isPromoted,
          targetRangeAfterPromotionId: params.recommendationInput.targetRangeAfterPromotion?.id,
        }),
    },
  });
};

const notifyNextReviewer = async (
  ctx: CompensationReviewContext,
  params: {
    employeeId: number;
    nextReviewerExternalEmployeeId: number;
  }
) => {
  const nextReviewerUser = await ctx.prisma.user.findFirst({
    where: {
      companyId: ctx.user.companyId,
      permissions: {
        externalEmployeeId: params.nextReviewerExternalEmployeeId,
      },
    },
    select: { email: true, locale: true },
  });

  if (!nextReviewerUser) return;

  const offCycleReviewRequest = await ctx.prisma.offCycleReviewRequest.findFirst({
    where: {
      configurationId: ctx.scope.id,
      employeeId: params.employeeId,
    },
    include: {
      externalEmployee: true,
    },
  });

  if (!offCycleReviewRequest) return;

  await sendOffCycleReviewPendingEmail(ctx, {
    user: nextReviewerUser,
    request: offCycleReviewRequest,
  });
};
