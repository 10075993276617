import { type IncomingMessage } from "http";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logInfo } from "~/lib/logger";
import { signOut } from "~/lib/session";
import { SignInError } from "~/services/auth/signInError";

export class AuthenticationRequiresProviderConfirmationError extends FiguresBaseError {
  constructor(
    public readonly email: string,
    public readonly providerName: string,
    public readonly oldProviderId: string,
    public readonly newProviderId: string
  ) {
    super("Authentication requires provider confirmation");
    Object.setPrototypeOf(this, AuthenticationRequiresProviderConfirmationError.prototype);
  }
}

export const handleAuthenticationRequiresProviderConfirmationError = async (
  ctx: AppContext,
  res: NextApiResponse,
  error: AuthenticationRequiresProviderConfirmationError
) => {
  logInfo(ctx, "[auth] Authentication requires provider confirmation", {
    error,
    oldProviderId: error.oldProviderId,
    newProviderId: error.newProviderId,
  });

  await signOut(ctx as IncomingMessage);

  res.redirect(
    `/sign-in?error=${SignInError.AUTHENTICATION_REQUIRES_PROVIDER_CONFIRMATION}&email=${error.email}&provider=${error.providerName}`
  );
};
