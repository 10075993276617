import { type Prisma } from "@prisma/client";
import { map } from "bluebird";
import { type ObjectShape, type OptionalObjectSchema } from "yup/lib/object";
import { type AppContext } from "~/lib/context";
import { chain, isArray } from "~/lib/lodash";
import { logError } from "~/lib/logger";
import { type QueueJobName } from "~/lib/queue/queueJobName";
import { getId } from "~/lib/utils";

type CheckoutJobCacheData<T extends ObjectShape> = {
  companyId: number;
  queueJobName: QueueJobName;
  validationSchema: OptionalObjectSchema<T>;
  chunkSize?: number;
};

export const checkoutJobCache = async <T extends ObjectShape>(ctx: AppContext, params: CheckoutJobCacheData<T>) => {
  const rowsFromQueueJobCache = await ctx.prisma.queueJobCache.findMany({
    where: {
      jobName: params.queueJobName,
      companyId: params.companyId,
    },
    select: { id: true, data: true },
    ...(params.chunkSize && { take: params.chunkSize }),
  });

  await ctx.prisma.queueJobCache.deleteMany({
    where: { id: { in: rowsFromQueueJobCache.map(getId) } },
  });

  const data = chain(rowsFromQueueJobCache)
    .map((rowFromQueueJobCache) => {
      const { data } = rowFromQueueJobCache;

      if (!params.validationSchema.isValidSync(data, { abortEarly: true })) {
        logError(ctx, "[queue-job-cache] An invalid QueueJobCache row was found", {
          data,
          companyId: params.companyId,
        });
        return;
      }

      return params.validationSchema.validateSync(data);
    })
    .compact()
    .value();

  return data;
};

type FillJobCacheData<T extends ObjectShape> = {
  companyId: number;
  queueJobName: QueueJobName;
  data: OptionalObjectSchema<T>["__outputType"] | OptionalObjectSchema<T>["__outputType"][];
  validationSchema: OptionalObjectSchema<T>;
};

export const fillJobCache = async <T extends ObjectShape>(ctx: AppContext, params: FillJobCacheData<T>) => {
  const data = isArray(params.data) ? params.data : [params.data];

  const chunks = chain(data)
    .map((row) => {
      try {
        return params.validationSchema.validateSync(row);
      } catch (error) {
        logError(ctx, "[queue-job-cache] Error while validating data for queueJobCache", {
          row,
          error,
          name: params.queueJobName,
          companyId: params.companyId,
        });
      }
    })
    .compact()
    .chunk(50)
    .value();

  await map(
    chunks,
    async (chunk) =>
      ctx.prisma.queueJobCache.createMany({
        data: chunk.map((row) => ({
          jobName: params.queueJobName,
          companyId: params.companyId,
          data: row as Prisma.InputJsonValue,
        })),
      }),
    { concurrency: 2 }
  );
};

export const getJobCacheSize = async (ctx: AppContext, params: { companyId: number; queueJobName: QueueJobName }) =>
  ctx.prisma.queueJobCache.count({
    where: {
      jobName: params.queueJobName,
      companyId: params.companyId,
    },
  });

export const jobCacheTransaction = async <T extends ObjectShape>(
  ctx: AppContext,
  params: {
    companyId: number;
    queueJobName: QueueJobName;
    chunkSize?: number;
    validationSchema: OptionalObjectSchema<T>;
  },
  callback: (ctx: AppContext, data: OptionalObjectSchema<T>["__outputType"][]) => Promise<void>
) => {
  const data = await checkoutJobCache(ctx, {
    companyId: params.companyId,
    queueJobName: params.queueJobName,
    validationSchema: params.validationSchema,
    ...(params.chunkSize && { chunkSize: params.chunkSize }),
  });

  await callback(ctx, data);

  const remainingLinesCount = await getJobCacheSize(ctx, params);

  return { remainingLinesCount };
};

export const filterValidRowsForSchema = <T extends ObjectShape>(
  data: OptionalObjectSchema<T>["__inputType"][],
  schema: OptionalObjectSchema<T>
): OptionalObjectSchema<T>["__outputType"][] => {
  return chain(data)
    .map((row) => {
      try {
        return schema.validateSync(row);
      } catch {
        // not an error, we just want to filter out invalid rows
      }
    })
    .compact()
    .value();
};
