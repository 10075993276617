import { ExternalEmployeeContractType, IntegrationSource } from "@prisma/client";
import { KomboEmploymentType } from "~/lib/hris/komboEmploymentType";
import { findKey, isNil } from "~/lib/lodash";

type ContractTypeByHris = {
  [key in IntegrationSource]?: {
    [key in ExternalEmployeeContractType]?: string[];
  };
};

const komboIntegrationSources = Object.values(IntegrationSource).filter((source) => source.includes("KOMBO"));

const CONTRACT_TYPE_BY_HRIS_MAP: ContractTypeByHris = {
  [IntegrationSource.BAMBOO]: {
    [ExternalEmployeeContractType.PERMANENT]: [
      "CDI - FULL TIME",
      "CDI (BELGIUM)",
      "CDI (FRANCE)",
      "EMPLOYEE",
      "FTE 0.1",
      "FTE 0.2",
      "FTE 0.3",
      "FTE 0.4",
      "FTE 0.5",
      "FTE 0.6",
      "FTE 0.7",
      "FTE 0.8",
      "FTE 0.9",
      "FTE 1.0",
      "FULL TIME REGULAR",
      "FULL TIME",
      "FULL TIME FIXED TERM",
      "FULL-TIME (PERMANENT CONTRACT)",
      "FULL-TIME",
      "INTERNAL_PERMANENT_FULL-TIME_REDUCED",
      "INTERNAL_PERMANENT, FULL-TIME",
      "INTERNAL_PERMANENT, PART-TIME",
      "MANDATAIRE SOCIAL",
      "PART TIME 40%",
      "PART TIME 60%",
      "PART TIME REGULAR",
      "PART TIME",
      "PART-TIME (PERMANENT CONTRACT)",
      "PART-TIME 0.1 FTE",
      "PART-TIME 0.2 FTE",
      "PART-TIME 0.3 FTE",
      "PART-TIME 0.4 FTE",
      "PART-TIME 0.5 FTE",
      "PART-TIME 0.6 FTE",
      "PART-TIME 0.7 FTE",
      "PART-TIME 0.8 FTE",
      "PART-TIME 0.9 FTE",
      "PART-TIME 1.0 FTE",
      "PART-TIME 50%",
      "PART-TIME",
      "PERMANENT (VIA EOR)",
      "PERMANENT PT 80%",
      "PERMANENT",
      "ZILCH POLAND - PERMANENT",
    ],
    [ExternalEmployeeContractType.TEMPORARY]: [
      "CDD - FULL TIME",
      "FIXED TERM 0.1 FTE",
      "FIXED TERM 0.2 FTE",
      "FIXED TERM 0.3 FTE",
      "FIXED TERM 0.4 FTE",
      "FIXED TERM 0.5 FTE",
      "FIXED TERM 0.6 FTE",
      "FIXED TERM 0.7 FTE",
      "FIXED TERM 0.8 FTE",
      "FIXED TERM 0.9 FTE",
      "FIXED TERM 1.0 FTE",
      "FIXED TERM CONTRACT",
      "FIXED TERM",
      "FIXED-TERM",
      "FULL TIME - DEFINITE CONTRACT",
      "FULL TIME FIXED TERM",
      "FULL-TIME (TEMP.)",
      "INTERNAL FIXED TERM, FULL-TIME",
      "INTERNAL FIXED TERM, PART-TIME",
      "PART-TIME (TEMP.)",
      "TEMPORARY",
      "TERM TIME",
    ],
    [ExternalEmployeeContractType.FREELANCER]: ["FREELANCER", "FREELANCE"],
    [ExternalEmployeeContractType.CONTRACTOR]: [
      "CONTRACTOR - DEEL",
      "CONTRACTOR (AGENCY)",
      "CONTRACTOR (B2B)",
      "CONTRACTOR (FULL-TIME)",
      "CONTRACTOR (SOLE TRADER)",
      "CONTRACTOR 0.8 FTE",
      "CONTRACTOR",
      "EXTERNAL CONTRACTOR (WITH DIRECT CONTRACT BETWEEN US)",
      "EXTERNAL CONTRACTOR VIA 3RD PARTY (HC BASED)",
      "EXTERNAL CONTRACTOR VIA 3RD PARTY (NOT IN LEAPSOME)",
      "EXTERNAL CONTRACTOR VIA 3RD PARTY (PROJECT BASED)",
      "INDEPENDENT CONTRACTOR",
    ],
    [ExternalEmployeeContractType.CONSULTANT]: ["CONSULTANT", "FULL TIME CONSULTANT"],
    [ExternalEmployeeContractType.INTERNSHIP]: [
      "AUSZUBILDENDE/R",
      "FULL TIME INTERN",
      "INTERN",
      "INTERNAL_INTERNSHIP",
      "INTERNSHIP",
      "INTERSHIP PART TIME",
    ],
    [ExternalEmployeeContractType.APPRENTICESHIP]: [
      "APPRENTICE",
      "APPRENTICESHIP",
      "FULLTIMEAPPRENTICE",
      "FULL TIME APPRENTICE",
    ],
    [ExternalEmployeeContractType.WORKING_STUDENT]: ["STUDENT", "WORKING STUDENT"],
    [ExternalEmployeeContractType.TRAINING]: ["TRAINING", "TRAINEE"],
  },
  [IntegrationSource.CHARLIEHR]: {
    [ExternalEmployeeContractType.PERMANENT]: ["PERMANENT"],
    [ExternalEmployeeContractType.FREELANCER]: ["FREELANCER"],
    [ExternalEmployeeContractType.CONTRACTOR]: ["CONTRACTOR"],
    [ExternalEmployeeContractType.INTERNSHIP]: ["INTERN"],
    [ExternalEmployeeContractType.APPRENTICESHIP]: ["APPRENTICESHIP"],
    [ExternalEmployeeContractType.WORKING_STUDENT]: ["STUDENT"],
    [ExternalEmployeeContractType.TRAINING]: ["TRAINING"],
  },
  ...[IntegrationSource.HIBOB, IntegrationSource.HIBOB_DEPRECATED].reduce(
    (acc, source) => ({
      ...acc,
      [source]: {
        [ExternalEmployeeContractType.PERMANENT]: [
          "CA KAM TL",
          "CA KAM",
          "DE EMPLOYEE",
          "DE IS",
          "DE KAM",
          "DK EMPLOYEE",
          "DK HOURLY PAID",
          "DK IS",
          "DK KAM TL",
          "DK KAM",
          "EMPLOYEE (PERMANENT)",
          "EMPLOYEE",
          "EOR (PERM CONTRACT)",
          "ES EMPLOYEE",
          "ES IS",
          "ES KAM TL",
          "ES KAM",
          "FI EMPLOYEE",
          "FI IS",
          "FI KAM",
          "FULLTIME EMPLOYEE",
          "INDEFINITE CONTRACT",
          "INDEFINITE TERM CONTRAT",
          "INDEFINITE",
          "MANAGEMENT AGREEMENT",
          "NL EMPLOYEE",
          "NL IS",
          "NL KAM TL",
          "NL KAM",
          "NO EMPLOYEE",
          "NO IS",
          "NO KAM TL",
          "NO KAM",
          "PERMANENT EMPLOYEE",
          "Permanent US",
          "PERMANENT: EOR",
          "PERMANENT: SINGAPORE",
          "PERMANENT: UK",
          "PERMANENT: USA",
          "PERMANENT",
          "PORTAGE",
          "SE Employee",
          "SE IS",
          "SE KAM TL",
          "SE KAM",
          "UK EMPLOYEE",
          "UK EMPLOYEE",
          "UK IS",
          "UK KAM TL",
          "UK KAM",
          "US EMPLOYEE",
          "US IS TL",
          "US IS",
          "US KAM TL",
          "US KAM",
        ],
        [ExternalEmployeeContractType.TEMPORARY]: [
          "EMPLOYER OF RECORD",
          "EOR EMPLOYEE",
          "EOR",
          "FIXED CONTRACT",
          "FIXED PERIOD",
          "FIXED TERM CONTRACT",
          "FIXED TERM EMPLOYEE",
          "FIXED TERM",
          "FIXED-TERM CONTRACT",
          "FIXED-TERM",
          "PARTTIME EMPLOYEE",
          "TEMPORARY EMPLOYEE > / = 6",
          "TEMPORARY EMPLOYEE",
          "TEMPORARY",
          "TEMPORARY/FIXED TERM CONTRACT",
        ],
        [ExternalEmployeeContractType.FREELANCER]: ["FREELANCER", "FREELANCE", "AGENCY WORKER"],
        [ExternalEmployeeContractType.INTERNSHIP]: ["INTERN", "INTERN (MANDATORY)", "INTERNSHIP"],
        [ExternalEmployeeContractType.CONTRACTOR]: [
          "CONTRACT",
          "CONTRACTOR (FIXED PROJECT)",
          "CONTRACTOR (PERMANENT)",
          "CONTRACTOR (ROLLING)",
          "CONTRACTOR < 6",
          "CONTRACTOR >/= 6",
          "CONTRACTOR",
          "DIRECT CONTRACTOR",
          "EOR CONTRACT",
          "EOR CONTRACTOR",
          "EXTERNAL CONTRACTOR",
          "EXTERNAL",
          "INTERIM",
          "INTERIMMER",
          "TOSS (EMPLOYER OF RECORD) - INDEFINITE",
        ],
        [ExternalEmployeeContractType.CONSULTANT]: ["EXTERNAL ADVISOR", "CONSULTANT"],
        [ExternalEmployeeContractType.APPRENTICESHIP]: ["APPRENTICE", "APPRENTICESHIP"],
        [ExternalEmployeeContractType.WORKING_STUDENT]: ["WORKING STUDENT"],
      },
    }),
    {}
  ),
  [IntegrationSource.HUMAANS]: {
    [ExternalEmployeeContractType.PERMANENT]: [
      "FULL-TIME",
      "FULLTIME",
      "FULL TIME",
      "PART-TIME",
      "PARTTIME",
      "PART TIME",
      "PERMANENT",
    ],
    [ExternalEmployeeContractType.TEMPORARY]: ["FIXED-TERM", "FIXED TERM EMPLOYEE"],
    [ExternalEmployeeContractType.INTERNSHIP]: ["INTERNSHIP"],
    [ExternalEmployeeContractType.CONTRACTOR]: ["CONTRACTOR"],
  },
  ...komboIntegrationSources.reduce(
    (acc, source) => ({
      ...acc,
      [source]: {
        [ExternalEmployeeContractType.PERMANENT]: [
          KomboEmploymentType.FULL_TIME,
          KomboEmploymentType.PART_TIME,
          "CORPORATE OFFICER",
          "HRIS_DIRECT_EMPLOYEE",
          "DIRECT_EMPLOYEE",
        ],
        [ExternalEmployeeContractType.TEMPORARY]: [
          KomboEmploymentType.CONTRACT,
          "PROFESSIONAL FIXED TERM CONTRACT ",
          "PERMANENT CONTRACT (DURATION)",
          "FIXED TERM, FULL TIME",
          "EOR",
          "PEO",
        ],
        [ExternalEmployeeContractType.FREELANCER]: [KomboEmploymentType.FREELANCE],
        [ExternalEmployeeContractType.CONTRACTOR]: [KomboEmploymentType.CONTRACT],
        [ExternalEmployeeContractType.INTERNSHIP]: [KomboEmploymentType.INTERNSHIP],
        [ExternalEmployeeContractType.APPRENTICESHIP]: [KomboEmploymentType.APPRENTICESHIP, "APPR"],
        [ExternalEmployeeContractType.WORKING_STUDENT]: [KomboEmploymentType.WORKING_STUDENT],
        [ExternalEmployeeContractType.TRAINING]: [KomboEmploymentType.TRAINING],
      },
    }),
    {}
  ),
  [IntegrationSource.LUCCA]: {
    [ExternalEmployeeContractType.PERMANENT]: [
      "CDI",
      "CDIC",
      "MANDATAIRE",
      "PART-TIME",
      "PERMANENT CONTRACT",
      "PERMANENT FULL TIME",
      "PERMANENT INTERNAL CONTRACT",
      "PERMANENT INTERNAL CONTRACT",
      "PERMANENT PART TIME",
      "PERMANENT",
      "UK _ PERMANENT",
    ],
    [ExternalEmployeeContractType.TEMPORARY]: [
      "CDD CIFRE",
      "CDD DE REMPLACEMENT",
      "CDD SURCROÎT",
      "CDD",
      "CDDU - INTERMITTENT",
      "CDDU - JOURNALISTE",
      "CONTRAT À DURÉE INDÉTERMINÉE",
      "FIXED TERM",
      "FIXED-TERM CONTRACT",
      "FIXED-TERM",
      "TEMPORARY FULL TIME",
      "TEMPORARY PART TIME",
    ],
    [ExternalEmployeeContractType.FREELANCER]: ["FREELANCE"],
    [ExternalEmployeeContractType.CONSULTANT]: ["CONSULTANT OYSTER"],
    [ExternalEmployeeContractType.CONTRACTOR]: [
      "CONTRACTOR CZ",
      "CONTRACTOR FR",
      "CONTRACTOR UK",
      "CONTRACTOR",
      "INDEPENDENT CONTRACTOR",
      "INTERIM",
      "INTÉRIM",
      "PRESTATAIRE",
    ],
    [ExternalEmployeeContractType.APPRENTICESHIP]: [
      "APPRENTICE",
      "APPRENTICESHIP 1",
      "APPRENTICESHIP 2",
      "APPRENTICESHIP CONTRACT",
      "APPRENTICESHIP",
      "APPRENTISSAGE",
      "CONTRAT D'APPRENTISSAGE",
    ],
    [ExternalEmployeeContractType.INTERNSHIP]: ["INTERNSHIP"],
    [ExternalEmployeeContractType.WORKING_STUDENT]: ["STAGE"],
    [ExternalEmployeeContractType.TRAINING]: ["CONTRAT PRO"],
  },
  [IntegrationSource.PERSONIO]: {
    [ExternalEmployeeContractType.PERMANENT]: [
      "CEO",
      "FULL-TIME",
      "INDEFINITE CONTRACT",
      "PERMANENT CONTRACT",
      "PERMANENT EMPLOYEE",
      "PERMANENT EMPLOYMENT",
      "PERMANENT FULL-TIME",
      "PERMANENT",
    ],
    [ExternalEmployeeContractType.TEMPORARY]: [
      "FIX-TERM EMPLOYMENT",
      "FIXED TERM CONTRACT",
      "FIXED-TERM EMPLOYEE",
      "ON-CALL CONTRACT",
      "TEMPORARY CONTRACT",
    ],
    [ExternalEmployeeContractType.FREELANCER]: ["FREELANCER", "FREELANCE (PERFREE)"],
    [ExternalEmployeeContractType.INTERNSHIP]: ["INTERNSHIP"],
    [ExternalEmployeeContractType.APPRENTICESHIP]: ["APPRENTICESHIP"],
    [ExternalEmployeeContractType.WORKING_STUDENT]: ["WORKING STUDENT", "WORKING STUDENT (UNDER 17.5 H)"],
    [ExternalEmployeeContractType.TRAINING]: ["TRAINING"],
  },
};

export const getContractType = (contractType: string | null | undefined, integrationSource: IntegrationSource) => {
  const integrationWithContractTypes = CONTRACT_TYPE_BY_HRIS_MAP[integrationSource];

  if (!integrationWithContractTypes || isNil(contractType)) {
    return ExternalEmployeeContractType.PERMANENT;
  }

  const externalEmployeeContractType = findKey(
    integrationWithContractTypes,
    (contractTypes) => contractTypes?.includes(contractType.toUpperCase())
  );

  if (!externalEmployeeContractType) {
    return ExternalEmployeeContractType.OTHER;
  }

  return externalEmployeeContractType as ExternalEmployeeContractType;
};
