import { type Currency, ExternalEmployeeStatus, Gender } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { parseExcelDate } from "~/lib/dates";
import { XlsxImportError } from "~/lib/errors/xlsxImportError";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { isBoolean, isNil, isString, partition } from "~/lib/lodash";
import { ErrorCode } from "~/lib/makeErrorPayload";
import { parseMoney } from "~/lib/money";
import { partialImportColumnsMap } from "~/services/external-employee/import/process/partial/partialImportColumnsMap";
import { type EntityRow, validateRowsWithSchema } from "~/services/xlsxToJson";

export const PARTIAL_IMPORT_SHEET_NAME = "1. Partial Data Collection";

export const importPartialExternalEmployeesSchema = (currencies: Pick<Currency, "code">[]) =>
  object({
    employeeNumber: string().required(),
    gender: mixed<Gender>()
      .oneOf([Gender.MALE, Gender.FEMALE, Gender.UNDISCLOSED])
      .transform((value) => {
        if (value.toLowerCase() === "male") {
          return Gender.MALE;
        }

        if (value.toLowerCase() === "female") {
          return Gender.FEMALE;
        }

        return Gender.UNDISCLOSED;
      }),
    birthDate: mixed<Date>().nullable().transform(parseExcelDate),
    firstName: string().nullable(),
    lastName: string().nullable(),
    email: string().nullable(),
    hireDate: mixed<Date>().nullable().transform(parseExcelDate),
    jobTitle: string().notRequired(),
    level: string().notRequired(),
    fteCoefficient: number()
      .nullable()
      .min(0)
      .max(200)
      .transform((value) => {
        if (!value) {
          return null;
        }

        const parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
          return null;
        }

        return parsedValue;
      }),
    currency: string()
      .oneOf(
        currencies.map((currency) => {
          return currency.code;
        })
      )
      .transform((value) => {
        return value.substr(0, 3);
      }),
    baseSalary: mixed<number>()
      .transform((value) => {
        if (value === "") {
          return null;
        }

        return parseMoney(value);
      })
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => (isNil(value) ? true : value >= 0),
      }),
    onTargetBonus: mixed<number>()
      .transform((value) => {
        if (value === "") {
          return null;
        }

        return parseMoney(value);
      })
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => (isNil(value) ? true : value >= 0),
      }),
    fixedBonus: mixed<number>()
      .transform((value) => {
        if (value === "") {
          return null;
        }

        return parseMoney(value);
      })
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => (isNil(value) ? true : value >= 0),
      }),
    location: string().notRequired(),
    isFounder: mixed<boolean>().transform((value) => {
      if (isString(value)) {
        return value.toUpperCase() === "YES";
      } else if (isBoolean(value)) {
        return value;
      }
    }),
    manager: string().transform((value) => {
      return `${value}`;
    }),
    performanceRating: string().notRequired(),
    businessUnit: string().notRequired(),
  });

export const parsePartialExternalEmployees = async (ctx: AppContext, input: { rows: EntityRow[] }) => {
  const user = getRequiredUser(ctx);

  const currencies = await ctx.prisma.currency.findMany({ select: { code: true } });

  const validRows = validateRowsWithSchema(ctx, {
    entityRows: input.rows.filter((row) => !!row.rowData.employeeNumber),
    columnMap: partialImportColumnsMap(ctx),
    schema: importPartialExternalEmployeesSchema(currencies),
  });

  const nonSkippedEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      status: { not: ExternalEmployeeStatus.SKIPPED },
      companyId: user.companyId,
    },
    select: {
      employeeNumber: true,
    },
  });
  const nonSkippedEmployeesNumbers = nonSkippedEmployees.map((employee) => employee.employeeNumber);

  const [rowsWithMatchingEmployee, unknownEmployeesRows] = partition(
    validRows,
    (row) => row.employeeNumber && nonSkippedEmployeesNumbers.includes(row.employeeNumber)
  );

  if (!rowsWithMatchingEmployee.length) {
    throw new XlsxImportError(
      ctx.t("services.partial-spreadsheet-update.import-partial-spreadsheet.could-not-find-any-valid-employee")
    ).withErrorCode(ErrorCode.SPREADSHEET_NO_VALID_EMPLOYEE_FOUND);
  }

  return {
    rows: rowsWithMatchingEmployee,
    warnings: {
      unknownEmployeesRows,
    },
  } as const;
};

export type ParsePartialExternalEmployeesResult = AsyncReturnType<typeof parsePartialExternalEmployees>;
