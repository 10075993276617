import { type FiguresBaseError } from "~/lib/errors/figuresBaseError";

export const ErrorCode = {
  // INTERNAL BASE CODES 000 => 099
  GENERIC: "F000",
  PRISMA_NOT_FOUND: "F001",
  PRISMA_UNEXPECTED: "F002",
  FORBIDDEN: "F003",
  BUSINESS_LOGIC: "F004",
  API_FEATURE_ACCESS: "F005",
  IMPERSONATION_FORBIDDEN: "F006",
  IMPERSONATION_MISMATCH: "F007",
  AUTHENTICATION: "F008",
  GITHUB: "F009",
  KOMBO: "F010",
  USER_NOT_FOUND: "F011",

  // INTERNAL CUSTOM ERROR CODES 100 => 399
  INVITATION_INVALID_TOKEN: "F104",

  SSO_NO_COMPANY_MATCHING_EMAIL_DOMAIN: "F122_0",
  SSO_NO_USER_FOUND_AND_NO_COMPANY_WITH_SAML_CONFIGURATION_FOUND: "F122_1",
  SSO_COMPANY_HAS_NO_SAML_CONFIGURATION: "F122_2",
  SSO_USER_NEED_INVITATION_TO_CREATE_ACCOUNT: "F122_3",

  SPREADSHEET_NO_VALID_EMPLOYEE_FOUND: "F156",

  // HTTP MATCHING CODES 400 => 499
  UNAUTHORISED: "F401",
  UNEXPECTED: "F418",
  UNPROCESSABLE_ENTITY: "F422",
  TOO_MANY_REQUESTS: "F429",
} as const satisfies Record<string, FiguresErrorCode>;

type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode];

export type FiguresErrorCode = `F${string}`;

export const makeErrorPayload = (params: { error: FiguresBaseError; defaultCode: ErrorCode }) => ({
  error: params.error.message,
  errorCode: params.error.errorCode ?? params.defaultCode,
  errorId: params.error.errorId,
});
