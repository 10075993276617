import { type AppContext } from "~/lib/context";
import { and } from "~/lib/prismaHelpers";
import { CompensationReviewScopeType } from "~/services/compensation-review/compensationReviewScope";
import { type EmployeePermissionsSchema } from "~/services/permissions/employeePermissions";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissionsSchema";

export const generateCompensationReviewPermissionsSchema = (
  ctx: AppContext,
  params: {
    filters: UserPermissionsSchemaFilters;
    employeePermissionsSchema: EmployeePermissionsSchema;
  }
) => {
  const { filters, employeePermissionsSchema } = params;

  const { ownedByCompany } = filters;

  const campaignId =
    ctx.compensationReviewScope?.type === CompensationReviewScopeType.CAMPAIGN
      ? ctx.compensationReviewScope.id
      : undefined;

  const currentCampaign = campaignId ? { id: campaignId } : undefined;
  const fromCurrentCampaign = campaignId ? { campaignId } : undefined;

  const canAccessCompensationReviewEmployee = {
    OR: [
      {
        eligibilities: { some: { ...fromCurrentCampaign } },
      },
      {
        externalEmployee: employeePermissionsSchema.ExternalEmployee.read,
      },
    ],
  };

  return {
    CompensationReviewCampaign: {
      read: and([ownedByCompany, currentCampaign]),
      update: and([ownedByCompany, currentCampaign]),
      delete: and([ownedByCompany, currentCampaign]),
    },

    CompensationReviewAdmin: {
      read: and([ownedByCompany, fromCurrentCampaign]),
      update: and([ownedByCompany, fromCurrentCampaign]),
      delete: and([ownedByCompany, fromCurrentCampaign]),
    },

    CompensationReviewCurrency: {
      read: and([ownedByCompany, fromCurrentCampaign]),
      update: and([ownedByCompany, fromCurrentCampaign]),
      delete: and([ownedByCompany, fromCurrentCampaign]),
    },

    CompensationReviewEmployee: {
      read: and([ownedByCompany, fromCurrentCampaign, canAccessCompensationReviewEmployee]),
      update: and([ownedByCompany, fromCurrentCampaign, canAccessCompensationReviewEmployee]),
      delete: and([ownedByCompany, fromCurrentCampaign, canAccessCompensationReviewEmployee]),
    },

    CompensationReviewReviewer: {
      read: and([ownedByCompany, fromCurrentCampaign]),
      update: and([ownedByCompany, fromCurrentCampaign]),
      delete: and([ownedByCompany, fromCurrentCampaign]),
    },

    CompensationReviewReviewerBudgetPermission: {
      read: true, // https://linear.app/figures/issue/FIG-2575/add-column-compensationreviewincreaserulecompanyid
      update: true,
      delete: true,
    },

    CompensationReviewRecommendation: {
      read: and([ownedByCompany, fromCurrentCampaign]),
      update: and([ownedByCompany, fromCurrentCampaign]),
      delete: and([ownedByCompany, fromCurrentCampaign]),
    },

    CompensationReviewAdjustment: {
      read: and([ownedByCompany, fromCurrentCampaign]),
      update: and([ownedByCompany, fromCurrentCampaign]),
      delete: and([ownedByCompany, fromCurrentCampaign]),
    },

    CompensationReviewIncreaseRule: {
      read: true, // https://linear.app/figures/issue/FIG-2575/add-column-compensationreviewincreaserulecompanyid
      update: true,
      delete: true,
    },

    CompensationReviewBudget: {
      read: true, // https://linear.app/figures/issue/FIG-2575/add-column-compensationreviewincreaserulecompanyid
      update: true,
      delete: true,
    },

    CompensationReviewBudgetEligibility: {
      read: { campaign: and([ownedByCompany, currentCampaign]) },
      update: { campaign: and([ownedByCompany, currentCampaign]) },
      delete: { campaign: and([ownedByCompany, currentCampaign]) },
    },

    CompensationReviewSubBudget: {
      read: { campaign: and([ownedByCompany, currentCampaign]) },
      update: { campaign: and([ownedByCompany, currentCampaign]) },
      delete: { campaign: and([ownedByCompany, currentCampaign]) },
    },

    OffCycleReviewConfiguration: {
      read: ownedByCompany,
      update: ownedByCompany,
      delete: ownedByCompany,
    },

    OffCycleReviewRequest: {
      read: ownedByCompany,
      update: ownedByCompany,
      delete: ownedByCompany,
    },
  } satisfies Partial<PermissionsSchema>;
};
