/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  CompensationFrequency,
  ExternalEmployeeSource,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isFuture, parseISO } from "date-fns";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { fetch } from "~/lib/fetch";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/buildCustomBaseSalaryRemunerationItem";
import { buildJobPayload } from "~/lib/hris/helpers/buildJobPayload";
import { buildLevelPayload } from "~/lib/hris/helpers/buildLevelPayload";
import { buildLocationPayload } from "~/lib/hris/helpers/buildLocationPayload";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { getContractType } from "~/lib/hris/helpers/getContractType";
import { getEmployeeCurrency } from "~/lib/hris/helpers/getEmployeeCurrency";
import { getExternalEmployeeStatus, getMappingSkipReason } from "~/lib/hris/helpers/getExternalEmployeeStatus";
import { getGender } from "~/lib/hris/helpers/getGender";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { getUploadedEmployeeProfilePicture } from "~/lib/hris/helpers/getUploadedEmployeeProfilePicture";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { type StaticModels } from "~/lib/integration";
import { chain, compact } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { buildExternalUrl } from "~/lib/url";
import { assertProps, isIn } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetchCompanyIntegrationDiagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

type Credentials = {
  clientId: string;
  clientSecret: string;
  anonymous: boolean;
};

export type CharliehrEmployee = {
  id: string;
  profile_picture?: string;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  start_date: string | null;
  gender: "Female" | "Male";
  employment_status: "not started" | "ended" | "active";
  office: string;
  display_name?: string;
  work_email?: string;
  personal_email?: string;
  address?: string;
  phone_number?: string;
  job_title?: string;
  notes: {
    label: string;
    id: string;
    content: string;
    team_member_note_type: string;
  }[];
  manager?: string;
};

export type CharliehrSalary = {
  id: string;
  team_member: string;
  job_title: string;
  pay_rate: string;
  pay_currency: string;
  pay_frequency: "Year" | "Month" | string;
  employment_type?: "Permanent" | string;
  effective_date: string;
};

export type CharliehrOffice = {
  id: string;
  name: string;
  address: string[];
};

type CharliehrNoteType = {
  id: string;
  name: string;
  type?: "Text" | "Number" | "Checklist";
};

export type CompleteCharliehrEmployee = CharliehrEmployee & {
  location: CharliehrOffice | undefined;
  salary: CharliehrSalary | undefined;
  historicalSalaries: CharliehrSalary[];
};

type CharliehrReportResponse<T extends CharliehrEmployee | CharliehrOffice | CharliehrSalary | CharliehrNoteType> = {
  success: boolean;
  meta: { current_page: number; total_pages: number };
  data: T[];
};

export type CharliehrIntegrationSettingsInput = Credentials & IntegrationCustomFields;

const charliehrFetch = async <T extends CharliehrEmployee | CharliehrOffice | CharliehrSalary | CharliehrNoteType>(
  credentials: Credentials,
  endpoint: string,
  {
    method = "GET",
    query,
  }: {
    method?: "POST" | "GET";
    query?: ParsedUrlQueryInput;
  } = {}
): Promise<T[]> => {
  const baseUrl = `https://charliehr.com/api/v1/${endpoint}`;
  const token = `${credentials.clientId}:${credentials.clientSecret}`;

  const res = await fetch(buildExternalUrl(baseUrl, { ...query, format: "json" }), {
    method,
    headers: {
      "Accept": "application/json",
      "Authorization": `Token token=${token}`,
      "Content-Type": "application/json",
      "User-Agent": "Figures-Integration-Bot",
    },
  });

  if (!res.ok) {
    throw new BusinessLogicError(`[charliehr] ${res.status} ${res.statusText} : ${await res.text()}`);
  }

  const json: CharliehrReportResponse<T> = await res.json();

  if (json.meta.current_page === json.meta.total_pages) {
    return json.data;
  }

  const nextPage = await charliehrFetch<T>(credentials, endpoint, {
    query: {
      page: json.meta.current_page + 1,
    },
  });

  return [...json.data, ...nextPage];
};

const getCharliehrProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: CompleteCharliehrEmployee }
) => {
  if (!options.apiEmployee.profile_picture) {
    return undefined;
  }

  return getUploadedEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: IntegrationSource.CHARLIEHR,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      fetch(options.apiEmployee.profile_picture as string, {
        headers: {
          "User-Agent": "Figures-Integration-Bot",
        },
      }),
  });
};

const getCustomField = (apiEmployee: CompleteCharliehrEmployee, fieldId: string | null) => {
  const note = apiEmployee.notes?.find((note) => note.team_member_note_type === fieldId);

  return note?.content;
};

export const mapCharliehrEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: CompleteCharliehrEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  // Handle custom fields
  const {
    fteCustomFieldName,
    levelCustomFieldName,
    externalIdCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    currencyCustomFieldName,
    countryCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = integrationSettings;
  const customFte = getCustomField(apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(apiEmployee, levelCustomFieldName);
  const customEmployeeNumber = getCustomField(apiEmployee, externalIdCustomFieldName);
  const customBaseSalary = getCustomField(apiEmployee, baseSalaryCustomFieldName);
  const customVariable = getCustomField(apiEmployee, variableCustomFieldName);
  const holidayAllowanceValue = getCustomField(apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(apiEmployee, jobCustomFieldName);
  const customCurrency = getCustomField(apiEmployee, currencyCustomFieldName);
  const customCountry = getCustomField(apiEmployee, countryCustomFieldName);
  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldId,
    additionalFieldMappingId: id,
    value: getCustomField(apiEmployee, hrisFieldName) ?? null,
  }));

  const customRemunerationItemsValues = customRemunerationItemMappings.map((customRemunerationItemMapping) => ({
    ...customRemunerationItemMapping,
    value: getCustomField(apiEmployee, customRemunerationItemMapping.hrisFieldName),
  }));

  const countryCode = apiEmployee.location?.address?.slice(-1).pop();
  const country = value(() => {
    if (!countryCode && !customCountry) {
      return null;
    }

    const customCountryModel = customCountry
      ? staticModels.countries.find((country) => country.alpha2 === customCountry)
      : null;

    if (customCountryModel) {
      return customCountryModel;
    }

    return staticModels.countries.find((country) => country.alpha2 === countryCode);
  });

  const currency = getEmployeeCurrency(ctx, {
    staticModels,
    currencyCode: customCurrency ?? apiEmployee.salary?.pay_currency,
    employeeCountry: country,
    company,
  });

  const job = buildJobPayload({
    companyId: company.id,
    name: customJob ?? apiEmployee.salary?.job_title ?? apiEmployee.job_title ?? null,
  });

  const location = buildLocationPayload({
    companyId: company.id,
    externalId: customLocation ?? apiEmployee.location?.name ?? `${country?.id}`,
    name: customLocation ?? apiEmployee.location?.name ?? `${country?.name}`,
    countryId: country?.id ?? null,
  });

  const level = buildLevelPayload({
    companyId: company.id,
    name: customLevel ?? null,
  });

  const rawContractType = apiEmployee.salary?.employment_type;

  const contractType = getContractType(rawContractType, IntegrationSource.CHARLIEHR);

  const input: EmployeeData["input"] = {
    source: IntegrationSource.CHARLIEHR,
    externalId: apiEmployee.id,
    status: getExternalEmployeeStatus(contractType),
    mappingSkipReason: getMappingSkipReason(contractType),
    firstName: apiEmployee.first_name,
    lastName: apiEmployee.last_name,
    email: apiEmployee.work_email,
    contractType,
    rawContractType,
    employeeNumber: customEmployeeNumber ? customEmployeeNumber : apiEmployee.id,
    gender: getGender({
      value: apiEmployee.gender,
      options: {
        female: "Female",
        male: "Male",
      },
    }),
    hireDate: apiEmployee.start_date ? parseISO(apiEmployee.start_date) : null,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency.code },
    },
    ...location,
    ...job,
    ...level,
    ...(businessUnit && { businessUnit }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: location?.location?.connectOrCreate?.create?.externalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
    baseSalaryCustomFieldFrequency,
    company,
    customBaseSalary,
    numberMonths,
    source: ExternalEmployeeSource.CHARLIEHR,
  });

  const remunerationItems: EmployeeData["remunerationItems"] = customBaseSalaryRemunerationItem
    ? [customBaseSalaryRemunerationItem]
    : compact([
        apiEmployee.salary &&
          mapCharlieHrBaseSalary(ctx, {
            company,
            salary: apiEmployee.salary,
            externalRemunerationStatus: ExternalRemunerationStatus.LIVE,
            numberMonths,
          }),
        ...apiEmployee.historicalSalaries.map((salary) =>
          mapCharlieHrBaseSalary(ctx, {
            company,
            salary,
            externalRemunerationStatus: ExternalRemunerationStatus.HISTORICAL,
            numberMonths,
          })
        ),
      ]);

  if (customVariable && integrationSettings.variableCustomFieldFrequency) {
    const multiplier = match(integrationSettings.variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = parseFloat(customVariable) * multiplier * 100;

    remunerationItems.push({
      company: {
        connect: { id: company.id },
      },
      source: IntegrationSource.CHARLIEHR,
      externalId: "variable-bonus",
      status: ExternalRemunerationStatus.LIVE,
      amount: amount,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: IntegrationSource.CHARLIEHR,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: IntegrationSource.CHARLIEHR,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    } satisfies EmployeeData["remunerationItems"][number]);
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    managerExternalId: apiEmployee.manager,
    profilePicture: !ignoreProfilePicture
      ? await getCharliehrProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    holidayAllowanceValue,
    // If one day they support hourly rates, we just have to update this (cf. bamboo)
    ...(customFte && { fte: customFte, ignoreFte: false }),
  };
};

const mapCharlieHrBaseSalary = (
  ctx: AppContext,
  params: {
    company: Company;
    salary?: CharliehrSalary;
    externalRemunerationStatus: ExternalRemunerationStatus;
    numberMonths: number;
  }
): EmployeeData["remunerationItems"][number] | null => {
  const { company, salary, externalRemunerationStatus, numberMonths } = params;

  if (!salary?.pay_rate) {
    return null;
  }

  const amount = parseFloat(salary.pay_rate);

  if (amount === 0) {
    return null;
  }

  const interval = salary.pay_frequency;

  if (!["Month", "Year"].includes(interval)) {
    logWarn(ctx, `[sync] Unhandled CharlieHR fix salary interval`, { interval });
  }

  const multiplier = interval === "Month" ? numberMonths : 1;

  const yearlySalary = amount * multiplier * 100;

  const externalIdSuffix = match(externalRemunerationStatus)
    .with(ExternalRemunerationStatus.LIVE, () => "")
    .with(ExternalRemunerationStatus.HISTORICAL, () => `-historical-${salary.id}`)
    .exhaustive();

  return {
    company: {
      connect: { id: company.id },
    },
    source: IntegrationSource.CHARLIEHR,
    externalId: `fix-salary${externalIdSuffix}`,
    amount: yearlySalary,
    numberMonths,
    status: externalRemunerationStatus,
    date: salary?.effective_date ? parseISO(salary?.effective_date) : null,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: IntegrationSource.CHARLIEHR,
            externalId: "fix-salary",
          },
        },
        create: {
          source: IntegrationSource.CHARLIEHR,
          externalId: "fix-salary",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  } satisfies EmployeeData["remunerationItems"][number];
};

export const getCharliehrCustomFields = async (credentials: Credentials): Promise<CharliehrNoteType[]> => {
  const customFields = await charliehrFetch<CharliehrNoteType>(credentials, "team_member_note_types");

  return customFields
    .filter((field) => isIn(field.type, ["Text", "Number"]))
    .map((field) => ({ id: field.id, name: field.name }));
};

const anonymise = (credentials: Credentials) => {
  return (employee: CharliehrEmployee): CharliehrEmployee => {
    if (credentials.anonymous) {
      delete employee.first_name;
      delete employee.last_name;
      delete employee.date_of_birth;
      delete employee.profile_picture;
      delete employee.work_email;
      delete employee.personal_email;
      delete employee.address;
      delete employee.phone_number;
      delete employee.display_name;
    }
    return employee;
  };
};

export const getCharliehrEmployees = async (credentials: Credentials) => {
  // Get all relevant data from CharlieHR API
  const [employees, offices, salaries] = await Promise.all([
    charliehrFetch<CharliehrEmployee>(credentials, "team_members"),
    charliehrFetch<CharliehrOffice>(credentials, "offices"),
    charliehrFetch<CharliehrSalary>(credentials, "salaries"),
  ]);

  return employees
    .filter((employee) => employee.employment_status === "active")
    .map(anonymise(credentials))
    .map((employee) => {
      const location = offices.find((office) => office.id === employee.office);

      const employeeSalaries = salaries.filter((salary) => salary.team_member === employee.id);

      // Employees with no salaries are always taken into account
      if (employeeSalaries.length === 0) {
        return { ...employee, location, salary: undefined, historicalSalaries: [] };
      }

      const [salary, ...historicalSalaries] = chain(employeeSalaries)
        .filter((salary) => !isFuture(parseISO(salary.effective_date)))
        .orderBy("effective_date", "desc")
        .value();

      return {
        ...employee,
        location,
        salary,
        historicalSalaries,
      };
    });
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientId", "clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedCharliehrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const charliehrEmployees = await getCharliehrEmployees(safeIntegrationSettings);

  return mapSeries(charliehrEmployees, (charliehrEmployee) =>
    mapCharliehrEmployee(ctx, company, charliehrEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export const getCharliehrDiagnostic = async (
  ctx: AppContext,
  input: CharliehrIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await charliehrFetch<CharliehrEmployee>(input, "team_members");

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your CharlieHR account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = await getCharliehrCustomFields(input);

    const missingFields = getMissingCustomFields(input, availableFields);

    return {
      connection: true,
      connectionError: "",
      missingFields,
      availableFields,
    };
  } catch (error) {
    return {
      connection: false,
      connectionError: error.message,
      missingFields: [],
      availableFields: [],
    };
  }
};

export const getRawCharliehrEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<CompleteCharliehrEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getCharliehrEmployees(safeIntegrationSettings);
};
