import { mapSeries } from "bluebird";
import { config } from "~/config";
import { sendCompensationReviewCampaignStartEmail } from "~/lib/external/sendinblue/templates";
import { compact } from "~/lib/lodash";
import { logInfo } from "~/lib/logger";
import { assertNotNil } from "~/lib/utils";
import { buildRevieweesWherePayload } from "~/services/compensation-review/campaigns/admin/employeeReviewers";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

export const sendLaunchNotifications = async (ctx: CompensationReviewCampaignContext) => {
  if (!ctx.campaign.launchNotificationContent) {
    logInfo(ctx, `[chore] No notification email content for campaign - Skipping.`, {
      campaignId: ctx.campaign.id,
    });
    return;
  }

  const reviewers = await ctx.prisma.compensationReviewReviewer.findMany({
    where: {
      campaignId: ctx.campaign.id,
      OR: compact([
        buildRevieweesWherePayload(ctx, { eligibilities: { some: {} } }),
        ctx.campaign.finalReviewer && { id: ctx.campaign.finalReviewer.id },
      ]),
    },
    select: {
      externalEmployee: {
        select: {
          userPermissions: {
            select: {
              user: { select: { email: true, locale: true } },
            },
          },
        },
      },
    },
  });

  await mapSeries(reviewers, async (reviewer, index) => {
    if (!config.app.isJest && !config.app.isProduction && !!index) return;

    const email = reviewer.externalEmployee.userPermissions?.user?.email;

    if (!email) return;

    await sendCompensationReviewCampaignStartEmail(ctx, {
      campaignId: ctx.campaign.id,
      campaignName: ctx.campaign.name,
      customMessage: assertNotNil(ctx.campaign.launchNotificationContent),
      email,
      locale: reviewer.externalEmployee.userPermissions?.user?.locale,
    });
  });
};
