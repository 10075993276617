import {
  type AdditionalFieldMapping,
  type CustomRemunerationItemMapping,
  type IntegrationSettings,
} from "@prisma/client";
import { getCustomFieldNames } from "~/lib/hris/helpers/getCustomFieldNames";
import { isNotNull } from "~/lib/utils";

export type IntegrationCustomFields = Pick<
  IntegrationSettings,
  | "holidayAllowanceCustomFieldName"
  | "fteCustomFieldName"
  | "levelCustomFieldName"
  | "baseSalaryCustomFieldName"
  | "variableCustomFieldName"
  | "externalIdCustomFieldName"
  | "businessUnitCustomFieldName"
  | "locationCustomFieldName"
  | "jobCustomFieldName"
  | "currencyCustomFieldName"
  | "countryCustomFieldName"
> & {
  additionalFieldMappings?: Pick<AdditionalFieldMapping, "hrisFieldName" | "additionalFieldId">[];
  customRemunerationItemMappings?: Pick<CustomRemunerationItemMapping, "hrisFieldName" | "customRemunerationItemId">[];
};

export const BlankIntegrationCustomFields: IntegrationCustomFields = {
  holidayAllowanceCustomFieldName: null,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  baseSalaryCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  businessUnitCustomFieldName: null,
  locationCustomFieldName: null,
  jobCustomFieldName: null,
  currencyCustomFieldName: null,
  countryCustomFieldName: null,
  additionalFieldMappings: [],
  customRemunerationItemMappings: [],
};

export const getMissingCustomFields = (
  integrationSettings: IntegrationCustomFields,
  availableFields: {
    id: string;
    name: string;
  }[]
) => {
  return getCustomFieldNames(integrationSettings)
    .filter(isNotNull)
    .filter((customFieldId) => !availableFields.find((field) => customFieldId === field.id.toString()));
};
