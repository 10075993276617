import { type Route } from "nextjs-routes";
import { type AppContext } from "~/lib/context";
import { ForbiddenError } from "~/lib/errors/forbiddenError";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const protectRouteWithAccess = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { access?: (ctx: AppContext) => Route | undefined }
) => {
  if (!options.access) {
    return next(ctx);
  }

  const redirectPath = options.access(ctx.req);
  if (!redirectPath) {
    return next(ctx);
  }

  throw new ForbiddenError("Unauthorised access", redirectPath).withErrorCode("F140");
};
