import { CompensationReviewCompensationItem, type CompensationReviewAdjustment } from "@prisma/client";
import { type SalaryRangeAutocompleteValue } from "~/components/compensation-review/SalaryRangeAutocomplete";
import { chain } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";
import { type CompensationReviewData } from "~/services/compensation-review/compensationReviewContext";
import {
  computeAmountForCompensationItem,
  isSalaryCompensationItem,
} from "~/services/compensation-review/shared/compensationItems";
import { type EnrichedRule } from "~/services/compensation-review/shared/rules/enrichIncreaseRulesWithPopulation";
import { resolveRules, type EmployeeForRules } from "~/services/compensation-review/shared/rules/resolveRules";

export const validateRules = (
  ctx: CompensationReviewData,
  params: {
    rules: EnrichedRule[];
    employee: EmployeeForRules;
    promotion: {
      isPromoted: boolean;
      targetRangeAfterPromotion: SalaryRangeAutocompleteValue | null;
    };
    adjustments: CompensationReviewAdjustment[];
    skipPermissions?: boolean;
  }
) => {
  const validatedRules = chain(params.rules)
    .filter((rule) => {
      return params.employee.eligibilities.some((eligibility) => eligibility.budgetId === rule.budgetId);
    })
    .groupBy((rule) => [rule.budgetId, rule.compensationItem].join(":"))
    .values()
    .map((rules) => {
      const { compensationItem, budgetId } = assertNotNil(rules[0]);

      const resolvedRule = resolveRules(ctx, {
        employee: params.employee,
        rules,
        compensationItem,
        promotion: params.promotion,
        skipPermissions: params.skipPermissions,
      });

      const matchCompensationItem = (otherCompensationItem: CompensationReviewCompensationItem) => {
        if (compensationItem === CompensationReviewCompensationItem.ON_TARGET_EARNINGS) {
          return isSalaryCompensationItem(otherCompensationItem);
        }

        return otherCompensationItem === compensationItem;
      };

      return {
        ...resolvedRule,
        compensationItem,
        budgetId,
        matchCompensationItem,
      };
    })
    .filter((resolvedRule) => resolvedRule.rules.length > 0)
    .map((resolvedRule) => {
      const increase = computeAmountForCompensationItem(
        params.adjustments.filter((adjustment) => adjustment.budgetId === resolvedRule.budgetId),
        resolvedRule.compensationItem,
        ["submittedAmount"]
      );

      return {
        ...resolvedRule,
        isWithinHardLimits: resolvedRule.checkHardLimits(increase),
        isWithinSoftLimits: resolvedRule.checkSoftLimits(increase),
      };
    })
    .value();

  return {
    validatedRules,
    isWithinHardLimits: validatedRules.every((result) => result.isWithinHardLimits),
    isWithinSoftLimits: validatedRules.every((result) => result.isWithinSoftLimits),
  };
};

export type ValidatedRule = ReturnType<typeof validateRules>["validatedRules"][number];
