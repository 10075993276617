import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logError } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

type GithubErrorContext = Record<string, string | number>;

export class GithubError extends FiguresBaseError {
  constructor(
    readonly message: string,
    public readonly context: GithubErrorContext
  ) {
    super(message);
    Object.setPrototypeOf(this, GithubError.prototype);
  }
}

export const handleGithubError = (ctx: AppContext, res: NextApiResponse, error: GithubError) => {
  logError(ctx, "[error] Github error", { error });

  res.status(HttpStatus.BAD_REQUEST).json(makeErrorPayload({ error, defaultCode: ErrorCode.GITHUB }));
};
