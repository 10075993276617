import {
  type CompanyTag,
  type Currency,
  DataQuality,
  type FundingRound,
  Gender,
  type Headcount,
  type Job,
} from "@prisma/client";
import { map } from "bluebird";
import computeMedian from "compute-median";
import percentile from "percentile";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { chain, maxBy, minBy, sumBy } from "~/lib/lodash";
import { weightedMeanBy } from "~/lib/math";
import { isNotNull } from "~/lib/utils";
import { combineDataQuality, computeDataQuality, hasInsufficientDataQuality } from "~/services/dataQuality";
import { Measure, computeEmployeeCompensation, hasGender, hasJob, hasKnownGender } from "~/services/employee";
import { type EmployeeLevel } from "~/services/employee/employeeLevel";
import { type WeightedJob } from "~/services/job";
import { getGenderDataAccess } from "~/services/market-data/getGenderDataAccess";
import { type JobsForPredictiveBenchmark } from "~/services/market-data/getJobsForPredictiveBenchmark";
import { type MarketDataEmployee, getRemunerationItemsByNature } from "~/services/market-data/getMarketDataEmployees";
import {
  type EmployeeLocationsForPredictiveBenchmark,
  getMarketDataPredictiveBenchmark,
} from "~/services/market-data/getMarketDataPredictiveBenchmark";
import { type PercentileBounds } from "~/services/percentiles";

export const TOP_PERCENTILE = 0.9;

export const BOTTOM_PERCENTILE = 0.1;

export type DataQualityDetailledByJob = {
  dataQuality: DataQuality;
  job: {
    id: number;
    name: string;
  };
  distinctCompaniesCount: number;
  employeesCount: number;
}[];

export type MarketDataCompensationData = {
  min: number;
  median: number;
  max: number;
  lowest: PercentileValue;
  low: PercentileValue;
  middle: PercentileValue;
  high: PercentileValue;
  highest: PercentileValue;
  target: PercentileValue;
  dataQuality: DataQuality;
  dataQualityDetailledByJob?: DataQualityDetailledByJob;
};

export const MarketDataStatsSource = {
  DATASET: "DATASET",
  PREDICTIVE: "PREDICTIVE",
} as const;

export type MarketDataStatsSource = (typeof MarketDataStatsSource)[keyof typeof MarketDataStatsSource];

export type MarketDataStats = MarketDataStatsFromDataset | MarketDataStatsFromFiguresAi;

export type MarketDataStatsFromDataset = {
  source: typeof MarketDataStatsSource.DATASET;
  count: number;
  distinctCompaniesCount: number;
  missingJobs: Job[];
  dataQuality: DataQuality;
  baseSalary: MarketDataCompensationData;
  totalCash: MarketDataCompensationData;
  onTargetEarnings: MarketDataCompensationData;
  profitSharing?: MarketDataCompensationData;
  equity?: MarketDataCompensationData;
  paidBonus?: MarketDataCompensationData;
  onTargetBonus: {
    distinctCompaniesCount: number;
    companiesPercentage: number;
    medianAmount: number | null;
    baseSalaryProportion: number | null;
  };
  genderEquality: {
    womenProportion: number | null;
    baseSalary: {
      menMedian: number | null;
      womenMedian: number | null;
      payGap: number | null;
    };
    totalCash: {
      menMedian: number | null;
      womenMedian: number | null;
      payGap: number | null;
    };
    onTargetEarnings: {
      menMedian: number | null;
      womenMedian: number | null;
      payGap: number | null;
    };
  } | null;
  companyEmployees: MarketDataEmployee[];
};

type MarketDataStatsFromFiguresAi = {
  source: typeof MarketDataStatsSource.PREDICTIVE;
  missingJobs: Job[];
  dataQuality: DataQuality;
  baseSalary: MarketDataCompensationData;
  totalCash: MarketDataCompensationData;
  onTargetEarnings: MarketDataCompensationData;
  companyEmployees: MarketDataEmployee[];
};

type PercentileValue = {
  percentile: number;
  value: number;
};

export type ComputeMarketCompensationStatsOptions = {
  currency: Currency;
  weightedJobs: WeightedJob[];
  level?: EmployeeLevel;
  locations: EmployeeLocationsForPredictiveBenchmark;
  percentiles: PercentileBounds;
  headcount?: Headcount | null;
  industry?: CompanyTag | null;
  industries?: CompanyTag[];
  fundingRounds?: FundingRound[];
};

export const computeMarketCompensationStats = async (
  ctx: AppContext,
  employees: MarketDataEmployee[],
  options: ComputeMarketCompensationStatsOptions
) => {
  const measures = ctx.featureFlags.CAN_ACCESS_DETAILED_MEASURES
    ? [
        Measure.BaseSalary,
        Measure.TotalCash,
        Measure.OnTargetEarnings,
        Measure.ProfitSharing,
        Measure.Equity,
        Measure.PaidBonus,
      ]
    : [Measure.BaseSalary, Measure.TotalCash, Measure.OnTargetEarnings];

  const compensationStats = await computeMarketCompensationData(ctx, employees, {
    ...options,
    fallbackToPredictiveData: isEligibleToFallbackOnPredictiveData(ctx, { options }),
    measures,
  });

  if (!compensationStats.data) {
    return null;
  }

  if (compensationStats.data.measures.baseSalary.dataQuality === DataQuality.AI_ESTIMATED) {
    return {
      source: MarketDataStatsSource.PREDICTIVE,
      dataQuality: compensationStats.data.measures.totalCash.dataQuality,
      missingJobs: compensationStats.missingJobs,
      baseSalary: compensationStats.data.measures.baseSalary,
      totalCash: compensationStats.data.measures.totalCash,
      onTargetEarnings: compensationStats.data.measures.onTargetEarnings,
      companyEmployees: [],
    };
  }

  const onTargetBonus = await computeMarketVariableCompensationStats(ctx, employees, options);
  const genderEquality = await computeMarketGenderEqualityStats(ctx, employees, options);

  return {
    source: MarketDataStatsSource.DATASET,
    count: compensationStats.data.employeesCount,
    distinctCompaniesCount: compensationStats.data.distinctCompaniesCount,
    dataQuality: compensationStats.data.measures.totalCash.dataQuality,
    missingJobs: compensationStats.missingJobs,

    baseSalary: compensationStats.data.measures.baseSalary,
    totalCash: compensationStats.data.measures.totalCash,
    onTargetEarnings: compensationStats.data.measures.onTargetEarnings,
    profitSharing: compensationStats.data.measures.profitSharing,
    equity: compensationStats.data.measures.equity,
    paidBonus: compensationStats.data.measures.paidBonus,
    onTargetBonus,
    genderEquality,
    companyEmployees: [],
  };
};

export const isEligibleToFallbackOnPredictiveData = (
  ctx: AppContext,
  params: { options: ComputeMarketCompensationStatsOptions }
) => {
  if (ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS) {
    //our machine learning model currently doesn't handle the level frameworks
    return false;
  }

  return !(params.options.industries && params.options.industries.length > 1);
};

type ComputeEmployeesCompensationDataOptions<T extends Measure> = {
  currency: Currency;
  measures: T[];
  level?: EmployeeLevel;
  locations: EmployeeLocationsForPredictiveBenchmark;
  percentiles: PercentileBounds;
  targetPercentile?: number;
  fallbackToPredictiveData?: boolean;
  fundingRounds?: FundingRound[];
  headcount?: Headcount | null;
  industry?: CompanyTag | null;
};

type ComputeMarketCompensationDataOptions<T extends Measure> = ComputeEmployeesCompensationDataOptions<T> & {
  weightedJobs: WeightedJob[];
};

type ComputeMarketCompensationDataResult<T extends Measure> = {
  missingJobs: Job[];
  data: null | {
    employeesCount: number;
    distinctCompaniesCount: number;
    measures: Record<T, MarketDataCompensationData>;
  };
};

export const computeJobsCompensationData = async <T extends Measure>(
  ctx: AppContext,
  employees: MarketDataEmployee[],
  options: ComputeMarketCompensationDataOptions<T>
) => {
  return map(options.weightedJobs, async (item) => {
    // Filter only employees with the given job
    const employeesWithJob = employees.filter(hasJob([item.job.id]));

    // Get distinct count of companies
    const distinctCompaniesIds = chain(employeesWithJob)
      .map(({ companyId }) => companyId)
      .uniq()
      .value();

    // Compute each measure & its compensation stats

    const measures = await computeEmployeesCompensationData(ctx, employeesWithJob, {
      ...options,
      jobs: [item.job],
    });

    if (!measures) {
      return { job: item.job, weight: item.weight };
    }

    // Ensure there are enough employees & companies
    if (
      !options.fallbackToPredictiveData &&
      hasInsufficientDataQuality(employeesWithJob.length, distinctCompaniesIds.length)
    ) {
      return { job: item.job, weight: item.weight };
    }

    return {
      job: item.job,
      weight: item.weight,
      employeesCount: employeesWithJob.length,
      distinctCompaniesIds,
      measures,
    };
  });
};

export const computeEmployeesCompensationData = async <T extends Measure>(
  ctx: AppContext,
  employees: MarketDataEmployee[],
  options: ComputeEmployeesCompensationDataOptions<T> & { jobs: JobsForPredictiveBenchmark }
) => {
  // Get distinct count of companies
  const distinctCompaniesIds = chain(employees)
    .map(({ companyId }) => companyId)
    .uniq()
    .value();

  // Ensure there are enough employees & companies
  const noRelevantData = hasInsufficientDataQuality(employees.length, distinctCompaniesIds.length);

  if (noRelevantData && !options.fallbackToPredictiveData) {
    return null;
  }

  if (noRelevantData && !!options.level) {
    return getMarketDataPredictiveBenchmark(ctx, {
      percentiles: options.percentiles,
      targetPercentile: options.targetPercentile,
      currency: options.currency,
      levels: [options.level],
      jobs: options.jobs,
      locations: options.locations,
      fundingRounds: options.fundingRounds,
      headcount: options.headcount,
      industry: options.industry,
    });
  }

  // Compute each measure & its compensation stats

  return chain(options.measures)
    .map((measure) => {
      const compensationData = employees.map((employee) => {
        return computeEmployeeCompensation(getRemunerationItemsByNature(employee), {
          measure,
          targetCurrency: options.currency,
        });
      });

      const median = computeMedian(compensationData);

      const [lowest = null, low = null, middle = null, high = null, highest = null, target = null] = percentile(
        [
          BOTTOM_PERCENTILE * 100,
          options.percentiles.low * 100,
          options.percentiles.middle * 100,
          options.percentiles.high * 100,
          TOP_PERCENTILE * 100,
          (options.targetPercentile ?? options.percentiles.middle) * 100,
        ],
        compensationData
      ) as [number, number, number, number, number, number];

      const min = lowest;

      const max = highest;

      const dataQuality = computeDataQuality(compensationData, distinctCompaniesIds.length);

      return {
        measure,
        dataQuality,
        median,
        min,
        max,
        lowest,
        low,
        middle,
        target,
        high,
        highest,
      };
    })
    .keyBy((row) => row.measure)
    .value();
};

export const computeMarketCompensationData = async <T extends Measure>(
  ctx: AppContext,
  employees: MarketDataEmployee[],
  options: ComputeMarketCompensationDataOptions<T>
): Promise<ComputeMarketCompensationDataResult<T>> => {
  // For each weighted job, compute counts & percentile stats
  const jobsCompensationData = await computeJobsCompensationData(ctx, employees, options);

  // Record jobs for which we couldn't get relevant compensation data
  const missingJobs = jobsCompensationData.filter((item) => !item.measures).map((item) => item.job);

  const relevantJobsCompensationData = jobsCompensationData.filter((item) => {
    return !!item.measures;
  }) as ((typeof jobsCompensationData)[0] & { measures: NonNullable<(typeof jobsCompensationData)[0]["measures"]> })[];

  // If we couldn't find any relevant data for any requested job, skip stats

  if (!relevantJobsCompensationData.length) {
    return { missingJobs, data: null };
  }

  // Compute the number of employees & companies for which we were able to compute compensation data

  const employeesCount = sumBy(relevantJobsCompensationData, (row) => row.employeesCount);

  const distinctCompaniesCount = chain(relevantJobsCompensationData)
    .flatMap((row) => row.distinctCompaniesIds)
    .uniq()
    .size()
    .value();

  // For each requested measure, aggregate weighted jobs compensation data

  const measures = chain(options.measures)
    .keyBy()
    .mapValues((measure) => {
      const dataQualityDetailledByJob = relevantJobsCompensationData
        .map((row) => {
          const dataQuality = row.measures[measure]?.dataQuality ?? null;

          if (!dataQuality) {
            return null;
          }

          return {
            job: { id: row.job.id, name: row.job.name },
            employeesCount: row.employeesCount,
            distinctCompaniesCount: row.distinctCompaniesIds.length,
            dataQuality,
          };
        })
        .filter(isNotNull);

      return {
        dataQuality: combineDataQuality(dataQualityDetailledByJob.map((row) => row.dataQuality)),
        dataQualityDetailledByJob,

        median: weightedMeanBy(relevantJobsCompensationData, (row) => {
          return [row.measures[measure]?.median ?? null, row.weight];
        }),
        min: minBy(relevantJobsCompensationData, (row) => {
          return row.measures[measure]?.min ?? 0;
        })?.measures[measure]?.min as number,
        max: maxBy(relevantJobsCompensationData, (row) => {
          return row.measures[measure]?.max ?? 0;
        })?.measures[measure]?.max as number,
        lowest: {
          percentile: BOTTOM_PERCENTILE,
          value: weightedMeanBy(relevantJobsCompensationData, (row) => {
            return [row.measures[measure]?.lowest ?? null, row.weight];
          }),
        },

        low: {
          percentile: options.percentiles.low,
          value: weightedMeanBy(relevantJobsCompensationData, (row) => {
            return [row.measures[measure]?.low ?? null, row.weight];
          }),
        },

        middle: {
          percentile: options.percentiles.middle,
          value: weightedMeanBy(relevantJobsCompensationData, (row) => {
            return [row.measures[measure]?.middle ?? null, row.weight];
          }),
        },

        high: {
          percentile: options.percentiles.high,
          value: weightedMeanBy(relevantJobsCompensationData, (row) => {
            return [row.measures[measure]?.high ?? null, row.weight];
          }),
        },

        highest: {
          percentile: TOP_PERCENTILE,
          value: weightedMeanBy(relevantJobsCompensationData, (row) => {
            return [row.measures[measure]?.highest ?? null, row.weight];
          }),
        },

        target: {
          percentile: options.targetPercentile ?? options.percentiles.middle,
          value: weightedMeanBy(relevantJobsCompensationData, (row) => {
            return [row.measures[measure]?.target ?? null, row.weight];
          }),
        },
      };
    })
    .value() as Record<T, MarketDataCompensationData & { dataQuality: DataQuality }>;

  return {
    missingJobs,
    data: {
      employeesCount,
      distinctCompaniesCount,
      measures,
    },
  };
};

// TODO: should this method exist?
const computeMarketVariableCompensationStats = async (
  ctx: AppContext,
  employees: MarketDataEmployee[],
  options: ComputeMarketCompensationStatsOptions
) => {
  const companiesStats = chain(employees)
    .groupBy((employee) => {
      return employee.companyId;
    })
    .values()
    .map((employees) => {
      const employeesWithVariableCompensation = employees.filter((employee) => {
        return !!employee.onTargetBonus;
      });
      const hasMajorityOfEmployeesWithVariableCompensation =
        employees.length <= employeesWithVariableCompensation.length * 2;

      return {
        employees,
        employeesWithVariableCompensation,
        hasMajorityOfEmployeesWithVariableCompensation,
      };
    })
    .value();

  const relevantCompanies = companiesStats.filter((stats) => {
    return stats.hasMajorityOfEmployeesWithVariableCompensation;
  });

  const relevantEmployees = relevantCompanies.flatMap((company) => {
    return company.employeesWithVariableCompensation;
  });

  const distinctCompaniesCount = relevantCompanies.length;
  const companiesPercentage = distinctCompaniesCount / companiesStats.length;

  const variableCompensationData = await computeMarketCompensationData(ctx, relevantEmployees, {
    ...options,
    measures: ["totalCash", "onTargetBonus"],
  });

  const medianTotalCash = variableCompensationData.data?.measures.totalCash.median ?? null;
  const medianAmount = variableCompensationData.data?.measures.onTargetBonus.median ?? null;

  const baseSalaryProportion =
    medianAmount !== null && medianTotalCash !== null ? medianAmount / medianTotalCash : null;

  return {
    distinctCompaniesCount,
    medianAmount,
    baseSalaryProportion,
    companiesPercentage,
  };
};

const computeMarketGenderEqualityStats = async (
  ctx: AppContext,
  employees: MarketDataEmployee[],
  options: ComputeMarketCompensationStatsOptions
) => {
  const canAccessGenderData = await getGenderDataAccess(ctx);

  if (!canAccessGenderData) {
    return null;
  }

  const employeesWithGender = employees.filter(hasKnownGender());
  const men = employeesWithGender.filter(hasGender(Gender.MALE));
  const women = employeesWithGender.filter(hasGender(Gender.FEMALE));

  const menData = await computeMarketCompensationData(ctx, men, {
    ...options,
    measures: ["baseSalary", "totalCash", "onTargetEarnings"],
  });
  const womenData = await computeMarketCompensationData(ctx, women, {
    ...options,
    measures: ["baseSalary", "totalCash", "onTargetEarnings"],
  });

  return {
    womenProportion: employeesWithGender.length ? women.length / employeesWithGender.length : null,
    baseSalary: value(() => {
      const menMedian = menData.data?.measures.baseSalary.median ?? null;
      const womenMedian = womenData.data?.measures.baseSalary.median ?? null;
      const payGap = menMedian && womenMedian ? (menMedian - womenMedian) / Math.max(menMedian, womenMedian) : null;

      return { menMedian, womenMedian, payGap };
    }),
    totalCash: value(() => {
      const menMedian = menData.data?.measures.totalCash.median ?? null;
      const womenMedian = womenData.data?.measures.totalCash.median ?? null;
      const payGap = menMedian && womenMedian ? (menMedian - womenMedian) / Math.max(menMedian, womenMedian) : null;

      return { menMedian, womenMedian, payGap };
    }),
    onTargetEarnings: value(() => {
      const menMedian = menData.data?.measures.onTargetEarnings.median ?? null;
      const womenMedian = womenData.data?.measures.onTargetEarnings.median ?? null;
      const payGap = menMedian && womenMedian ? (menMedian - womenMedian) / Math.max(menMedian, womenMedian) : null;

      return { menMedian, womenMedian, payGap };
    }),
  };
};
