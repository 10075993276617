import { EmployeeMappingSkipReason, ExternalEmployeeContractType, ExternalEmployeeStatus } from "@prisma/client";
import { match } from "ts-pattern";

export const getExternalEmployeeStatus = (
  contractType: ExternalEmployeeContractType,
  isPartTimeWithoutFte?: boolean
) => {
  if (isPartTimeWithoutFte) {
    return ExternalEmployeeStatus.NON_BENCHMARKABLE;
  }

  return match(contractType)
    .with(ExternalEmployeeContractType.PERMANENT, () => ExternalEmployeeStatus.UNMAPPED)
    .with(ExternalEmployeeContractType.TEMPORARY, () => ExternalEmployeeStatus.UNMAPPED)
    .with(ExternalEmployeeContractType.CONTRACTOR, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .with(ExternalEmployeeContractType.CONSULTANT, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .with(ExternalEmployeeContractType.FREELANCER, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .with(ExternalEmployeeContractType.INTERNSHIP, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .with(ExternalEmployeeContractType.APPRENTICESHIP, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .with(ExternalEmployeeContractType.WORKING_STUDENT, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .with(ExternalEmployeeContractType.TRAINING, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .with(ExternalEmployeeContractType.OTHER, () => ExternalEmployeeStatus.NON_BENCHMARKABLE)
    .exhaustive();
};

export const getMappingSkipReason = (contractType: ExternalEmployeeContractType) => {
  return match(contractType)
    .with(ExternalEmployeeContractType.PERMANENT, () => null)
    .with(ExternalEmployeeContractType.TEMPORARY, () => null)
    .otherwise(() => EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE);
};
