import { QueueJobStatus } from "@prisma/client";
import { type QueryClient, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { useAlerts } from "~/hooks/useAlerts";
import { useApi } from "~/hooks/useApi";
import { isEmpty } from "~/lib/lodash";
import { type JobOutput } from "~/lib/queue/fetchJobQueueJobStatus";
import { type QueueJobName } from "~/lib/queue/queueJobName";
import { isIn } from "~/lib/utils";
import { type FetchJobQueueJobStatusResponse } from "~/pages/api/fetch-job-queue-job-status";

const EARLY_POLLING_DELAY_IN_MS = 1_000;
const BASE_POLLING_DELAY_IN_MS = 5_000;

export type UseJobQueueParams = {
  jobName: QueueJobName;
  singletonKey: string;
  multipartJob?: true;
  onComplete?: (queryClient: QueryClient) => void | Promise<void>;
  onError?: (alerts: ReturnType<typeof useAlerts>) => void | Promise<void>;
  enablePolling?: boolean;
  initialStatus?: QueueJobStatus | null;
  enabled?: boolean;
};

export const useJobQueue = (params: UseJobQueueParams) => {
  const { jobName, singletonKey, onComplete, onError, enablePolling = true, initialStatus, enabled = true } = params;

  const { apiFetch } = useApi();
  const alerts = useAlerts();
  const queryClient = useQueryClient();
  const [pollCount, setPollCount] = useState(0);
  const [status, setStatus] = useState<QueueJobStatus | null>(initialStatus ?? null);
  const [progress, setProgress] = useState<number | undefined>(0);
  const [actualJobName, setActualJobName] = useState<QueueJobName | null>();
  const [jobOutput, setJobOutput] = useState<JobOutput | null>(null);
  const previousStatus = usePrevious(status);
  const isSingletonKeyProperlySet = !isEmpty(singletonKey.trim());

  const fetchJobStatus = async (fetchJobStatusOptions?: { initialFetch?: boolean }) => {
    const {
      jobStatus,
      progress,
      jobName: actualJobName,
      output,
    } = await apiFetch<FetchJobQueueJobStatusResponse>("/api/fetch-job-queue-job-status", {
      body: { jobName, singletonKey, multipartJob: !!params.multipartJob },
    });

    if (fetchJobStatusOptions?.initialFetch && !isStatusInProgress(jobStatus)) {
      return;
    }

    setStatus(jobStatus);
    setProgress(progress);
    setActualJobName(actualJobName);
    setJobOutput(output ?? null);
  };

  // check if an import is already in progress on mount
  useEffect(() => {
    if (enabled && isSingletonKeyProperlySet) {
      void fetchJobStatus({ initialFetch: true });
    }
  }, []);

  useEffect(() => {
    if (!enabled) return;
    if (!enablePolling) return;
    if (!isSingletonKeyProperlySet) return;
    if (skipStatusPolling({ initialStatus, status })) return;

    if (stopStatusPolling({ previousStatus, status })) {
      if (jobHasBeenCompleted({ previousStatus, status })) {
        if (status === QueueJobStatus.COMPLETED) {
          void onComplete?.(queryClient);
        }

        if (status === QueueJobStatus.FAILED) {
          void onError?.(alerts);
        }
      }

      setPollCount(0);

      return;
    }

    const delay = getDelay(pollCount);
    const intervalId = setInterval(async () => {
      await fetchJobStatus();
      setPollCount(pollCount + 1);
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
  }, [enabled, enablePolling, fetchJobStatus, pollCount, previousStatus, setPollCount, status]);

  return {
    jobStatus: status,
    isLoading: isStatusInProgress(status),
    progress,
    setJobStatus: setStatus,
    setJobProgress: setProgress,
    actualJobName,
    jobOutput,
  };
};

const getDelay = (pollCount: number) => {
  if (pollCount === 0) return EARLY_POLLING_DELAY_IN_MS;

  if (pollCount < 3) return BASE_POLLING_DELAY_IN_MS;

  if (pollCount < 6) return BASE_POLLING_DELAY_IN_MS * 2;

  return BASE_POLLING_DELAY_IN_MS * 3;
};

const isStatusInProgress = (status: QueueJobStatus | null | undefined) =>
  isIn(status, [QueueJobStatus.SCHEDULED, QueueJobStatus.IN_PROGRESS]);

const isStatusCompleted = (status: QueueJobStatus | null | undefined) =>
  isIn(status, [QueueJobStatus.COMPLETED, QueueJobStatus.FAILED]);

const jobHasBeenCompleted = ({
  previousStatus,
  status,
}: {
  previousStatus: QueueJobStatus | null | undefined;
  status: QueueJobStatus | null;
}) => isStatusInProgress(previousStatus) && isStatusCompleted(status);

const skipStatusPolling = (params: {
  initialStatus: QueueJobStatus | null | undefined;
  status: QueueJobStatus | null;
}) => {
  return isStatusCompleted(params.initialStatus) && !isStatusInProgress(params.status);
};

const stopStatusPolling = (params: {
  previousStatus: QueueJobStatus | null | undefined;
  status: QueueJobStatus | null;
}) => {
  const { previousStatus, status } = params;

  const noJobInProgress = previousStatus !== undefined && !isStatusInProgress(status);

  return noJobInProgress || jobHasBeenCompleted({ previousStatus, status });
};
