export const QueueJobName = {
  CREATE_SALARY_BANDS: "CREATE_SALARY_BANDS",
  SYNC_EXTERNAL_EMPLOYEES_FOR_COMPANY: "SYNC_EXTERNAL_EMPLOYEES_FOR_COMPANY",
  SYNC_SALARY_RANGE_EMPLOYEES: "SYNC_SALARY_RANGE_EMPLOYEES",
  CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES: "CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES",
  PRE_SYNC_EXTERNAL_EMPLOYEES: "PRE_SYNC_EXTERNAL_EMPLOYEES",
  SYNC_EXTERNAL_EMPLOYEES: "SYNC_EXTERNAL_EMPLOYEES",
  POST_SYNC_EXTERNAL_EMPLOYEES: "POST_SYNC_EXTERNAL_EMPLOYEES",
  UPDATE_SALARY_RANGE_SETTINGS: "UPDATE_SALARY_RANGE_SETTINGS",
  DUPLICATE_SALARY_GRID_FOR_NEW_VERSION: "DUPLICATE_SALARY_GRID_FOR_NEW_VERSION",
  IMPORT_SPREADSHEET: "IMPORT_SPREADSHEET",
  IMPORT_SPREADSHEET_ROW: "IMPORT_SPREADSHEET_ROW",
  POST_IMPORT_SPREADSHEET: "POST_IMPORT_SPREADSHEET",
  GENERATE_SPREADSHEET: "GENERATE_SPREADSHEET",
  UPDATE_COMPANY_STATS: "UPDATE_COMPANY_STATS",
  UPDATE_COMPANY_EMPLOYEES_STATS: "UPDATE_COMPANY_EMPLOYEES_STATS",
  UPDATE_EMPLOYEES_STATS: "UPDATE_EMPLOYEES_STATS",
  UPDATE_LOCATIONS_STATS: "UPDATE_LOCATIONS_STATS",
  SUGGEST_JOBS_MAPPING: "SUGGEST_JOBS_MAPPING",
  SYNC_DATASET_EMPLOYEES: "SYNC_DATASET_EMPLOYEES",
  START_COMPENSATION_REVIEW_CAMPAIGN_SANDBOX: "START_COMPENSATION_REVIEW_CAMPAIGN_SANDBOX",
  DELETE_COMPENSATION_REVIEW_CAMPAIGN: "DELETE_COMPENSATION_REVIEW_CAMPAIGN",
  REFRESH_CAMPAIGN_CONVERTED_AMOUNTS: "REFRESH_CAMPAIGN_CONVERTED_AMOUNTS",
  APPLY_CAMPAIGN_RECOMMENDATION_DISTRIBUTION: "APPLY_CAMPAIGN_RECOMMENDATION_DISTRIBUTION",
  REFRESH_CAMPAIGN_EMPLOYEES_REVIEWERS: "REFRESH_CAMPAIGN_EMPLOYEES_REVIEWERS",
  UPDATE_CAMPAIGN_BUDGETS: "UPDATE_CAMPAIGN_BUDGETS",
  START_COMPENSATION_REVIEW_CAMPAIGN: "START_COMPENSATION_REVIEW_CAMPAIGN",
} as const;

export type QueueJobName = (typeof QueueJobName)[keyof typeof QueueJobName];
