import { useRouter } from "next/router";
import { trackSidePanelOpened } from "~/lib/external/segment/web/events";
import { parseNumber } from "~/lib/queryParams";
import { currentUrlWithQueryParams } from "~/lib/url";
import { type CompensationReviewRecommendationEmployee } from "~/services/compensation-review/shared/fetchCompensationReviewRecommendations";
import { type ExternalEmployeePanelSelect } from "~/services/external-employee/externalEmployeePanelSelect";

export const EXTERNAL_EMPLOYEE_ID_KEY = "external-employee-id";
export const EMPLOYEE_ID_KEY = "employee-id";

type Row = {
  externalEmployee: ExternalEmployeePanelSelect;
  performanceRating: CompensationReviewRecommendationEmployee["performanceRating"];
};

export const useExternalEmployeePanel = <T extends Row>(params: { prefix?: string; rows: T[] }) => {
  const router = useRouter();
  const queryKey = params.prefix ? `${params.prefix}-${EXTERNAL_EMPLOYEE_ID_KEY}` : EXTERNAL_EMPLOYEE_ID_KEY;

  const externalEmployeeId = parseNumber(router.query, queryKey);

  const activeRow = params.rows.find((row) => {
    return row.externalEmployee.id === externalEmployeeId;
  });

  const open = (externalEmployeeId: number | null) => {
    void router.push(
      currentUrlWithQueryParams(router, { [queryKey]: externalEmployeeId, "employee-tab": null }),
      undefined,
      {
        shallow: true,
      }
    );

    if (!!externalEmployeeId) {
      // otherwise, we double the events with close and open
      void trackSidePanelOpened({ name: "External Employee Panel", router, externalEmployeeId });
    }
  };

  return {
    open,
    sidePanelProps: {
      isOpen: !!activeRow,
      onClose: () => open(null),
      disableBottomPadding: true,
    },
    activeRow,
    externalEmployeeId,
  };
};
