import { ArrowDropDown, MoreVert } from "@mui/icons-material";
import { IconButton, ListSubheader, Menu, MenuList } from "@mui/material";
import classNames from "classnames";
import { type ComponentProps, forwardRef, useEffect, useState } from "react";
import { Button } from "~/components/ui/core/Button";

type ButtonProps = Omit<ComponentProps<typeof Button>, "endIcon" | "onClick">;

type MenuProps = Omit<ComponentProps<typeof Menu>, "anchorEl" | "onClose" | "open" | "sx">;

type Props = React.PropsWithChildren<{
  open?: boolean;
  variant?: "button" | "icon";
  onOpenChange?: (open: boolean) => void;
  label?: string | JSX.Element;
  icon?: JSX.Element;
  disabled?: boolean;
  className?: string;
  ButtonProps?: ButtonProps;
  MenuProps?: MenuProps;
  header?: string;
  disablePortal?: boolean;
}>;

export const DropDown = forwardRef<HTMLButtonElement, Props>(
  (
    {
      open,
      onOpenChange,
      className,
      label,
      icon,
      header,
      children,
      variant = "button",
      ButtonProps,
      MenuProps,
      disabled,
      disablePortal = true,
    },
    ref
  ) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const onClose = () => {
      setMenuAnchorEl(null);
      onOpenChange?.(false);
    };
    const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget);
      onOpenChange?.(true);
    };
    const isMenuOpen = !!menuAnchorEl;

    useEffect(() => {
      if (!open) onClose();
    }, [open]);

    return (
      <>
        {variant === "button" && (
          <Button
            {...ButtonProps}
            ref={ref}
            className={className}
            onClick={onButtonClick}
            size="small"
            endIcon={
              ButtonProps?.endIcon === undefined ? (
                <ArrowDropDown fontSize="medium" className={classNames({ "rotate-180 transition": isMenuOpen })} />
              ) : (
                ButtonProps?.endIcon
              )
            }
            variant={ButtonProps?.variant ?? "contained"}
            color={ButtonProps?.color ?? "white"}
            disabled={disabled}
          >
            {label}
          </Button>
        )}

        {variant === "icon" && (
          <IconButton ref={ref} size="small" onClick={onButtonClick} disabled={disabled}>
            {icon ?? <MoreVert fontSize="small" />}
          </IconButton>
        )}

        <Menu
          {...MenuProps}
          sx={{
            "& .MuiPaper-root": {
              "marginTop": "4px",
              "& .MuiList-root": {
                padding: "4px",
              },
            },
          }}
          slotProps={{
            paper: {
              variant: "outlined",
              elevation: 0,
            },
          }}
          disablePortal={disablePortal}
          className="!p-0"
          anchorEl={menuAnchorEl}
          open={isMenuOpen}
          onClose={onClose}
          anchorOrigin={
            MenuProps?.anchorOrigin ?? {
              vertical: "bottom",
              horizontal: "left",
            }
          }
          transformOrigin={
            MenuProps?.transformOrigin ?? {
              vertical: "top",
              horizontal: "left",
            }
          }
        >
          {header && (
            <ListSubheader className="mb-1 border-b border-gray-300 p-2 leading-6 text-gray-900">
              {header}
            </ListSubheader>
          )}

          <MenuList className="!p-0 focus:outline-none" tabIndex={-1}>
            {children}
          </MenuList>
        </Menu>
      </>
    );
  }
);

DropDown.displayName = "DropDown";
