import { match } from "ts-pattern";
import { config } from "~/config";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { AsyncDriver } from "~/lib/queue/drivers/asyncDriver";
import { PgBossDriver } from "~/lib/queue/drivers/pgBossDriver";
import { type QueueDriver } from "~/lib/queue/drivers/queueDriver";
import { isIn } from "~/lib/utils";

const driverCache = new Map<string, QueueDriver>();

export const makeQueueDriver = (): QueueDriver => {
  const driverName = getQueueDriverName();

  const cachedDriver = driverCache.get(driverName);
  if (cachedDriver) {
    return cachedDriver;
  }

  const driver = match(driverName)
    .with("async", () => new AsyncDriver())
    .with("pg-boss", () => new PgBossDriver())
    .exhaustive();

  driverCache.set(driverName, driver);

  return driver;
};

type QueueDriverName = "async" | "pg-boss";

const getQueueDriverName = () => {
  if (config.queue.driver) {
    if (!isIn(config.queue.driver, ["async", "pg-boss"])) {
      throw new BusinessLogicError(`Invalid queue driver: ${config.queue.driver}`);
    }

    return config.queue.driver as QueueDriverName;
  }

  return config.app.isLocal ? "async" : "pg-boss";
};
