import { SalaryGridMeasure } from "@prisma/client";
import linearInterpolator from "linear-interpolator";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { clamp } from "~/lib/lodash";
import { type ComputeSalaryRangePercentileRankInput } from "~/pages/api/salary-bands/compute-salary-range-percentile-rank";
import { getDefaultCurrency } from "~/services/currency";
import { whereSalaryRangeIs } from "~/services/salary-bands/access/helpers";
import {
  computeMarketDataForBenchmarks,
  type ComputeMarketDataForBenchmarksResult,
} from "~/services/salary-bands/benchmark/computeMarketDataForBenchmarks";

export const computeSalaryRangePercentileRank = async (
  ctx: AppContext,
  input: ComputeSalaryRangePercentileRankInput
) => {
  const currency = await getDefaultCurrency(ctx);

  const salaryRange = await ctx.prisma.salaryRange.findFirstOrThrow({
    where: whereSalaryRangeIs(input),
    select: {
      id: true,
      level: {
        select: {
          benchmarkedLevels: {
            select: {
              level: true,
              benchmarkLevelId: true,
            },
          },
        },
      },
      band: {
        select: {
          measure: true,
          marketPositioning: true,
          benchmarkedJobs: {
            select: {
              job: {
                select: {
                  id: true,
                  familyId: true,
                },
              },
            },
          },
          benchmarkedLocations: {
            select: {
              location: {
                select: { id: true },
              },
            },
          },
        },
      },
    },
  });

  const marketData = await computeMarketDataForBenchmarks(ctx, {
    benchmark: {
      jobIds: salaryRange.band.benchmarkedJobs.map((benchmarkedJob) => benchmarkedJob.job.id),
      locationIds: salaryRange.band.benchmarkedLocations.map((benchmarkedLocation) => benchmarkedLocation.location.id),
      levels: salaryRange.level.benchmarkedLevels.map((benchmarkedLevel) => benchmarkedLevel.level),
      benchmarkLevelIds: salaryRange.level.benchmarkedLevels.map(
        (benchmarkedLevel) => benchmarkedLevel.benchmarkLevelId ?? 0
      ),
      marketPositioning: salaryRange.band.marketPositioning,
    },
    currency,
    measure: salaryRange.band.measure,
  });

  return interpolateAmountPercentileRank({
    marketData,
    amount: input.amount,
    measure: salaryRange.band.measure,
  });
};

export const interpolateAmountPercentileRank = (params: {
  marketData: ComputeMarketDataForBenchmarksResult;
  amount: number;
  measure: SalaryGridMeasure;
}) => {
  const { marketData, amount, measure } = params;

  if (!marketData) {
    return null;
  }

  const marketDataMeasure = match(measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => marketData.baseSalary)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => marketData.onTargetEarnings)
    .exhaustive();

  const points: [number, number][] = [
    [marketDataMeasure.p10, 0.1],
    [marketDataMeasure.p25, 0.25],
    [marketDataMeasure.p50, 0.5],
    [marketDataMeasure.p75, 0.75],
    [marketDataMeasure.p90, 0.9],
  ];

  const interpolate = linearInterpolator(points);

  return clamp(interpolate(amount), 0, 1);
};
