/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  CompensationFrequency,
  ExternalEmployeeSource,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { add, isAfter } from "date-fns";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { fetch } from "~/lib/fetch";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/buildCustomBaseSalaryRemunerationItem";
import { buildJobPayload } from "~/lib/hris/helpers/buildJobPayload";
import { buildLevelPayload } from "~/lib/hris/helpers/buildLevelPayload";
import { buildLocationPayload } from "~/lib/hris/helpers/buildLocationPayload";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { getContractType } from "~/lib/hris/helpers/getContractType";
import { getEmployeeCurrency } from "~/lib/hris/helpers/getEmployeeCurrency";
import { getExternalEmployeeStatus, getMappingSkipReason } from "~/lib/hris/helpers/getExternalEmployeeStatus";
import { getGender } from "~/lib/hris/helpers/getGender";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { getUploadedEmployeeProfilePicture } from "~/lib/hris/helpers/getUploadedEmployeeProfilePicture";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { type StaticModels } from "~/lib/integration";
import { compact, get, isArray, isNumber, isString, map, values } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { assertProps } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetchCompanyIntegrationDiagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

type Credentials = {
  clientId: string;
  clientSecret: string;
  anonymous: boolean;
};

// Those headers are used by Personio to monitor their API usage
const tracingHeaders = {
  "X-Personio-Partner-ID": "FIGURES_HR",
  "X-Personio-App-ID": "FIGURES_HR_SYNC",
};

const authorization: Record<string, { accessToken: string; expiresAt: Date } | null> = {};

const authenticate = async (credentials: Credentials): Promise<string> => {
  const res = await fetch(
    `https://api.personio.de/v1/auth?client_id=${credentials.clientId}&client_secret=${credentials.clientSecret}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        ...tracingHeaders,
      },
    }
  );

  const json = await res.json();

  if (!res.ok || !json.success) {
    throw new BusinessLogicError(`Personio authentication failed: [${json.error.code}] - ${json.error.message}`);
  }

  return json.data.token as string;
};

const personioFetch = async (url: string, credentials: Credentials) => {
  const needsRefresh = value(() => {
    const authInfo = authorization[credentials.clientId];
    if (!authInfo) {
      return true;
    }
    if (isAfter(new Date(), authInfo.expiresAt)) {
      return true;
    }
    return false;
  });

  if (needsRefresh) {
    const accessToken = await authenticate(credentials);
    const expiresAt = add(new Date(), { hours: 23 });

    authorization[credentials.clientId] = {
      accessToken: `Bearer ${accessToken}`,
      expiresAt,
    };
  }

  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      Authorization: authorization[credentials.clientId]?.accessToken as string,
      ...tracingHeaders,
    },
  });

  if (!res.ok) {
    throw new BusinessLogicError(`[personio] ${res.status} ${res.statusText}`).withErrorCode("F133_0");
  }

  const accessToken = res.headers.get("authorization");

  authorization[credentials.clientId] = accessToken
    ? {
        accessToken,
        expiresAt: add(new Date(), { hours: 23 }),
      }
    : null;

  return res;
};

export enum SalaryIntervalEnum {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum GenderEnum {
  MALE = "male",
  FEMALE = "female",
  UNDISCLOSED = "",
}

export enum StatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export type PersonioApiEmployee = {
  id: { label: string; value: number };
  first_name?: { label: string; value: string };
  last_name?: { label: string; value: string };
  email?: { label: string; value: string };
  gender?: { label: string; value: GenderEnum };
  status?: { label: string; value: StatusEnum };
  position?: { label: string; value: "" | string };
  hire_date?: { label: string; value: string | null };
  office?: {
    label: string;
    value: { attributes: { id: number; name: string } } | null;
  };
  fix_salary?: { label: string; value: 0 | number; currency: string };
  fix_salary_interval?: { label: string; value: SalaryIntervalEnum | string };
  profile_picture?: { label: string; value: string | null };
  supervisor?: {
    label: string;
    value: { attributes: { id: { value: number } } } | null;
  };
} & Record<`dynamic_${string}`, { label: string; value: string | null | { attributes: { id: number; name: string } } }>;

const anonymise = (user: PersonioApiEmployee): PersonioApiEmployee => {
  delete user.first_name;
  delete user.last_name;
  delete user.profile_picture;
  delete user.email;
  return user;
};

export const getPersonioEmployees = async (
  credentials: Credentials,
  options?: { offset?: number; limit?: number; paginate?: boolean; filter?: boolean }
): Promise<PersonioApiEmployee[]> => {
  const limit = options?.limit ?? 100;
  const offset = options?.offset ?? 0;
  const paginate = options?.paginate ?? true;
  const filter = options?.filter ?? true;

  const res = await personioFetch(
    `https://api.personio.de/v1/company/employees?limit=${limit}&offset=${offset}`,
    credentials
  );

  const json = await res.json();

  if (!json.success) {
    throw new BusinessLogicError(`[personio] ${json.error.code} ${json.error.message}`).withErrorCode("F133_1");
  }

  const employees = map(json.data, "attributes")
    // Keep only active employees
    .filter((model: PersonioApiEmployee) => !filter || !model.status || model.status.value === StatusEnum.ACTIVE)
    .map((model: PersonioApiEmployee) => {
      if (credentials.anonymous) {
        return anonymise(model);
      }
      return model;
    });

  if (json.metadata.current_page === json.metadata.total_pages || !paginate) {
    return employees;
  }

  const nextEmployees = await getPersonioEmployees(credentials, { offset: offset + limit });

  return [...employees, ...nextEmployees];
};

const getPersonioProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: PersonioApiEmployee }
) => {
  if (!options.apiEmployee.profile_picture?.value) {
    return undefined;
  }

  return getUploadedEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id.value.toString(),
    source: ExternalEmployeeSource.PERSONIO,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      personioFetch(
        `https://api.personio.de/v1/company/employees/${options.apiEmployee.id.value}/profile-picture/512`,
        options.integrationSettings
      ),
  });
};

const getCustomField = (ctx: AppContext, apiEmployee: PersonioApiEmployee, fieldId: string | null): string | null => {
  if (!fieldId) {
    return null;
  }

  const field = get(apiEmployee, fieldId);

  if (!field || !field.value) {
    return null;
  }

  if (isArray(field.value)) {
    return null;
  }

  if (!isString(field.value) && !isNumber(field.value)) {
    if (!!field.value.attributes.name) {
      return field.value.attributes.name;
    }

    logWarn(ctx, "[personio] Cannot handle custom field format, skipping", {
      fieldId,
      value: field.value,
    });

    return null;
  }

  return `${field.value}`;
};

export type PersonioIntegrationSettingsInput = Credentials & IntegrationCustomFields;

export const getPersonioDiagnostic = async (
  ctx: AppContext,
  input: PersonioIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await getPersonioEmployees(input, { limit: 1, paginate: false, filter: false });

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your Personio account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = Object.entries(employee)
      .filter(([, field]) => isString(field.value) || isNumber(field.value) || !!field.value?.attributes)
      .map(([id, field]) => ({ id, name: field.label }));

    const missingFields = getMissingCustomFields(input, availableFields);

    return { connection: true, connectionError: "", missingFields, availableFields };
  } catch (error) {
    return { connection: false, connectionError: error.message, missingFields: [], availableFields: [] };
  }
};

export const mapPersonioUser = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: PersonioApiEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  const {
    externalIdCustomFieldName,
    fteCustomFieldName,
    levelCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldName,
    variableCustomFieldFrequency,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    currencyCustomFieldName,
    countryCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = integrationSettings;

  const customExternalId = getCustomField(ctx, apiEmployee, externalIdCustomFieldName);
  const customFte = getCustomField(ctx, apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(ctx, apiEmployee, levelCustomFieldName);
  const customBaseSalary = getCustomField(ctx, apiEmployee, baseSalaryCustomFieldName);
  const customVariable = getCustomField(ctx, apiEmployee, variableCustomFieldName);
  const holidayAllowanceValue = getCustomField(ctx, apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(ctx, apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(ctx, apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(ctx, apiEmployee, jobCustomFieldName);
  const customCurrency = getCustomField(ctx, apiEmployee, currencyCustomFieldName);
  const customCountry = getCustomField(ctx, apiEmployee, countryCustomFieldName);
  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldId,
    additionalFieldMappingId: id,
    value: getCustomField(ctx, apiEmployee, hrisFieldName),
  }));

  const customRemunerationItemsValues = customRemunerationItemMappings.map((customRemunerationItemMapping) => ({
    ...customRemunerationItemMapping,
    value: getCustomField(ctx, apiEmployee, customRemunerationItemMapping.hrisFieldName),
  }));

  const customCountryModel = customCountry
    ? staticModels.countries.find((country) => country.alpha2 === customCountry)
    : null;

  const currency = getEmployeeCurrency(ctx, {
    staticModels,
    currencyCode: customCurrency ?? apiEmployee.fix_salary?.currency,
    employeeCountry: customCountryModel,
    company,
  });

  const job = buildJobPayload({
    companyId: company.id,
    name: customJob ?? apiEmployee.position?.value ?? null,
  });

  const location = buildLocationPayload({
    companyId: company.id,
    externalId: customLocation ?? apiEmployee.office?.value?.attributes.name ?? null,
    name: customLocation ?? apiEmployee.office?.value?.attributes.name ?? null,
    countryCode: customCountryModel?.alpha2 ?? null,
  });

  const level = buildLevelPayload({
    companyId: company.id,
    name: customLevel ?? null,
  });

  const rawContractType = values(apiEmployee)
    .find((attribute) => attribute.label === "Occupation type")
    ?.value?.toString();

  const contractType = getContractType(rawContractType, integrationSettings.source);

  const input: EmployeeData["input"] = {
    source: ExternalEmployeeSource.PERSONIO,
    externalId: apiEmployee.id.value.toString(),
    status: getExternalEmployeeStatus(contractType),
    mappingSkipReason: getMappingSkipReason(contractType),
    firstName: apiEmployee.first_name?.value,
    lastName: apiEmployee.last_name?.value,
    email: apiEmployee.email?.value,
    contractType,
    rawContractType,
    employeeNumber: customExternalId || apiEmployee.id.value.toString(),
    gender: getGender({
      value: apiEmployee.gender?.value,
      options: {
        female: GenderEnum.FEMALE,
        male: GenderEnum.MALE,
      },
    }),
    hireDate: apiEmployee.hire_date?.value,
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency.code },
    },
    ...location,
    ...job,
    ...level,
    ...(businessUnit && { businessUnit }),
  };

  const remunerationItems: EmployeeData["remunerationItems"] = value(() => {
    const numberMonths = getNumberOfMonth({
      externalId: location?.location?.connectOrCreate?.create?.externalId,
      additionalMonthRules: staticModels.additionalMonthRules,
      externalLocations: staticModels.externalLocations,
    });

    const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
      customBaseSalary,
      baseSalaryCustomFieldFrequency,
      numberMonths,
      company,
      source: ExternalEmployeeSource.PERSONIO,
    });

    if (customBaseSalaryRemunerationItem) {
      return [customBaseSalaryRemunerationItem];
    }

    const amount = (apiEmployee.fix_salary?.value ?? 0) * 100;
    if (!amount) {
      return [];
    }

    const interval = apiEmployee.fix_salary_interval?.value ?? "";

    if (interval !== SalaryIntervalEnum.YEARLY && interval !== SalaryIntervalEnum.MONTHLY) {
      logWarn(ctx, `[personio] Unhandled Personio fix salary interval`, { interval });
    }

    return [
      {
        company: {
          connect: { id: company.id },
        },
        source: ExternalEmployeeSource.PERSONIO,
        externalId: "fix-salary",
        amount: interval === SalaryIntervalEnum.MONTHLY ? amount * numberMonths : amount,
        numberMonths,
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: ExternalEmployeeSource.PERSONIO,
                externalId: "fix-salary",
              },
            },
            create: {
              source: ExternalEmployeeSource.PERSONIO,
              externalId: "fix-salary",
              name: "Fixed salary",
              mappedType: ExternalRemunerationType.FIXED_SALARY,
              company: {
                connect: {
                  id: company.id,
                },
              },
            },
          },
        },
      } satisfies EmployeeData["remunerationItems"][number],
    ];
  });

  if (customVariable && parseFloat(customVariable) && variableCustomFieldFrequency) {
    const multiplier = match(variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = parseFloat(customVariable) * multiplier * 100;

    remunerationItems.push({
      company: {
        connect: { id: company.id },
      },
      source: ExternalEmployeeSource.PERSONIO,
      externalId: "variable-bonus",
      amount: amount,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.PERSONIO,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: ExternalEmployeeSource.PERSONIO,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    } satisfies EmployeeData["remunerationItems"][number]);
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    profilePicture: !ignoreProfilePicture
      ? await getPersonioProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId: apiEmployee.supervisor?.value?.attributes.id.value.toString(),
    holidayAllowanceValue,
    // If one day they support hourly rates, we just have to update this (cf. bamboo)
    ...(customFte && { fte: customFte, ignoreFte: false }),
  };
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientId", "clientSecret"]);

export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedPersonioEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const personioEmployees = await getPersonioEmployees(safeIntegrationSettings);

  return mapSeries(personioEmployees, (personioEmployee) =>
    mapPersonioUser(ctx, company, personioEmployee, safeIntegrationSettings, staticModels, ignoreProfilePicture)
  );
};

export const getRawPersonioEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<PersonioApiEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getPersonioEmployees(safeIntegrationSettings);
};
