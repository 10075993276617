import React, { createContext, useContext, useMemo } from "react";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import {
  ProductPermission,
  type SubscriptionStatusWithPermissions,
} from "~/services/subscriptions/validateSubscription";

type SubscriptionsProps = React.PropsWithChildren<{
  subscriptions: SubscriptionStatusWithPermissions;
}>;

const SubscriptionsContext = createContext<{ subscriptions: SubscriptionStatusWithPermissions } | null>(null);

export const useSubscriptions = () => {
  const context = useContext(SubscriptionsContext);

  if (!context) {
    throw new BusinessLogicError("useSubscriptions must be used within a SubscriptionsProvider");
  }

  return context;
};

export const SubscriptionsProvider: React.FC<SubscriptionsProps> = ({ subscriptions, children }) => {
  const props = useMemo(
    () => ({
      subscriptions: changeSubscriptionsToPermissions(subscriptions),
    }),
    [subscriptions]
  );

  return <SubscriptionsContext.Provider value={props}>{children}</SubscriptionsContext.Provider>;
};

//might be obsolote...
const changeSubscriptionsToPermissions = (
  subscriptions: SubscriptionStatusWithPermissions
): SubscriptionStatusWithPermissions => {
  return {
    [ProductPermission.CAN_ACCESS_BENCHMARK]: subscriptions[ProductPermission.CAN_ACCESS_BENCHMARK],
    [ProductPermission.CAN_ACCESS_COMPENSATION_REVIEW]: subscriptions[ProductPermission.CAN_ACCESS_COMPENSATION_REVIEW],
    [ProductPermission.CAN_ACCESS_SALARY_BANDS]: subscriptions[ProductPermission.CAN_ACCESS_SALARY_BANDS],
  };
};
