type JobPayloadParams = {
  companyId: number;
  externalId?: string | null;
  name: string | null;
};

export const buildJobPayload = (params: JobPayloadParams) => {
  const { companyId, externalId, name } = params;

  if (!name) {
    return null;
  }

  return {
    job: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId,
            externalId: externalId ?? name,
          },
        },
        create: {
          name,
          externalId: externalId ?? name,
          autoMappingEnabled: true,
          company: {
            connect: { id: companyId },
          },
        },
      },
    },
  };
};
