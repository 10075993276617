import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { getFromSession, initImpersonationContext, SessionKey } from "~/lib/session";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const updateImpersonationContext = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>
) => {
  const companyId = getFromSession(ctx.req, { key: SessionKey.IMPERSONATED_COMPANY_ID });

  initImpersonationContext(ctx.req, {
    user: ctx.impersonatingUser
      ? {
          target: ctx.user,
          impersonatedBy: ctx.impersonatingUser,
        }
      : null,
    companyId: companyId ? +companyId : null,
  });

  return next(ctx);
};
