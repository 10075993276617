import { value } from "~/components/helpers";
import { isDate } from "~/lib/lodash";
import { type Transformer, transformObject } from "~/lib/transformObject";

/*
 * This function gives us a flattened object with keys in dot notation.
 * For instance: { user: { id: 3 } } becomes { "user.id": 3 }.
 * This is required as datadog only supports flat objects but recreates them in the web app.
 * */
export const flattenObject = (
  obj?: Record<string, unknown> | null,
  options?: {
    prefix?: string;
    transformers?: Transformer[];
  }
) => {
  if (!obj) {
    return {};
  }

  const prefix = options?.prefix?.trim() || "";
  const sourceObject = value(() => {
    if (options?.transformers) {
      return transformObject(obj, { transformers: options.transformers });
    }

    return obj;
  });

  return Object.entries(sourceObject).reduce((acc: Record<string, unknown>, [key, value]) => {
    const newPrefix = (prefix.length ? prefix + "." : "") + key;

    if (isDate(value)) {
      acc[newPrefix] = value.toISOString();
      return acc;
    }

    if (typeof value === "object" && value !== null) {
      Object.assign(acc, flattenObject(value as Record<string, unknown>, { ...options, prefix: newPrefix }));
      return acc;
    }

    acc[newPrefix] = value;
    return acc;
  }, {});
};
