import { array, number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { logError, logInfo } from "~/lib/logger";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { type SendOptions } from "~/lib/queue/drivers/queueDriver";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { updateEmployeesStats } from "~/services/employee-stats/updateEmployeesStats";

const UpdateEmployeesStatsJobDataSchema = BaseJobDataSchema.concat(
  object({
    employeeIds: array().of(number().required()).required(),
  })
);

export type UpdateEmployeesStatsJobData = YupOutputType<typeof UpdateEmployeesStatsJobDataSchema>;

export const updateEmployeesStatsWorkerService = async (ctx: AppContext, data: UpdateEmployeesStatsJobData) => {
  const { companyId, employeeIds } = UpdateEmployeesStatsJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  try {
    await updateEmployeesStats(ctx, {
      companyId,
      employeesIds: employeeIds,
    });

    logInfo(ctx, `[employee-stats] Updated ${employeeIds.length} employee stats for company`, {
      companyId,
      employeesCount: employeeIds.length,
    });
  } catch (error) {
    logError(
      ctx,
      `[employee-stats] There was an error while updating ${employeeIds.length} employee stats for company`,
      {
        error,
        companyId,
        employeesCount: employeeIds.length,
      }
    );

    throw error;
  }
};

export const sendUpdateEmployeesStatsJob = async (
  ctx: AppContext,
  data: UpdateEmployeesStatsJobData,
  options?: Pick<SendOptions, "priority">
) => {
  return sendJob(ctx, {
    jobName: QueueJobName.UPDATE_EMPLOYEES_STATS,
    data,
    options: {
      ...options,
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.UPDATE_EMPLOYEES_STATS },
      }),
    },
  });
};
