import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logWarn } from "~/lib/logger";
import type { RowValidationError } from "~/services/xlsxToJson";
import { ErrorCode, makeErrorPayload } from "../makeErrorPayload";

export class XlsxImportError extends FiguresBaseError {
  constructor(
    readonly message: string,
    public readonly rowErrors: RowValidationError[] = []
  ) {
    super(message);

    Object.setPrototypeOf(this, XlsxImportError.prototype);
  }
}

export const handleSpreadsheetImportError = (ctx: AppContext, res: NextApiResponse, error: XlsxImportError) => {
  logWarn(ctx, "[error] Spreadsheet import error", { error });

  res.status(HttpStatus.UNPROCESSABLE_ENTITY).json({
    ...makeErrorPayload({ error, defaultCode: ErrorCode.UNPROCESSABLE_ENTITY }),
    fields: error.rowErrors
      ? error.rowErrors.map((item) => {
          return {
            id: item.id ?? `-`,
            value: item.value,
            error: item.message,
            meta: {
              count: item.count,
            },
          };
        })
      : [],
  });
};
