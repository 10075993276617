import {
  type CompensationReviewAdditionalField,
  CompensationReviewSalaryDisplay,
  type Currency,
  type SalaryGridTiersMode,
} from "@prisma/client";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type AuthenticatedUser } from "~/lib/session";
import { getKeys } from "~/lib/utils";
import {
  type CompensationReviewCampaignBudget,
  fetchCampaign,
  type FetchCampaignResult,
} from "~/services/compensation-review/campaigns/fetchCampaign";
import {
  canAccessAnalytics,
  canAccessConfiguration,
  canAccessOffCycleConfiguration,
  canAccessOffCycleOverview,
  canAccessOverview,
  canAccessReviewers,
  canAccessYourReviews,
  canApproveOffCycleReview,
  canBypassOffCycleRules,
  canBypassRules,
  canConfigureAfterLaunch,
  canConfigureCampaign,
  canReviewCampaign,
  canSeeAnalytics,
  canSeeOverview,
  canSeeReviewers,
  canSeeYourReviews,
  canSetCurrentReviewer,
  canSkipOffCycleReviewersRecommendation,
  canUpdateOffCycleRecommendation,
  canUpdatePerformanceRatings,
  canUpdatePromotions,
  canUpdateReviewers,
} from "~/services/compensation-review/compensationReviewPermissions";
import {
  campaignScope,
  type CompensationReviewScope,
  CompensationReviewScopeType,
  offCycleReviewScope,
} from "~/services/compensation-review/compensationReviewScope";
import {
  fetchAuthenticatedCompensationReviewReviewer,
  type FetchAuthenticatedCompensationReviewReviewerResult,
} from "~/services/compensation-review/fetchAuthenticatedCompensationReviewReviewer";
import {
  fetchOffCycleReviewConfiguration,
  type FetchOffCycleReviewConfigurationResult,
} from "~/services/compensation-review/off-cycle-reviews/fetchOffCycleReviewConfiguration";
import { getDefaultCurrency } from "~/services/currency";

export type CompensationReviewData = {
  scope: CompensationReviewScope;
  campaign?: FetchCampaignResult;
  companyId: number;

  authenticatedReviewer: FetchAuthenticatedCompensationReviewReviewerResult;

  salaryGrid?: {
    tiersMode: SalaryGridTiersMode;
    tiersNames: string[];
  };

  parameters: {
    budgets: CompensationReviewCampaignBudget[];
    currency: Pick<Currency, "code" | "euroExchangeRate" | "symbol" | "decimals">;
    companyCurrency: Pick<Currency, "code" | "euroExchangeRate">;

    finalReviewerId: number | null;
    maxReviewersCount: number;
    reviewerGuidelines: string | null;

    preferedSalaryDisplay: CompensationReviewSalaryDisplay;

    additionalFields: Pick<CompensationReviewAdditionalField, "id" | "name" | "nature">[];
  };

  permissions: {
    canAccessConfiguration: boolean;
    canReview: boolean;
    canUpdatePromotions: boolean;
    canUpdatePerformanceRatings: boolean;
    canConfigure: boolean;
    canConfigureAfterLaunch: boolean;
    canReviewExceptionalBonus: boolean;
    canSetCurrentReviewer: boolean;
    canUpdateReviewers: boolean;
    canReviewAnnualPerformanceBonus: boolean;
    canAccessOverview: boolean;
    canBypassRules: boolean;
  };
};

export type CompensationReviewCampaignData = Omit<CompensationReviewData, "campaign"> & {
  campaign: FetchCampaignResult;

  permissions: CompensationReviewData["permissions"] & {
    canSeeYourReviews: boolean;
    canSeeOverview: boolean;
    canSeeReviewers: boolean;
    canSeeAnalytics: boolean;
    canAccessYourReviews: boolean;
    canAccessReviewers: boolean;
    canAccessAnalytics: boolean;
  };
};

export type OffCycleReviewData = Omit<CompensationReviewData, "campaign"> & {
  configuration: FetchOffCycleReviewConfigurationResult;

  permissions: CompensationReviewData["permissions"] & {
    canApproveOffCycleReview: boolean;
  };
};

export type CompensationReviewContext = Omit<AppContext, "user"> & { user: AuthenticatedUser } & CompensationReviewData;

export type CompensationReviewCampaignContext = Omit<CompensationReviewContext, "campaign"> &
  CompensationReviewCampaignData;

export type OffCycleReviewContext = Omit<CompensationReviewContext, "campaign"> & OffCycleReviewData;

export const isCompensationReviewCampaignContext = (
  ctx: CompensationReviewContext
): ctx is CompensationReviewCampaignContext => {
  return !!ctx.campaign;
};

export const fetchCompensationReviewContext = async (
  ctx: AppContext,
  params: { scope: CompensationReviewScope }
): Promise<CompensationReviewContext> => {
  return match(params.scope)
    .with({ type: CompensationReviewScopeType.CAMPAIGN }, () =>
      fetchCompensationReviewCampaignContext(ctx, { campaignId: params.scope.id })
    )
    .with({ type: CompensationReviewScopeType.OFF_CYCLE_REVIEW }, () => fetchCompensationOffCycleReviewContext(ctx))
    .exhaustive();
};

export const fetchCompensationReviewCampaignContext = async (
  ctx: AppContext,
  params: { campaignId: number }
): Promise<CompensationReviewCampaignContext> => {
  const user = getRequiredUser(ctx);
  const companyCurrency = await getDefaultCurrency(ctx);

  const campaign = await fetchCampaign(ctx, params);
  const scope = campaignScope(campaign.id);

  const authenticatedReviewer = await fetchAuthenticatedCompensationReviewReviewer(ctx, { scope });

  const checkContext = { user, campaign, authenticatedReviewer, impersonation: ctx.impersonation };

  const permissions = {
    canSeeYourReviews: canSeeYourReviews(checkContext),
    canSeeOverview: canSeeOverview(checkContext),
    canSeeReviewers: canSeeReviewers(checkContext),
    canSeeAnalytics: canSeeAnalytics(checkContext),
    canAccessYourReviews: canAccessYourReviews(checkContext),
    canAccessOverview: canAccessOverview(checkContext),
    canAccessReviewers: canAccessReviewers(checkContext),
    canAccessConfiguration: canAccessConfiguration(checkContext),
    canAccessAnalytics: canAccessAnalytics(checkContext),
    canConfigure: canConfigureCampaign(checkContext),
    canConfigureAfterLaunch: canConfigureAfterLaunch(checkContext),
    canReview: canReviewCampaign(checkContext),
    canUpdatePromotions: canUpdatePromotions(checkContext),
    canUpdatePerformanceRatings: canUpdatePerformanceRatings(checkContext),
    canSetCurrentReviewer: canSetCurrentReviewer(checkContext),
    canUpdateReviewers: canUpdateReviewers(checkContext),
    canBypassRules: canBypassRules(checkContext),
  };

  Object.assign(ctx, {
    user,
    companyId: campaign.companyId,
    scope,
    campaign,
    authenticatedReviewer,
    salaryGrid: campaign.company.defaultSalaryGrid ?? undefined,
    parameters: {
      budgets: campaign.budgets,
      currency: campaign.currency,
      companyCurrency,
      finalReviewerId: campaign.finalReviewer?.id ?? null,
      maxReviewersCount: campaign.maxReviewersCount,
      reviewerGuidelines: campaign.reviewerGuidelines,
      preferedSalaryDisplay: campaign.preferedSalaryDisplay,
      additionalFields: campaign.additionalFields,
    },
    permissions,
  });

  return ctx as CompensationReviewCampaignContext;
};

export const fetchCompensationOffCycleReviewContext = async (ctx: AppContext): Promise<OffCycleReviewContext> => {
  const user = getRequiredUser(ctx);

  const companyCurrency = await getDefaultCurrency(ctx);

  const configuration = await fetchOffCycleReviewConfiguration(ctx);
  const scope = offCycleReviewScope(configuration.id);

  const authenticatedReviewer = await fetchAuthenticatedCompensationReviewReviewer(ctx, { scope }); // ?

  const checkContext = { user, configuration };

  const permissions = {
    canReviewExceptionalBonus: true,
    canReviewAnnualPerformanceBonus: true,
    canAccessConfiguration: canAccessOffCycleConfiguration(checkContext),
    canReview: !!authenticatedReviewer,
    canUpdatePromotions: authenticatedReviewer?.canUpdatePromotions ?? false,
    canUpdatePerformanceRatings: authenticatedReviewer?.canUpdatePerformanceRatings ?? false,
    canSetCurrentReviewer: canSkipOffCycleReviewersRecommendation(checkContext),
    canUpdateRecommendation: canUpdateOffCycleRecommendation(checkContext),
    canAccessOverview: canAccessOffCycleOverview(checkContext),
    canBypassRules: canBypassOffCycleRules(checkContext),
    canApproveOffCycleReview: canApproveOffCycleReview(checkContext),
  };

  if (!ctx.globalPermissionsContext.canAccessOffCycleReviews) {
    getKeys(permissions).forEach((key) => {
      permissions[key] = false;
    });
  }

  Object.assign(ctx, {
    user,
    configuration,
    companyId: configuration.companyId,
    scope,
    authenticatedReviewer,
    salaryGrid: configuration.company.defaultSalaryGrid ?? undefined,
    parameters: {
      budgets: configuration.budgets,
      currency: companyCurrency,
      companyCurrency,
      finalReviewerId: configuration.finalReviewer?.id ?? null,
      maxReviewersCount: configuration.maxReviewersCount,
      reviewerGuidelines: configuration.reviewerGuidelines,
      preferedSalaryDisplay: CompensationReviewSalaryDisplay.ON_TARGET_EARNINGS,
      additionalFields: configuration.additionalFields,
    },
    permissions,
  });

  return ctx as OffCycleReviewContext;
};
