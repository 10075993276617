import { type Prisma, SalaryGridMeasure } from "@prisma/client";
import { mapSeries } from "bluebird";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { auditLogUpdateRange } from "~/services/salary-bands/audit-logs/updateRange";
import {
  computeMarketDataForBenchmarks,
  type ComputeMarketDataForBenchmarksResult,
} from "~/services/salary-bands/benchmark/computeMarketDataForBenchmarks";
import { computeSalaryRangeBounds } from "~/services/salary-bands/helpers/computeSalaryRangeBounds";
import { buildSalaryRangeWhereClause } from "~/services/salary-bands/helpers/salaryGridFilters";
import { getWidthsFromSalaryRange } from "~/services/salary-bands/helpers/salaryRangeWidth";
import { type BulkUpdateSalaryRangeTargetPercentilesInput } from "~/workers/updateSalaryRangeSettings";

export const updateSalaryRangeTargetPercentiles = async (
  ctx: AppContext,
  input: BulkUpdateSalaryRangeTargetPercentilesInput
) => {
  const filtersWhereClause = buildSalaryRangeWhereClause(ctx, { salaryGridId: input.salaryGridId }, input.filters);
  const ranges = await ctx.prisma.salaryRange.findMany({
    where: { ...filtersWhereClause, ...(!input.allRanges && { id: { in: input.salaryRangeIds } }) },
    select: rangeSelect,
  });

  await mapSeries(ranges, async (range) => {
    const marketData = await computeMarketDataForBenchmarks(ctx, {
      currency: range.band.currency,
      percentileRank: input.percentileRank,
      benchmark: {
        jobIds: range.band.benchmarkedJobs.map(({ job }) => job.id),
        levels: range.level.benchmarkedLevels.map(({ level }) => level),
        benchmarkLevelIds: range.level.benchmarkedLevels.map(({ benchmarkLevelId }) => benchmarkLevelId ?? 0),
        locationIds: range.band.benchmarkedLocations.map(({ location }) => location.id),
        marketPositioning: range.band.marketPositioning,
      },
      measure: range.band.measure,
    });

    const midpoint = getMidpointForMeasure(range.band.measure, marketData);
    if (!midpoint) return;

    const { upperWidth } = getWidthsFromSalaryRange(range);
    const { min, max } = computeSalaryRangeBounds({ midpoint, width: upperWidth });

    const data = {
      inferredPercentileRank: input.percentileRank,
      midpoint,
      min,
      max,
      targetPercentileRank: input.percentileRank,
    };
    await ctx.prisma.salaryRange.update({ where: { id: range.id }, data });

    await auditLogUpdateRange(ctx, {
      rangeId: range.id,
      bandId: range.band.id,
      gridId: input.salaryGridId,
      initialState: mapRangeToAuditLogState(range),
      newState: mapRangeToAuditLogState({ ...range, ...data }),
      reason: input.reason,
    });
  });
};

const rangeSelect = {
  band: {
    select: {
      benchmarkedJobs: { select: { job: { select: { id: true, familyId: true } } } },
      benchmarkedLocations: { select: { location: { select: { id: true } } } },
      id: true,
      job: { select: { id: true, name: true } },
      location: { select: { id: true, name: true } },
      marketPositioning: true,
      currency: true,
      measure: true,
    },
  },
  id: true,
  inferredPercentileRank: true,
  level: {
    select: { benchmarkedLevels: { select: { level: true, benchmarkLevelId: true } }, id: true, name: true },
  },
  max: true,
  midpoint: true,
  min: true,
  targetPercentileRank: true,
} satisfies Prisma.SalaryRangeSelect;

type RangeData = Prisma.SalaryRangeGetPayload<{ select: typeof rangeSelect }>;

const getMidpointForMeasure = (measure: SalaryGridMeasure, marketData: ComputeMarketDataForBenchmarksResult) =>
  match(measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => marketData?.baseSalary?.target)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => marketData?.onTargetEarnings?.target)
    .exhaustive();

const mapRangeToAuditLogState = (range: RangeData) => {
  return {
    id: range.id,
    min: range.min,
    midpoint: range.midpoint,
    max: range.max,
    targetPercentileRank: range.targetPercentileRank,
    inferredPercentileRank: range.inferredPercentileRank,
    band: {
      id: range.band.id,
      job: range.band.job,
      location: range.band.location,
    },
    level: { id: range.level.id, name: range.level.name },
  };
};
