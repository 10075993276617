import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { updateBudgets } from "~/services/compensation-review/campaigns/admin/updateBudgetDefinition";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

const UpdateCampaignBudgetsJobDataSchema = BaseJobDataSchema.concat(
  object({
    campaignId: number().required(),
    budgetId: number().required(),
  })
);

export type UpdateCampaignBudgetsJobData = YupOutputType<typeof UpdateCampaignBudgetsJobDataSchema>;

export const updateCampaignBudgetsWorkerService = async (ctx: AppContext, data: UpdateCampaignBudgetsJobData) => {
  const { campaignId, budgetId } = UpdateCampaignBudgetsJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  const context = await fetchCompensationReviewCampaignContext(ctx, { campaignId });

  await updateBudgets(context, budgetId);
};

export const sendUpdateCampaignBudgetsJob = async (ctx: AppContext, data: UpdateCampaignBudgetsJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.UPDATE_CAMPAIGN_BUDGETS,
    data,
    options: {
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.UPDATE_CAMPAIGN_BUDGETS },
        with: { campaignId: data.campaignId },
      }),
    },
  });
};
