/**
 * Everything you need to know (and more !) about integrations is in The Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  CompensationFrequency,
  EmployeeSource,
  ExternalEmployeeContractType,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isPast, parseISO } from "date-fns";
import { match } from "ts-pattern";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { fetch } from "~/lib/fetch";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/buildCustomBaseSalaryRemunerationItem";
import { buildJobPayload } from "~/lib/hris/helpers/buildJobPayload";
import { buildLevelPayload } from "~/lib/hris/helpers/buildLevelPayload";
import { buildLocationPayload } from "~/lib/hris/helpers/buildLocationPayload";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { getEmployeeCurrency } from "~/lib/hris/helpers/getEmployeeCurrency";
import { getExternalEmployeeStatus, getMappingSkipReason } from "~/lib/hris/helpers/getExternalEmployeeStatus";
import { getGender } from "~/lib/hris/helpers/getGender";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { type StaticModels } from "~/lib/integration";
import { compact, get, isArray, isObject, pick } from "~/lib/lodash";
import { hasValidHostname } from "~/lib/url";
import { assertProps, getKeys } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetchCompanyIntegrationDiagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

type Credentials = {
  clientId: string;
  clientSecret: string;
  domain: string;
  anonymous: boolean;
};

const standardFields = [
  "employeeId",
  "firstName",
  "lastName",
  "email",
  "birthDate",
  "gender",
  "hireDate",
  "endDate",
  "jobTitle",
  "country",
  "location",
  "basePay",
  "fixedBonus",
  "variableBonus",
  "currency",
  "seniorityLevel",
  "managerId",
] as const;
export type StandardFields = (typeof standardFields)[number];

type RequireOne<T> = T & { [P in keyof T]: Required<Pick<T, P>> }[keyof T];

export type WorkdayEmployee = (
  | {
      [key in StandardFields]?: string;
    }
  | {
      [key: string]:
        | RequireOne<{ [key in StandardFields]?: string }>
        | [RequireOne<{ [key in StandardFields]?: string }>];
    }
) &
  Record<`custom${string}`, string>;

export type FlatWorkdayEmployee = {
  employeeId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  birthDate?: string;
  gender?: string;
  hireDate?: string;
  endDate?: string;
  jobTitle?: string;
  country?: string;
  location?: string;
  basePay?: string;
  fixedBonus?: string;
  variableBonus?: string;
  currency?: string;
  seniorityLevel?: string;
  managerId?: string;
};

type WorkdayResponse = {
  Report_Entry: WorkdayEmployee[];
};

export type WorkdayIntegrationSettingsInput = Credentials & IntegrationCustomFields;

export const validWorkdayHostnames = ["myworkday.com", "workday.com"] as const;

const workdayFetch = async (credentials: Credentials) => {
  if (!config.app.isLocal && !hasValidHostname(credentials.domain, validWorkdayHostnames)) {
    throw new BusinessLogicError("Invalid Workday domain name");
  }

  const authKey = Buffer.from(`${credentials.clientId}:${credentials.clientSecret}`).toString("base64");

  const res = await fetch(credentials.domain, {
    headers: {
      Accept: "application/json",
      Authorization: `Basic ${authKey}`,
    },
  });

  if (!res.ok) {
    throw new BusinessLogicError(`[workday] ${res.status} ${res.statusText}`);
  }

  const report: WorkdayResponse = await res.json();

  return report.Report_Entry.map((employee) => flattenWorkdayEmployee(employee));
};

const anonymise = (credentials: Credentials) => {
  return (employee: FlatWorkdayEmployee): FlatWorkdayEmployee => {
    if (credentials.anonymous) {
      delete employee.firstName;
      delete employee.lastName;
      delete employee.birthDate;
      delete employee.email;
    }
    return employee;
  };
};

export const getCustomField = (apiEmployee: FlatWorkdayEmployee, fieldId: string | null): string | null => {
  if (!fieldId) {
    return null;
  }

  const customField = get(apiEmployee, fieldId);

  return customField?.toString() ?? null;
};

const flattenWorkdayEmployee = (apiEmployee: WorkdayEmployee) => {
  for (const key in apiEmployee) {
    const currentValue = get(apiEmployee, key);
    if (isArray(currentValue) && isObject(currentValue[0])) {
      apiEmployee = { ...apiEmployee, ...currentValue[0] };
    } else if (isObject(currentValue)) {
      apiEmployee = { ...apiEmployee, ...currentValue };
    }
  }

  return apiEmployee as FlatWorkdayEmployee;
};

const getCountryFromEmployee = (apiEmployee: FlatWorkdayEmployee, staticModels: StaticModels) => {
  if (!apiEmployee.country) {
    return null;
  }

  const countryFromEmployee = staticModels.countries.find((country) => country.alpha2 === apiEmployee.country);

  return countryFromEmployee ?? null;
};

export const mapWorkdayEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: FlatWorkdayEmployee,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels
): Promise<EmployeeData> => {
  const {
    externalIdCustomFieldName,
    fteCustomFieldName,
    levelCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldFrequency,
    variableCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    currencyCustomFieldName,
    countryCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = integrationSettings;

  const customFte = getCustomField(apiEmployee, fteCustomFieldName);
  const customLevel = getCustomField(apiEmployee, levelCustomFieldName);
  const customEmployeeNumber = getCustomField(apiEmployee, externalIdCustomFieldName);
  const customBaseSalary = getCustomField(apiEmployee, baseSalaryCustomFieldName);
  const customVariable = getCustomField(apiEmployee, variableCustomFieldName);
  const holidayAllowanceValue = getCustomField(apiEmployee, holidayAllowanceCustomFieldName);
  const businessUnit = getCustomField(apiEmployee, businessUnitCustomFieldName);
  const customLocation = getCustomField(apiEmployee, locationCustomFieldName);
  const customJob = getCustomField(apiEmployee, jobCustomFieldName);
  const customCurrency = getCustomField(apiEmployee, currencyCustomFieldName);
  const customCountry = getCustomField(apiEmployee, countryCustomFieldName);
  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldId,
    additionalFieldMappingId: id,
    value: getCustomField(apiEmployee, hrisFieldName),
  }));

  const customRemunerationItemsValues = customRemunerationItemMappings.map((customRemunerationItemMapping) => ({
    ...customRemunerationItemMapping,
    value: getCustomField(apiEmployee, customRemunerationItemMapping.hrisFieldName),
  }));

  const countryFromEmployee = customCountry
    ? staticModels.countries.find((country) => country.alpha2 === customCountry)
    : getCountryFromEmployee(apiEmployee, staticModels);

  const currency = getEmployeeCurrency(ctx, {
    staticModels,
    currencyCode: customCurrency ?? apiEmployee.currency,
    employeeCountry: countryFromEmployee,
    company,
  });

  const job = buildJobPayload({
    companyId: company.id,
    name: customJob ?? apiEmployee.jobTitle ?? null,
  });

  const location = buildLocationPayload({
    companyId: company.id,
    externalId: customLocation ?? apiEmployee.location ?? null,
    name: customLocation ?? apiEmployee.location ?? null,
    countryId: !customLocation ? countryFromEmployee?.id ?? null : null,
  });

  const level = buildLevelPayload({
    companyId: company.id,
    name: customLevel ?? apiEmployee.seniorityLevel ?? null,
  });

  const managerExternalId = apiEmployee.managerId;

  const input: EmployeeData["input"] = {
    source: EmployeeSource.WORKDAY,
    externalId: apiEmployee.employeeId,
    status: getExternalEmployeeStatus(ExternalEmployeeContractType.PERMANENT),
    mappingSkipReason: getMappingSkipReason(ExternalEmployeeContractType.PERMANENT),
    firstName: apiEmployee.firstName,
    lastName: apiEmployee.lastName,
    email: apiEmployee.email,
    employeeNumber: customEmployeeNumber ?? apiEmployee.employeeId,
    gender: getGender({
      value: apiEmployee.gender,
      options: {
        female: "Female",
        male: "Male",
      },
    }),
    ...(apiEmployee.birthDate && { birthDate: parseISO(apiEmployee.birthDate) }),

    ...(apiEmployee.hireDate && { hireDate: parseISO(apiEmployee.hireDate) }),
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency.code },
    },

    ...location,
    ...job,
    ...level,
    ...(businessUnit && { businessUnit }),
  };

  const fixedSalaryAmount = parseFloat(apiEmployee.basePay ?? "");
  const fixedBonusAmount = parseFloat(apiEmployee.fixedBonus ?? "");
  const variableBonusAmount = parseFloat(apiEmployee.variableBonus ?? "");

  const remunerationItems: EmployeeData["remunerationItems"] = [];

  const numberMonths = getNumberOfMonth({
    externalId: location?.location?.connectOrCreate?.create?.externalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
    customBaseSalary,
    baseSalaryCustomFieldFrequency,
    company,
    numberMonths,
    source: EmployeeSource.WORKDAY,
  });

  if (customBaseSalaryRemunerationItem) {
    remunerationItems.push(customBaseSalaryRemunerationItem);
  } else {
    remunerationItems.push({
      company: {
        connect: { id: company.id },
      },
      source: EmployeeSource.WORKDAY,
      externalId: "fix-salary",
      amount: fixedSalaryAmount * 100,
      status: ExternalRemunerationStatus.LIVE,
      numberMonths,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: EmployeeSource.WORKDAY,
              externalId: "fix-salary",
            },
          },
          create: {
            source: EmployeeSource.WORKDAY,
            externalId: "fix-salary",
            name: "Fixed salary",
            mappedType: ExternalRemunerationType.FIXED_SALARY,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    } satisfies EmployeeData["remunerationItems"][number]);
  }

  if (!!fixedBonusAmount) {
    remunerationItems.push({
      company: {
        connect: { id: company.id },
      },
      source: EmployeeSource.WORKDAY,
      externalId: "fixed-bonus",
      amount: fixedBonusAmount * 100,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: EmployeeSource.WORKDAY,
              externalId: "fixed-bonus",
            },
          },
          create: {
            source: EmployeeSource.WORKDAY,
            externalId: "fixed-bonus",
            name: "Fixed bonus",
            mappedType: ExternalRemunerationType.FIXED_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    } satisfies EmployeeData["remunerationItems"][number]);
  }

  if (customVariable && variableCustomFieldFrequency) {
    const multiplier = match(variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = parseFloat(customVariable) * multiplier;

    remunerationItems.push({
      company: {
        connect: { id: company.id },
      },
      source: EmployeeSource.WORKDAY,
      externalId: "variable-bonus",
      amount: amount * 100,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: EmployeeSource.WORKDAY,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: EmployeeSource.WORKDAY,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    } satisfies EmployeeData["remunerationItems"][number]);
  }

  if (!!variableBonusAmount && !customVariable) {
    remunerationItems.push({
      company: {
        connect: { id: company.id },
      },
      source: EmployeeSource.WORKDAY,
      externalId: "variable-bonus",
      amount: variableBonusAmount * 100,
      status: ExternalRemunerationStatus.LIVE,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: EmployeeSource.WORKDAY,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: EmployeeSource.WORKDAY,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    } satisfies EmployeeData["remunerationItems"][number]);
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId,
    holidayAllowanceValue,
    // If one day they support hourly rates, we just have to update this (cf. bamboo)
    ...(customFte && { fte: customFte, ignoreFte: false }),
  };
};

export const stripSensitiveInformation = (credentials: SafeIntegrationSettings) => {
  const usedFields = compact([
    ...standardFields,
    credentials.externalIdCustomFieldName,
    credentials.fteCustomFieldName,
    credentials.levelCustomFieldName,
    credentials.variableCustomFieldName,
    credentials.holidayAllowanceCustomFieldName,
    credentials.businessUnitCustomFieldName,
    credentials.locationCustomFieldName,
    credentials.jobCustomFieldName,
    ...compact(credentials.additionalFieldMappings?.map(({ hrisFieldName }) => hrisFieldName)),
    ...compact(credentials.customRemunerationItemMappings?.map(({ hrisFieldName }) => hrisFieldName)),
  ]);

  return (employee: FlatWorkdayEmployee) => pick(employee, usedFields) as FlatWorkdayEmployee;
};

export const getWorkdayEmployees = async (credentials: SafeIntegrationSettings) => {
  const employees = await workdayFetch(credentials);

  return employees.map(stripSensitiveInformation(credentials)).map(anonymise(credentials));
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret", "clientId", "domain"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedWorkdayEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const workdayEmployees = await getWorkdayEmployees(safeIntegrationSettings);

  //filter employees that endDate is past
  const filteredWorkdayEmployees = workdayEmployees.filter((workdayEmployee) => {
    if (!workdayEmployee.endDate) return true;

    return isPast(parseISO(workdayEmployee.endDate));
  });

  return mapSeries(filteredWorkdayEmployees, (workdayEmployee) =>
    mapWorkdayEmployee(ctx, company, workdayEmployee, safeIntegrationSettings, staticModels)
  );
};

export const getWorkdayDiagnostic = async (
  ctx: AppContext,
  input: WorkdayIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  try {
    const [employee] = await workdayFetch(input);

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your Workday account",
        missingFields: [],
        availableFields: [],
      };
    }

    const availableFields = getKeys(employee)
      .filter((key) => !standardFields.includes(key as StandardFields))
      .map((field) => ({ id: field, name: field }));

    const missingFields = getMissingCustomFields(input, availableFields);

    return { connection: true, connectionError: "", missingFields, availableFields };
  } catch (error) {
    return { connection: false, connectionError: error.message, missingFields: [], availableFields: [] };
  }
};

export const getRawWorkdayEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<WorkdayEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getWorkdayEmployees(safeIntegrationSettings);
};
