import { EmployeeStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { match } from "ts-pattern";
import { mixed, object } from "yup";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { getCityCostOfLivingIndex, getCountryCostOfLivingIndex } from "~/lib/external/numbeo/client";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { chain, sumBy } from "~/lib/lodash";
import { logError, logInfo } from "~/lib/logger";
import { medianBy, weightedMeanBy } from "~/lib/math";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { getEuroCurrency } from "~/services/currency";
import { computeEmployeeCompensation } from "~/services/employee";
import type { EmployeeLocationWithCountry } from "~/services/employee/employeeLocation";
import type { EmployeeRow } from "~/services/employee/getLiveEmployees";

export const LocationIndex = {
  Numbeo: "numbeo",
  Figures: "figures",
} as const;
type LocationIndex = (typeof LocationIndex)[keyof typeof LocationIndex];

const UpdateLocationsStatsJobDataSchema = BaseJobDataSchema.concat(
  object({
    indexToUpdate: mixed<LocationIndex>().oneOf(Object.values(LocationIndex)).required(),
  })
);

export type UpdateLocationsStatsJobData = YupOutputType<typeof UpdateLocationsStatsJobDataSchema>;

export const updateLocationsStatsWorkerService = async (ctx: AppContext, data: UpdateLocationsStatsJobData) => {
  const { indexToUpdate } = UpdateLocationsStatsJobDataSchema.validateSync(data, {
    abortEarly: false,
  });

  try {
    await match(indexToUpdate)
      .with(LocationIndex.Numbeo, async () => updateNumbeoIndices(ctx))
      .with(LocationIndex.Figures, async () => updateFiguresIndices(ctx))
      .exhaustive();
  } catch (error) {
    logError(ctx, "[update-locations-stats] Failed to update stats for index", {
      error,
      indexToUpdate,
    });
  }
};

const updateNumbeoIndices = async (ctx: AppContext) => {
  const CountryAliases: { [key: string]: string } = {
    "United States of America": "United States",
  };

  const locations = await ctx.prisma.employeeLocation.findMany({
    where: { isRemote: false },
    include: { country: true },
  });

  const locationWithCostOfLiving = await mapSeries(locations, async (location) => {
    const countryName = CountryAliases[location.country.name] ?? location.country.name;

    const costOfLiving = await value(async () => {
      let result = await getCityCostOfLivingIndex(ctx, `${location.name}, ${countryName}`);
      if (result) {
        return result;
      }

      result = await getCityCostOfLivingIndex(ctx, location.name);
      if (result) {
        return result;
      }

      result = await getCountryCostOfLivingIndex(ctx, countryName);
      if (result) {
        return result;
      }
    });

    if (!costOfLiving) {
      logInfo(ctx, "[chore] Could not find cost of living index", {
        locationName: location.name,
        countryName: location.country.name,
      });
    }

    return { location, costOfLiving };
  });

  const paris = locationWithCostOfLiving.find(({ location }) => {
    return location.name === "Paris";
  }) as (typeof locationWithCostOfLiving)[0];

  await mapSeries(locationWithCostOfLiving, async ({ location, costOfLiving }) => {
    const refIndex = paris.costOfLiving?.value as number;

    if (!costOfLiving) {
      return;
    }

    const numbeoIndex = costOfLiving.value / refIndex;

    await ctx.prisma.employeeLocation.update({
      where: { id: location.id },
      data: {
        stats: {
          upsert: {
            create: { numbeoIndex },
            update: { numbeoIndex },
          },
        },
      },
    });

    logInfo(ctx, "[chore] Numbeo index set", {
      locationName: location.name,
      countryName: location.country.name,
      numbeoIndex,
    });
  });
};

const updateFiguresIndices = async (ctx: AppContext) => {
  const employees = await ctx.prisma.employee.findMany({
    where: { status: EmployeeStatus.LIVE },
    include: {
      currency: true,
      location: { include: { country: true } },
    },
  });

  const euro = await getEuroCurrency(ctx);

  const groups = chain(employees)
    .groupBy((employee) => {
      return [employee.locationId, employee.jobId, employee.level];
    })
    .mapValues((employees) => {
      const templateEmployee = employees[0] as EmployeeRow;

      const medianTotalCash = medianBy(employees, (employee) => {
        return computeEmployeeCompensation(employee, { measure: "totalCash", targetCurrency: euro });
      });

      return {
        location: templateEmployee.location,
        count: employees.length,
        medianTotalCash,
      };
    })
    .groupBy((group) => group.location.id)
    .mapValues((groups) => {
      const templateLocation = groups[0]?.location as EmployeeLocationWithCountry;

      const relevantGroups = groups.filter((group) => {
        return group.count >= 0;
      });

      const incumbentsCount = sumBy(groups, (group) => {
        return group.count;
      });

      const medianTotalCash = weightedMeanBy(relevantGroups, (group) => {
        return [group.medianTotalCash, group.count];
      });

      return {
        location: templateLocation,
        incumbentsCount,
        medianTotalCash,
      };
    })
    .values()
    .value();

  const referenceGroup = groups.find((group) => {
    return group.location.name === "Paris";
  }) as (typeof groups)[0];

  const normalisedGroups = groups.map((group) => {
    return {
      ...group,
      index: group.medianTotalCash ? group.medianTotalCash / referenceGroup.medianTotalCash : null,
    };
  });

  await mapSeries(normalisedGroups, async (group) => {
    if (!group.index) {
      return;
    }

    await ctx.prisma.employeeLocation.update({
      where: { id: group.location.id },
      data: {
        stats: {
          upsert: {
            create: { figuresIndex: group.index, figuresIncumbentsCount: group.incumbentsCount },
            update: { figuresIndex: group.index, figuresIncumbentsCount: group.incumbentsCount },
          },
        },
      },
    });

    logInfo(ctx, "[chore] Figures index set", {
      locationName: group.location.name,
      countryName: group.location.country.name,
      figuresIndex: group.index,
    });
  });
};

export const sendUpdateLocationsStatsJob = async (ctx: AppContext, data: UpdateLocationsStatsJobData) => {
  return sendJob(ctx, {
    jobName: QueueJobName.UPDATE_LOCATIONS_STATS,
    data,
    options: {
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.UPDATE_LOCATIONS_STATS },
        with: { indexToUpdate: data.indexToUpdate },
      }),
    },
  });
};
