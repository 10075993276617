import { QueueJobStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { CORRELATION_ID_KEY, logInfo } from "~/lib/logger";
import { type BaseJobData } from "~/lib/queue/baseJobDataSchema";
import { JobPriority, type SendOptions } from "~/lib/queue/drivers/queueDriver";
import { makeQueueDriver } from "~/lib/queue/makeQueueDriver";
import { type QueueJobName } from "~/lib/queue/queueJobName";
import { updateQueueJobStatus } from "~/lib/queue/updateQueueJobStatus";
import { makeQueueJobDataRedactorTransformer, transformObject } from "~/lib/transformObject";

const DEFAULT_JOB_OPTIONS: SendOptions = {
  retryLimit: 0,
  retryDelay: 0,
  retryBackoff: true,
  priority: JobPriority.MEDIUM,
} as const;

export const sendJob = async <T extends BaseJobData>(
  ctx: AppContext,
  params: {
    data: T;
    jobName: QueueJobName;
    options: SendOptions & { singletonKey: string };
  }
) => {
  const { data, jobName, options } = params;

  const defaultedOptions = { ...DEFAULT_JOB_OPTIONS, ...options };

  const dataToSend = {
    ...data,
    ...(ctx.metadata.has(CORRELATION_ID_KEY) && { correlationId: ctx.metadata.get(CORRELATION_ID_KEY) }),
  };

  try {
    const jobId = await makeQueueDriver().sendJob(ctx, jobName, dataToSend, defaultedOptions);

    if (jobId) {
      logInfo(ctx, "[worker] Job created", {
        jobId,
        jobName,
        data: transformObject(data, {
          transformers: [makeQueueJobDataRedactorTransformer("sendJob.ts")],
        }),
      });

      return updateQueueJobStatus(ctx, {
        name: jobName,
        singletonKey: options.singletonKey,
        status: QueueJobStatus.SCHEDULED,
        externalJobId: jobId,
        companyId: data.companyId,
      });
    }

    if (!options.singletonKey) {
      throw new BusinessLogicError("Could not create job").withMetadata({ jobName });
    }
  } catch (error) {
    throw new BusinessLogicError("Unexpected error while sending job").fromError(error).withMetadata({
      jobName,
      data: transformObject(data, {
        transformers: [makeQueueJobDataRedactorTransformer("sendJob.ts")],
      }),
    });
  }
};
