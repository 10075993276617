import { BusinessUnitsRestrictionMode, type ExternalEmployee, type Prisma, UserRole } from "@prisma/client";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { compact, uniq } from "~/lib/lodash";
import { dangerouslyIgnorePrismaRestrictions } from "~/lib/prismaTokens";
export const makeInSameBusinessUnitFilter = async (
  ctx: AppContext,
  params: { externalEmployeeManageeIds: number[] | null }
) => {
  const user = getRequiredUser(ctx);

  if (user.permissions.role !== UserRole.HR) {
    return null;
  }

  const companyPermissions = await ctx.prisma.companyPermissions.findUnique({
    where: { companyId: user.companyId },
    select: { businessUnitsRestrictionMode: true },
  });

  if (!companyPermissions) {
    return null;
  }

  return match(companyPermissions.businessUnitsRestrictionMode)
    .with(BusinessUnitsRestrictionMode.NONE, () => null)
    .with(BusinessUnitsRestrictionMode.CLASSIC, () => handleClassicBusinessUnitRestriction(ctx, params))
    .with(BusinessUnitsRestrictionMode.SPECIFIED, () => handleSpecifiedBusinessUnitRestriction(ctx, params))
    .exhaustive();
};

const handleClassicBusinessUnitRestriction = async (
  ctx: AppContext,
  params: {
    externalEmployeeManageeIds: number[] | null;
  }
) => {
  const user = getRequiredUser(ctx);

  const permissionsWithBusinessUnit = await ctx.prisma.userPermissions.findUnique({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: { id: user.permissionsId },
    select: {
      externalEmployee: {
        select: { businessUnit: true, extraBusinessUnits: true },
      },
    },
  });

  if (!user.permissions.isManager) {
    if (!permissionsWithBusinessUnit?.externalEmployee?.businessUnit) {
      // Since there is no businessUnit or externalEmployee, restrict access on id -1
      return { id: -1 } satisfies Prisma.ExternalEmployeeWhereInput;
    }

    const allowedBusinessUnits = getAllowedBusinessUnits({
      externalEmployee: permissionsWithBusinessUnit.externalEmployee,
    });

    return { businessUnit: { in: allowedBusinessUnits } } satisfies Prisma.ExternalEmployeeWhereInput;
  }

  if (!permissionsWithBusinessUnit?.externalEmployee?.businessUnit) {
    // Since there is no businessUnit or externalEmployee, restrict access on id -1
    return { id: { in: params.externalEmployeeManageeIds ?? [-1] } } satisfies Prisma.ExternalEmployeeWhereInput;
  }

  const allowedBusinessUnits = getAllowedBusinessUnits({
    externalEmployee: permissionsWithBusinessUnit.externalEmployee,
  });

  return {
    OR: compact([
      params.externalEmployeeManageeIds && { id: { in: params.externalEmployeeManageeIds } },
      { businessUnit: { in: allowedBusinessUnits } },
    ]),
  } satisfies Prisma.ExternalEmployeeWhereInput;
};

const handleSpecifiedBusinessUnitRestriction = async (
  ctx: AppContext,
  params: {
    externalEmployeeManageeIds: number[] | null;
  }
) => {
  const user = getRequiredUser(ctx);

  const permissionsWithBusinessUnit = await ctx.prisma.userPermissions.findUnique({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: { id: user.permissionsId },
    select: {
      externalEmployee: {
        select: { businessUnit: true, extraBusinessUnits: true },
      },
    },
  });

  const extraBusinessUnits = value(() => {
    if (!permissionsWithBusinessUnit?.externalEmployee?.extraBusinessUnits) {
      return null;
    }

    if (permissionsWithBusinessUnit.externalEmployee.extraBusinessUnits.length === 0) {
      return null;
    }

    return permissionsWithBusinessUnit.externalEmployee.extraBusinessUnits;
  });

  if (!user.permissions.isManager) {
    if (!extraBusinessUnits) {
      return { id: -1 } satisfies Prisma.ExternalEmployeeWhereInput;
    }

    return {
      businessUnit: { in: extraBusinessUnits },
    } satisfies Prisma.ExternalEmployeeWhereInput;
  }

  if (!extraBusinessUnits) {
    return { id: { in: params.externalEmployeeManageeIds ?? [-1] } } satisfies Prisma.ExternalEmployeeWhereInput;
  }

  return {
    OR: compact([
      params.externalEmployeeManageeIds && { id: { in: params.externalEmployeeManageeIds } },
      { businessUnit: { in: extraBusinessUnits } },
    ]),
  } satisfies Prisma.ExternalEmployeeWhereInput;
};

const getAllowedBusinessUnits = (params: {
  externalEmployee: Pick<ExternalEmployee, "businessUnit" | "extraBusinessUnits">;
}) => {
  return compact(uniq([params.externalEmployee.businessUnit, ...params.externalEmployee.extraBusinessUnits]));
};
