import { AuthenticationError } from "~/lib/errors/authenticationError";
import { done, type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { getFromSession, SessionKey } from "~/lib/session";

export const fetchUserIdFromSession = async (ctx: MiddlewareContext, options: { optional: boolean }) => {
  const userId = extractUserIdFromSession(ctx);

  if (userId) {
    return next({ userId });
  }

  if (!userId && !options.optional) {
    throw new AuthenticationError("Unauthorised access", {
      redirect: {
        targetUrl: ctx.resolvedUrl,
        error: "no_session",
      },
    }).withErrorCode("F136");
  }

  return done();
};

const extractUserIdFromSession = (ctx: MiddlewareContext) => {
  // In tests, the user is set directly in the request object
  if (process.env.IS_JEST) {
    if (!ctx.req.user) {
      return null;
    }

    return `${ctx.req.user.id}`;
  }

  return getFromSession(ctx.req, { key: SessionKey.USER_ID });
};
