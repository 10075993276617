import { ExternalEmployeeStatus, ExternalRemunerationType, type Gender, type Prisma } from "@prisma/client";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { logInfo, logWarn } from "~/lib/logger";
import { assertNotNil } from "~/lib/utils";
import { updateEmployeesStats } from "~/services/employee-stats/updateEmployeesStats";
import { createEmployee } from "~/services/employee/employeeCreate";
import { deleteEmployee } from "~/services/employee/employeeDelete";
import { sumRemunerationItems } from "~/services/employee/sumRemunerationItems";
import { mapToEmployee } from "~/services/external-employee/externalEmployeeMapping";
import {
  type ExternalEmployeeForMappedCheck,
  getMappedBenchmarkLevel,
  getMappedJobId,
  getMappedLevel,
  getMappedLocationId,
  type IndividualMapping,
  isValidMappedEmployee,
  isValidPartialEmployee,
  selectExternalEmployeeForMappedCheck,
} from "~/services/external-employee/statusCheckers";

type AutomaticallyAttainableStatus = Exclude<ExternalEmployeeStatus, "SKIPPED" | "NON_BENCHMARKABLE">;

export const fetchEmployeeForCheck = (ctx: AppContext, externalEmployeeId: number) =>
  ctx.prisma.externalEmployee.findUniqueOrThrow({
    where: { id: externalEmployeeId },
    select: {
      ...selectExternalEmployeeForMappedCheck,
      mappedEmployeeId: true,
    },
  });

const nextStatusMatrix: Record<
  AutomaticallyAttainableStatus | "NONE",
  Record<"incomplete" | "validPartial" | "validMapped", AutomaticallyAttainableStatus>
> = {
  NONE: {
    incomplete: ExternalEmployeeStatus.UNMAPPED,
    validPartial: ExternalEmployeeStatus.PARTIAL,
    validMapped: ExternalEmployeeStatus.MAPPED,
  },
  [ExternalEmployeeStatus.UNMAPPED]: {
    incomplete: ExternalEmployeeStatus.UNMAPPED,
    validPartial: ExternalEmployeeStatus.PARTIAL,
    validMapped: ExternalEmployeeStatus.MAPPED,
  },
  [ExternalEmployeeStatus.PARTIAL]: {
    incomplete: ExternalEmployeeStatus.UNMAPPED,
    validPartial: ExternalEmployeeStatus.PARTIAL,
    validMapped: ExternalEmployeeStatus.MAPPED,
  },
  [ExternalEmployeeStatus.MAPPED]: {
    incomplete: ExternalEmployeeStatus.NEEDS_REMAPPING,
    validPartial: ExternalEmployeeStatus.NEEDS_REMAPPING,
    validMapped: ExternalEmployeeStatus.MAPPED,
  },
  [ExternalEmployeeStatus.NEEDS_REMAPPING]: {
    incomplete: ExternalEmployeeStatus.NEEDS_REMAPPING,
    validPartial: ExternalEmployeeStatus.NEEDS_REMAPPING,
    validMapped: ExternalEmployeeStatus.MAPPED,
  },
};

export const getNextStatus = (
  initialStatus: AutomaticallyAttainableStatus | null,
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => {
  if (isValidMappedEmployee(externalEmployee, individualMapping)) {
    return nextStatusMatrix[initialStatus ?? "NONE"].validMapped;
  }

  if (isValidPartialEmployee(externalEmployee)) {
    return nextStatusMatrix[initialStatus ?? "NONE"].validPartial;
  }

  return nextStatusMatrix[initialStatus ?? "NONE"].incomplete;
};

export const selectExternalEmployeeForTransition = {
  ...selectExternalEmployeeForMappedCheck,
  source: true,
  companyId: true,
  currencyId: true,
  employeeNumber: true,
  externalId: true,
  firstName: true,
  lastName: true,
  isFounder: true,
  hireDate: true,
  birthDate: true,
  pictureId: true,
  job: {
    select: {
      mappedJobId: true,
      name: true,
    },
  },
  level: {
    select: {
      mappedLevel: true,
      mappedBenchmarkLevelId: true,
      name: true,
    },
  },
  location: {
    select: {
      mappedLocationId: true,
    },
  },
  currency: true,
  mappedEmployee: {
    select: {
      id: true,
      jobId: true,
      level: true,
      locationId: true,
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

type ExternalEmployeeForTransition = Prisma.ExternalEmployeeGetPayload<{
  select: typeof selectExternalEmployeeForTransition;
}>;

const transitionExternalEmployeeToMapped = async (
  ctx: AppContext,
  externalEmployee: ExternalEmployeeForTransition,
  individualMapping: IndividualMapping | null
) => {
  logInfo(ctx, "[ext-emp] Transitioning employee to MAPPED status", { externalEmployeeId: externalEmployee.id });

  await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployee.id },
    data: { status: ExternalEmployeeStatus.MAPPED, mappingSkipReason: null, driftFields: { set: [] } },
  });

  if (!externalEmployee.mappedEmployee) {
    logInfo(ctx, "[ext-emp] Creating new Employee for MAPPED external employee", {
      externalEmployeeId: externalEmployee.id,
    });

    const baseSalary = sumRemunerationItems(externalEmployee.remunerationItems, ExternalRemunerationType.FIXED_SALARY);
    const fixedBonus = sumRemunerationItems(externalEmployee.remunerationItems, ExternalRemunerationType.FIXED_BONUS);
    const onTargetBonus = sumRemunerationItems(
      externalEmployee.remunerationItems,
      ExternalRemunerationType.VARIABLE_BONUS
    );

    const jobId = assertNotNil(getMappedJobId(externalEmployee, individualMapping));
    const level = assertNotNil(getMappedLevel(externalEmployee, individualMapping));
    const benchmarkLevelId = assertNotNil(
      await getMappedBenchmarkLevel(ctx, { externalEmployee, individualMapping, level })
    );
    const locationId = assertNotNil(getMappedLocationId(externalEmployee, individualMapping));

    const { id: employeeId } = await createEmployee(
      ctx,
      {
        employeeNumber: externalEmployee.employeeNumber ?? externalEmployee.externalId,
        firstName: externalEmployee.firstName,
        lastName: externalEmployee.lastName,
        gender: externalEmployee.gender as Gender,
        isFounder: !!externalEmployee.isFounder,
        hireDate: externalEmployee.hireDate,
        birthDate: externalEmployee.birthDate,
        pictureId: externalEmployee.pictureId,

        baseSalary,
        fixedBonusPercentage: null,
        fixedBonus,
        onTargetBonusPercentage: null,
        onTargetBonus,

        externalJobTitle: externalEmployee.job?.name,
        externalLevel: externalEmployee.level?.name,

        level,
        benchmarkLevel: { id: benchmarkLevelId },
        job: { id: jobId },
        location: { id: locationId },
        currency: { id: externalEmployee.currencyId },
      },
      {
        companyId: externalEmployee.companyId,
        source: externalEmployee.source,
        externalId: externalEmployee.externalId,
        updateReason: "Auto Employee creation",
        shouldCreateExternalEmployee: false,
      }
    );

    await updateEmployeesStats(ctx, {
      companyId: externalEmployee.companyId,
      employeesIds: [employeeId],
    });

    await mapToEmployee(ctx, { externalEmployeeId: externalEmployee.id, employeeId });
  }

  logInfo(ctx, "[ext-emp] Transitioned employee to MAPPED status", { externalEmployeeId: externalEmployee.id });
};

const transitionExternalEmployeeToPartial = async (
  ctx: AppContext,
  externalEmployee: ExternalEmployeeForTransition
) => {
  logInfo(ctx, "[ext-emp] Transitioning employee to PARTIAL status", { externalEmployeeId: externalEmployee.id });

  await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployee.id },
    data: {
      status: ExternalEmployeeStatus.PARTIAL,
      mappedEmployee: { disconnect: true },
      mappingSkipReason: null,
      driftFields: { set: [] },
    },
  });

  if (!!externalEmployee.mappedEmployee) {
    logInfo(ctx, "[ext-emp] Deleting mapped Employee", {
      externalEmployeeId: externalEmployee.id,
      employeeId: externalEmployee.mappedEmployee.id,
    });
    await deleteEmployee(ctx, externalEmployee.mappedEmployee.id, {
      reason: "delete-from-dataset",
      shouldDisconnectExternalEmployee: false,
    });
  }

  logInfo(ctx, "[ext-emp] Transitioned employee to PARTIAL status", { externalEmployeeId: externalEmployee.id });
};

const transitionExternalEmployeeToUnmapped = async (
  ctx: AppContext,
  externalEmployee: ExternalEmployeeForTransition
) => {
  logInfo(ctx, "[ext-emp] Transitioning employee to UNMAPPED status", { externalEmployeeId: externalEmployee.id });

  await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployee.id },
    data: {
      status: ExternalEmployeeStatus.UNMAPPED,
      mappedEmployee: { disconnect: true },
      mappingSkipReason: null,
      driftFields: { set: [] },
    },
  });

  if (!!externalEmployee.mappedEmployee) {
    logInfo(ctx, "[ext-emp] Deleting mapped Employee", {
      externalEmployeeId: externalEmployee.id,
      employeeId: externalEmployee.mappedEmployee.id,
    });
    await deleteEmployee(ctx, externalEmployee.mappedEmployee.id, {
      reason: "delete-from-dataset",
      shouldDisconnectExternalEmployee: false,
    });
  }

  logInfo(ctx, "[ext-emp] Transitioned employee to UNMAPPED status", { externalEmployeeId: externalEmployee.id });
};

const transitionExternalEmployeeToNeedsRemapping = async (
  ctx: AppContext,
  externalEmployee: ExternalEmployeeForTransition
) => {
  logInfo(ctx, "[ext-emp] Transitioning employee to NEEDS_REMAPPING status", {
    externalEmployeeId: externalEmployee.id,
  });

  await ctx.prisma.externalEmployee.update({
    where: { id: externalEmployee.id },
    data: { status: ExternalEmployeeStatus.NEEDS_REMAPPING },
  });

  logInfo(ctx, "[ext-emp] Transitioned employee to NEEDS_REMAPPING status", {
    externalEmployeeId: externalEmployee.id,
  });
};

export const transitionExternalEmployeeStatus = async (
  ctx: AppContext,
  externalEmployeeId: number,
  prospectiveMapping?: IndividualMapping
) => {
  const externalEmployee = await ctx.prisma.externalEmployee.findUniqueOrThrow({
    where: { id: externalEmployeeId },
    select: selectExternalEmployeeForTransition,
  });

  const individualMapping = value(() => {
    if (externalEmployee.status === ExternalEmployeeStatus.NEEDS_REMAPPING) {
      return prospectiveMapping ?? null;
    }

    return (
      prospectiveMapping ?? {
        jobId: externalEmployee.mappedEmployee?.jobId,
        level: externalEmployee.mappedEmployee?.level,
        locationId: externalEmployee.mappedEmployee?.locationId,
      }
    );
  });

  logInfo(ctx, "[ext-emp] Considering employee for status transition", { externalEmployeeId: externalEmployee.id });

  if (
    externalEmployee.status === ExternalEmployeeStatus.SKIPPED ||
    externalEmployee.status === ExternalEmployeeStatus.NON_BENCHMARKABLE
  ) {
    logWarn(ctx, "[ext-emp] This method cannot operate on a SKIPPED or NON_BENCHMARKABLE employee");
    return;
  }

  const nextStatus = getNextStatus(externalEmployee.status, externalEmployee, individualMapping);

  if (nextStatus !== externalEmployee.status) {
    await match(nextStatus)
      .with(
        ExternalEmployeeStatus.UNMAPPED,
        async () => await transitionExternalEmployeeToUnmapped(ctx, externalEmployee)
      )
      .with(
        ExternalEmployeeStatus.PARTIAL,
        async () => await transitionExternalEmployeeToPartial(ctx, externalEmployee)
      )
      .with(
        ExternalEmployeeStatus.MAPPED,
        async () => await transitionExternalEmployeeToMapped(ctx, externalEmployee, individualMapping)
      )
      .with(
        ExternalEmployeeStatus.NEEDS_REMAPPING,
        async () => await transitionExternalEmployeeToNeedsRemapping(ctx, externalEmployee)
      )
      .exhaustive();
  }
};
