import { type Currency, type Prisma, SalaryGridMeasure, type SalaryGridTiersMode } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type TFunction } from "next-i18next";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chain, chunk, difference } from "~/lib/lodash";
import { logInfo } from "~/lib/logger";
import { transaction } from "~/lib/transaction";
import { computeExternalEmployeeCompensation } from "~/services/external-employee/computeCompensation";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { computeCompaRatio } from "~/services/salary-bands/benchmark/compaRatio";
import { computeRangePenetration } from "~/services/salary-bands/benchmark/rangePenetration";
import { computeSalaryBandTemplates } from "~/services/salary-bands/configuration/computeSalaryBandTemplates";
import { getSalaryGridMeasureDetail } from "~/services/salary-bands/helpers/formatSalaryGridMeasure";
import { getSalaryBandPositioning } from "~/services/salaryBandsPositioning";

const salaryRangeEmployeesSelectForUpdate = {
  id: true,
  baseSalaryCompaRatio: true,
  baseSalaryRangePenetration: true,
  baseSalaryRangePositioning: true,
  onTargetEarningsCompaRatio: true,
  onTargetEarningsRangePenetration: true,
  onTargetEarningsRangePositioning: true,
  orderingRangePenetration: true,
  orderingCompaRatio: true,
  orderingRangePositioning: true,
  externalEmployee: {
    select: {
      id: true,
      currency: true,
      remunerationItems: {
        select: {
          amount: true,
          nature: { select: { mappedType: true } },
        },
      },
      mappedEmployee: {
        select: {
          id: true,
          baseSalary: true,
          fixedBonus: true,
          onTargetBonus: true,
          currency: true,
        },
      },
    },
  },
  range: {
    select: {
      band: { select: { id: true, measure: true, currency: true } },
      min: true,
      max: true,
      midpoint: true,
    },
  },
} satisfies Prisma.SalaryRangeEmployeeSelect;

type SalaryRangeEmployeeSelectForUpdate = Prisma.SalaryRangeEmployeeGetPayload<{
  select: typeof salaryRangeEmployeesSelectForUpdate;
}>;

const getSalaryRangeEmployeeUpdateData = (
  t: TFunction,
  params: {
    salaryRangeEmployee: SalaryRangeEmployeeSelectForUpdate;
    currency: Currency;
    tiersMode: SalaryGridTiersMode;
  }
) => {
  const {
    externalEmployee,
    id: salaryRangeEmployeeId,
    range,
    baseSalaryCompaRatio: initialBaseSalaryCompaRatio,
    baseSalaryRangePenetration: initialBaseSalaryRangePenetration,
    baseSalaryRangePositioning: initialBaseSalaryRangePositioning,
    onTargetEarningsCompaRatio: initialOnTargetEarningsCompaRatio,
    onTargetEarningsRangePenetration: initialOnTargetEarningsRangePenetration,
    onTargetEarningsRangePositioning: initialOnTargetEarningsRangePositioning,
    orderingRangePenetration: initialOrderingRangePenetration,
    orderingCompaRatio: initialOrderingCompaRatio,
    orderingRangePositioning: initialOrderingRangePositioning,
  } = params.salaryRangeEmployee;

  const { compensation: baseSalary } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: getSalaryGridMeasureDetail(t, SalaryGridMeasure.BASE_SALARY, "marketDataMeasure"),
    targetCurrency: params.currency,
  });

  if (!externalEmployee.mappedEmployee && !baseSalary) {
    return null;
  }

  const { compensation: onTargetEarnings } = computeExternalEmployeeCompensation(externalEmployee, {
    measure: getSalaryGridMeasureDetail(t, SalaryGridMeasure.ON_TARGET_EARNINGS, "marketDataMeasure"),
    targetCurrency: params.currency,
  });

  const baseSalaryCompaRatio = computeCompaRatio({
    amount: baseSalary,
    midpoint: params.salaryRangeEmployee.range.midpoint,
  });

  const baseSalaryRangePenetration = computeRangePenetration({
    amount: baseSalary,
    min: range.min,
    max: range.max,
  });

  const baseSalaryRangePositioning =
    baseSalaryRangePenetration !== null
      ? getSalaryBandPositioning({
          rangePenetration: baseSalaryRangePenetration,
          tiersMode: params.tiersMode,
        }).type
      : null;

  const onTargetEarningsCompaRatio = computeCompaRatio({
    amount: onTargetEarnings,
    midpoint: params.salaryRangeEmployee.range.midpoint,
  });

  const onTargetEarningsRangePenetration = computeRangePenetration({
    amount: onTargetEarnings,
    min: range.min,
    max: range.max,
  });

  const onTargetEarningsRangePositioning =
    onTargetEarningsRangePenetration !== null
      ? getSalaryBandPositioning({
          rangePenetration: onTargetEarningsRangePenetration,
          tiersMode: params.tiersMode,
        }).type
      : null;

  const orderingRangePenetration = match(range.band.measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => baseSalaryRangePenetration)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => onTargetEarningsRangePenetration)
    .exhaustive();

  const orderingCompaRatio = match(range.band.measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => baseSalaryCompaRatio)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => onTargetEarningsCompaRatio)
    .exhaustive();

  const orderingRangePositioning = match(range.band.measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => baseSalaryRangePositioning)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => onTargetEarningsRangePositioning)
    .exhaustive();

  const nothingToUpdate =
    (baseSalaryCompaRatio ?? 0) === (initialBaseSalaryCompaRatio ?? 0) &&
    (baseSalaryRangePenetration ?? 0) === (initialBaseSalaryRangePenetration ?? 0) &&
    baseSalaryRangePositioning === initialBaseSalaryRangePositioning &&
    (onTargetEarningsCompaRatio ?? 0) === (initialOnTargetEarningsCompaRatio ?? 0) &&
    (onTargetEarningsRangePenetration ?? 0) === (initialOnTargetEarningsRangePenetration ?? 0) &&
    onTargetEarningsRangePositioning === initialOnTargetEarningsRangePositioning &&
    (orderingRangePenetration ?? 0) === (initialOrderingRangePenetration ?? 0) &&
    (orderingCompaRatio ?? 0) === (initialOrderingCompaRatio ?? 0) &&
    orderingRangePositioning === initialOrderingRangePositioning;

  if (nothingToUpdate) {
    return null;
  }

  return {
    salaryRangeEmployeeId,
    measure: range.band.measure,
    baseSalaryCompaRatio,
    baseSalaryRangePenetration,
    baseSalaryRangePositioning,
    onTargetEarningsCompaRatio,
    onTargetEarningsRangePenetration,
    onTargetEarningsRangePositioning,
    orderingRangePenetration,
    orderingCompaRatio,
    orderingRangePositioning,
  };
};

export const synchronizeSalaryRangeEmployees = async (ctx: AppContext, params: { salaryGridId: number }) => {
  const user = getRequiredUser(ctx);

  const salaryGrid = await ctx.prisma.salaryGrid.findUniqueOrThrow({
    where: { id: params.salaryGridId },
    select: { tiersMode: true },
  });

  const drifts = await computeSalaryRangeEmployeeDrifts(ctx, params);

  const isDefaultSalaryGrid = user.company.defaultSalaryGridId === params.salaryGridId;

  if (drifts.salaryRangeEmployeesToDelete.length) {
    const externalEmployeeIds = drifts.salaryRangeEmployeesToDelete.map(
      (salaryRangeEmployee) => salaryRangeEmployee.externalEmployeeId
    );

    logInfo(ctx, "[salary-bands] Removing external employees from salary bands", { externalEmployeeIds });

    await ctx.prisma.salaryRangeEmployee.deleteMany({
      where: {
        range: { band: { grid: whereSalaryGridIs(params) } },
        externalEmployeeId: { in: externalEmployeeIds },
      },
    });
  }

  if (drifts.salaryRangeEmployeesToCreate.length) {
    logInfo(ctx, "[salary-bands] Adding external employees to salary ranges", {
      salaryRangeEmployees: drifts.salaryRangeEmployeesToCreate,
    });

    await mapSeries(drifts.salaryRangeEmployeesToCreate, async ({ rangeId, externalEmployeeId }) => {
      await transaction(ctx, async (ctx) => {
        const salaryRangeEmployee = await ctx.prisma.salaryRangeEmployee.create({
          data: { gridId: params.salaryGridId, rangeId, externalEmployeeId },
          select: { id: true },
        });

        if (isDefaultSalaryGrid) {
          await ctx.prisma.externalEmployee.update({
            where: { id: externalEmployeeId },
            data: { liveSalaryRangeEmployeeId: salaryRangeEmployee.id },
          });
        }
      });
    });
  }

  const salaryRangeEmployeesToUpdate = await ctx.prisma.salaryRangeEmployee.findMany({
    where: { range: { band: { grid: whereSalaryGridIs(params) } } },
    select: salaryRangeEmployeesSelectForUpdate,
  });

  const salaryRangeEmployeesToUpdateByChunks = chunk(salaryRangeEmployeesToUpdate, 50);

  await mapSeries(salaryRangeEmployeesToUpdateByChunks, async (salaryRangeEmployeesToUpdateChunk) => {
    const updateData = chain(salaryRangeEmployeesToUpdateChunk)
      .map((salaryRangeEmployee) =>
        getSalaryRangeEmployeeUpdateData(ctx.t_en, {
          salaryRangeEmployee,
          currency: salaryRangeEmployee.range.band.currency,
          tiersMode: salaryGrid.tiersMode,
        })
      )
      .compact()
      .value();

    logInfo(ctx, "[salary-bands] Updating salary range employees", {
      salaryRangeEmployeesCount: updateData.length,
    });

    await mapSeries(updateData, async (update) => {
      await ctx.prisma.salaryRangeEmployee.update({
        where: { id: update.salaryRangeEmployeeId },
        data: {
          baseSalaryCompaRatio: update.baseSalaryCompaRatio,
          baseSalaryRangePenetration: update.baseSalaryRangePenetration,
          baseSalaryRangePositioning: update.baseSalaryRangePositioning,
          onTargetEarningsCompaRatio: update.onTargetEarningsCompaRatio,
          onTargetEarningsRangePenetration: update.onTargetEarningsRangePenetration,
          onTargetEarningsRangePositioning: update.onTargetEarningsRangePositioning,
          orderingRangePenetration: update.orderingRangePenetration,
          orderingCompaRatio: update.orderingCompaRatio,
          orderingRangePositioning: update.orderingRangePositioning,
        },
      });
    });
  });
};

export const computeSalaryRangeEmployeeDrifts = async (ctx: AppContext, params: { salaryGridId: number }) => {
  const salaryRangeTemplates = await getSalaryRangeTemplates(ctx, params);
  const salaryRangeEmployees = await fetchSalaryRangeEmployees(ctx, params);

  const salaryRangeEmployeesToCreate: { rangeId: number; externalEmployeeId: number }[] = [];
  const salaryRangeEmployeesToDelete: { externalEmployeeId: number }[] = [];

  /* Identify employees that are connected to band but should not. */
  await mapSeries(salaryRangeEmployees, async (salaryRangeEmployee) => {
    const targetRange = salaryRangeTemplates.find(
      (template) =>
        template.salaryBandJob.id === salaryRangeEmployee.range.band.jobId &&
        template.salaryBandLocation.id === salaryRangeEmployee.range.band.locationId &&
        template.salaryBandLevel.id === salaryRangeEmployee.range.levelId
    );

    if (
      !targetRange ||
      (!salaryRangeEmployee.overridden &&
        !targetRange.externalEmployees.find(({ id }) => id === salaryRangeEmployee.externalEmployeeId))
    ) {
      salaryRangeEmployeesToDelete.push(salaryRangeEmployee);
    }
  });

  /* Identify employees should be in band, but are not. */
  await mapSeries(salaryRangeTemplates, async (template) => {
    const externalEmployeeIdsInRange = salaryRangeEmployees
      .filter(
        ({ range, overridden }) =>
          (range.levelId === template.salaryBandLevel.id &&
            range.band.jobId === template.salaryBandJob.id &&
            range.band.locationId === template.salaryBandLocation.id) ||
          overridden
      )
      .map(({ externalEmployeeId }) => externalEmployeeId);

    const externalEmployeeIdsThatShouldBeInRange = template.externalEmployees.map(({ id }) => id);

    const missingExternalEmployeeIds = difference(externalEmployeeIdsThatShouldBeInRange, externalEmployeeIdsInRange);
    if (missingExternalEmployeeIds.length) {
      const salaryRange = await ctx.prisma.salaryRange.findFirst({
        where: {
          band: {
            grid: whereSalaryGridIs(params),
            jobId: template.salaryBandJob.id,
            locationId: template.salaryBandLocation.id,
          },
          levelId: template.salaryBandLevel.id,
        },
      });

      if (salaryRange) {
        salaryRangeEmployeesToCreate.push(
          ...missingExternalEmployeeIds.map((externalEmployeeId) => ({
            rangeId: salaryRange.id,
            externalEmployeeId,
          }))
        );
      }
    }
  });

  return {
    salaryRangeEmployeesToCreate,
    salaryRangeEmployeesToDelete,
    externalEmployeeIds: chain([...salaryRangeEmployeesToCreate, ...salaryRangeEmployeesToDelete])
      .map(({ externalEmployeeId }) => externalEmployeeId)
      .uniq()
      .value(),
  };
};

const getSalaryRangeTemplates = async (ctx: AppContext, params: { salaryGridId: number }) => {
  const bandTemplates = await computeSalaryBandTemplates(ctx, params);

  return bandTemplates.flatMap(({ salaryBandJob, salaryBandLocation, salaryBandLevels }) =>
    salaryBandLevels.map(({ externalEmployees, ...salaryBandLevel }) => ({
      salaryBandJob,
      salaryBandLocation,
      salaryBandLevel,
      externalEmployees,
    }))
  );
};

const fetchSalaryRangeEmployees = async (ctx: AppContext, params: { salaryGridId: number }) => {
  return ctx.prisma.salaryRangeEmployee.findMany({
    where: { grid: whereSalaryGridIs(params) },
    select: {
      externalEmployeeId: true,
      overridden: true,
      range: {
        select: {
          levelId: true,
          band: { select: { jobId: true, locationId: true } },
        },
      },
    },
  });
};
