import { type Prisma } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { and } from "~/lib/prismaHelpers";
import { type FilterOptionsInput } from "~/services/employee-filter";
import { buildExternalEmployeeFilterWhereClauses } from "~/services/employee-filter/fetchExternalEmployeeFilterOptions";
import { searchExternalEmployee } from "~/services/users/search";

export const fetchAllExternalEmployeesToUpdate = async (
  ctx: AppContext,
  input: {
    externalEmployeeIds?: number[] | null;
    filters: FilterOptionsInput | null;
    query: string | null;
    extraWhere?: Prisma.ExternalEmployeeWhereInput;
    select?: Prisma.ExternalEmployeeSelect;
  }
) => {
  const user = getRequiredUser(ctx);

  const select =
    input.select ??
    ({
      id: true,
      mappedEmployeeId: true,
    } satisfies Prisma.ExternalEmployeeSelect);

  if (input.filters || input.query) {
    const baseWhere = and([input.extraWhere, { companyId: user.companyId }]);
    const whereFilters = input.filters ? await buildExternalEmployeeFilterWhereClauses(ctx, input.filters) : undefined;
    const whereSearch = input.query ? searchExternalEmployee(input.query) : null;

    const where = and([baseWhere, whereFilters, whereSearch]);

    return ctx.prisma.externalEmployee.findMany({
      where,
      select,
    });
  }

  if (input.externalEmployeeIds) {
    const where = and([input.extraWhere, { companyId: user.companyId, id: { in: input.externalEmployeeIds } }]);
    const externalEmployees = await ctx.prisma.externalEmployee.findMany({
      where,
      select,
    });

    return externalEmployees;
  }

  throw new BusinessLogicError("Invalid input. Either pass 'externalEmployeeIds' or 'filters' and 'query'.");
};
