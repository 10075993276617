import { UserLocale } from "@prisma/client";
import computeMedian from "compute-median";
import ordinal from "ordinal";
import weightedMean from "weighted-mean";
import { chain, sortBy, sumBy } from "~/lib/lodash";
import { arrayHasValues, assertNotNil } from "~/lib/utils";

export const formatPercent = (value: number, decimals = 0, signed = false): string => {
  const sign = value > 0 && signed ? "+" : "";

  return `${sign}${(value * 100).toFixed(decimals)}%`;
};

export const formatPercentile = (value: number, locale: UserLocale, decimals = 0): string => {
  const percentile = +(value * 100).toFixed(decimals);

  if (locale === UserLocale.FR) {
    const affix = percentile === 1 ? "er" : "ème";

    return `${percentile}${affix}`;
  }

  return ordinal(percentile);
};

export const serializePercent = (value: number): number => {
  return value / 100;
};

export const roundTo = (value: number, multiple: number): number => {
  return Math.round(value / multiple) * multiple;
};

export const percentageDifferenceToTarget = (amount: number, target: number) => {
  return amount / target - 1;
};

export const medianBy = <T>(values: T[], iteratee: (item: T) => number): number => {
  const mapped = values.map(iteratee);

  return computeMedian(mapped);
};

export const weightedMeanBy = <T>(items: T[], iteratee: (item: T) => [number | null, number]): number => {
  return chain(items)
    .map((item) => {
      return iteratee(item) as [number | null, number];
    })
    .filter((item): item is [number, number] => {
      return item[0] !== null;
    })
    .thru((items) => {
      return items.length ? weightedMean(items) : null;
    })
    .value();
};

export const ratioBy = <T>(items: T[], iteratee: (item: T) => boolean): number => {
  if (!items.length) {
    return 0;
  }

  const filteredCount = items.filter(iteratee).length;

  return filteredCount / items.length;
};

export const addNoise = (value: number, percentage: number) =>
  value * (1 + Math.random() * percentage - percentage / 2);

type WeightedValue = {
  value: number;
  weight: number;
};

export const computeWeightedMedian = (weightedValues: WeightedValue[]): number | null => {
  if (!weightedValues.length) {
    return null; // Return null if the array is empty
  }

  const sortedValues = sortBy(weightedValues, (weightedValue) => weightedValue.value);
  const totalWeight = sumBy(sortedValues, (weightedValue) => weightedValue.weight);
  const halfWeight = totalWeight / 2;

  let cumulativeWeight = 0;

  for (let i = 0; i < sortedValues.length; i++) {
    const { value, weight } = assertNotNil(sortedValues[i]);
    cumulativeWeight += weight;

    if (cumulativeWeight > halfWeight) {
      const prevCumulativeWeight = cumulativeWeight - weight;
      if (prevCumulativeWeight < halfWeight && !Number.isInteger(halfWeight)) {
        const prevValue = assertNotNil(sortedValues[i - 1]).value;
        return (prevValue + value) / 2;
      }
      return value;
    }

    if (cumulativeWeight === halfWeight) {
      return value;
    }
  }
  return null;
};

export const computeWeightedPercentiles = (
  percentiles: number[],
  weightedValues: WeightedValue[]
): (number | null)[] => {
  if (!arrayHasValues(weightedValues)) {
    return percentiles.map(() => null);
  }

  const sortedValues = sortBy(weightedValues, "value");
  const totalWeight = sumBy(sortedValues, "weight");

  return percentiles.map((percentile) => {
    const targetWeight = (percentile / 100) * totalWeight;
    let cumulativeWeight = 0;

    for (let i = 0; i < sortedValues.length; i++) {
      const { value, weight } = assertNotNil(sortedValues[i]);
      cumulativeWeight += weight;

      if (cumulativeWeight > targetWeight) {
        const prevCumulativeWeight = cumulativeWeight - weight;
        if (prevCumulativeWeight < targetWeight) {
          if (i === 0) {
            return value;
          }

          const prevValue = assertNotNil(sortedValues[i - 1]).value;
          const fraction = (targetWeight - prevCumulativeWeight) / weight;
          return prevValue + fraction * (value - prevValue);
        }
        return value;
      }

      if (cumulativeWeight === targetWeight) {
        return value;
      }
    }

    return null;
  });
};
