import { createHmac } from "crypto";
import { type NextApiRequest } from "next";
import { KomboWebhookError } from "~/lib/errors/komboWebhookError";
import { done, type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { type AuthenticationOptions } from "~/lib/session";

/**
 * Protects kombo webhook routes from unauthorised access.
 *
 * To use this middleware locally you MUST have the following environment variables set:
 * QUEUE_DRIVER=pg-boss
 *
 * More info: https://www.notion.so/figures-hr/Testing-Kombo-webhook-locally-19d50b3c4777807b89f8da8bb53d7ac6
 */

export const protectKomboWebhookRoutes = async (
  ctx: MiddlewareContext,
  options: { kombo?: AuthenticationOptions["kombo"] }
) => {
  if (!options.kombo) {
    return next();
  }

  const signatureHeader = ctx.req.headers["x-kombo-signature"];
  const secret = process.env["KOMBO_WEBHOOK_SECRET"];

  if (!secret) {
    throw new KomboWebhookError("Unauthorised access").withErrorCode("F137_0");
  }

  const body = JSON.stringify((ctx.req as NextApiRequest).body, null, 2);

  const signature = createHmac("sha256", secret).update(body, "utf8").digest("base64url");

  if (signatureHeader === signature) {
    return done(); // Using done() with AsyncDriver can cause webhook execution issues
  }

  throw new KomboWebhookError("Unauthorised access").withErrorCode("F137_1");
};
