import { GetSecretValueCommand, SecretsManagerClient } from "@aws-sdk/client-secrets-manager";
import { createCipheriv, createDecipheriv, createHash, createHmac, randomBytes } from "crypto";
import { addMinutes, isBefore } from "date-fns";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { isString } from "~/lib/lodash";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

const IV_LENGTH = 16;
const ENCRYPTION_KEY_LENGTH = 32;

const STORAGE_KEY = createHash("sha256")
  .update(String(config.app.password))
  .digest("base64")
  .slice(0, ENCRYPTION_KEY_LENGTH);

const CLIENT_SECRET_ENCRYPTION_KEY_CACHE_TTL_IN_MINS = 15;
const CLIENT_SECRET_ENCRYPTION_KEY_CACHE = new Map<
  string | undefined,
  ClientSecretEncryptionKeyPayload & { expiresAt: Date }
>();

type ClientSecretEncryptionKeyPayload = {
  encryptionKey: string;
  encryptionKeyVersionId: string;
};

export const encryptForStorage = (clearMessage: string) => {
  return encrypt(clearMessage, { encryptionKey: STORAGE_KEY });
};

export const decryptFromStorage = (message: string) => {
  return decrypt(message, { encryptionKey: STORAGE_KEY });
};

export const encryptClientSecret = async (ctx: AppContext, params: { secret: string }) => {
  const { encryptionKey, encryptionKeyVersionId } = await getClientSecretEncryptionKey(ctx);
  const encryptedSecret = encrypt(params.secret, { encryptionKey });
  return { encryptedSecret, encryptionKeyVersionId };
};

export const decryptClientSecret = async (
  ctx: AppContext,
  params: { secret: string } & GetClientSecretEncryptionKeyParams
) => {
  const { encryptionKey } = await getClientSecretEncryptionKey(ctx, params);
  return decrypt(params.secret, { encryptionKey });
};

export const generateUserHMAC = (user: NullableAuthenticatedUser) => {
  if (!user) {
    return null;
  }

  return createHmac("SHA256", config.intercom.secretKey).update(user.id.toString()).digest("hex");
};

const encrypt = (message: string, params: { encryptionKey: string }) => {
  const iv = randomBytes(IV_LENGTH);
  const cipher = createCipheriv("aes-256-gcm", params.encryptionKey, iv);

  const encryptedMessage = cipher.update(message, "utf8", "hex") + cipher.final("hex");

  const payload = JSON.stringify({
    iv: iv.toString("hex"),
    authTag: cipher.getAuthTag().toString("hex"),
    encryptedMessage,
  });

  return Buffer.from(payload).toString("hex");
};

const decrypt = (message: string, params: { encryptionKey: string }) => {
  const json = Buffer.from(message, "hex").toString();
  const { iv, authTag, encryptedMessage } = JSON.parse(json) as {
    iv: string;
    authTag: string;
    encryptedMessage: string;
  };

  const decipher = createDecipheriv("aes-256-gcm", params.encryptionKey, Buffer.from(iv, "hex"));
  decipher.setAuthTag(Buffer.from(authTag, "hex"));

  return decipher.update(encryptedMessage, "hex", "utf-8") + decipher.final();
};

export const AES_256_ENCRYPTION_KEY_NAME = "AES256Key";

const client = new SecretsManagerClient({ region: config.secretsManager.region });

type GetClientSecretEncryptionKeyParams = {
  encryptionKeyVersionId: string;
  _secretIdOverride_FOR_CLI_ONLY_?: string;
};

export const getClientSecretEncryptionKey = async (ctx: AppContext, params?: GetClientSecretEncryptionKeyParams) => {
  if (isString(params?._secretIdOverride_FOR_CLI_ONLY_) && !config.app.isCli) {
    throw new BusinessLogicError("Cannot override secret ID outside of a CLI");
  }

  const cachedKey = getClientSecretEncryptionKeyFromCache(params);

  if (cachedKey) {
    return cachedKey;
  }

  const command = new GetSecretValueCommand({
    SecretId: params?._secretIdOverride_FOR_CLI_ONLY_ ?? config.secretsManager.secretId,
    VersionId: params?.encryptionKeyVersionId,
  });

  const response = await client.send(command);

  if (!response.SecretString) {
    throw new BusinessLogicError("Failed to fetch client secret encryption key")
      .withErrorCode("F100_0")
      .withMetadata({ command, response });
  }

  if (!response.VersionId) {
    throw new BusinessLogicError("Failed to fetch client secret encryption key version id")
      .withErrorCode("F100_1")
      .withMetadata({ command, response });
  }

  try {
    const payload = {
      encryptionKey: JSON.parse(response.SecretString)[AES_256_ENCRYPTION_KEY_NAME],
      encryptionKeyVersionId: response.VersionId,
    } as const as ClientSecretEncryptionKeyPayload;

    saveClientSecretEncryptionKeyToCache(payload, params);

    return payload;
  } catch (baseError) {
    throw new BusinessLogicError("Failed to parse client secret encryption key")
      .fromError(baseError)
      .withErrorCode("F100_2")
      .withMetadata({ command, response });
  }
};

const getClientSecretEncryptionKeyFromCache = (params?: { encryptionKeyVersionId: string }) => {
  const cachedKey = CLIENT_SECRET_ENCRYPTION_KEY_CACHE.get(params?.encryptionKeyVersionId);

  if (!cachedKey) {
    return null;
  }

  if (isBefore(cachedKey.expiresAt, new Date())) {
    CLIENT_SECRET_ENCRYPTION_KEY_CACHE.delete(params?.encryptionKeyVersionId);
    return null;
  }

  return {
    encryptionKey: cachedKey.encryptionKey,
    encryptionKeyVersionId: cachedKey.encryptionKeyVersionId,
  } as const as ClientSecretEncryptionKeyPayload;
};

const saveClientSecretEncryptionKeyToCache = (
  payload: ClientSecretEncryptionKeyPayload,
  params?: { encryptionKeyVersionId: string }
) => {
  const expiresAt = addMinutes(new Date(), CLIENT_SECRET_ENCRYPTION_KEY_CACHE_TTL_IN_MINS);

  CLIENT_SECRET_ENCRYPTION_KEY_CACHE.set(payload.encryptionKeyVersionId, {
    ...payload,
    expiresAt,
  });

  CLIENT_SECRET_ENCRYPTION_KEY_CACHE.set(params?.encryptionKeyVersionId, {
    ...payload,
    expiresAt,
  });
};
