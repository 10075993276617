export const makeSamlRedirectionUrl = (params: { email: string; redirect?: string; invitationToken?: string }) => {
  return {
    pathname: "/api/auth/saml/redirect",
    query: {
      email: params.email,
      redirect: params.redirect ?? "/",
      invitationToken: params.invitationToken,
    },
  } as const;
};
