/* eslint-disable-next-line no-restricted-imports */
import { IconButton as MuiIconButton, type ButtonProps } from "@mui/material";
import { forwardRef, useState, type ComponentProps } from "react";
import { ConfirmationModal } from "~/components/ui/core/ConfirmationModal";
import { TitledTooltip } from "~/components/ui/core/TitledTooltip";

type IconButtonType = <C extends React.ElementType>(
  props: ButtonProps<
    C,
    {
      component?: C;
      isLoading?: boolean;
      confirmation?: Omit<ComponentProps<typeof ConfirmationModal>, "isOpen" | "onClose" | "onConfirm">;
      tooltip?: Omit<ComponentProps<typeof TitledTooltip>, "children">;
    }
  >,
  ref?: React.Ref<HTMLButtonElement>
) => React.ReactElement;

type OnClickEvent = Parameters<NonNullable<ComponentProps<"button">["onClick"]>>[0];

const CastedIconButton: IconButtonType = (props, ref) => {
  const { children, isLoading, disabled, onClick, confirmation, tooltip, ...rest } = props;
  const [confirmationEvent, setConfirmationEvent] = useState<OnClickEvent | null>(null);

  const iconButton = (
    <MuiIconButton
      ref={ref}
      disabled={disabled || isLoading}
      onClick={(event) => {
        if (confirmation) {
          setConfirmationEvent(event);
        } else {
          onClick?.(event);
        }
      }}
      {...rest}
    >
      {children}
      {confirmation && (
        <ConfirmationModal
          {...confirmation}
          isOpen={!!confirmationEvent}
          onClose={() => setConfirmationEvent(null)}
          onConfirm={() => {
            setConfirmationEvent(null);
            setTimeout(() => {
              onClick?.(confirmationEvent);
            }, 100);
          }}
        />
      )}
    </MuiIconButton>
  );

  return tooltip && !disabled ? <TitledTooltip {...tooltip}>{iconButton}</TitledTooltip> : iconButton;
};

export const IconButton = forwardRef(CastedIconButton) as IconButtonType;
