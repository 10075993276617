import { HrisSyncStatus, QueueJobStatus } from "@prisma/client";
import { map, mapSeries } from "bluebird";
import { number, object } from "yup";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { decryptManyIntegrationSettings } from "~/lib/decryptIntegrationSettings";
import { notifyHrisSyncFailedDueToError } from "~/lib/external/slack/notifications";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { logError, logInfo } from "~/lib/logger";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { fillJobCache } from "~/lib/queue/queueJobCache";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { cancelJobQueueJob } from "~/services/admin/cancelJobQueueJob";
import { fetchExternalEmployeesFromHRIS } from "~/services/synchronization/fetchExternalEmployeesFromHris";
import { updateIntegrationCounters } from "~/services/synchronization/postSyncExternalEmployees";
import {
  EmployeeDataSchema,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/syncExternalEmployees";
import { PostSyncExternalEmployeesDeletesReportDataSchema } from "~/workers/postSyncExternalEmployees";
import { sendSyncExternalEmployeesJob } from "~/workers/syncExternalEmployees";

const PreSyncExternalEmployeesJobDataSchemaWithCampaign = BaseJobDataSchema.concat(
  object({ hrisSyncCampaignId: number().nullable() })
);
const PreSyncExternalEmployeesJobDataSchemaWithSync = BaseJobDataSchema.concat(
  object({ hrisSyncId: number().required() })
);

export type PreSyncExternalEmployeesJobDataWithCampaign = YupOutputType<
  typeof PreSyncExternalEmployeesJobDataSchemaWithCampaign
>;
export type PreSyncExternalEmployeesJobDataWithSync = YupOutputType<
  typeof PreSyncExternalEmployeesJobDataSchemaWithSync
>;

export const preSyncExternalEmployeesWorkerService = async (
  ctx: AppContext,
  data: PreSyncExternalEmployeesJobDataWithSync
) => {
  const { companyId, hrisSyncId } = PreSyncExternalEmployeesJobDataSchemaWithSync.validateSync(data, {
    abortEarly: false,
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: { defaultCountry: true },
  });

  const integrationSettings = await value(async () => {
    const settings = await ctx.prisma.integrationSettings.findMany({
      where: { companyId, enabled: true },
      include: integrationSettingsForSyncInclude,
    });

    return decryptManyIntegrationSettings(ctx, settings);
  });

  const hrisSync = await ctx.prisma.hrisSync.findUniqueOrThrow({
    where: { id: hrisSyncId },
    include: { campaign: true },
  });

  logInfo(ctx, "[pre-sync-external-employees] Starting HRIS Sync", {
    companyId: company.id,
    hrisSyncId: hrisSync.id,
    activeIntegrations: integrationSettings.map((integration) => integration.source),
    ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
  });

  try {
    let totalEmployeeCount = 0;

    await map(
      integrationSettings,
      async (companyIntegrationSettings) => {
        const { deleted, mappedEmployees } = await fetchExternalEmployeesFromHRIS(
          ctx,
          { ...company, integrationSettings },
          companyIntegrationSettings
        );

        logInfo(ctx, "[pre-sync-external-employees] Fetched external employees", {
          deleted,
          companyId: company.id,
          hrisSyncId: hrisSync.id,
          source: companyIntegrationSettings.source,
          employees: mappedEmployees.length,
          ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
        });

        if (mappedEmployees.length === 0) {
          logInfo(ctx, "[pre-sync-external-employees] No external employees to fetch", {
            companyId: company.id,
            hrisSyncId: hrisSync.id,
            source: companyIntegrationSettings.source,
            ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
          });

          await updateIntegrationCounters(ctx, {
            company,
            integrationSettings,
            deletes: [{ source: companyIntegrationSettings.source, deleted }],
            updates: [],
          });

          return;
        }

        totalEmployeeCount += mappedEmployees.length;

        await fillJobCache(ctx, {
          companyId: company.id,
          queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
          data: mappedEmployees as YupOutputType<typeof EmployeeDataSchema>[],
          validationSchema: EmployeeDataSchema,
        });

        await fillJobCache(ctx, {
          companyId: company.id,
          queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
          data: { deleted, source: companyIntegrationSettings.source },
          validationSchema: PostSyncExternalEmployeesDeletesReportDataSchema,
        });
      },
      { concurrency: 5 }
    );

    logInfo(ctx, "[pre-sync-external-employees] Done fetching external employees", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });

    if (totalEmployeeCount > 0) {
      await ctx.prisma.hrisSync.update({
        where: { id: hrisSync.id },
        data: {
          status: HrisSyncStatus.IN_PROGRESS,
          totalEmployeeCount,
        },
      });

      await sendSyncExternalEmployeesJob(ctx, {
        companyId: company.id,
        hrisSyncId: hrisSync.id,
      });
      return;
    }

    await ctx.prisma.hrisSync.update({
      where: { id: hrisSync.id },
      data: { status: HrisSyncStatus.SUCCESS },
    });
  } catch (error) {
    logError(ctx, "[pre-sync-external-employees] Failed to fetch employees", {
      error,
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });

    await notifyHrisSyncFailedDueToError(ctx, {
      error,
      company,
      hrisSyncId: hrisSync.id,
      hrisSyncCampaignId: hrisSync.campaignId,
      campaignMessageTimestamp: hrisSync.campaign?.messageTimestamp,
    });

    await ctx.prisma.hrisSync.update({
      where: { id: hrisSync.id },
      data: { status: HrisSyncStatus.FAILURE },
    });

    throw error;
  }
};

export const sendPreSyncExternalEmployeesJob = async (
  ctx: AppContext,
  data: PreSyncExternalEmployeesJobDataWithCampaign
) => {
  await cancelOutstandingJobs(ctx, { companyId: data.companyId });

  const hrisSync = await ctx.prisma.hrisSync.create({
    data: {
      status: HrisSyncStatus.CREATED,
      totalEmployeeCount: 0,
      processedEmployeeCount: 0,
      company: { connect: { id: data.companyId } },
      ...(data.hrisSyncCampaignId && { campaign: { connect: { id: data.hrisSyncCampaignId } } }),
    },
  });

  const job = await sendJob(ctx, {
    jobName: QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES,
    data: { ...data, hrisSyncId: hrisSync.id },
    options: {
      expireInMinutes: 10,
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES },
      }),
    },
  });

  if (job) {
    await ctx.prisma.hrisSync.update({
      where: { id: hrisSync.id },
      data: {
        queueJobIds: { push: job.id },
      },
    });
  }
};

const cancelOutstandingJobs = async (ctx: AppContext, params: { companyId: number }) => {
  const SYNC_EXTERNAL_EMPLOYEES_JOB_NAMES = [
    QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES,
    QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
    QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
  ];

  const outstandingJobs = await ctx.prisma.queueJob.findMany({
    where: {
      name: { in: SYNC_EXTERNAL_EMPLOYEES_JOB_NAMES },
      companyId: params.companyId,
      status: { in: [QueueJobStatus.SCHEDULED, QueueJobStatus.IN_PROGRESS] },
    },
    select: { id: true, name: true },
  });

  return Promise.all([
    ctx.prisma.queueJobCache.deleteMany({
      where: {
        companyId: params.companyId,
        jobName: { in: SYNC_EXTERNAL_EMPLOYEES_JOB_NAMES },
      },
    }),

    mapSeries(outstandingJobs, async (outstandingJob) => {
      try {
        logInfo(ctx, "[pre-sync-external-employees] Cancellling outstanding job", {
          name: outstandingJob.name,
          jobId: outstandingJob.id,
          companyId: params.companyId,
        });

        await cancelJobQueueJob(ctx, { jobId: outstandingJob.id });
      } catch (error) {
        logError(ctx, "[pre-sync-external-employees] Cannot cancel outstanding job", {
          error,
          name: outstandingJob.name,
          jobId: outstandingJob.id,
          companyId: params.companyId,
        });

        return ctx.prisma.queueJob.update({
          where: { id: outstandingJob.id },
          data: { status: QueueJobStatus.ABORTED },
        });
      }
    }),
  ]);
};
