import { type Prisma, type SalaryGrid, SalaryGridMeasure } from "@prisma/client";
import { mapSeries } from "bluebird";
import { randomUUID } from "crypto";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { trackSalaryBandCreated } from "~/lib/external/segment/server/events";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type CreateNewSalaryBandsInput } from "~/pages/api/salary-bands/create-new-salary-bands";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { auditLogCreateBand } from "~/services/salary-bands/audit-logs/createBand";
import { computeMarketDataForBenchmarks } from "~/services/salary-bands/benchmark/computeMarketDataForBenchmarks";
import { interpolateMissingSalaryRangeTargets } from "~/services/salary-bands/creation/interpolateMissingSalaryRangeTargets";
import {
  approximateTargetPercentileRankFromJobFamilies,
  type CompanyWithTargetPercentiles,
  selectCompanyTargetPercentiles,
} from "~/services/salary-bands/creation/targetPercentilesHelpers";
import { computeSalaryRangeBounds } from "~/services/salary-bands/helpers/computeSalaryRangeBounds";
import { getSalaryBandCurrencyIdFromLocation } from "~/services/salary-bands/helpers/getSalaryBandCurrencyIdFromLocation";
import { sendSyncSalaryRangeEmployeesJob } from "~/workers/synchroniseSalaryRangeEmployees";

export const DEFAULT_INITIAL_MIDPOINT = 50_000_00;

export const selectCreatedSalaryBand = {
  id: true,
  gridId: true,
  grid: { select: { width: true } },
  job: { select: { id: true, name: true } },
  location: { select: { id: true, name: true } },
  currency: true,
  benchmarkedJobs: {
    select: {
      job: { select: { id: true, familyId: true, name: true } },
    },
  },
  benchmarkedLocations: {
    select: {
      location: {
        select: { id: true, name: true },
      },
    },
  },
  measure: true,
  marketPositioning: true,
} satisfies Prisma.SalaryBandSelect;

export const selectCreatedSalaryRange = {
  id: true,
  midpoint: true,
};

type CreatedSalaryBand = Prisma.SalaryBandGetPayload<{ select: typeof selectCreatedSalaryBand }>;

export const selectSalaryBandLevelForRangeCreation = {
  id: true,
  benchmarkedLevels: {
    select: { level: true, benchmarkLevelId: true },
    orderBy: { level: "asc" },
  },
} satisfies Prisma.SalaryBandLevelSelect;

export type SalaryBandLevelForRangeCreation = Prisma.SalaryBandLevelGetPayload<{
  select: typeof selectSalaryBandLevelForRangeCreation;
}>;

export const selectSalaryBandLocation = {
  id: true,
  mappedLocations: {
    select: {
      location: {
        select: {
          country: {
            select: {
              defaultCurrencyId: true,
            },
          },
        },
      },
      externalLocation: {
        select: {
          mappedLocation: {
            select: {
              country: {
                select: {
                  defaultCurrencyId: true,
                },
              },
            },
          },
          country: {
            select: {
              defaultCurrencyId: true,
            },
          },
        },
      },
    },
  },
} satisfies Prisma.SalaryBandLocationSelect;

export type SalaryBandLocationForCreate = Prisma.SalaryBandLocationGetPayload<{
  select: typeof selectSalaryBandLocation;
}>;

export const createNewSalaryBands = async (ctx: AppContext, input: CreateNewSalaryBandsInput) => {
  const user = getRequiredUser(ctx);

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: user.companyId },
    select: selectCompanyTargetPercentiles,
  });

  const salaryGrid = await ctx.prisma.salaryGrid.findFirstOrThrow({
    where: whereSalaryGridIs(input),
    select: {
      id: true,
      width: true,
      measure: true,
    },
  });

  const salaryBandLevels = await ctx.prisma.salaryBandLevel.findMany({
    where: {
      grid: whereSalaryGridIs(input),
    },
    orderBy: { position: "asc" },
    select: selectSalaryBandLevelForRangeCreation,
  });

  const salaryBandJobs = await ctx.prisma.salaryBandJob.findMany({
    where: {
      id: { in: input.salaryBandJobIds },
      grid: whereSalaryGridIs(input),
    },
    select: { id: true },
  });

  const salaryBandLocation = await ctx.prisma.salaryBandLocation.findFirstOrThrow({
    where: {
      id: input.salaryBandLocationId,
      grid: whereSalaryGridIs(input),
    },
    select: selectSalaryBandLocation,
  });

  const salaryBandIds = await mapSeries(salaryBandJobs, async (salaryBandJob) => {
    const existingSalaryBand = await ctx.prisma.salaryBand.findFirst({
      where: {
        gridId: salaryGrid.id,
        jobId: salaryBandJob.id,
        locationId: salaryBandLocation.id,
      },
    });

    if (existingSalaryBand) {
      return existingSalaryBand.id;
    }

    const salaryBandMarketPositioning = company.marketPositioning
      ? await ctx.prisma.salaryBandMarketPositioning.create({
          data: {
            industryId: company.marketPositioning.industryId,
            headcount: company.marketPositioning.headcount,
            minHeadcount: company.marketPositioning.minHeadcount,
            maxHeadcount: company.marketPositioning.maxHeadcount,
            fundingRounds: company.marketPositioning.fundingRounds,
            type: company.marketPositioning.type,
          },
        })
      : null;

    const currencyId = await getSalaryBandCurrencyIdFromLocation(ctx, salaryBandLocation);

    const salaryBand = await ctx.prisma.salaryBand.create({
      data: {
        gridId: salaryGrid.id,
        jobId: salaryBandJob.id,
        locationId: salaryBandLocation.id,
        currencyId,
        isDraft: true,
        measure: input.measure ?? salaryGrid.measure,
        marketPositioningId: salaryBandMarketPositioning?.id ?? null,
        ...(salaryBandJobs.length === 1 && {
          benchmarkedJobs: {
            createMany: {
              data: input.benchmark.jobIds.map((jobId) => ({ gridId: salaryGrid.id, jobId })),
            },
          },
          benchmarkedLocations: {
            createMany: {
              data: input.benchmark.locationIds.map((locationId) => ({ gridId: salaryGrid.id, locationId })),
            },
          },
        }),
      },
      select: selectCreatedSalaryBand,
    });

    const salaryRanges = await createSalaryRanges(ctx, {
      salaryGrid,
      salaryBand,
      salaryBandLevels,
      company,
      measure: salaryBand.measure,
    });

    const actionId = randomUUID();

    await auditLogCreateBand(ctx, salaryBand, salaryRanges, { actionId });

    await trackSalaryBandCreated(ctx, { salaryGridId: salaryGrid.id, salaryBandId: salaryBand.id });

    return salaryBand.id;
  });

  await sendSyncSalaryRangeEmployeesJob(ctx, { salaryGridId: input.salaryGridId, companyId: user.companyId });

  return salaryBandIds;
};

export const createSalaryRanges = async (
  ctx: AppContext,
  params: {
    salaryGrid: Pick<SalaryGrid, "id" | "width">;
    company: CompanyWithTargetPercentiles;
    salaryBand: CreatedSalaryBand;
    salaryBandLevels: SalaryBandLevelForRangeCreation[];
    measure: SalaryGridMeasure;
  }
) => {
  const salaryRangesMarketData = await mapSeries(params.salaryBandLevels, async (salaryBandLevel) =>
    computeMarketDataForRange(ctx, {
      company: params.company,
      salaryBand: params.salaryBand,
      salaryBandLevel,
    })
  );

  const interpolatedSalaryRangesMarketData = interpolateMissingSalaryRangeTargets(salaryRangesMarketData);

  const salaryRanges = await mapSeries(interpolatedSalaryRangesMarketData, async (salaryRangeMarketData) => {
    const target = match(params.measure)
      .with(SalaryGridMeasure.BASE_SALARY, () => salaryRangeMarketData?.baseSalary.target)
      .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => salaryRangeMarketData?.onTargetEarnings.target)
      .exhaustive();

    const targetOrDefault = Math.round(target ?? DEFAULT_INITIAL_MIDPOINT);

    const { min, max } = computeSalaryRangeBounds({
      midpoint: targetOrDefault,
      width: params.salaryGrid.width,
    });

    const commonUpdatePayload = {
      inferredPercentileRank: salaryRangeMarketData.hasMarketData ? salaryRangeMarketData.targetPercentileRank : null,

      baseSalaryP10: salaryRangeMarketData.baseSalary.p10 ?? null,
      baseSalaryP25: salaryRangeMarketData.baseSalary.p25 ?? null,
      baseSalaryP50: salaryRangeMarketData.baseSalary.p50 ?? null,
      baseSalaryP75: salaryRangeMarketData.baseSalary.p75 ?? null,
      baseSalaryP90: salaryRangeMarketData.baseSalary.p90 ?? null,
      baseSalaryDataQuality: salaryRangeMarketData.baseSalary.dataQuality ?? null,

      onTargetEarningsP10: salaryRangeMarketData.onTargetEarnings.p10 ?? null,
      onTargetEarningsP25: salaryRangeMarketData.onTargetEarnings.p25 ?? null,
      onTargetEarningsP50: salaryRangeMarketData.onTargetEarnings.p50 ?? null,
      onTargetEarningsP75: salaryRangeMarketData.onTargetEarnings.p75 ?? null,
      onTargetEarningsP90: salaryRangeMarketData.onTargetEarnings.p90 ?? null,
      onTargetEarningsDataQuality: salaryRangeMarketData.onTargetEarnings.dataQuality ?? null,

      distinctCompaniesCount: salaryRangeMarketData.distinctCompaniesCount ?? null,
      employeesCount: salaryRangeMarketData.employeesCount ?? null,
    };

    const salaryRange = await ctx.prisma.salaryRange.findUnique({
      where: { bandId_levelId: { bandId: params.salaryBand.id, levelId: salaryRangeMarketData.salaryBandLevel.id } },
      select: { id: true },
    });

    if (!salaryRange) {
      return ctx.prisma.salaryRange.create({
        data: {
          bandId: params.salaryBand.id,
          levelId: salaryRangeMarketData.salaryBandLevel.id,
          midpoint: targetOrDefault,
          min,
          max,
          createdWithoutMarketData: !salaryRangeMarketData.hasMarketData,
          ...commonUpdatePayload,
        },
        select: selectCreatedSalaryRange,
      });
    }

    return ctx.prisma.salaryRange.update({
      where: { id: salaryRange.id },
      data: commonUpdatePayload,
      select: selectCreatedSalaryRange,
    });
  });

  return salaryRanges;
};

export const computeMarketDataForRange = async (
  ctx: AppContext,
  params: {
    company: CompanyWithTargetPercentiles;
    salaryBand: CreatedSalaryBand;
    salaryBandLevel: SalaryBandLevelForRangeCreation;
  }
) => {
  const targetPercentileRank = approximateTargetPercentileRankFromJobFamilies({
    company: params.company,
    jobFamilyIds: params.salaryBand.benchmarkedJobs.map((benchmarkedJob) => benchmarkedJob.job.familyId),
  });

  const marketData = await computeMarketDataForBenchmarks(ctx, {
    currency: params.salaryBand.currency,
    percentileRank: targetPercentileRank,
    benchmark: {
      jobIds: params.salaryBand.benchmarkedJobs.map((benchmarkedJob) => benchmarkedJob.job.id),
      locationIds: params.salaryBand.benchmarkedLocations.map((benchmarkedLocation) => benchmarkedLocation.location.id),
      levels: params.salaryBandLevel.benchmarkedLevels.map((benchmarkedLevel) => benchmarkedLevel.level),
      benchmarkLevelIds: params.salaryBandLevel.benchmarkedLevels.map(
        (benchmarkedLevel) => benchmarkedLevel.benchmarkLevelId ?? 0
      ),
      marketPositioning: params.salaryBand.marketPositioning,
    },
    measure: params.salaryBand.measure,
  });

  return {
    salaryBandLevel: params.salaryBandLevel,
    targetPercentileRank,
    hasMarketData: !!marketData,
    ...marketData,
  };
};
