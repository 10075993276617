import { number, object, string } from "yup";
import { decryptFromStorage, encryptForStorage } from "~/lib/crypto";
import { isEmpty, isNil } from "~/lib/lodash";
import type { YupOutputType } from "~/lib/utils";

export const InvitationTokenSchema = object({
  companyId: number().required(),
  companyName: string().required(),
});

export type InvitationToken = YupOutputType<typeof InvitationTokenSchema>;

export const makeInvitationToken = (payload: InvitationToken) => {
  return encryptForStorage(JSON.stringify(payload));
};

export const parseInvitationToken = (token: string | null | undefined) => {
  if (isNil(token)) {
    return null;
  }

  if (isEmpty(token)) {
    return null;
  }

  const tmpToken = JSON.parse(decryptFromStorage(token));
  return InvitationTokenSchema.validateSync(tmpToken, { abortEarly: false });
};
