import { value } from "~/components/helpers";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { compact, isEmpty, isNil, isString } from "~/lib/lodash";
import { QueueJobName } from "~/lib/queue/queueJobName";

export const makeSingletonKey = <JobName extends QueueJobName>(params: {
  for: { jobName: JobName; companyId: SingeltonKeyPart };
  with?: SingletonKeyPartsForJobAsDict<JobName> & { companyId?: never };
  ignoreUniqueness?: true;
}) => {
  if (isString(params.for.companyId) && isEmpty(params.for.companyId)) {
    throw new BusinessLogicError("A companyId is required to make a singleton key");
  }

  // This guarantees we'll always get the same order of keys
  const valuesSortedByKeys = Object.entries(params.with ?? {})
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([, value]) => value);

  const uniqueness = value(() => {
    if (params.ignoreUniqueness) {
      return null;
    }

    const isUnique = QUEUE_JOB_CONFIG_MAP[params.for.jobName].unique;

    if (!isUnique) {
      return null;
    }

    return Date.now();
  });

  // If you update this piece, make sure to check that the frontend can
  // actually still fetch the job queue status through useJobQueue
  return compact([...valuesSortedByKeys, makeCompanyIdKey(params.for.companyId), uniqueness]).join(
    SINGLETON_KEY_SEPARATOR
  );
};

export const parseSingletonkey = <JobName extends QueueJobName>(params: {
  key: string;
  jobName: JobName;
  companyId: number;
}) => {
  const singletonKeyParts = params.key.split(SINGLETON_KEY_SEPARATOR);
  const jobConfig = QUEUE_JOB_CONFIG_MAP[params.jobName];
  const parts = jobConfig.parts as SingletonKeyPartsForJobAsList<JobName>;
  const companyIdKey = makeCompanyIdKey(params.companyId);

  if (jobConfig.unique) {
    singletonKeyParts.pop();
  }

  return singletonKeyParts
    .filter((part) => part !== companyIdKey)
    .reduce(
      (acc, part, index) => {
        const keyPart = parts[index];

        if (isNil(keyPart)) {
          return acc;
        }

        acc[keyPart as keyof typeof acc] = part;
        return acc;
      },
      {} as Record<SingletonKeyPartsForJobAsList<JobName>[number], string>
    );
};

const SINGLETON_KEY_SEPARATOR = "-";

const makeCompanyIdKey = (companyId: SingeltonKeyPart) => `#${companyId}`;

const QUEUE_JOB_CONFIG_MAP = {
  [QueueJobName.CREATE_SALARY_BANDS]: { unique: false, parts: ["salaryGridId"] },
  [QueueJobName.SYNC_EXTERNAL_EMPLOYEES_FOR_COMPANY]: { unique: false, parts: [] },
  [QueueJobName.SYNC_SALARY_RANGE_EMPLOYEES]: { unique: false, parts: ["salaryGridId"] },
  [QueueJobName.CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES]: { unique: false, parts: ["campaignId"] },
  [QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES]: { unique: true, parts: [] },
  [QueueJobName.SYNC_EXTERNAL_EMPLOYEES]: { unique: true, parts: [] },
  [QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES]: { unique: true, parts: [] },
  [QueueJobName.UPDATE_SALARY_RANGE_SETTINGS]: { unique: false, parts: ["salaryGridId"] },
  [QueueJobName.DUPLICATE_SALARY_GRID_FOR_NEW_VERSION]: { unique: false, parts: ["salaryGridId"] },
  [QueueJobName.IMPORT_SPREADSHEET]: { unique: false, parts: ["importId"] },
  [QueueJobName.IMPORT_SPREADSHEET_ROW]: { unique: false, parts: ["importId", "rowIds"] },
  [QueueJobName.POST_IMPORT_SPREADSHEET]: { unique: false, parts: ["importId"] },
  [QueueJobName.GENERATE_SPREADSHEET]: { unique: false, parts: ["googleSheetId"] },
  [QueueJobName.UPDATE_COMPANY_STATS]: { unique: false, parts: [] },
  [QueueJobName.UPDATE_COMPANY_EMPLOYEES_STATS]: { unique: false, parts: [] },
  [QueueJobName.UPDATE_EMPLOYEES_STATS]: { unique: true, parts: [] },
  [QueueJobName.UPDATE_LOCATIONS_STATS]: { unique: true, parts: ["indexToUpdate"] },
  [QueueJobName.SUGGEST_JOBS_MAPPING]: { unique: false, parts: [] },
  [QueueJobName.SYNC_DATASET_EMPLOYEES]: { unique: true, parts: [] },
  [QueueJobName.START_COMPENSATION_REVIEW_CAMPAIGN_SANDBOX]: { unique: false, parts: ["campaignId"] },
  [QueueJobName.DELETE_COMPENSATION_REVIEW_CAMPAIGN]: { unique: false, parts: ["campaignId"] },
  [QueueJobName.REFRESH_CAMPAIGN_CONVERTED_AMOUNTS]: { unique: false, parts: ["campaignId"] },
  [QueueJobName.APPLY_CAMPAIGN_RECOMMENDATION_DISTRIBUTION]: { unique: false, parts: ["campaignId"] },
  [QueueJobName.REFRESH_CAMPAIGN_EMPLOYEES_REVIEWERS]: { unique: false, parts: ["campaignId"] },
  [QueueJobName.UPDATE_CAMPAIGN_BUDGETS]: { unique: false, parts: ["campaignId"] },
  [QueueJobName.START_COMPENSATION_REVIEW_CAMPAIGN]: { unique: false, parts: ["campaignId"] },
} as const satisfies Record<QueueJobName, { unique: boolean; parts: string[] }>;

// Sorting all parts alphabetically once at bootstrap to match later on
Object.keys(QUEUE_JOB_CONFIG_MAP).forEach((jobName) => {
  QUEUE_JOB_CONFIG_MAP[jobName as QueueJobName].parts.sort((a, b) => a.localeCompare(b));
});

type SingeltonKeyPart = string | number;

type SingletonKeyPartsForJobAsDict<Job extends QueueJobName> = {
  [K in (typeof QUEUE_JOB_CONFIG_MAP)[Job]["parts"][number]]: SingeltonKeyPart;
};

type SingletonKeyPartsForJobAsList<Job extends QueueJobName> = (typeof QUEUE_JOB_CONFIG_MAP)[Job]["parts"];
