import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logInfo } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class UserNotFoundError extends FiguresBaseError {
  constructor(
    public readonly origin: string,
    public readonly userId: number
  ) {
    super("User not found");
    Object.setPrototypeOf(this, UserNotFoundError.prototype);
  }
}

export const handleUserNotFoundError = (ctx: AppContext, res: NextApiResponse, error: UserNotFoundError) => {
  logInfo(ctx, "[error] User not found", { error, userId: error.userId, origin: error.origin });

  res.status(HttpStatus.BAD_REQUEST).json(makeErrorPayload({ error, defaultCode: ErrorCode.USER_NOT_FOUND }));
};
