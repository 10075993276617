import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
export type CompanyAdditionalFields = AsyncReturnType<typeof fetchCompanyAdditionalFields>;

export const fetchCompanyAdditionalFields = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.additionalField.findMany({
    where: {
      companyId: user.companyId,
    },
    select: {
      id: true,
      name: true,
      nature: true,
    },
  });
};
