import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { refreshConvertedAmounts } from "~/services/compensation-review/campaigns/admin/refreshConvertedAmounts";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

const RefreshCampaignConvertedAmountsJobDataSchema = BaseJobDataSchema.concat(
  object({
    campaignId: number().required(),
  })
);

export type RefreshCampaignConvertedAmountsJobData = YupOutputType<typeof RefreshCampaignConvertedAmountsJobDataSchema>;

export const refreshCampaignConvertedAmountsWorkerService = async (
  ctx: AppContext,
  data: RefreshCampaignConvertedAmountsJobData
) => {
  const { campaignId } = RefreshCampaignConvertedAmountsJobDataSchema.validateSync(data, { abortEarly: false });

  const campaignContext = await fetchCompensationReviewCampaignContext(ctx, { campaignId });

  await refreshConvertedAmounts(campaignContext);
};

export const sendRefreshCampaignConvertedAmountsJob = async (
  ctx: AppContext,
  data: RefreshCampaignConvertedAmountsJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.REFRESH_CAMPAIGN_CONVERTED_AMOUNTS,
    data,
    options: {
      singletonKey: makeSingletonKey({
        for: { companyId: data.companyId, jobName: QueueJobName.REFRESH_CAMPAIGN_CONVERTED_AMOUNTS },
        with: { campaignId: data.campaignId },
      }),
    },
  });
};
