import { CompensationReviewConfigurationStep } from "@prisma/client";
import { mapSeries } from "bluebird";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { compensationReviewEmployeeSelectForReviewersChain } from "~/services/compensation-review/campaigns/admin/compensationReviewEmployeeSelectForReviewers";
import { highlightConfigurationStep } from "~/services/compensation-review/campaigns/admin/highlightConfigurationStep";
import {
  isCompensationReviewCampaignContext,
  type CompensationReviewContext,
} from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { fetchExternalEmployeeManagers } from "~/services/compensation-review/off-cycle-reviews/fetchExternalEmployeeManagers";

export const findOrCreateCompensationReviewReviewer = async (
  ctx: CompensationReviewContext,
  params: { externalEmployeeId: number; highlightConfigurationStep?: boolean }
) => {
  const user = getRequiredUser(ctx);

  const existingReviewer = await ctx.prisma.compensationReviewReviewer.findFirst({
    where: {
      companyId: user.companyId,
      externalEmployeeId: params.externalEmployeeId,
      ...prismaCompensationReviewScope(ctx.scope),
    },
    select: compensationReviewEmployeeSelectForReviewersChain,
  });

  if (existingReviewer) return existingReviewer;

  const reviewer = await ctx.prisma.compensationReviewReviewer.create({
    data: {
      companyId: user.companyId,
      ...prismaCompensationReviewScope(ctx.scope),
      externalEmployeeId: params.externalEmployeeId,
      canSeeAnalytics: false,
      canUpdatePromotions: true,
      canUpdatePerformanceRatings: false,
    },
    select: compensationReviewEmployeeSelectForReviewersChain,
  });

  if (isCompensationReviewCampaignContext(ctx) && params.highlightConfigurationStep) {
    await highlightConfigurationStep(ctx, CompensationReviewConfigurationStep.USERS_INVITATION);
  }

  await mapSeries(ctx.parameters.budgets, async (budget) => {
    await ctx.prisma.compensationReviewReviewerBudgetPermission.create({
      data: {
        ...prismaCompensationReviewScope(ctx.scope),
        budgetId: budget.id,
        reviewerId: reviewer.id,
        canView: true,
        canAllocate: true,
      },
    });
  });

  return reviewer;
};

export const findOrCreateCompensationReviewReviewers = async (
  ctx: CompensationReviewContext,
  params: { externalEmployeeId: number; highlightConfigurationStep?: boolean }
) => {
  const { managers } = await fetchExternalEmployeeManagers(ctx, {
    externalEmployeeId: params.externalEmployeeId,
    scope: ctx.scope,
  });

  return mapSeries(managers, async (manager) => {
    return findOrCreateCompensationReviewReviewer(ctx, {
      externalEmployeeId: manager.id,
      highlightConfigurationStep: params.highlightConfigurationStep,
    });
  });
};
