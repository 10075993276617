import { HrisSyncCampaignStatus, HrisSyncStatus, type IntegrationSource } from "@prisma/client";
import { map, mapSeries } from "bluebird";
import { number, object, string } from "yup";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { decryptManyIntegrationSettings } from "~/lib/decryptIntegrationSettings";
import { getStaticModelsForSync } from "~/lib/integration";
import { makeSingletonKey } from "~/lib/jobQueueSingletonKey";
import { chain, values } from "~/lib/lodash";
import { logError, logInfo, logWarn } from "~/lib/logger";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { fillJobCache, jobCacheTransaction } from "~/lib/queue/queueJobCache";
import { QueueJobName } from "~/lib/queue/queueJobName";
import { sendJob } from "~/lib/queue/sendJob";
import { transaction } from "~/lib/transaction";
import { type YupOutputType } from "~/lib/utils";
import { syncExternalEmployee } from "~/services/synchronization/syncExternalEmployee";
import {
  EmployeeDataSchema,
  guessFteFactor,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/syncExternalEmployees";
import {
  PostSyncExternalEmployeesHierarchyDataSchema,
  PostSyncExternalEmployeesUpdatesReportDataSchema,
  sendPostSyncExternalEmployeesJob,
} from "~/workers/postSyncExternalEmployees";

export const SYNC_EXTERNAL_EMPLOYEES_CHUNK_SIZE = 10;
const SYNC_EXTERNAL_EMPLOYEES_MAX_CONCURRENCY = 1;

const SyncExternalEmployeesJobDataSchema = BaseJobDataSchema.concat(
  object({
    hrisSyncId: number().required(),
  })
);

export type SyncExternalEmployeesJobData = YupOutputType<typeof SyncExternalEmployeesJobDataSchema>;

const SyncExternalEmployeesJobDataWithSingletonKeySchema = SyncExternalEmployeesJobDataSchema.concat(
  object({
    singletonKey: string().required(),
  })
);

type SyncExternalEmployeesJobDataWithSingletonKey = YupOutputType<
  typeof SyncExternalEmployeesJobDataWithSingletonKeySchema
>;

export const syncExternalEmployeesWorkerService = async (
  ctx: AppContext,
  data: SyncExternalEmployeesJobDataWithSingletonKey
) => {
  const { singletonKey, companyId, hrisSyncId } = SyncExternalEmployeesJobDataWithSingletonKeySchema.validateSync(
    data,
    {
      abortEarly: false,
    }
  );

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: { defaultCountry: true },
  });

  const companyIntegrationSettings = await value(async () => {
    const settings = await ctx.prisma.integrationSettings.findMany({
      where: { companyId, enabled: true },
      include: integrationSettingsForSyncInclude,
    });

    return decryptManyIntegrationSettings(ctx, settings);
  });

  const hrisSync = await ctx.prisma.hrisSync.findUniqueOrThrow({
    where: { id: hrisSyncId },
    include: { campaign: { include: { hrisSyncs: { select: { id: true } } } } },
  });

  if (hrisSync.campaign?.status === HrisSyncCampaignStatus.ABORTED) {
    logWarn(ctx, "[sync-external-employee] Found an aborted campaign, aborting all syncs", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      hrisSyncCampaignId: hrisSync.campaignId,
    });

    await mapSeries(hrisSync.campaign.hrisSyncs, (sync) =>
      ctx.prisma.hrisSync.update({
        where: { id: sync.id },
        data: { status: HrisSyncStatus.ABORTED },
      })
    );
    return;
  }

  if (hrisSync.status === HrisSyncStatus.ABORTED) {
    logWarn(ctx, "[sync-external-employee] Found an aborted sync, stopping", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });
    return;
  }

  if (hrisSync.status !== HrisSyncStatus.IN_PROGRESS) {
    logWarn(ctx, "[sync-external-employee] Trying to run a sync that is not in progress, aborting", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      hrisSyncStatus: hrisSync.status,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });
    return;
  }

  if (!!hrisSync.campaignId) {
    await ctx.prisma.hrisSyncCampaign.updateMany({
      where: {
        id: hrisSync.campaignId,
        status: HrisSyncCampaignStatus.CREATED,
      },
      data: { status: HrisSyncCampaignStatus.IN_PROGRESS },
    });
  }

  const staticModels = await getStaticModelsForSync(ctx);

  const { remainingLinesCount } = await jobCacheTransaction(
    ctx,
    {
      companyId: company.id,
      queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
      validationSchema: EmployeeDataSchema,
      chunkSize: SYNC_EXTERNAL_EMPLOYEES_CHUNK_SIZE,
    },
    async (ctx, employeesData) => {
      const fteFactor = guessFteFactor(employeesData);

      const managerExternalIds: { source: IntegrationSource; externalEmployeeId: number; managerExternalId: string }[] =
        [];

      const updatesBySource = chain(companyIntegrationSettings)
        .groupBy("source")
        .mapValues((_, key) => ({ source: key as IntegrationSource, created: 0, updated: 0 }))
        .value();

      await map(
        employeesData,
        async (employeeData) => {
          const integrationSettings = companyIntegrationSettings.find(
            (integrationSettings) => integrationSettings.source === employeeData.input.source
          );

          if (!integrationSettings) {
            logError(ctx, "[sync-external-employee] No integration settings found for employee", {
              source: employeeData.input.source,
              companyId: company.id,
              hrisSyncId: hrisSync.id,
              externalId: employeeData.input.externalId,
              ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
            });
            return;
          }

          let syncRes;

          try {
            const { externalEmployee, created: isCreated } = await transaction(
              ctx,
              () =>
                syncExternalEmployee(ctx, employeeData, {
                  company,
                  staticModels,
                  fteFactor,
                  integrationSettings,
                  hrisSyncId,
                }),
              { timeout: 8_000 }
            );

            syncRes = {
              isCreated,
              externalEmployeeId: externalEmployee.id,
            };
          } catch (error) {
            logError(ctx, "[sync-external-employee] Couldn't sync employee", {
              error,
              companyId: company.id,
              hrisSyncId: hrisSync.id,
              employeeNumber: employeeData.input.employeeNumber,
              ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
            });

            await ctx.prisma.hrisSync.update({
              where: { id: hrisSync.id },
              data: {
                failedEmployeeNumbers: { push: `${employeeData.input.employeeNumber}` },
              },
            });
          } finally {
            await ctx.prisma.hrisSync.update({
              where: { id: hrisSync.id },
              data: {
                processedEmployeeCount: { increment: 1 },
              },
            });
          }

          if (syncRes) {
            const { isCreated, externalEmployeeId } = syncRes;
            const updates = updatesBySource[employeeData.input.source];

            if (isCreated) {
              updates && updates.created++;
            } else {
              updates && updates.updated++;
            }

            if (employeeData.managerExternalId) {
              managerExternalIds.push({
                source: integrationSettings.source,
                externalEmployeeId,
                managerExternalId: employeeData.managerExternalId,
              });
            }
          }
        },
        { concurrency: SYNC_EXTERNAL_EMPLOYEES_MAX_CONCURRENCY }
      );

      await fillJobCache(ctx, {
        companyId: company.id,
        queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
        data: values(updatesBySource),
        validationSchema: PostSyncExternalEmployeesUpdatesReportDataSchema,
      });

      if (managerExternalIds.length > 0) {
        await fillJobCache(ctx, {
          companyId: company.id,
          queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
          data: managerExternalIds,
          validationSchema: PostSyncExternalEmployeesHierarchyDataSchema,
        });
      }
    }
  );

  if (remainingLinesCount > 0) {
    logInfo(ctx, "[sync-external-employees] More employees to sync, scheduling another job", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      chunkSize: SYNC_EXTERNAL_EMPLOYEES_CHUNK_SIZE,
      queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
      remainingLinesCount,
      singletonKey,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });

    return sendSyncExternalEmployeesJob(ctx, {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
    });
  }

  if (remainingLinesCount === 0) {
    logInfo(ctx, "[sync-external-employees] No more employees to sync", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
      remainingLinesCount,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });
  }

  return sendPostSyncExternalEmployeesJob(ctx, {
    companyId: company.id,
    hrisSyncId: hrisSync.id,
  });
};

export const sendSyncExternalEmployeesJob = async (ctx: AppContext, data: SyncExternalEmployeesJobData) => {
  const singletonKey = makeSingletonKey({
    for: { companyId: data.companyId, jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES },
  });

  const job = await sendJob(ctx, {
    jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
    data: { ...data, singletonKey },
    options: { singletonKey, expireInMinutes: 10 },
  });

  if (job) {
    await ctx.prisma.hrisSync.update({
      where: { id: data.hrisSyncId },
      data: {
        queueJobIds: { push: job.id },
      },
    });
  }
};
