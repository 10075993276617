import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logWarn } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class ApiValidationError extends FiguresBaseError {
  constructor(
    public readonly error: string,
    public readonly fields: Record<string, string>
  ) {
    super("API Validation error");

    Object.setPrototypeOf(this, ApiValidationError.prototype);
  }
}

export const handleApiValidationError = (ctx: AppContext, res: NextApiResponse, error: ApiValidationError) => {
  logWarn(ctx, "[error] API Validation error", { error });

  res.status(HttpStatus.UNPROCESSABLE_ENTITY).json({
    ...makeErrorPayload({ error, defaultCode: ErrorCode.UNPROCESSABLE_ENTITY }),
    fields: error.fields,
  });
};
