import { type SendOptions as PgBossSendOptions } from "pg-boss";
import { type AppContext } from "~/lib/context";
import { type BaseJobData } from "~/lib/queue/baseJobDataSchema";
import { type QueueJobName } from "~/lib/queue/queueJobName";

export const JobPriority = {
  CRITICAL: 0,
  HIGH: 10,
  MEDIUM: 20,
  LOW: 30,
} as const;
export type JobPriority = (typeof JobPriority)[keyof typeof JobPriority];

export type SendOptions = PgBossSendOptions & { priority?: JobPriority };

export interface QueueDriver {
  start(ctx: AppContext): Promise<void>;

  initWorkers: (ctx: AppContext) => Promise<void>;

  sendJob: <T extends BaseJobData>(
    ctx: AppContext,
    jobName: QueueJobName,
    data: T,
    options: SendOptions
  ) => Promise<string | null>;

  cancelJob(ctx: AppContext, params: { jobName: QueueJobName; jobId: string }): Promise<void>;
}
