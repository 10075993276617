import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import { type AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logError } from "~/lib/logger";
import { SignInError } from "~/services/auth/signInError";

export class MissingTransientSignInDataError extends FiguresBaseError {
  constructor() {
    super("Missing transient sign in payload");
    Object.setPrototypeOf(this, MissingTransientSignInDataError.prototype);
  }
}

export const handleMissingTransientSignInDataError = (
  ctx: AppContext,
  res: NextApiResponse,
  error: MissingTransientSignInDataError
) => {
  logError(ctx, "[multi-user-accounts] Failed to sign-in with multi user accounts", { error });

  res.status(HttpStatus.UNAUTHORIZED);
  res.redirect(`/sign-in?error=${SignInError.MULTI_ACCOUNT_FAILURE}`);
};
