import { nanoid } from "nanoid";
import { type FiguresErrorCode } from "~/lib/makeErrorPayload";

export class FiguresBaseError extends Error {
  public readonly errorId = nanoid(16);

  private _metadata: Record<string, unknown> = {};
  private _errorCode?: FiguresErrorCode;

  constructor(public readonly message: string) {
    super(message);

    Object.setPrototypeOf(this, FiguresBaseError.prototype);
  }

  public withErrorCode(errorCode: FiguresErrorCode) {
    this._errorCode = errorCode;
    return this;
  }

  public get errorCode() {
    return this._errorCode;
  }

  public withMetadata(metadata: Record<string, unknown>) {
    this._metadata = { ...this._metadata, ...metadata };
    return this;
  }

  public get metadata() {
    return this._metadata;
  }

  public fromError(error: Error) {
    this.cause = error;

    if (isFiguresBaseError(error)) {
      this.withMetadata({
        causeErrorId: error.errorId,
        causeMetadata: error.metadata,
      });
    }

    return this;
  }
}

export const isFiguresBaseError = (error: unknown): error is FiguresBaseError => error instanceof FiguresBaseError;

export const isFiguresError = (error: unknown, errorCode: FiguresErrorCode) => {
  return isFiguresBaseError(error) && error.errorCode === errorCode;
};
