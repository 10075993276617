import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { FiguresBaseError } from "~/lib/errors/figuresBaseError";
import { logWarn } from "~/lib/logger";
import { ErrorCode, makeErrorPayload } from "~/lib/makeErrorPayload";

export class ApiImpersonationForbiddenError extends FiguresBaseError {
  constructor(
    readonly message: string,
    public readonly impersonatedCompanyId: string | number
  ) {
    super(message);
    Object.setPrototypeOf(this, ApiImpersonationForbiddenError.prototype);
  }
}

export const handleImpersonationForbiddenError = (
  ctx: AppContext,
  res: NextApiResponse,
  error: ApiImpersonationForbiddenError
) => {
  logWarn(ctx, "[error] Impersonation Forbidden", { error });

  res.redirect(`/impersonation-forbidden?impersonatedCompanyId=${error.impersonatedCompanyId}`);

  res.status(HttpStatus.FORBIDDEN).json({
    impersonatedCompanyId: error.impersonatedCompanyId,
    ...makeErrorPayload({ error, defaultCode: ErrorCode.IMPERSONATION_FORBIDDEN }),
  });
};
